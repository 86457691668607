import { Box, Grid } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Provider_Program } from "../../../shared/types/types";
import DisplayBox from "../../../shared/components/DisplayBox";
import ProgramCard from "../../../shared/components/programCard/ProgramCard";
import { SelectedProgramSource } from "../../../shared/types/enums";
import useRecommendedPrograms from "../../hooks/dashboard/useRecommendedPrograms";

type Props = {
  setSelectedProgram: Dispatch<SetStateAction<Provider_Program | null>>;
};

const RecommendedPrograms = ({ setSelectedProgram }: Props) => {
  const { sortedPrograms } = useRecommendedPrograms();

  return (
    <>
      {sortedPrograms.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <DisplayBox title="Staff Recommended Programs" boxPadding={0}>
            <Box
              sx={{
                maxHeight: "80VH",
                overflow: "scroll",
                overflowX: "hidden",
                backgroundColor: "#fafafa",
                margin: 0,
              }}
            >
              <Grid container spacing={2}>
                {sortedPrograms.map((match) => (
                  <Grid key={match.id} item xs={12} sm={12} md={12}>
                    <ProgramCard
                      program={match}
                      source={SelectedProgramSource.Recommended}
                      setSelectedLearnMoreProgram={setSelectedProgram}
                      isEditable={false}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </DisplayBox>
        </Box>
      )}
    </>
  );
};

export default RecommendedPrograms;
