import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { RemoveReason } from "../../types/enums";
import Loading from "../Loading";
import useRemoveMatchDialog from "../../hooks/programCard/useRemoveMatchDialog";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  programId: string;
};

const RemoveMatchDialog = ({ open, setOpen, programId }: Props) => {
  const { loading, reasons, handleChange, saveReason } = useRemoveMatchDialog(programId);

  const handleSave = () => {
    saveReason();
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle variant="h4">Delete Program Confirmation</DialogTitle>
        <DialogContent>
          {!loading ? (
            <>
              <DialogContentText variant="h6">
                Why didn't you like this recommendation? Once this program is discarded, you won't
                see it again.
              </DialogContentText>
              <Box>
                <FormGroup>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={reasons[RemoveReason.Too_Long]}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        name={RemoveReason.Too_Long}
                      />
                    }
                    label={RemoveReason.Too_Long}
                  />
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={reasons[RemoveReason.Too_Expensive]}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        name={RemoveReason.Too_Expensive}
                      />
                    }
                    label={RemoveReason.Too_Expensive}
                  />
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={reasons[RemoveReason.To_Far]}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        name={RemoveReason.To_Far}
                      />
                    }
                    label={RemoveReason.To_Far}
                  />
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={reasons[RemoveReason.Not_Interested]}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        name={RemoveReason.Not_Interested}
                      />
                    }
                    label={RemoveReason.Not_Interested}
                  />
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={reasons[RemoveReason.Admissions]}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        name={RemoveReason.Admissions}
                      />
                    }
                    label={RemoveReason.Admissions}
                  />
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={reasons[RemoveReason.Better_Reputation]}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        name={RemoveReason.Better_Reputation}
                      />
                    }
                    label={RemoveReason.Better_Reputation}
                  />
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={reasons[RemoveReason.Other]}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        name={RemoveReason.Other}
                      />
                    }
                    label={RemoveReason.Other}
                  />
                </FormGroup>
              </Box>
            </>
          ) : (
            <Loading size={80} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={handleSave}
            color="error"
            disabled={!Object.values(reasons).some((value) => value)}
          >
            Remove this Program
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveMatchDialog;
