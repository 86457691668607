import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  allStudentApplicationDataAtom,
  loggedInStaffAtom,
  selectedStudentAtom,
} from "../../../shared/recoil/userAtoms";
import {
  allMatchedProgramsAtom,
  allWriteInProgramsAtom,
  programsAtom,
  studentWriteInProgramsAtom,
} from "../../../shared/recoil/providerAtoms";
import { Box, Button } from "@mui/material";
import ApplicationCard from "./ApplicationCard";
import WriteInDialog from "./WriteInDialog";
import WriteInCard from "./WriteInCard";
import { Provider_Program, WriteInProgramRecord } from "../../../shared/types/types";

type Props = {
  setSelectedProgram: Dispatch<SetStateAction<Provider_Program | null>>;
};

const ApplicationTracker = ({ setSelectedProgram }: Props) => {
  //if a program isn't recommended to a student, but they have one or more they want to apply to, they can manually add them
  const [writeInOpen, setWriteInOpen] = useState(false);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const studentWriteInPrograms = useRecoilValue(studentWriteInProgramsAtom);
  const allWriteInPrograms = useRecoilValue(allWriteInProgramsAtom);
  const [writeInPrograms, setWriteInPrograms] = useState<WriteInProgramRecord[]>([]);
  const [allApplicationData, setAppApplicationData] = useRecoilState(allStudentApplicationDataAtom);
  const programs = useRecoilValue(programsAtom);
  const allMatchedPrograms = useRecoilValue(allMatchedProgramsAtom);
  const [favoritedPrograms, setFavoritedPrograms] = useState<Provider_Program[]>([]);

  useEffect(() => {
    const allPrograms = loggedInStaff ? allMatchedPrograms : programs;
    const studentFavoritePrograms = allPrograms.filter((program) =>
      selectedStudent?.favoriteProviderIds.includes(program.id)
    );
    const studentFavoriteRecommendedPrograms = programs.filter((program) =>
      selectedStudent?.favoriteRecommendedProgramIds.includes(program.id)
    );
    setFavoritedPrograms([...studentFavoritePrograms, ...studentFavoriteRecommendedPrograms]);
  }, [
    allMatchedPrograms,
    loggedInStaff,
    programs,
    selectedStudent?.favoriteProviderIds,
    selectedStudent?.favoriteRecommendedProgramIds,
  ]);

  const handleManualOpen = () => {
    setWriteInOpen(true);
  };

  useEffect(() => {
    if (loggedInStaff) {
      const filteredWriteInPrograms = allWriteInPrograms.filter(
        (writeInProgram) => writeInProgram.studentId === selectedStudent?.id
      );
      setWriteInPrograms(filteredWriteInPrograms);
    } else {
      setWriteInPrograms(studentWriteInPrograms);
    }
  }, [allWriteInPrograms, loggedInStaff, selectedStudent?.id, studentWriteInPrograms]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={handleManualOpen} sx={{ mt: 1 }} variant="outlined">
          Manually Add A Program
        </Button>
      </Box>
      <Box>
        {writeInPrograms.map((writeInProgram) => (
          <WriteInCard key={writeInProgram.id} writeInProgram={writeInProgram} />
        ))}
      </Box>
      <Box sx={{ mt: 2, p: 0 }}>
        {selectedStudent &&
          favoritedPrograms.map((provider) => (
            <ApplicationCard
              provider={provider}
              key={provider.id}
              applicationData={allApplicationData}
              setApplicationData={setAppApplicationData}
              selectedStudent={selectedStudent}
              setSelectedLearnMoreProgram={setSelectedProgram}
            />
          ))}
      </Box>
      <WriteInDialog open={writeInOpen} setOpen={setWriteInOpen} />
    </>
  );
};

export default ApplicationTracker;
