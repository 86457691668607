import { Button, Box, Divider } from "@mui/material";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "../../recoil/userAtoms";
import { useAuth } from "../../contexts/AuthContext";

type Props = {
  handleClose: () => void;
};

export default function StudentMenuDropdown({ handleClose }: Props) {
  const { logout } = useAuth();
  const userType = useRecoilValue(userTypeAtom);

  return (
    <>
      {userType && (
        <>
          <Box sx={{ padding: 2, textAlign: "center" }}>
            <Divider />
            <Button
              sx={{ mt: 2, p: 2 }}
              variant="outlined"
              fullWidth
              color="primary"
              onClick={() => logout()}
            >
              Logout
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
