import { DataGridPro, GridRowParams } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { Box, Stack } from "@mui/material";
import useProgramTableColumns from "../../hooks/dashboard/useProgramTableColumns";
import { ProviderDashboardProgramRow } from "../../../shared/types/types";
import { useRecoilValue } from "recoil";
import { programRowsAtom } from "../../../shared/recoil/providerDashboardAtoms";
const KEY = process.env.REACT_APP_DATATABLE_KEY ?? "";
LicenseInfo.setLicenseKey(KEY);

type Props = {
  onClickEditProgram: (row: ProviderDashboardProgramRow) => void;
};

const ProgramTable = ({ onClickEditProgram }: Props) => {
  const programRows = useRecoilValue(programRowsAtom);
  const { providerColumns } = useProgramTableColumns();
  return (
    <>
      {programRows && (
        <Box sx={{ height: 700 }} id="app-manager-table">
          <Box
            sx={{
              display: "flex",
              height: "100%",
            }}
          >
            <Box sx={{ flexGrow: 1, maxWidth: "100VW", height: "100%" }}>
              <DataGridPro
                rows={programRows}
                columns={providerColumns}
                className="counselor-table"
                disableColumnMenu
                rowHeight={60}
                disableColumnResize
                onRowClick={(data: GridRowParams) => onClickEditProgram(data.row)}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
                }
                getRowId={(row) => row.id}
                sx={{ fontSize: 12, border: "none", height: "100%" }}
                slots={{
                  noRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      No result found
                    </Stack>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProgramTable;
