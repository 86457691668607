import { Box, Button, CircularProgress, Container, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { questionsAtom } from "../../../shared/recoil/questionAtoms";
import { useRecoilState, useRecoilValue } from "recoil";
import QuestionRouter from "./QuestionRouter";
import { AnswerTypeForm, QuestionRecord } from "../../../shared/types/types";
import { BLANK_ANSWER_TYPE_FORM } from "../../../shared/libraries/blankForms";
import useQuiz from "../../../shared/hooks/useQuiz";
import { studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { Collection, QuestionType } from "../../../shared/types/enums";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../shared/hooks/useWindowDimensions";
import useUpdateMatches from "../../../shared/hooks/useUpdateMatches";
import { ListType, addUserToKlaviyoList } from "../../../shared/utils/klaviyoFunctions";

const TakeQuizContainer = () => {
  const questions = useRecoilValue(questionsAtom);
  const [currentQuestion, setCurrentQuestion] = useState<QuestionRecord | null>(null);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState<AnswerTypeForm>({
    ...BLANK_ANSWER_TYPE_FORM,
  });
  const { submitAnswer, validateAnswer, filterMatrixQuestions, handleUpdateAnswers } = useQuiz();
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [answers, setAnswers] = useRecoilState(studentAnswersAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const [filteredQuestions, setFilteredQuestions] = useState<QuestionRecord[]>([]);
  const navigate = useNavigate();
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();
  const { updateMatchPrograms } = useUpdateMatches();

  useEffect(() => {
    if (questions?.length === 0 || !questions || !answers) return;
    setFilteredQuestions(filterMatrixQuestions({ answers, questions }));
  }, [answers, questions, filterMatrixQuestions]);

  //this only gets trigged on refresh if the user has not completed a quiz or is retaking one
  useEffect(() => {
    if (
      !selectedStudent ||
      filteredQuestions.length === 0 ||
      selectedStudent.quizComplete ||
      !initialLoading
    )
      return;
    setInitialLoading(false);
    const lastQuestion = filteredQuestions.find((q) => q.id === selectedStudent.lastQuestionId);
    if (!lastQuestion) return;
    const index = filteredQuestions.indexOf(lastQuestion);
    const questionId = filteredQuestions[index].id;
    setQuestionIndex(questionId === "anything_else" ? index : index + 1);
  }, [selectedStudent, filteredQuestions, initialLoading]);

  useEffect(() => {
    if (!currentQuestion || !selectedStudent || !answers) return;
    setValidated(
      validateAnswer({
        question: currentQuestion,
        answer,
        answers,
        selectedStudent,
      })
    );
  }, [answer, answers, currentQuestion, selectedStudent, validateAnswer, validated]);

  useEffect(() => {
    if (filteredQuestions.length === 0) return;
    setCurrentQuestion(filteredQuestions[questionIndex]);
  }, [filteredQuestions, questionIndex]);

  const handleChange = async (change: number) => {
    if (!currentQuestion || !selectedStudent || !answers) return;
    const lastQuestion = currentQuestion.id === "anything_else" && change === 1;
    if (currentQuestion.type !== QuestionType.Message) {
      const newAnswer = await submitAnswer({
        question: currentQuestion,
        answer,
        answers,
        selectedStudent,
      });
      if (!newAnswer) return;
      setAnswers(handleUpdateAnswers({ answers, newAnswer }));

      updateDoc({
        col: Collection.Students,
        id: selectedStudent.id,
        data: {
          lastQuestionId: lastQuestion ? 0 : currentQuestion.id,
          quizComplete: lastQuestion,
        },
      });
      if (lastQuestion) {
        setIsLoading(true);
        setSelectedStudent((pV) =>
          !pV ? null : { ...pV, quizComplete: true, lastQuestionId: null }
        );
        const programIds = await updateMatchPrograms({
          selectedStudent: selectedStudent,
          studentAnswers: answers,
          editQuestionId: currentQuestion.id,
          newAnswer: newAnswer as AnswerTypeForm,
        });
        await updateDoc({
          col: Collection.Students,
          id: selectedStudent.id,
          data: { matchedProgramIds: programIds, stageId: "stageTwo" },
        });
        if (selectedStudent.klaviyoProfileId) {
          await addUserToKlaviyoList({
            listType: ListType.CompletedQuizList,
            userId: selectedStudent.klaviyoProfileId,
          });
        }
        setSelectedStudent(
          (pV) => pV && { ...pV, matchedProgramIds: programIds, stageId: "stageTwo" }
        );
        setQuestionIndex(0);
        setValidated(false);
        setIsLoading(false);
        navigate("/congrats");
      }
    }
    setQuestionIndex(questionIndex + change);
    setValidated(false);
  };

  return (
    <Container sx={{ mt: 2 }}>
      {filteredQuestions.length === 0 || isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Paper sx={{ padding: 3, margin: 0 }}>
          <Box
            sx={{
              maxHeight: width < 900 ? "60VH" : "75VH",
              overflow: "scroll",
            }}
          >
            {currentQuestion && (
              <QuestionRouter
                questionIndex={questionIndex}
                question={currentQuestion}
                setAnswer={setAnswer}
                answer={answer}
              />
            )}
          </Box>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {questionIndex > 0 && (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleChange(-1)}
                >
                  Previous
                </Button>
              </Grid>
            )}
            <Grid item xs={questionIndex === 0 ? 12 : 6}>
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress size={30} />
                </Box>
              ) : (
                <Button
                  disabled={currentQuestion?.required && !validated}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleChange(1)}
                >
                  {currentQuestion?.type !== QuestionType.Message &&
                  !currentQuestion?.required &&
                  !validated
                    ? currentQuestion?.id === "anything_else"
                      ? "Skip & Finish"
                      : "Skip"
                    : currentQuestion?.id === "anything_else"
                    ? "Finish"
                    : "Next"}
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      )}
    </Container>
  );
};

export default TakeQuizContainer;
