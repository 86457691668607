import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomAvatar from "../../../shared/components/CustomAvatar";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { Color, PageRoute } from "../../../shared/types/enums";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "../../../shared/components/FeatherIcon";
import { formatPhoneNumber } from "../../../shared/utils/functions";
import EditPersonalDetailsDialog from "./EditPersonalDetailsDialog";

const StudentCard = () => {
  const navigate = useNavigate();
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const [open, setOpen] = useState(false);

  const handlePhoneIconClick = () => {
    if (selectedStudent?.phone) {
      navigate(`${PageRoute.Messages}/${selectedStudent.id}`);
    }
  };

  return (
    <>
      {selectedStudent && (
        <>
          <Box
            sx={{
              backgroundColor: Color.Chalkboard,
              p: 2,
              borderRadius: 3,
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CustomAvatar
                size={100}
                fileName={selectedStudent?.avatar ?? ""}
                collection="students"
                documentId={selectedStudent.id}
                formField="avatarURL"
                edit={true}
                propAvatarURL={selectedStudent.avatarURL}
              />
            </Box>
            <Box>
              <Typography
                sx={{ color: Color.Chalk }}
                variant="h4"
              >{`${selectedStudent.firstName} ${selectedStudent.lastName}`}</Typography>
              <Box sx={{ display: "flex", verticalAlign: "center", color: Color.Chalk, mt: 1 }}>
                <span
                  style={{ marginRight: "8px", cursor: "pointer" }}
                  onClick={handlePhoneIconClick}
                >
                  <FeatherIcon width="20px" icon="smartphone" />
                </span>
                <Typography sx={{ color: Color.Chalk }} variant="body1">
                  {formatPhoneNumber(selectedStudent.phone)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", verticalAlign: "center", color: Color.Chalk, mt: 1 }}>
                <a
                  href={selectedStudent.schoolEmail ? `mailto:${selectedStudent.schoolEmail}` : "#"}
                  style={{ marginRight: "8px", textDecoration: "none", color: "inherit" }}
                >
                  <span>
                    <FeatherIcon width="20px" icon="mail" />
                  </span>
                </a>
                <Typography sx={{ color: Color.Chalk }} variant="body1">
                  {selectedStudent.schoolEmail}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ position: "absolute", right: 10, top: 10 }}>
              <IconButton onClick={() => setOpen(true)} sx={{ color: Color.Chalk }}>
                <FeatherIcon width="20px" icon="edit-2" />
              </IconButton>
            </Box>
          </Box>
          <EditPersonalDetailsDialog open={open} setOpen={setOpen} />
        </>
      )}
    </>
  );
};

export default StudentCard;
