import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Provider } from "../../types/types";
import { SelectChangeEvent } from "@mui/material";

type Props = {
  setForm: Dispatch<SetStateAction<Provider>>;
};

const useEditProviderForm = ({ setForm }: Props) => {
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((pV) => ({ ...pV, [e.target.name]: Number(e.target.value) }));
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    setForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
  };

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm((pV) => ({ ...pV, [e.target.name]: e.target.checked }));
  };
  return {
    handleTextChange,
    handleSwitchChange,
    handleNumberChange,
    handleSelectChange,
  };
};

export default useEditProviderForm;
