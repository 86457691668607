import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "../../shared/recoil/userAtoms";
import Navbar from "../../shared/components/navigation/Navbar";
import { UserType } from "../../shared/types/enums";
import Sidebar from "../../shared/components/navigation/Sidebar";
import { useMentorData } from "../contexts/MentorDataContext";
import DashboardContainer from "../components/dashboard/DashboardContainer";

const MentorDashboard = () => {
  const userType = useRecoilValue(userTypeAtom);
  const [open, setOpen] = useState(false);
  const { loading } = useMentorData();

  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
        <Sidebar open={open} setOpen={setOpen} />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Navbar open={open} title="Mentor Dashboard" />
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "calc(100VH-64px)",
                width: "100%",
                pt: 4,
              }}
            >
              <CircularProgress size={80} />
            </Box>
          ) : (
            <Box sx={{ minHeight: "calc(100VH-64px)", pt: 1, pr: 4, pl: 4 }}>
              {userType && userType >= UserType.MentorUser && <DashboardContainer />}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default MentorDashboard;
