import { useCallback, useEffect, useState } from "react";
import { Provider_Program, ProgramFilters } from "../../types/types";
import { WillowIndustryId } from "../../types/enums";
import { GridPaginationModel } from "@mui/x-data-grid-pro";

const useManageProgramsContainer = () => {
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 200000,
    page: 0,
  });
  const [programs, setPrograms] = useState<Provider_Program[]>([]);
  const [filters, setFilters] = useState<ProgramFilters>({
    online: false,
    states: [],
    city: null,
    providerNameSearchString: "",
  });

  const handleUpdateResults = () => {
    queryDB({
      filters,
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
    });
  };

  const handleCIPUpdate = async () => {
    const results = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/addProgramNames`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        programId: "",
        programName: "",
      }),
    });
    // const data = (await results.json()) as { rows: Program[] };
  };

  const handleUploadProviders = async (filePath: string) => {
    const results = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/uploadProviders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        table: "providers",
        filePath: filePath,
      }),
    });
    // const data = (await results.json()) as { rows: Program[] };
  };

  const handleUploadPrograms = async (filePath: string) => {
    const results = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/uploadPrograms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        table: "programs",
        filePath: filePath,
      }),
    });
    // const data = (await results.json()) as { rows: Program[] };
  };

  type QueryProps = {
    filters: ProgramFilters;
    limit: number;
    offset: number;
  };

  const queryDB = useCallback(async ({ filters, limit, offset }: QueryProps) => {
    setLoading(true);
    const results = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getProgramsOnJoin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        online: false,
        states: filters.states,
        city: null,
        notInterestedIndustries: [WillowIndustryId.Arts],
        notInterestedSubIndustries: [],
        acceptRateCeiling: 0.3,
        limit: limit,
        offset: offset,
        providerNameSearchString: filters.providerNameSearchString,
      }),
    });
    const data = (await results.json()) as { rows: Provider_Program[] };
    setPrograms(data.rows);
    setLoading(false);
  }, []);

  const handlePageChange = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel);
    queryDB({
      filters,
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
    });
  };

  // useEffect(() => {
  //   //runCIPNameFunction();
  //   queryDB({
  //     filters: filters,
  //     limit: paginationModel.pageSize,
  //     offset: paginationModel.pageSize * paginationModel.page,
  //   });
  // }, [queryDB]);

  return {
    programs,
    handleUpdateResults,
    filters,
    setFilters,
    totalRows,
    paginationModel,
    handlePageChange,
    loading,
    handleCIPUpdate,
    handleUploadProviders,
    handleUploadPrograms,
  };
};

export default useManageProgramsContainer;
