import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Provider } from "../../../shared/types/types";
import { loggedInProviderAtom } from "../../../shared/recoil/userAtoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ownProviderAtom } from "../../../shared/recoil/providerAtoms";

type Return = {
  setGetProviderTrigger: Dispatch<SetStateAction<boolean>>;
};

const useProviderDetailCard = (): Return => {
  const loggedInProvider = useRecoilValue(loggedInProviderAtom);
  const setOwnProvider = useSetRecoilState(ownProviderAtom);
  const [getProviderTrigger, setGetProviderTrigger] = useState(false);

  useEffect(() => {
    if (!loggedInProvider) {
      return;
    }

    const getOwnPrograms = async () => {
      const results = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getProvider`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          providerId: loggedInProvider?.providerId,
        }),
      });
      console.log("sdfsdf");
      const { provider } = (await results.json()) as { provider: Provider };

      setOwnProvider(provider ? provider : null);
    };
    getOwnPrograms();
  }, [getProviderTrigger, loggedInProvider, setOwnProvider]);

  return {
    setGetProviderTrigger,
  };
};

export default useProviderDetailCard;
