import React from "react";
import DisplayBox from "../DisplayBox";
import { Box, Typography } from "@mui/material";
import { Provider } from "../../types/types";
import { numberToPercent, numberToRound } from "../../utils/functions";
import { Color } from "../../types/enums";

type Props = {
  provider: Provider;
};

const typeSX = {
  textAlign: "left",
  mb: 3,
  fontSize: 14,
  color: Color.Charcoal,
};

const AtAGlance = ({ provider }: Props) => {
  //data in religionAffiliation is sometimes "null" as a string
  return (
    <DisplayBox title="At a Glance">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 3,
          justifyContent: "space-between",
        }}
      >
        <Typography sx={typeSX}>
          <b>Admission Rate: </b>
          {provider.admissionPercent ? numberToPercent(provider.admissionPercent) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Total Size: </b>
          {provider.undergradTotalEnrollment
            ? numberToRound(provider.undergradTotalEnrollment)
            : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Median ACT: </b>
          {provider.actMid ? numberToRound(provider.actMid) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent Black: </b>
          {provider.percentBlack ? numberToPercent(provider.percentBlack) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent Latinx: </b>
          {provider.percentHispanic ? numberToPercent(provider.percentHispanic) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent Asian: </b>
          {provider.percentAsian ? numberToPercent(provider.percentAsian) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent AIAN: </b>
          {provider.percentAIAN ? numberToPercent(provider.percentAIAN) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent NHPI: </b>
          {provider.percentNHPI ? numberToPercent(provider.percentNHPI) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent Two or More: </b>
          {provider.percentTwoOrMore ? numberToPercent(provider.percentTwoOrMore) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Percent White: </b>
          {provider.percentWhite ? numberToPercent(provider.percentWhite) : "Unknown"}
        </Typography>
        <Typography sx={typeSX}>
          <b>HBCU: </b>
          {provider.hbcu ? "Yes" : "No"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Religious Affiliation: </b>
          {(provider.religiousAffiliation && provider.religiousAffiliation !== "null") ? provider.religiousAffiliation : "None"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Single Gender: </b>
          {provider.menOnly ? "Men Only" : provider.womenOnly ? "Women Only" : "No"}
        </Typography>
        <Typography sx={typeSX}>
          <b>Type: </b>
          {provider.ownership}
        </Typography>
      </Box>
    </DisplayBox>
  );
};

export default AtAGlance;
