import { allEnrolledGroupStudentsAtom } from "../../recoil/userAtoms";
import { useRecoilValue } from "recoil";
import { getProviderTargetLevel } from "../../utils/targetTypeUtils";
import { getAnswers } from "../../utils/dbProviderFunctions";
import { Provider_Program, StudentRecord } from "../../types/types";
import { Collection } from "../../types/enums";
import useUpdateDoc from "../useUpdateDoc";
import useUpdateStudentState from "../useUpdateStudentState";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

type StudentWithLabel = StudentRecord & { label: string };

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  program: Provider_Program;
};

const useRecommendDialog = ({ setOpen, program }: Props) => {
  const allEnrolledGroupStudents = useRecoilValue(allEnrolledGroupStudentsAtom);
  const [studentLabels, setStudentLabels] = useState<StudentWithLabel[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<StudentWithLabel[]>([]);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { updateStudentState } = useUpdateStudentState();

  useEffect(() => {
    setSelectedStudents([]);
    studentLabels.forEach((student) => {
      if (student.staffRecommendedProgramIds.includes(program.id)) {
        setSelectedStudents((pV) => [...pV, student]);
      }
    });
  }, [program.id, studentLabels]);

  /**
   * Updates the student's recommendations with a new program.
   * @param student - The student record to update.
   * @returns A promise that resolves when the update is complete.
   */
  const updateStudentRecommendations = async (student: StudentRecord) => {
    const recommendations = [...(student.staffRecommendedProgramIds ?? [])];
    recommendations.push(program.id);
    updateStudentState({
      key: "staffRecommendedProgramIds",
      value: recommendations,
      studentId: student.id,
    });
    return updateDoc({
      col: Collection.Students,
      id: student.id,
      data: { staffRecommendedProgramIds: recommendations },
    });
  };

  const handleSave = async () => {
    const promises: Promise<string | null>[] = selectedStudents.map(updateStudentRecommendations);
    try {
      await Promise.all(promises);
      setOpen(false);
    } catch (error) {
      console.error("Error updating student recommendations:", error);
    }
  };

  useEffect(() => {
    setStudentLabels([]);
    /**
     * Calculates labels for filtered students based on their quiz responses.
     * @returns {Promise<void>} A promise that resolves when the labels are calculated.
     */
    const calculateLabels = async () => {
      const filteredStudents = allEnrolledGroupStudents.filter((student) => student.quizComplete);
      const newLabels = await Promise.all(
        filteredStudents.map(async (student) => {
          const response = await getAnswers({ studentId: student.id, questionId: "gpa" });
          let label = `${student.firstName} ${student.lastName}`;
          if (response.selectedAnswer) {
            const [numerator, denominator] = response.selectedAnswer.gpa;
            const gpaPercentage = (numerator / denominator);
            const schoolType = await getProviderTargetLevel({
              providerId: program.providerId,
              GPA: gpaPercentage,
              admissionPercent: program.admissionPercent,
            });
            label += ` - ${schoolType}`;
          }
          return {
            ...student,
            label,
          };
        })
      );
      setStudentLabels(newLabels);
    };
    calculateLabels();
  }, [allEnrolledGroupStudents, program]);
  return { studentLabels, selectedStudents, setSelectedStudents, handleSave };
};

export default useRecommendDialog;
