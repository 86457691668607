/**
 * Represents a resource component that displays an article.
 *
 * @component
 * @param {Props} props - The props for the Resource component.
 * @param {Article} props.article - The article object to be displayed.
 * @returns {JSX.Element} The rendered Resource component.
 */
import * as React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { Article } from "../../../shared/types/types";

type Props = {
  article: Article;
};

export default function Resource({ article }: Props) {
  return (
    <>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <Grid item xs={6} sm={3}>
          <img
            src={article.image}
            alt={article.imgText}
            style={{
              width: "100%",
              margin: "auto",
              display: "block",
              objectFit: "cover",
              height: "120px",
              padding: "8px",
            }}
          />
        </Grid>
        <Grid item xs={6} sm={9}>
          <a
            href={article.link}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Typography variant="h5" sx={{ padding: 1 }}>
              {article.title}
            </Typography>
          </a>
          <Typography variant="body1" sx={{ padding: 1 }}>
            {article.description}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
