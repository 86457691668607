import React, { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import { studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import { Box, Chip, Divider, IconButton, Paper, Typography } from "@mui/material";
import { questionsAtom } from "../../../shared/recoil/questionAtoms";
import EditDetailsDialog from "./EditDetailsDialog";
import { loggedInStaffAtom } from "../../../shared/recoil/userAtoms";
import FeatherIcon from "../../../shared/components/FeatherIcon";

const StatsDetails = () => {
  const answers = useRecoilValue(studentAnswersAtom);
  const questions = useRecoilValue(questionsAtom);
  const [editQuestionId, setEditQuestionId] = useState<string | null>(null);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  const formatGPA = () => {
    if (!answers) return;
    const answer = answers.find((answer) => answer.questionId === "gpa")?.gpa ?? [];
    return `${answer[0]} out of ${answer[1]}`;
  };

  const formatSAT = () => {
    if (!answers) return;
    const satAnswer = answers.find((answer) => answer.questionId === "sat")?.number ?? 0;
    return satAnswer > 0 ? satAnswer : "None Provided";
  };

  const formatACT = () => {
    if (!answers) return;
    const actAnswer = answers.find((answer) => answer.questionId === "act")?.number ?? 0;
    return actAnswer > 0 ? actAnswer : "None Provided";
  };

  const handleEditClick = (questionId: string) => {
    setEditQuestionId(questionId);
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
              GPA:
            </Typography>
            <Typography component="span" variant="body1" sx={{ ml: 1 }}>
              {formatGPA()}
            </Typography>
          </Box>
          <IconButton onClick={() => handleEditClick("gpa")}>
            <FeatherIcon width="20px" icon="edit-2" />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
              SAT:
            </Typography>
            <Typography component="span" variant="body1" sx={{ ml: 1 }}>
              {formatSAT()}
            </Typography>
          </Box>
          <IconButton onClick={() => handleEditClick("sat")}>
            <FeatherIcon width="20px" icon="edit-2" />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
              ACT:
            </Typography>
            <Typography component="span" variant="body1" sx={{ ml: 1 }}>
              {formatACT()}
            </Typography>
          </Box>
          <IconButton onClick={() => handleEditClick("act")}>
            <FeatherIcon width="20px" icon="edit-2" />
          </IconButton>
        </Box>
      </Box>
      <EditDetailsDialog editQuestionId={editQuestionId} setEditQuestionId={setEditQuestionId} />
    </>
  );
};

export default StatsDetails;
