import { useRecoilValue } from "recoil";
import { Provider_Program } from "../../types/types";
import { selectedStudentAtom } from "../../recoil/userAtoms";

const useAutoAdmit = () => {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const availableShowMessage = (program: Provider_Program) => {
    if (selectedStudent) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const isAfterJuly1st = currentDate.getMonth() >= 6;

      if (
        !selectedStudent.graduationYear ||
        selectedStudent.graduationYear === currentYear.toString()
      ) {
        return Boolean(program.autoAdmit) && !isAfterJuly1st;
      } else if (
        !selectedStudent.graduationYear ||
        selectedStudent.graduationYear === (currentYear + 1).toString()
      ) {
        return Boolean(program.autoAdmit) && isAfterJuly1st;
      }
    } else {
      return false;
    }
  };
  return { availableShowMessage };
};

export default useAutoAdmit;
