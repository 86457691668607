import React, { useState } from "react";
import Navbar from "../../shared/components/navigation/Navbar";
import { Box } from "@mui/material";
import Sidebar from "../../shared/components/navigation/Sidebar";
import StudentProfileContainer from "../components/profile/StudentProfileContainer";
import useWindowDimensions from "../../shared/hooks/useWindowDimensions";
import BottomNavigationMenu from "../../shared/components/navigation/BottomNavigationMenu";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../shared/recoil/userAtoms";

const Profile = () => {
  const [open, setOpen] = useState(false);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  if (loggedInStaff) {
    navigate("/staff-dashboard");
  }
  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
        {width > 900 && <Sidebar open={open} setOpen={setOpen} />}
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Navbar open={open} title="Student Profile" />
          <Box sx={{ minHeight: "calc(100VH-64px)" }}>
            <StudentProfileContainer />
          </Box>
        </Box>
        {width <= 900 && <BottomNavigationMenu />}
      </Box>
    </>
  );
};

export default Profile;
