import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";
import { Color, PageRoute } from "../../types/enums";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom, userTypeAtom } from "../../recoil/userAtoms";
import FeatherIcon from "../FeatherIcon";
import { Box } from "@mui/material";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const StudentSidebar = ({ open, setOpen }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userType = useRecoilValue(userTypeAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  return (
    <>
      {/* Results */}
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => navigate(PageRoute.Student_Stages)}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color: location.pathname === PageRoute.Student_Stages ? "#1DBC9D" : "#F4F0DC",
            }}
          >
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Stages" sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
      {/* Profile */}
      {selectedStudent?.stageId !== "stageOne" && (
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(PageRoute.Student_Profile)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: location.pathname === PageRoute.Student_Profile ? "#1DBC9D" : "#F4F0DC",
              }}
            >
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      )}
      {/* Search */}
      {userType && userType >= 1 && (
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(PageRoute.Explore_Programs)}
          >
            <Box sx={{ marginRight: open ? 3 : "auto" }}>
              <FeatherIcon
                width="24px"
                fillColor="none"
                icon="search"
                strokeColor={
                  location.pathname === PageRoute.Explore_Programs ? "#1DBC9D" : "#F4F0DC"
                }
              />
            </Box>
            <ListItemText primary="Explore Programs" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      )}
      {/* Alma */}
      {userType && userType >= 1 && (
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(PageRoute.Alma)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: location.pathname === PageRoute.Alma ? Color.Mint : Color.Chalk,
              }}
            >
              <FeatherIcon
                width="24px"
                fillColor="none"
                icon="ai"
                strokeColor={location.pathname === PageRoute.Alma ? Color.Mint : Color.Chalk}
              />
            </ListItemIcon>
            <ListItemText primary="Alma AI Advisory" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      )}
      {/* Messages */}
      {/* <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => navigate(PageRoute.Messages)}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color: location.pathname === PageRoute.Messages ? "#1DBC9D" : "#F4F0DC",
            }}
          >
            <ChatBubbleOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Messages" sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem> */}
    </>
  );
};

export default StudentSidebar;
