import { useRecoilValue } from "recoil";
import { allEnrolledGroupStudentsAtom } from "../../../shared/recoil/userAtoms";
import { stagesAtom } from "../../../shared/recoil/stageAtoms";

type Props = {
  stageId: string;
};

type StageWithStudents = {
  stageId: string;
  numberOfStudents: number;
};

const useStageSummaryBar = ({ stageId }: Props) => {
  const allEnrolledGroupStudents = useRecoilValue(allEnrolledGroupStudentsAtom);
  const stages = useRecoilValue(stagesAtom);
  //filter students by the current stage for this particular bar
  const filteredStudents = allEnrolledGroupStudents.filter(
    (student) => student.stageId === stageId
  );

  const calcHeight = (filteredStudents: number) => {
    const stagesWithStudents: StageWithStudents[] = [];
    stages.forEach((stage) => {
      stagesWithStudents.push({
        stageId: stage.id,
        numberOfStudents: allEnrolledGroupStudents.filter(
          (student) => student.stageId === stage.id
        ).length,
      });
    });
    const max = Math.max(...stagesWithStudents.map((s) => s.numberOfStudents));
    return max === 0 ? 0 : filteredStudents / max;
  };

  const height = calcHeight(filteredStudents.length);

  return { filteredStudents, allEnrolledGroupStudents, height };
};

export default useStageSummaryBar;
