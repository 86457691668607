import React, { useEffect, useState } from "react";
import { Collection } from "../../../shared/types/enums";
import useGetDocs from "../../../shared/hooks/useGetDocs";
import { StaffRecord, Student, StudentRecord } from "../../../shared/types/types";

const ExportsForEmail = () => {
  const { sendRequest: getDocs } = useGetDocs();
  const [staff, setStaff] = useState<StaffRecord[]>([]);
  const [students, setStudents] = useState<StudentRecord[]>([]);
  useEffect(() => {
    const getStaffAndStudents = async () => {
      const staffResults = await getDocs<StaffRecord>({ col: Collection.Staff });
      const studentResults = await getDocs<StudentRecord>({ col: Collection.Students });
      setStaff(staffResults);
      setStudents(studentResults);
    };
    getStaffAndStudents();
  }, [getDocs]);
  return (
    <>
      <table>
        <thead>
          <tr>
            <td>First Name</td>
            <td>Last Name</td>
            <td>Email</td>
            <td>Role</td>
          </tr>
        </thead>
        <tbody>
          {staff.map((staff) => (
            <tr key={staff.id}>
              <td>{staff.firstName}</td>
              <td>{staff.lastName}</td>
              <td>{staff.email}</td>
              <td>Staff</td>
            </tr>
          ))}
          {students.map((student) => (
            <tr key={student.id}>
              <td>{student.firstName}</td>
              <td>{student.lastName}</td>
              <td>{student.schoolEmail ? student.schoolEmail : student.email}</td>
              <td>Student</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ExportsForEmail;
