import React, { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import { questionsAtom } from "../../../shared/recoil/questionAtoms";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { Chip, Typography } from "@mui/material";
import { StudentRecord } from "../../../shared/types/types";

type Return = {
  selectedStudent: StudentRecord | null;
  formatOnline: () => void;
  formatFinancialAid: () => void;
  formatPreferredProgram: () => void;
  formatGeography: () => void;
};

const usePreferencesDetails = (): Return => {
  const answers = useRecoilValue(studentAnswersAtom);
  const questions = useRecoilValue(questionsAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);

  const formatOnline = () => {
    if (!answers) return;
    const answer = answers.find((answer) => answer.questionId === "online")?.mc ?? 0;
    const question = questions?.find((question) => question.id === "online");
    if (!question) return "";
    const answerText = question.options.find((a) => a.id === Number(answer))?.text;
    return <Typography>{answerText ?? ""}</Typography>;
  };

  const formatFinancialAid = useCallback(() => {
    if (!answers) return;
    const financialAidAnswers =
      answers.find((answer) => answer.questionId === "financial_aid")?.ms ?? [];
    const question = questions?.find((question) => question.id === "financial_aid");
    if (!question) return "";
    return question.options.map((option) =>
      financialAidAnswers.includes(option.id) ? (
        <Chip
          sx={{
            ml: 1,
            mb: 1,
            height: "auto",
            "& .MuiChip-label": {
              display: "block",
              whiteSpace: "normal",
              padding: 1,
            },
            mt: 1,
          }}
          color="surfaceGreen"
          key={option.id}
          label={<Typography key={option.id}>{option.text}</Typography>}
        />
      ) : (
        ""
      )
    );
  }, [answers, questions]);

  const formatGeography = useCallback(() => {
    if (!answers) return;
    const geographyAnswers = answers.find((answer) => answer.questionId === "geography")?.ms ?? [];
    const question = questions?.find((question) => question.id === "geography");
    if (!question) return "";
    return geographyAnswers.map((option) => (
      <Chip
        sx={{
          ml: 1,
          mb: 1,
          height: "auto",
          "& .MuiChip-label": {
            display: "block",
            whiteSpace: "normal",
            padding: 1,
          },
          mt: 1,
        }}
        color="surfaceGreen"
        key={option}
        label={<Typography key={option}>{option}</Typography>}
      />
    ));
  }, [answers, questions]);

  const formatPreferredProgram = () => {
    if (!answers) return;
    const answer = answers.find((answer) => answer.questionId === "certification")?.mc ?? 0;
    const question = questions?.find((question) => question.id === "certification");
    if (!question) return "";
    const answerText = question.options.find((a) => a.id === answer)?.text;
    return <Typography>{answerText ?? ""}</Typography>;
  };
  
  return {
    selectedStudent,
    formatOnline,
    formatFinancialAid,
    formatPreferredProgram,
    formatGeography,
  };
}

export default usePreferencesDetails;