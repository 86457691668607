import React from "react";
import { WriteInProgramRecord } from "../../../shared/types/types";
import { Box, Typography } from "@mui/material";
import DisplayBox from "../../../shared/components/DisplayBox";
import { AppStatus } from "../../../shared/types/enums";
import ApplicationProgressBar from "./ApplicationProgressBar";
import ApplicationResults from "./ApplicationResults";
import useWriteInCard from "../../hooks/dashboard/useWriteInCard";

type Props = {
  writeInProgram: WriteInProgramRecord;
};

const WriteInCard = ({ writeInProgram }: Props) => {
  const { status, result, handleResults, handleStatus } = useWriteInCard({ writeInProgram });

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <DisplayBox title={`${writeInProgram.programName} at ${writeInProgram.providerName}`}>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ fontSize: 12 }}>This Program was Manually Added</Typography>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Application Progress</Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <ApplicationProgressBar status={status} />
              </Box>
            </Box>
            {status === AppStatus.Completed && (
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Application Results</Typography>
                <ApplicationResults result={result} />
              </Box>
            )}
          </Box>
        </DisplayBox>
      </Box>
    </>
  );
};

export default WriteInCard;
