import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Color, UserType } from "../../types/enums";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom, selectedStudentAtom, userTypeAtom } from "../../recoil/userAtoms";
import StaffSidebar from "./StaffSidebar";
import StudentSidebar from "./StudentSidebar";
import { Box, IconButton, ListItemText } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import ProviderSidebar from "./ProviderSidebar";
type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const drawerWidth = 221;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  backgroundColor: Color.Chalkboard,
  color: Color.Chalk,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#00362E",
  color: "#F4F0DC",
  width: `calc(${theme.spacing(7)} + 0px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 0px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar({ open, setOpen }: Props) {
  const theme = useTheme();
  const location = useLocation();
  const storage = getStorage();
  const [avatarURL, setAvatarURL] = React.useState<string | null>(null);
  const navigate = useNavigate();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const userType = useRecoilValue(userTypeAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const getImageURL = async () => {
      const fileName = loggedInStaff ? loggedInStaff.avatar : selectedStudent?.avatar ?? "";
      if (fileName.length > 5) {
        let url = "";
        try {
          try {
            url = await getDownloadURL(ref(storage, `${fileName}_200x200`));
          } catch (error) {
            url = await getDownloadURL(ref(storage, `${fileName}`));
          }
        } catch (error) {
          console.error(error);
        }
        setAvatarURL(url);
      }
    };
    getImageURL();
  }, [loggedInStaff, selectedStudent?.avatar, storage]);

  return (
    <>
      <Drawer variant="permanent" open={open} color="primary" sx={{ zIndex: 999 }}>
        <DrawerHeader>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/")}
            >
              <Box sx={{ marginRight: open ? 3 : "auto" }}>
                <img width="24px" src="../../assets/branding/inline-chalk.svg" alt="Chalk Logo" />
              </Box>

              <ListItemText primary="Willow" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            height: "100VH",
          }}
        >
          <ListItem sx={{ display: "block" }}>
            {open ? (
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon
                  sx={{
                    justifyContent: "center",
                    color: Color.Chalk,
                  }}
                />
              </IconButton>
            ) : (
              <ListItemButton
                onClick={handleDrawerOpen}
                sx={{
                  justifyContent: "center",
                }}
              >
                <ChevronRightIcon />
              </ListItemButton>
            )}
          </ListItem>
          <Box id="user-specific-content">
            {userType && userType >= UserType.Staff && userType !== UserType.ProviderUser && userType !== UserType.MentorUser && (
              <StaffSidebar open={open} setOpen={setOpen} />
            )}
            {userType && userType === UserType.Student && (
              <StudentSidebar open={open} setOpen={setOpen} />
            )}
            {userType && userType === UserType.ProviderUser && (
              <ProviderSidebar open={open} setOpen={setOpen} />
            )}
          </Box>
        </List>
      </Drawer>
    </>
  );
}
