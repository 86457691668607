import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AppResult, AppStatus, Collection } from "../../../shared/types/enums";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import useAddDoc from "../../../shared/hooks/useAddDoc";
import {
  ApplicationData,
  ApplicationDataRecord,
  Provider_Program,
  StudentRecord,
} from "../../../shared/types/types";
import { SetterOrUpdater } from "recoil";

type Props = {
  provider: Provider_Program;
  applicationData: ApplicationDataRecord[];
  setApplicationData: SetterOrUpdater<ApplicationDataRecord[]>;
  selectedStudent: StudentRecord;
  setSelectedLearnMoreProgram: Dispatch<
    SetStateAction<Provider_Program | null>
  >;
};

type Return = {
  status: AppStatus;
  applyLink: string | null;
  result: AppResult;
  handleLearnMore: () => void;
  handleStatus: (newStatus: AppStatus) => Promise<void>;
  handleResults: (newResult: AppResult) => Promise<void>;
};

const useApplicationCard = ({
  provider,
  applicationData,
  setApplicationData,
  selectedStudent,
  setSelectedLearnMoreProgram,
}: Props): Return => {
  const [status, setStatus] = useState<AppStatus>(AppStatus.NotStarted);
  const [result, setResult] = useState<AppResult>(AppResult.NoResponse);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: addDoc } = useAddDoc();
  const programData = applicationData.find(
    (data) =>
      data.programId === provider.id && data.studentId === selectedStudent.id
  );
  const [applyLink, setApplyLink] = useState<string | null>(null);

  useEffect(() => {
    if (
      !selectedStudent ||
      !selectedStudent.schoolId ||
      !selectedStudent.districtId
    )
      return;
    const fetchApplicationData = async () => {
      if (programData) {
        setStatus(programData.status);
        setResult(programData.result);
      }
    };
    fetchApplicationData();
  }, [programData, selectedStudent]);

  useEffect(() => {
    if (!provider) return;
    const { applyURL, homeURL } = provider;
    if (applyURL && applyURL.length > 3) {
      if (applyURL.includes("http")) {
        setApplyLink(applyURL);
      } else {
        setApplyLink(`https://${applyURL}`);
      }
    } else if (homeURL && homeURL.length > 3) {
      if (homeURL.includes("http")) {
        setApplyLink(homeURL);
      } else {
        setApplyLink(`https://${homeURL}`);
      }
    }
  }, [provider]);

  const handleStatus = async (newStatus: AppStatus) => {
    if (!selectedStudent) return;
    setStatus(newStatus);
    if (programData) {
      await updateDoc({
        col: Collection.ApplicationData,
        id: programData.id,
        data: { status: newStatus },
      });
      setApplicationData((pV) =>
        pV.map((data) => {
          if (data.id === programData.id) {
            return { ...data, status: newStatus };
          }
          return data;
        })
      );
    } else {
      const newDoc: ApplicationData = {
        programId: provider.id,
        status: newStatus,
        result: result,
        studentId: selectedStudent.id,
        schoolId: selectedStudent?.schoolId ?? "",
        districtId: selectedStudent?.districtId ?? "",
      };

      const record = await addDoc({
        col: Collection.ApplicationData,
        data: newDoc,
      });
      if (!record) return;
      setApplicationData((pV) => [...pV, { ...newDoc, ...record }]);
    }
  };

  const handleResults = async (newResult: AppResult) => {
    if (!selectedStudent) return;
    setResult(newResult);
    if (programData) {
      await updateDoc({
        col: Collection.ApplicationData,
        id: programData.id,
        data: { result: newResult },
      });
      setApplicationData((pV) =>
        pV.map((data) => {
          if (data.id === programData.id) {
            return { ...data, result: newResult };
          }
          return data;
        })
      );
    } else {
      const newDoc: ApplicationData = {
        programId: provider.id,
        status: status,
        result: newResult,
        studentId: selectedStudent.id,
        schoolId: selectedStudent?.schoolId ?? "",
        districtId: selectedStudent?.districtId ?? "",
      };

      const record = await addDoc({
        col: Collection.ApplicationData,
        data: newDoc,
      });
      if (!record) return;
      setApplicationData((pV) => [...pV, { ...newDoc, ...record }]);
    }
  };

  const handleLearnMore = () => {
    setSelectedLearnMoreProgram(provider);
  };

  return {
    status,
    applyLink,
    result,
    handleLearnMore,
    handleStatus,
    handleResults,
  };
};

export default useApplicationCard;
