import { createContext, useContext } from "react";

type UserContextType = {
  loading: boolean;
};

const defaultValue: UserContextType = {
  loading: false,
};

export const UserContext = createContext<UserContextType>(defaultValue);

export const useUser = () => {
  return useContext(UserContext);
};
