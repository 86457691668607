import { Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { ProviderUser } from "../../../shared/types/types";
import PhoneNumber from "../../../shared/components/PhoneNumber";
import { validationType } from "../../hooks/providerForm/useEditProviderForm";

type Props = {
  validation: validationType;
  providerUserForm: ProviderUser;
  handleTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (fieldName: string) => void;
};

const AboutYou = ({ validation, providerUserForm, handleTextChange, handleBlur }: Props) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          About You
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={providerUserForm.firstName}
          name="firstName"
          onChange={handleTextChange}
          fullWidth
          label="First Name"
          required
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          error={!validation.firstName.validated && validation.firstName.show}
          helperText={validation.firstName.show ? validation.firstName.message : ""}
          onBlur={() => handleBlur("firstName")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={providerUserForm.lastName}
          name="lastName"
          onChange={handleTextChange}
          fullWidth
          required
          label="Last Name"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!validation.lastName.validated && validation.lastName.show}
          helperText={validation.lastName.show ? validation.lastName.message : ""}
          onBlur={() => handleBlur("lastName")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={providerUserForm.email}
          name="email"
          onChange={handleTextChange}
          fullWidth
          required
          label="Email"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          error={!validation.email.validated && validation.email.show}
          helperText={validation.email.show ? validation.email.message : ""}
          onBlur={() => handleBlur("email")}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PhoneNumber
          label="Phone Number"
          name="phone"
          value={providerUserForm.phone}
          required={false}
          onChange={handleTextChange}
        />
      </Grid>
    </>
  );
};

export default AboutYou;
