import React from "react";
import SignupNavbar from "../components/signup/SignupNavbar";
import OnboardingContainer from "../components/onboarding/OnboardingContainer";
import { Container } from "@mui/material";

const StudentOnboarding = () => {
  return (
    <>
      <SignupNavbar />
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <OnboardingContainer />
      </Container>
    </>
  );
};

export default StudentOnboarding;
