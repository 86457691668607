/**
 * Renders a list of resources.
 * @returns The Resources component.
 */

import { Box, Divider, Grid } from "@mui/material";
import Resource from "./Resource";
import { articles } from "../../../shared/libraries/static";
import DisplayBox from "../../../shared/components/DisplayBox";

const Resources = () => {
  return (
    <>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6}>
          <DisplayBox title="Resources" height="auto">
            {articles.map((article) => (
              <Box key={article.title}>
                <Resource article={article} />
                <Divider sx={{ borderColor: "#eee" }} />
              </Box>
            ))}
          </DisplayBox>
        </Grid>
      </Grid>
    </>
  );
};

export default Resources;
