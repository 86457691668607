import { Box, Button, TextField, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { programFiltersAtom } from "../../../shared/recoil/providerDashboardAtoms";

type Props = {
  onClickAddProgram: () => void;
};

const ProgramTableTools = ({ onClickAddProgram }: Props) => {
	const [{ search }, setProgramFilters] = useRecoilState(programFiltersAtom);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          mt: 0,
        }}
      >
        <Typography variant="h5">Program List</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <TextField
            id="student-search"
            label="Search"
            value={search}
            name="student-search"
            onChange={(e) => setProgramFilters((pV) => ({
							...pV,
							search: e.target.value,
						}))}
            variant="outlined"
            size="small"
            sx={{
              width: "40%",
              minWidth: 200,
            }}
          ></TextField>
          <Button
            variant="outlined"
            sx={{ ml: 1 }}
            onClick={onClickAddProgram}
          >
            Add Program
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ProgramTableTools;
