import { GetRecoilValue, atom, selector } from "recoil";
import { allEnrolledGroupStudentsAtom, allStudentApplicationDataAtom } from "./userAtoms";
import { allAnswersAtom, GPAAtom } from "./answersAtoms";
import { questionsAtom } from "./questionAtoms";
import { determineHollandResults, mostInterested } from "../utils/functions";
import { StaffDashboardStudentRow } from "../types/types";
import { AppResult, AppStatus, GradeLevel } from "../types/enums";
import { allWriteInProgramsAtom } from "./providerAtoms";

type StudentFilter = {
  search: string;
  stages: string[];
  personalityTypes: string[];
  mostInterestedPathways: string[];
  lastActivity: number;
  gpa: string[];
  grades: string[];
};

const getGrade = (gradeLevel: string, graduationYear: string, birthday: string) => {
  if (gradeLevel) {
    if (gradeLevel === "9") {
      return GradeLevel.NINTH;
    } else if (gradeLevel === "10") {
      return GradeLevel.TENTH;
    } else if (gradeLevel === "11") {
      return GradeLevel.ELEVENTH;
    } else if (gradeLevel === "12") {
      return GradeLevel.TWELFTH;
    } else {
      return gradeLevel as GradeLevel;
    }
  }
  if (graduationYear) {
    if (graduationYear === "2024") return GradeLevel.TWELFTH;
    if (graduationYear === "2025") return GradeLevel.ELEVENTH;
    if (graduationYear === "2026") return GradeLevel.TENTH;
    if (graduationYear === "2027") return GradeLevel.NINTH;
  }
  if (birthday === "") return null;
  const twelfthStartDate = new Date("2005-10-01T00:00:00Z");
  const twelfthEndDate = new Date("2006-09-30T23:59:59Z");
  const eleventhStartDate = new Date("2006-10-01T00:00:00Z");
  const eleventhEndDate = new Date("2007-09-30T23:59:59Z");
  const tenthStartDate = new Date("2007-10-01T00:00:00Z");
  const tenthEndDate = new Date("2008-09-30T23:59:59Z");
  const birthdayDate = new Date(birthday);
  if (birthdayDate >= twelfthStartDate && birthdayDate <= twelfthEndDate) return GradeLevel.TWELFTH;
  if (birthdayDate >= eleventhStartDate && birthdayDate <= eleventhEndDate)
    return GradeLevel.ELEVENTH;
  if (birthdayDate >= tenthStartDate && birthdayDate <= tenthEndDate) return GradeLevel.TENTH;
  return null;
};

const studentRowsGetter = ({ get }: { get: GetRecoilValue }) => {
  const students = get(allEnrolledGroupStudentsAtom);
  const answers = get(allAnswersAtom);
  const questions = get(questionsAtom);
  const studentFilters = get(studentFiltersAtom);
  const applicationData = get(allStudentApplicationDataAtom);
  const GPAAnswers = get(GPAAtom);
  const allWriteInPrograms = get(allWriteInProgramsAtom);

  if (!questions || !answers || !students) return null;
  //filter out students not meeting filter criteria before building new row object
  const studentsForFilter = students.filter((student) => {
    const { firstName, lastName, stageId, lastActivity, lastUpdatedAt, id } = student;
    const searchText = studentFilters.search.toLowerCase();
    const studentLastActivity = lastActivity || lastUpdatedAt;
    const studentGPA = GPAAnswers.find((GPA) => GPA.studentId === id)?.gpa;
    const GPAInPercentage = studentGPA ? (studentGPA[0] / studentGPA[1]) * 100 : null;
    const combinedName = `${firstName} ${lastName}`.toLowerCase();

    if (studentFilters.search && !combinedName.includes(searchText)) {
      return false;
    }

    if (studentFilters.stages.length > 0 && !studentFilters.stages.includes(stageId)) {
      return false;
    }

    if (
      studentLastActivity &&
      Date.now() - new Date(studentLastActivity).getTime() > studentFilters.lastActivity
    ) {
      return false;
    }

    const grade = getGrade(student.gradeLevel, student.graduationYear, student?.birthday ?? "");
    //remove all students that's grade are not in the grade filter item grades, unless filter.grades is empty
    if (
      studentFilters.grades.length > 0 &&
      (grade === null || !studentFilters.grades.includes(grade))
    ) {
      return false;
    }

    if (Array.isArray(studentFilters.gpa) && studentFilters.gpa.length === 0) return true;
    if (GPAInPercentage === null) return false;

    return studentFilters.gpa.some((gpaValue) => {
      switch (gpaValue) {
        case "90up":
          return GPAInPercentage >= 90;
        case "75to89":
          return GPAInPercentage >= 75 && GPAInPercentage < 90;
        case "50to74":
          return GPAInPercentage >= 50 && GPAInPercentage < 75;
        case "below50":
          return GPAInPercentage < 50;
        default:
          return false;
      }
    });
  });

  const parsedRows = studentsForFilter.map((student) => {
    const studentAnswers = answers.filter((answer) => answer.studentId === student.id);
    const highest = determineHollandResults({ answers: studentAnswers, questions });
    const gpaAnswer = studentAnswers.find((answer) => answer.questionId === "gpa")?.gpa;

    return {
      id: student.id,
      firstName: student.firstName,
      lastName: student.lastName,
      gpa: gpaAnswer ? gpaAnswer : null,
      grade: getGrade(student.gradeLevel, student.graduationYear, student?.birthday ?? ""),
      stageId: student.stageId,
      phone: student.phone,
      lastActivity: student.lastActivity ? student.lastActivity : student.lastUpdatedAt,
      avatarURL: student.avatarURL,
      heartedPrograms:
        student.favoriteProviderIds.length + student.favoriteRecommendedProgramIds.length,
      appliedPrograms:
        applicationData.filter(
          (app) => app.studentId === student.id && app.status === AppStatus.Completed
        ).length +
        allWriteInPrograms.filter(
          (writeIn) => writeIn.studentId === student.id && writeIn.status === AppStatus.Completed
        ).length,
      acceptedPrograms:
        applicationData.filter(
          (app) => app.studentId === student.id && app.result === AppResult.Accepted
        ).length +
        allWriteInPrograms.filter(
          (writeIn) => writeIn.studentId === student.id && writeIn.result === AppResult.Accepted
        ).length,
      avatar: student.avatar ?? "",
      personalityType: highest ? highest : "",
      mostInterestedPathways: mostInterested({
        questions,
        studentAnswers,
        studentId: student.id,
      }),
    };
  });
  return parsedRows as StaffDashboardStudentRow[];
};

export const studentRowsAtom = selector({
  key: "studentRows",
  get: studentRowsGetter,
});

export const studentFiltersAtom = atom<StudentFilter>({
  key: "studentFilters",
  default: {
    search: "",
    stages: [],
    personalityTypes: [],
    mostInterestedPathways: [],
    lastActivity: 999999999999,
    gpa: [],
    grades: [],
  },
});
