import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";

type Props = {
  message: string | null;
  setMessage: React.Dispatch<React.SetStateAction<string | null>>;
};

const MatrixDialog = ({ message, setMessage }: Props) => {
  const handleClose = () => {
    setMessage(null);
  };

  return (
    <Dialog open={Boolean(message)}>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MatrixDialog;
