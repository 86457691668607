import React, { useState } from "react";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { MentorUserRecord } from "../../../shared/types/types";
import { mentorUsersAtom } from "../../../shared/recoil/providerAtoms";
import DisplayBox from "../../../shared/components/DisplayBox";
import { Collection, Color } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/components/FeatherIcon";
import EditMentorDialog from "./EditMentorDialog";
import CustomAvatar from "../../../shared/components/CustomAvatar";

const MyMentors = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedMentorUser, setSelectedMentorUser] = useState<MentorUserRecord | null>(
    null
  );
  const mentorUsers = useRecoilValue(mentorUsersAtom);

  const handleAddClick = () => {
    setSelectedMentorUser(null);
    setShowDialog(true);
  };

  const handleEditClick = (mentorUser: MentorUserRecord) => {
    setSelectedMentorUser(mentorUser);
    setShowDialog(true);
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DisplayBox
          showAddButton
          onClickAdd={() => handleAddClick()}
          title="My Mentors"
        >
          <Box sx={{ display: "flex", gap: 2, padding: 2 }}>
            {mentorUsers.map((mentorUser) => (
              <Box
                key={mentorUser.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <CustomAvatar
                  size={70}
                  fileName={mentorUser.avatar ?? ""}
                  collection={Collection.MentorUsers}
                  documentId={mentorUser.id}
                  formField="avatarURL"
                  edit={true}
                  propAvatarURL={mentorUser.avatarURL}
                />
                <Box
                  sx={{ display: "flex", gap: 1, alignItems: "center", pl: 1 }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    {mentorUser.firstName + " " + mentorUser.lastName}
                  </Typography>
                  <IconButton
                    sx={{ padding: 0, color: Color.Standard_Gray }}
                    disableRipple
                    onClick={() => handleEditClick(mentorUser)}
                  >
                    <FeatherIcon width="14" icon="edit-2" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        </DisplayBox>
        <EditMentorDialog
          open={showDialog}
          setOpen={setShowDialog}
          mentorUser={selectedMentorUser}
        />
      </Box>
    </>
  );
};

export default MyMentors;
