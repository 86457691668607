import { FormEvent, SetStateAction, useEffect, useState } from "react";
import { Provider, ProviderUser, ProviderUserRecord } from "../../../shared/types/types";
import { BLANK_PROVIDER_FORM, BLANK_PROVIDERUSER_FORM } from "../../../shared/libraries/blankForms";
import { useNavigate } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loggedInProviderAtom } from "../../../shared/recoil/userAtoms";
import useSetDoc from "../../../shared/hooks/useSetDoc";
import { useAuth } from "../../../shared/contexts/AuthContext";
import { Collection, PageRoute } from "../../../shared/types/enums";
import { parseSingleProviderUserResponse } from "../../../shared/utils/parsers";
import { ownProviderAtom } from "../../../shared/recoil/providerAtoms";

type Props = {
  user?: ProviderUser;
  provider?: Provider;
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}

type Return = {
  loading: boolean;
  validated: boolean;
  userForm: ProviderUser;
  providerForm: Provider;
  setUserForm: React.Dispatch<SetStateAction<ProviderUser>>;
  setProviderForm: React.Dispatch<SetStateAction<Provider>>;
  handleValidateChange: (validated: boolean) => void;
  handleClose: () => void;
  handleSave: (e: FormEvent) => Promise<void>;
}

const useManageProviderDialog = ({ user, provider, open, setOpen }: Props): Return => {
  const navigate = useNavigate();
  const { sendRequest: setDoc } = useSetDoc();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const { currentAuthUser } = useAuth();
  const setOwnProvider = useSetRecoilState(ownProviderAtom);
  const [loggedInProvider, setLoggedInProvider] = useRecoilState(loggedInProviderAtom);
  const [userForm, setUserForm] = useState<ProviderUser>({ ...BLANK_PROVIDERUSER_FORM });
  const [providerForm, setProviderForm] = useState<Provider>({ ...BLANK_PROVIDER_FORM });

  useEffect(() => {
    if (open && user && provider) {
      setUserForm({...user});
      setProviderForm({...provider});
    }
  }, [open, user, provider]);

  useEffect(() => {
    if (loggedInProvider && loggedInProvider.providerId) {
      console.log("Navigating to Provider Dashboard ");
      navigate(PageRoute.Provider_Dashboard);
    }

    if (user) {
      setUserForm((pV) => {
        return {
          ...pV,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
        };
      });
    } else if (currentAuthUser) {
      setUserForm((pV) => {
        return {
          ...pV,
          email: `${currentAuthUser.email}`,
          firstName: `${currentAuthUser.displayName?.split(" ")[0] || ""}`,
          lastName: `${currentAuthUser.displayName?.split(" ")[1] || ""}`,
          phone: `${currentAuthUser.phoneNumber}`,
        };
      });
    }
  }, [currentAuthUser, user, navigate]);

  const handleValidateChange = (validated: boolean) => {
    setValidated(validated);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSave = async () => {
    if (!currentAuthUser) return;
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/updateColumns`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tableName: "providers",
            idColumn: "id",
            id: providerForm.id,
            data: [providerForm],
          }),
        }
      );

      if (!response.ok) {
        setLoading(false);
        return;
      }

      setOwnProvider(providerForm);
      const resultUserRecord = await setDoc<ProviderUserRecord>({
        col: Collection.ProviderUsers,
        data: userForm,
        id: currentAuthUser.uid,
      });

      setLoading(false);
      if (!resultUserRecord) return;
      setLoggedInProvider(
        parseSingleProviderUserResponse({ ...userForm, ...resultUserRecord })
      );

      setOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    loading,
    validated,
    userForm,
    providerForm,
    setUserForm,
    setProviderForm,
    handleValidateChange,
    handleClose,
    handleSave,
  };
};

export default useManageProviderDialog;
