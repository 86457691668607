import React, { useEffect } from "react";
import { WriteInProgramRecord } from "../../../shared/types/types";
import { Box, Typography } from "@mui/material";
import DisplayBox from "../../../shared/components/DisplayBox";
import { AppResult, AppStatus, Collection, Color } from "../../../shared/types/enums";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";

import ApplicationProgressBar from "./ApplicationProgressBar";
import ApplicationResults from "./ApplicationResults";
import { allStudentApplicationDataAtom, selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { allWriteInProgramsAtom, studentWriteInProgramsAtom } from "../../../shared/recoil/providerAtoms";

type Props = {
  writeInProgram: WriteInProgramRecord;
};

const WriteInCard = ({ writeInProgram }: Props) => {
  const [status, setStatus] = React.useState<AppStatus>(AppStatus.Completed);
  const [result, setResult] = React.useState<AppResult>(AppResult.NoResponse);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const setStudentWriteInPrograms = useSetRecoilState(studentWriteInProgramsAtom);
  const setAllWriteInPrograms = useSetRecoilState(allWriteInProgramsAtom);

  useEffect(() => {
    if (!selectedStudent || !selectedStudent.schoolId || !selectedStudent.districtId) return;
    const fetchApplicationData = async () => {
      if (writeInProgram) {
        setStatus(writeInProgram.status);
        setResult(writeInProgram.result);
      }
    };
    fetchApplicationData();
  }, [writeInProgram, selectedStudent]);

  const handleStatus = async (newStatus: AppStatus) => {
    if (!selectedStudent) return;
    setStatus(newStatus);
    await updateDoc({
      col: Collection.WriteInPrograms,
      id: writeInProgram.id,
      data: { status: newStatus },
    });
    setStudentWriteInPrograms((pV) =>
      pV.map((data) => {
        if (data.id === writeInProgram.id) {
          return { ...data, status: newStatus };
        }
        return data;
      })
    );
    setAllWriteInPrograms((pV) =>
      pV.map((data) => {
        if (data.id === writeInProgram.id) {
          return { ...data, status: newStatus };
        }
        return data;
      })
    );
  };

  const handleResults = async (newResult: AppResult) => {
    if (!selectedStudent) return;
    setResult(newResult);
    await updateDoc({
      col: Collection.WriteInPrograms,
      id: writeInProgram.id,
      data: { result: newResult },
    });
    setStudentWriteInPrograms((pV) =>
      pV.map((data) => {
        if (data.id === writeInProgram.id) {
          return { ...data, result: newResult };
        }
        return data;
      })
    );
    setAllWriteInPrograms((pV) =>
      pV.map((data) => {
        if (data.id === writeInProgram.id) {
          return { ...data, result: newResult };
        }
        return data;
      })
    );
  };
  return (
    <>
      <Box sx={{ mt: 1 }}>
        <DisplayBox title={`${writeInProgram.programName} at ${writeInProgram.providerName}`}>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ fontSize: 12 }}>This Program was Manually Added</Typography>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Application Progress</Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <ApplicationProgressBar status={status} handleStatus={handleStatus} />
              </Box>
            </Box>
            {status === AppStatus.Completed && (
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Application Results</Typography>
                <ApplicationResults result={result} status={status} handleResults={handleResults} />
              </Box>
            )}
          </Box>
        </DisplayBox>
      </Box>
    </>
  );
};

export default WriteInCard;
