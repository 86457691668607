import { Box, Divider, Grid, Typography } from "@mui/material";
import ProgramCard from "../../../shared/components/programCard/ProgramCard";
import { SelectedProgramSource } from "../../../shared/types/enums";
import { useRecoilState, useRecoilValue } from "recoil";
import { programsAtom } from "../../../shared/recoil/providerAtoms";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { Provider_Program } from "../../../shared/types/types";
import { Dispatch, SetStateAction, useState } from "react";

type Props = {
  setLearnMoreSelectedProgram: Dispatch<SetStateAction<Provider_Program | null>>;
};

const MatchedPrograms = ({ setLearnMoreSelectedProgram }: Props) => {
  const programs = useRecoilValue(programsAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const sortedPrograms = [...programs];

  sortedPrograms.sort((a, b) => {
    //sort programs by favorite
    const aFavorited =
      selectedStudent?.favoriteRecommendedProgramIds.includes(a.id) ||
      selectedStudent?.favoriteProviderIds.includes(a.id);
    const bFavorited =
      selectedStudent?.favoriteRecommendedProgramIds.includes(b.id) ||
      selectedStudent?.favoriteProviderIds.includes(b.id);

    // If only one of the programs is favorited, prioritize it
    if (aFavorited && !bFavorited) {
      return -1;
    } else if (!aFavorited && bFavorited) {
      return 1;
    }

    //sort programs by willowROI
    if (a.willowROI && b.willowROI) {
      return b.willowROI - a.willowROI;
    } else {
      return 0;
    }
  });

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h4">Matched Programs</Typography>
        <Divider />
        <Box sx={{ maxHeight: "80VH", overflow: "scroll", overflowX: "hidden" }}>
          <Grid container spacing={2}>
            {sortedPrograms.map((match) => (
              <Grid key={match.id} item xs={12} sm={12} md={12} lg={6}>
                <ProgramCard
                  program={match}
                  source={SelectedProgramSource.Matched}
                  setSelectedLearnMoreProgram={setLearnMoreSelectedProgram}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default MatchedPrograms;
