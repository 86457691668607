import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Provider, ProviderUser } from "../../../shared/types/types";
import EditProviderForm from "../providerForm/EditProviderForm";
import useManageProviderDialog from "../../hooks/dashboard/useManageProviderDialog";
import Loading from "../../../shared/components/Loading";

type Props = {
  user: ProviderUser;
  provider: Provider;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ManageProviderDialog = ({ user, provider, open, setOpen }: Props) => {
  const {
    loading,
    validated,
    userForm,
    providerForm,
    setUserForm,
    setProviderForm,
    handleValidateChange,
    handleClose,
    handleSave,
  } = useManageProviderDialog({ user, provider, open, setOpen });

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ color: "black" }}>
        Edit {provider?.providerName ?? ""}
      </DialogTitle>
      <DialogContent sx={{ minHeight: 470 }}>
        {loading ? (
          <Loading size={80} />
        ) : (
          <EditProviderForm
            userForm={userForm}
            providerForm={providerForm}
            setUserForm={setUserForm}
            setProviderForm={setProviderForm}
            onValidateChange={handleValidateChange}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button disabled={!validated || loading} onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageProviderDialog;
