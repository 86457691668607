import { useState, useCallback } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";

interface SendRequestProps {
  col: string;
  data: {};
  id: string;
}

const useSetDoc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(
    async <T>({ col, data, id }: SendRequestProps): Promise<T | null> => {
      setIsLoading(true);
      setError(null);
      try {
        const now = new Date().toISOString();
        const finalData = { ...data, createdAt: now, lastUpdatedAt: now, active: true };
        const docRef = doc(db, col, id);
        await setDoc(docRef, finalData);
        return { ...finalData, id } as T;
      } catch (err: any) {
        setError(err.message || "Something went wrong!");
        setIsLoading(false);
        console.error(err);
        return err.message || "Something went wrong!";
      }
    },
    []
  );
  return { isLoading, error, sendRequest };
};

export default useSetDoc;
