import { ChangeEvent, Dispatch, useCallback, useEffect, useState } from "react";
import { GroupRecord, StudentRecord } from "../../../shared/types/types";
import useAddDoc from "../../../shared/hooks/useAddDoc";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  allEnrolledDistrictStudentsAtom,
  allEnrolledSchoolStudentsAtom,
} from "../../../shared/recoil/userAtoms";
import { SelectChangeEvent } from "@mui/material";
import { groupsAtom, selectedDistrictAtom, selectedSchoolAtom } from "../../../shared/recoil/districtAtoms";
import { Collection } from "../../../shared/types/enums";

type Props = {
  studentForm: StudentRecord;
  setStudentForm: Dispatch<React.SetStateAction<StudentRecord>>;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const useManageStudentDialog = ({ studentForm, setStudentForm, open, setOpen }: Props) => {
  const { sendRequest: addDoc } = useAddDoc();
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setAllEnrolledSchoolStudents = useSetRecoilState(allEnrolledSchoolStudentsAtom);
  const setAllEnrolledDistrictStudents = useSetRecoilState(allEnrolledDistrictStudentsAtom);
  const selectedSchool = useRecoilValue(selectedSchoolAtom);
  const selectedDistrict = useRecoilValue(selectedDistrictAtom);
  const [groups, setGroups] = useRecoilState(groupsAtom);
  const [selectedGroups, setSelectedGroups] = useState<GroupRecord[]>([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);
  const [alert, setAlert] = useState("");

  useEffect(() => {
    if (!groups || !open) return;
    setSelectedGroups(groups.filter((group) => group.studentIds.includes(studentForm.id)));
  }, [open, groups, studentForm.id]);

  const handleGroupSelect = useCallback(
    (event: any, values: GroupRecord[]) => {
      const currentSelectedGroups: GroupRecord[] = [];
      const currentSelectedGroupIds: string[] = [];
      values.forEach((s) => {
        currentSelectedGroups.push(s);
        currentSelectedGroupIds.push(s.id);
      });
      setSelectedGroups(currentSelectedGroups);
      setSelectedGroupIds(currentSelectedGroupIds);
    },
    []
  );

  const handleTextChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setStudentForm((pV) => {
      return { ...pV, [name as keyof StudentRecord]: value };
    });
  };

  const handleSelectChange = ({ target: { name, value } }: SelectChangeEvent) => {
    setStudentForm((pV) => {
      return { ...pV, [name as keyof StudentRecord]: value };
    });
  };

  const handleClose = () => {
    setAlert("");
    setOpen(false);
  };

  const handleUnenroll = () => {
    setAlert("");
    setOpen(false);
    updateDoc({ col: "students", id: studentForm.id, data: { enrolled: false } });
    setAllEnrolledSchoolStudents((pV) =>
      pV.filter(student => student.id !== studentForm.id)
    );
    setAllEnrolledDistrictStudents((pV) =>
      pV.filter(student => student.id !== studentForm.id)
    );
  };

  const handleSave = async () => {
    setAlert("");
    if (!selectedSchool || !selectedDistrict) return;

    if (
      !studentForm.firstName ||
      !studentForm.lastName ||
      !studentForm.schoolEmail ||
      !studentForm.phone ||
      !studentForm.graduationYear
    ) {
      setAlert("Please fill out all fields");
      return;
    }

    setOpen(false);
    let formId = studentForm.id;
    if (studentForm.id === "") {
      const response = await addDoc({
        col: "students",
        data: { ...studentForm, schoolId: selectedSchool.id, districtId: selectedDistrict.id },
      });
      if (!response) return;
      formId = response.id;
      setAllEnrolledSchoolStudents((pV) => {
        const newArray = [
          {
            ...studentForm,
            id: response.id,
            createdAt: response.createdAt,
            lastUpdatedAt: response.lastUpdatedAt,
            schoolId: selectedSchool.id,
            districtId: selectedDistrict.id,
          },
          ...pV,
        ];
        return newArray;
      });
      setAllEnrolledDistrictStudents((pV) => {
        const newArray = [
          {
            ...studentForm,
            id: response.id,
            createdAt: response.createdAt,
            lastUpdatedAt: response.lastUpdatedAt,
            schoolId: selectedSchool.id,
            districtId: selectedDistrict.id,
          },
          ...pV,
        ];
        return newArray;
      });
    } else {
      updateDoc({ col: Collection.Students, id: studentForm.id, data: studentForm });
      setAllEnrolledSchoolStudents((pV) => {
        const newArray = pV.map((student) => {
          if (student.id === studentForm.id) {
            return { ...student, ...studentForm };
          } else {
            return student;
          }
        });
        return newArray;
      });
      setAllEnrolledDistrictStudents((pV) => {
        const newArray = pV.map((student) => {
          if (student.id === studentForm.id) {
            return { ...student, ...studentForm };
          } else {
            return student;
          }
        });
        return newArray;
      });
    }

    const selectedGroupIdsSet = new Set(selectedGroupIds);

    const updatedGroups = groups.map((group) => {
      const isInSelectedValues = selectedGroupIdsSet.has(group.id);
      const hasStudentId = group.studentIds.includes(formId);
      let newStudentIds = group.studentIds;

      if (isInSelectedValues && !hasStudentId) {
        newStudentIds = [...group.studentIds, formId];
      } else if (!isInSelectedValues && hasStudentId) {
        newStudentIds = group.studentIds.filter((id) => id !== formId);
      }

      if (newStudentIds !== group.studentIds) {
        updateDoc({
          col: "groups",
          id: group.id,
          data: {
            studentIds: newStudentIds,
          },
        });
      }

      return newStudentIds !== group.studentIds ? { ...group, studentIds: newStudentIds } : group;
    });

    setGroups(updatedGroups);
  };

  return {
    handleTextChange,
    handleClose,
    handleSave,
    handleSelectChange,
    handleUnenroll,
    alert,
    groups,
    selectedGroups,
    handleGroupSelect,
  };
};

export default useManageStudentDialog;
