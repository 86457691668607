import React, { Dispatch, SetStateAction } from "react";
import { Avatar, Box, Typography, Grid, IconButton } from "@mui/material";
import FeatherIcon from "../FeatherIcon";
import { formatCurrency, formatPercentage } from "../../utils/functions";
import { Provider_Program } from "../../types/types";
import useProgramCard from "../../hooks/programCard/useProgramCard";

type Props = {
  currentProgram: Provider_Program;
  setDescriptionModal: Dispatch<SetStateAction<DescriptionModal>>;
};

type DescriptionModal = {
  title: string;
  description: string;
  open: boolean;
};

const ProgramStats = ({ currentProgram, setDescriptionModal }: Props) => {
  const { renderDuration } = useProgramCard(currentProgram);

  const handleDescriptionClick = (infoType: string) => {
    switch (infoType) {
      case "average_salary":
        setDescriptionModal({
          title: "Average starting salary",
          description: "The amount of money you can expect to make per year after you graduate.",
          open: true,
        });
        break;

      case "average_cost":
        setDescriptionModal({
          title: "Average cost",
          description:
            "The average amount of money you can expect to spend in total on this program. This includes tuition for the full duration of the program and associated costs like books and materials. It also subtracts the average amount of money students get in scholarship and grants. This is a good indicator of how much you can expect to pay for the program.",
          open: true,
        });
        break;

      case "graduation_rate":
        setDescriptionModal({
          title: "Average graduation rate",
          description:
            "The number of students who enroll in the program that graduate with in 1.5 times the normal length of the program. For example, if the program is 4 years long, the graduation rate is calculated for students who graduate within 6 years. This is a good indicator of how well the program supports students to succeed.",
          open: true,
        });
        break;

      case "employment_rate":
        setDescriptionModal({
          title: "Employment rate",
          description:
            "The employment rate is the percentage of students who are employed after they graduate. This is a good indicator of how well the program prepares students for the workforce.",
          open: true,
        });
        break;

      case "admission_rate":
        setDescriptionModal({
          title: "Admission rate",
          description:
            "The admission rate is the percentage of students who are admitted to the program. This is a good indicator of how competitive the program is.",
          open: true,
        });
        break;

      case "length_program":
        setDescriptionModal({
          title: "Length of program",
          description:
            "The length of the program. This is a good indicator of how long you can expect to be in school.",
          open: true,
        });
        break;

      case "time_commitment":
        setDescriptionModal({
          title: "Time commitment",
          description: "This describes whether the program is full time or part time.",
          open: true,
        });
        break;

      case "location":
        setDescriptionModal({
          title: "Format",
          description:
            "This describes if the program is online only, in person only, or providers the option to do both.",
          open: true,
        });
        break;

      default:
        break;
    }
  };

  const formatModality = (modality: string | null, onlineOnly: boolean | null) => {
    if (onlineOnly) {
      return "All Online";
    } else if (currentProgram?.modality === "1") {
      return "All In Person";
    } else if (currentProgram?.modality === "2") {
      return "A Mix of In Person and Online";
    } else if (currentProgram?.modality === "3") {
      return "Can all be done online, but some in person options are available";
    } else {
      return "Unknown";
    }
  };

  const formatTimeCommitment = (timeCommitment: string | null) => {
    if (timeCommitment !== null && timeCommitment !== "null" && timeCommitment !== "NULL") {
      return timeCommitment;
    } else {
      return "Unknown";
    }
  };

  type StatProps = {
    field: string;
    label: string;
    value: string | number | null;
    icon: string;
    width: number;
    height: number;
  };

  const Stat = ({ field, label, value, icon, width, height }: StatProps) => (
    <>
      <Box
        sx={{
          display: "flex",
          mt: 1,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Avatar src={`../../assets/stats/${icon}.svg`} variant="square" sx={{ width, height }} />
        <Typography sx={{ textAlign: "left", fontWeight: 700, ml: 1 }}>{`${label}`}</Typography>
        <IconButton
          onClick={() => handleDescriptionClick(field)}
          sx={{
            display: "flex",
            gap: 1,
            ":hover": { backgroundColor: "transparent" },
          }}
        >
          <Typography variant="body1" sx={{ fontSize: 14, color: "#333" }}>
            {value}
          </Typography>
          <FeatherIcon icon="info" width="12px" />
        </IconButton>
      </Box>
    </>
  );

  const GridItem = ({ children }: { children: JSX.Element }) => (
    <Grid item md={6} sm={12}>
      {children}
    </Grid>
  );

  return (
    <>
      <Grid container spacing={2}>
        <GridItem>
          <Stat
            field="average_salary"
            icon="average"
            label="Average starting salary"
            width={30}
            height={30}
            value={
              currentProgram.startingSalary
                ? formatCurrency.format(currentProgram.startingSalary)
                : "Unknown"
            }
          />
        </GridItem>
        <GridItem>
          <Stat
            field="average_cost"
            icon="estimated"
            label="Average cost"
            width={30}
            height={30}
            value={
              currentProgram.averageTotalCost
                ? formatCurrency.format(currentProgram.averageTotalCost)
                : "Unknown"
            }
          />
        </GridItem>
        <GridItem>
          <Stat
            field="graduation_rate"
            icon="graduate"
            label="Average graduation rate"
            width={30}
            height={30}
            value={
              currentProgram.completionRateIn6Years
                ? formatPercentage(currentProgram.completionRateIn6Years)
                : "Unknown"
            }
          />
        </GridItem>
        <GridItem>
          <Stat
            field="employment_rate"
            icon="employment"
            label="Employment rate"
            width={30}
            height={30}
            value={
              currentProgram.rateOfEmployment
                ? formatPercentage(currentProgram.rateOfEmployment)
                : "Unknown"
            }
          />
        </GridItem>
        <GridItem>
          <Stat
            field="admission_rate"
            icon="admission"
            label="Admission rate"
            width={30}
            height={30}
            value={
              currentProgram.admissionPercent
                ? formatPercentage(currentProgram.admissionPercent)
                : "Unknown"
            }
          />
        </GridItem>
        <GridItem>
          <Stat
            field="length_program"
            icon="length"
            label="Length of program"
            width={30}
            height={30}
            value={renderDuration({
              months: currentProgram?.durationMonths ?? 0,
              years: currentProgram?.durationYears ?? 0,
            })}
          />
        </GridItem>
        <GridItem>
          <Stat
            field="time_commitment"
            icon="time"
            label="Time commitment"
            width={30}
            height={28}
            value={formatTimeCommitment(currentProgram.commitment)}
          />
        </GridItem>
        <GridItem>
          <Stat
            field="location"
            icon="location"
            label="Format"
            width={30}
            height={32}
            value={formatModality(currentProgram.modality, currentProgram.onlineOnly)}
          />
        </GridItem>
      </Grid>
    </>
  );
};

export default ProgramStats;
