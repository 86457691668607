import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { CIPRecord, Program } from "../../../shared/types/types";
import { SelectChangeEvent } from "@mui/material";
import useGetDocs from "../../../shared/hooks/useGetDocs";
import { Collection, Modality } from "../../../shared/types/enums";

type Props = {
  setForm: Dispatch<SetStateAction<Program>>;
};

const useManageProgramForm = ({ setForm }: Props) => {
  const [cipCodes, setCipCodes] = useState<CIPRecord[]>([]);
  const { sendRequest: getDocs } = useGetDocs();
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setForm((pV) => ({
      ...pV,
      [e.target.name]: value === "" ? "" : Number(value),
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    setForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
    if (e.target.name === "modality") {
      if (e.target.value === Modality.AllOnline) {
        setForm((pV) => ({ ...pV, onlineOnly: true }));
      } else {
        setForm((pV) => ({ ...pV, onlineOnly: false }));
      }
    }
  };

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm((pV) => ({ ...pV, [e.target.name]: e.target.checked }));
  };

  const handleSliderChange = (event: Event, newValue: number | number[], name: string) => {
    setForm((pV) => ({ ...pV, [name]: newValue }));
  };

  useEffect(() => {
    const getCIPCodes = async () => {
      try {
        const results = await getDocs<CIPRecord>({ col: Collection.cipCodes });
        //sort results by cip code
        results.sort((a, b) => a.cipTitle.localeCompare(b.cipTitle));
        setCipCodes(results);
      } catch (error) {
        console.error("Error fetching CIP codes from useManageProgramForm", error);
      }
    };
    getCIPCodes();
  }, [getDocs]);

  return {
    handleTextChange,
    handleSwitchChange,
    handleNumberChange,
    handleSelectChange,
    handleSliderChange,
    cipCodes,
  };
};

export default useManageProgramForm;
