import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { Dispatch, useEffect, useState } from "react";
import { LoginStage } from "../../../shared/types/enums";
import { studentFormAtom } from "../../../shared/recoil/userAtoms";
import { useRecoilState } from "recoil";
import { DistrictRecord, SchoolRecord, Student } from "../../../shared/types/types";
import useGetDocs from "../../../shared/hooks/useGetDocs";

type Props = {
  onboardingForm: Student;
  setOnboardingForm: Dispatch<React.SetStateAction<Student>>;
};

const SelectSchool = ({ onboardingForm, setOnboardingForm }: Props) => {
  const [selected, setSelected] = useState(false);
  const [touched, setTouched] = useState(false);
  const [schoolsForList, setSchoolsForList] = useState<SchoolRecord[]>([]);
  const { sendRequest: getDocs } = useGetDocs();

  useEffect(() => {
    const getSchools = async () => {
      const schools = await getDocs<SchoolRecord>({ col: "schools" });
      setSchoolsForList(schools);
    };
    getSchools();
  }, [getDocs]);

  const handleSelect = (event: SelectChangeEvent) => {
    const school = schoolsForList.find((school) => school.id === event.target.value);
    if (!school) return;
    setOnboardingForm((pV) => ({
      ...pV,
      schoolId: event.target.value,
      districtId: school.districtId,
    }));
    setSelected(event.target.value.length > 0 ? true : false);
    setTouched(true);
  };
  return (
    <>
      <FormControl required fullWidth>
        <InputLabel shrink id="school-label">
          School
        </InputLabel>
        <Select
          fullWidth
          value={onboardingForm?.schoolId ?? ""}
          onChange={handleSelect}
          variant="outlined"
          label="School"
          required
          labelId="school-label"
          notched
        >
          <MenuItem value={""}>Select A School</MenuItem>
          {schoolsForList &&
            schoolsForList.map((school) => (
              <MenuItem key={school.id} value={school.id}>
                {school.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectSchool;
