import { useRecoilValue } from "recoil";
import { userTypeAtom } from "./shared/recoil/userAtoms";
import StudentBootstrap from "./StudentBootstrap";
import StaffBootstrap from "./StaffBootstrap";
import ProviderBootstrap from "./ProviderBootstrap";
import { UserType } from "./shared/types/enums";
import ErrorSnackbar from "./shared/components/ErrorSnackbar";
import NotLoggedInBootstrap from "./NotLoggedInBootstrap";
import MentorBootstrap from "./MentorBootstrap";

function AppBootstrap() {
  const userType = useRecoilValue(userTypeAtom);
  return (
    <>
      {!userType ? (
        <NotLoggedInBootstrap />
      ) : userType === UserType.Student ? (
        <StudentBootstrap />
      ) : userType === UserType.ProviderUser ? (
        <ProviderBootstrap />
      ) : userType === UserType.MentorUser ? (
        <MentorBootstrap />
      ) : userType >= UserType.Staff ? (
        <StaffBootstrap />
      ) : (
        <NotLoggedInBootstrap />
      )}
      <ErrorSnackbar />
    </>
  );
}

export default AppBootstrap;