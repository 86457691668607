import React, { Dispatch, SetStateAction } from "react";
import { AnswerTypeForm, QuestionRecord } from "../../../shared/types/types";
import { QuestionType } from "../../../shared/types/enums";
import MCQuestion from "../../../shared/components/questionTypes/MCQuestion";
import MSQuestion from "../../../shared/components/questionTypes/MSQuestion";
import OpenResponseQuestion from "../../../shared/components/questionTypes/OpenResponseQuestion";
import MatrixQuestion from "../../../shared/components/questionTypes/MatrixQuestion";
import GPAQuestion from "../../../shared/components/questionTypes/GPAQuestion";
import NumberQuestion from "../../../shared/components/questionTypes/NumberQuestion";
import NextStepsMessage from "./NextStepsMessage";
import HollandResultsContainer from "../../../shared/components/hollandResults/HollandResultsContainer";
import GeographyQuestion from "../../../shared/components/questionTypes/GeographyQuestion";

type Props = {
  question: QuestionRecord;
  setAnswer: Dispatch<SetStateAction<AnswerTypeForm>>;
  answer: AnswerTypeForm;
  questionIndex: number;
};

const QuestionRouter = ({ question, setAnswer, answer, questionIndex }: Props) => {
  return (
    <>
      {question.type === QuestionType.MultipleChoice && (
        <MCQuestion question={question} setAnswer={setAnswer} answer={answer} />
      )}
      {question.type === QuestionType.MultipleSelect && (
        <MSQuestion question={question} setAnswer={setAnswer} answer={answer} />
      )}
      {question.type === QuestionType.OpenResponse && (
        <OpenResponseQuestion question={question} setAnswer={setAnswer} answer={answer} />
      )}
      {question.type === QuestionType.Matrix && (
        <MatrixQuestion question={question} setAnswer={setAnswer} answer={answer} />
      )}
      {question.type === QuestionType.GPA && (
        <GPAQuestion question={question} setAnswer={setAnswer} answer={answer} />
      )}
      {question.type === QuestionType.Geography && (
        <GeographyQuestion question={question} setAnswer={setAnswer} answer={answer} />
      )}
      {question.type === QuestionType.Number && (
        <NumberQuestion question={question} setAnswer={setAnswer} answer={answer} />
      )}
      {question.type === QuestionType.Message && question.id === "next_steps_message" && (
        <NextStepsMessage />
      )}
      {question.type === QuestionType.Message && question.id === "holland_type_results" && (
        <HollandResultsContainer />
      )}
    </>
  );
};

export default QuestionRouter;
