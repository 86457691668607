import ProgramList from "./ProgramList";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import ProviderList from "./ProviderList";
import useStudentsByProgramContainer from "../../hooks/studentsByProgram/useStudentsByProgramContainer";
import useWindowDimensions from "../../../shared/hooks/useWindowDimensions";
import { Color } from "../../../shared/types/enums";
import { useState } from "react";
import { Provider_Program } from "../../../shared/types/types";
import ProgramDetailsDialog from "../../../shared/components/programDetails/ProgramDetailsDialog";

enum SortOption {
  Program = "program",
  Provider = "provider",
}

const StudentsByProgramContainer = () => {
  const {
    favoriteOnlyToggle,
    setFavoriteOnlyToggle,
    availableFromAllSchoolsToggle,
    fromAllSchoolsToggle,
    setFromAllSchoolsToggle,
    studentsAllForExport,
    sortBy,
    setSortBy,
    uniqueMatchedIds,
    uniqueProviderIds,
    matchedPrograms,
  } = useStudentsByProgramContainer();
  const { width } = useWindowDimensions();
  const [selectedProgramDetail, setSelectedProgramDetail] = useState<Provider_Program | null>(
    null
  );

  return (
    <>
      <Grid container spacing={0} sx={{ mt: 3, px: 2 }}>
        <Grid item xs={12} sm={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" sx={{ marginRight: 2 }}>
              Sort Students By:
            </Typography>
            <FormControl>
              <RadioGroup
                defaultValue={SortOption.Program}
                name="radio-buttons-group"
                row
                onChange={(e) => {
                  setSortBy(e.target.value);
                }}
              >
                <FormControlLabel value={SortOption.Program} control={<Radio />} label="Program" />
                <FormControlLabel
                  value={SortOption.Provider}
                  control={<Radio />}
                  label="Provider"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={
              <Switch
                checked={favoriteOnlyToggle}
                onChange={() => setFavoriteOnlyToggle((pV) => !pV)}
              />
            }
            label="Favorited Students Only"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          {availableFromAllSchoolsToggle && (
            <FormControlLabel
              control={
                <Switch
                  checked={fromAllSchoolsToggle}
                  onChange={() => setFromAllSchoolsToggle((pV) => !pV)}
                />
              }
              label="Students from all schools"
            />
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box sx={{ display: "flex", justifyContent: width < 900 ? "left" : "right" }}>
            <Button variant="contained" sx={{p:1}}>
              <a
                href={`data:text/csv;charset=utf-8,${encodeURIComponent(studentsAllForExport)}`}
                download={`student-program-matches.csv`}
                style={{ textDecoration: "none", color: Color.Chalk }}
              >
                Export all Data
              </a>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ p: 2 }}>
        {sortBy === SortOption.Program &&
          uniqueMatchedIds.map((id) => (
            <ProgramList
              key={id}
              programId={id}
              favoriteOnlyToggle={favoriteOnlyToggle}
              fromAllSchoolsToggle={fromAllSchoolsToggle}
              matchedPrograms={matchedPrograms}
              setSelectedProgramDetail={setSelectedProgramDetail}
            />
          ))}
        {sortBy === SortOption.Provider &&
          uniqueProviderIds.map((id) => (
            <ProviderList
              key={id}
              providerId={id}
              favoriteOnlyToggle={favoriteOnlyToggle}
              fromAllSchoolsToggle={fromAllSchoolsToggle}
              matchedPrograms={matchedPrograms}
            />
          ))}
      </Box>
      {selectedProgramDetail && (
        <ProgramDetailsDialog
          selectedProgram={selectedProgramDetail}
          setSelectedProgram={setSelectedProgramDetail}
        />
      )}
    </>
  );
};

export default StudentsByProgramContainer;
