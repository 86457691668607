import React, { useState } from "react";
import Navbar from "../components/navigation/Navbar";
import { Box } from "@mui/material";
import Sidebar from "../components/navigation/Sidebar";
import ExploreProgramsContainer from "../components/explorePrograms/ExploreProgramsContainer";
import { userTypeAtom } from "../recoil/userAtoms";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useRecoilValue } from "recoil";
import { UserType } from "../types/enums";
import BottomNavigationMenu from "../components/navigation/BottomNavigationMenu";

const ExplorePrograms = () => {
  const [open, setOpen] = useState(false);
  const { width } = useWindowDimensions();
  const userType = useRecoilValue(userTypeAtom);
  const studentDesktop = width > 900 && userType === UserType.Student;
  const staff = userType && userType >= UserType.Staff;
  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
        {(studentDesktop || staff) && <Sidebar open={open} setOpen={setOpen} />}
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Navbar open={open} title="Explore Programs" />
          <Box sx={{ minHeight: "calc(100VH-64px)" }}>
            <ExploreProgramsContainer />
          </Box>
        </Box>
        {width <= 900 && <BottomNavigationMenu />}
      </Box>
    </>
  );
};

export default ExplorePrograms;
