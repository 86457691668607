import { FormEvent, SetStateAction, useEffect, useState } from "react";
import { Provider, ProviderUser, ProviderUserRecord } from "../../../shared/types/types";
import { BLANK_PROVIDER_FORM, BLANK_PROVIDERUSER_FORM } from "../../../shared/libraries/blankForms";
import { useNavigate } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loggedInProviderAtom, userTypeAtom } from "../../../shared/recoil/userAtoms";
import useSetDoc from "../../../shared/hooks/useSetDoc";
import { useAuth } from "../../../shared/contexts/AuthContext";
import { Collection, PageRoute, UserType } from "../../../shared/types/enums";
import { parseSingleProviderUserResponse } from "../../../shared/utils/parsers";
import { ownProviderAtom } from "../../../shared/recoil/providerAtoms";
import { generateRandomId } from "../../../shared/utils/dbProviderFunctions";

type Props = {
  user?: ProviderUser;
  provider?: Provider;
}

type Return = {
  loading: boolean;
  validated: boolean;
  userForm: ProviderUser;
  providerForm: Provider;
  setUserForm: React.Dispatch<SetStateAction<ProviderUser>>;
  setProviderForm: React.Dispatch<SetStateAction<Provider>>;
  handleValidateChange: (validated: boolean) => void;
  handleSave: (e: FormEvent) => Promise<void>;
}

const useProviderFormContainer = ({ user, provider }: Props): Return => {
  const navigate = useNavigate();
  const { sendRequest: setDoc } = useSetDoc();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const { currentAuthUser } = useAuth();
  const [userType, setUserType] = useRecoilState(userTypeAtom);
  const setOwnProvider = useSetRecoilState(ownProviderAtom);
  const [loggedInProvider, setLoggedInProvider] = useRecoilState(loggedInProviderAtom);
  const [userForm, setUserForm] = useState<ProviderUser>({ ...BLANK_PROVIDERUSER_FORM });
  const [providerForm, setProviderForm] = useState<Provider>({ ...BLANK_PROVIDER_FORM });

  useEffect(() => {
    if (!provider) return;
    setProviderForm({...provider})
  }, [provider]);

  useEffect(() => {
    if (!user) return;
    setUserForm({...user})
  }, [user]);

  useEffect(() => {
    if (loggedInProvider && loggedInProvider.providerId) {
      console.log("Navigating to Provider Dashboard ");
      navigate(PageRoute.Provider_Dashboard);
    }

    if (user) {
      setUserForm((pV) => {
        return {
          ...pV,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
        };
      });
    } else if (currentAuthUser) {
      setUserForm((pV) => {
        return {
          ...pV,
          email: `${currentAuthUser.email}`,
          firstName: `${currentAuthUser.displayName?.split(" ")[0] || ""}`,
          lastName: `${currentAuthUser.displayName?.split(" ")[1] || ""}`,
          phone: `${currentAuthUser.phoneNumber}`,
        };
      });
    }
  }, [currentAuthUser, user, navigate, userType]);

  const handleValidateChange = (validated: boolean) => {
    setValidated(validated);
  }

  const handleSave = async () => {
    if (!currentAuthUser) return;
    setLoading(true);

    try {
      const providerData: Provider = { ...providerForm, id: generateRandomId() };
      const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/createRows`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tableName: "providers",
          data: [providerData],
        }),
      });

      if (!response.ok) {
        setLoading(false);
        return;
      }

      setOwnProvider(providerData);
      const providerUserData: ProviderUser = {
        ...userForm,
        providerId: providerData.id,
        setupComplete: true,
      };
      const resultUserRecord = await setDoc<ProviderUserRecord>({
        col: Collection.ProviderUsers,
        data: {
          ...providerUserData,
          // klaviyoProfileId,
        },
        id: currentAuthUser.uid,
      });

      setLoading(false);
      if (!resultUserRecord) return;
      setLoggedInProvider(
        parseSingleProviderUserResponse({ ...providerUserData, ...resultUserRecord })
      );
      setUserType(UserType.ProviderUser);

      navigate(PageRoute.Provider_Dashboard);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    loading,
    validated,
    userForm,
    providerForm,
    setUserForm,
    setProviderForm,
    handleValidateChange,
    handleSave,
  };
};

export default useProviderFormContainer;
