import React, { Dispatch } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { MentorUserRecord } from "../../../shared/types/types";
import FeatherIcon from "../../../shared/components/FeatherIcon";
import useEditMentorDialog from "../../hooks/profile/useEditMentorDialog";
import Loading from "../../../shared/components/Loading";

const BGCOLOR = "#fbfbfb";

type Props = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  mentorUser: MentorUserRecord | null;
};

const EditMentorDialog = ({ open, setOpen, mentorUser }: Props) => {
  const {
    loading,
    validated,
    mentorUserForm,
    handleSave,
    handleDelete,
    handleTextChange,
    handleClose,
  } = useEditMentorDialog({ mentorUser, setOpen, open });

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle
        variant="h4"
        sx={{ fontSize: 44, textAlign: "center", backgroundColor: BGCOLOR }}
      >
        Manage Mentor
      </DialogTitle>
      {loading ? (
        <Box sx={{ padding: 8, backgroundColor: BGCOLOR }}>
          <Loading size={60} />
        </Box>
      ) : (
        <>
          <DialogContent sx={{ backgroundColor: BGCOLOR }}>
            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={mentorUserForm.firstName}
                  name="firstName"
                  onChange={handleTextChange}
                  fullWidth
                  label="First Name"
                  required
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={mentorUserForm.lastName}
                  name="lastName"
                  onChange={handleTextChange}
                  fullWidth
                  required
                  label="Last Name"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  value={mentorUserForm.email}
                  name="email"
                  onChange={handleTextChange}
                  fullWidth
                  required
                  label="Email"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  disabled={mentorUser ? true : false}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ backgroundColor: BGCOLOR }}>
            <Button variant="contained" disabled={!validated || loading} onClick={handleSave}>
              <FeatherIcon icon="thumbs-up" width="16px" />{" "}
              <Typography sx={{ ml: 1 }}>Save</Typography>
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              <FeatherIcon icon="x" width="16px" />
              Cancel
            </Button>
            {mentorUser && (
              <Button variant="outlined" color="error" disabled={loading} onClick={handleDelete}>
                <FeatherIcon icon="trash" width="16px" />
                <Typography sx={{ ml: 1 }}>Delete</Typography>
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default EditMentorDialog;
