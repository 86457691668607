import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { ChangeEvent, Dispatch } from "react";
import { ProgramFilters } from "../../types/types";
import { stateAbbreviations } from "../../libraries/objects";

type Props = {
  handleUpdateResults: () => void;
  setFilters: Dispatch<React.SetStateAction<ProgramFilters>>;
  filters: ProgramFilters;
};

const ManageProgramsFilters = ({ handleUpdateResults, filters, setFilters }: Props) => {
  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters((pV) => ({ ...pV, [event.target.name]: event.target.value }));
  };
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            value={filters.providerNameSearchString}
            onChange={handleTextChange}
            name="providerNameSearchString"
            label="Provider Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            options={stateAbbreviations}
            value={filters.states}
            multiple
            onChange={(event, values) => {
              setFilters((pV) => ({ ...pV, states: values as string[] }));
            }}
            renderInput={(params) => <TextField {...params} label="States" />}
          />
        </Grid>
      </Grid>
      <Button sx={{ mt: 1 }} onClick={handleUpdateResults} variant="outlined" color="secondary">
        Run Filter
      </Button>
    </>
  );
};

export default ManageProgramsFilters;
