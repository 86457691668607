import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Theme";
import { routes } from "./Routes";
import { ProviderDBProvider } from "./programProviders/providers/ProviderDBProvider";

function ProviderBootstrap() {
  return (
    <ProviderDBProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <main className="root">{routes}</main>
        </Router>
      </ThemeProvider>
    </ProviderDBProvider>
  );
}

export default ProviderBootstrap;
