import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import React, { useState } from "react";
import { Provider } from "../../../shared/types/types";

type Props = {
  providerForm: Provider;
  handleSwitchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const SpecialPopulations = ({
  providerForm,
  handleSwitchChange,
  handleTextChange,
}: Props) => {
  const [religiousCheck, setReligiousCheck] = useState(false);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Switch
              name="hbcu"
              onChange={handleSwitchChange}
              checked={Boolean(providerForm.hbcu)}
            />
          }
          label="Is your institution designed to primarily serve black and brown students?"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Switch
              name="menOnly"
              onChange={handleSwitchChange}
              checked={Boolean(providerForm.menOnly)}
            />
          }
          label="Is your institution designed to only serve men?"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Switch
              onChange={handleSwitchChange}
              name="womenOnly"
              checked={Boolean(providerForm.womenOnly)}
            />
          }
          label="Is your institution designed to only serve women?"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Switch
              onChange={() => setReligiousCheck(!religiousCheck)}
              name="religiousCheck"
              checked={religiousCheck}
            />
          }
          label="Is your institution affiliated with a religious organization?"
        />
      </Grid>
      {religiousCheck && (
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            onChange={handleTextChange}
            name="religiousAffiliation"
            label="What is the religious affiliation of your institution?"
            value={providerForm.religiousAffiliation}
          />
        </Grid>
      )}
    </>
  );
};

export default SpecialPopulations;
