import React, { Dispatch, SetStateAction } from "react";
import { Box } from "@mui/material";
import { Provider_Program } from "../../../shared/types/types";
import WriteInCard from "./WriteInCard";
import ApplicationCard from "./ApplicationCard";
import useApplicationTracker from "../../hooks/dashboard/useApplicationTracker";

type Props = {
  setSelectedProgram: Dispatch<SetStateAction<Provider_Program | null>>;
};

const ApplicationTracker = ({ setSelectedProgram }: Props) => {
  const {
    writeInPrograms,
    selectedStudent,
    favoritedPrograms,
    allApplicationData,
    setAppApplicationData,
  } = useApplicationTracker();

  return (
    <>
      <Box>
        {writeInPrograms.map((writeInProgram) => (
          <WriteInCard key={writeInProgram.id} writeInProgram={writeInProgram} />
        ))}
      </Box>
      <Box sx={{ mt: 2, p: 0 }}>
        {selectedStudent &&
          favoritedPrograms.map((provider) => (
            <ApplicationCard
              provider={provider}
              key={provider.id}
              applicationData={allApplicationData}
              setApplicationData={setAppApplicationData}
              selectedStudent={selectedStudent}
              setSelectedLearnMoreProgram={setSelectedProgram}
            />
          ))}
      </Box>
    </>
  );
};

export default ApplicationTracker;
