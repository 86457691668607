import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  allEnrolledDistrictStudentsAtom,
  allEnrolledGroupStudentsAtom,
  loggedInStaffAtom,
} from "../../../shared/recoil/userAtoms";
import { Provider_Program, StudentsAllForExport } from "../../../shared/types/types";
import { convertToCSV, generateStudentExportBlankObj } from "../../../shared/utils/functions";
import { allMatchedProgramsAtom } from "../../../shared/recoil/providerAtoms";

type KeysMatching<T extends object, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

type YesNoOnlyKeys = KeysMatching<StudentsAllForExport, "Yes" | "No">;
type StringOnlyKeys = Exclude<keyof StudentsAllForExport, YesNoOnlyKeys>;

function formatForCSV(text: string) {
  // If the text potentially contains commas, quotes, or needs escaping.
  if (text.includes(",") || text.includes('"') || text.includes("\n")) {
    // Surround the text in double quotes.
    text = `"${text}"`;

    // // Replace existing double quotes within the text with two double quotes for proper CSV escaping.
    // text = text.replace(/"/g, '""');
  }
  return text;
}

const getMatchedPrograms = async (uniqueProgramIds: string[]) => {
  const results = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getListOfPrograms`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      programIds: uniqueProgramIds,
    }),
  });
  const { rows } = (await results.json()) as { rows: Provider_Program[] };
  return rows;
};

const useStudentsByProgramContainer = () => {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const [favoriteOnlyToggle, setFavoriteOnlyToggle] = useState(false);
  const [availableFromAllSchoolsToggle, setAvailableFromAllSchoolsToggle] = useState(false);
  const [fromAllSchoolsToggle, setFromAllSchoolsToggle] = useState(false);
  const [studentsAllForExport, setStudentsAllForExport] = useState("");
  const [sortBy, setSortBy] = useState("program");
  const allEnrolledGroupStudents = useRecoilValue(allEnrolledGroupStudentsAtom);
  const allEnrolledDistrictStudents = useRecoilValue(allEnrolledDistrictStudentsAtom);
  const [uniqueProviderIds, setUniqueProviderIds] = useState<string[]>([]);
  const [allMatchedIds, setAllMatchedIds] = useState<string[]>([]);
  const [uniqueMatchedIds, setUniqueMatchedIds] = useState<string[]>([]);
  const [matchedPrograms, setMatchedPrograms] = useState<Provider_Program[]>([]);

  useEffect(() => {
    const buildList = async () => {
      const studentsForListBuilding = fromAllSchoolsToggle
        ? [...allEnrolledDistrictStudents]
        : [...allEnrolledGroupStudents];
      const tempAllMatchedIds = studentsForListBuilding.flatMap(
        ({ matchedProgramIds }) => matchedProgramIds
      );
      //remove duplicates from allMatchedIds
      const tempUniqueMatchedIds = Array.from(new Set(tempAllMatchedIds));

      //sort so that the most popular programs are at the top
      tempUniqueMatchedIds.sort((a, b) => {
        if (
          tempAllMatchedIds.filter((matchedId) => matchedId === a).length >
          tempAllMatchedIds.filter((matchedId) => matchedId === b).length
        ) {
          return -1;
        }
        return 1;
      });
      setUniqueMatchedIds(tempUniqueMatchedIds);
      setAllMatchedIds(tempAllMatchedIds);
      const programsForList = await getMatchedPrograms(tempUniqueMatchedIds);
      setMatchedPrograms(programsForList);
      const allMatchedProviderIds = programsForList.flatMap(({ providerId }) => providerId);
      const filteredProviderIds = Array.from(new Set(allMatchedProviderIds));
      setUniqueProviderIds(filteredProviderIds);
    };
    buildList();
  }, [allEnrolledDistrictStudents, allEnrolledGroupStudents, fromAllSchoolsToggle]);

  // const allUsedProviders = providers.filter((provider) => uniqueMatchedIds.includes(provider.id));
  // const uniqueProviderIds = Array.from(
  //   new Set(allUsedProviders.map((provider) => provider.Prov_ID))
  // );

  useEffect(() => {
    const tempArrayForExport: StudentsAllForExport[] = [];

    //generates the data needed for the overall CSV export
    (fromAllSchoolsToggle ? allEnrolledDistrictStudents : allEnrolledGroupStudents).forEach(
      (student) => {
        const studentExportData = generateStudentExportBlankObj(student);

        student.matchedProgramIds.forEach((matchedProgramId, index) => {
          const providerNameKey = `providerName${index + 1}` as StringOnlyKeys;
          const programNameKey = `programName${index + 1}` as StringOnlyKeys;
          const heartedKey = `program${index + 1}hearted` as YesNoOnlyKeys;

          const currentProvider = matchedPrograms.find((p) => p.id === matchedProgramId);

          const programName = currentProvider?.programName ?? "";

          studentExportData[providerNameKey] = currentProvider?.providerName ?? "";
          studentExportData[programNameKey] = formatForCSV(programName);
          studentExportData[heartedKey] = student.favoriteProviderIds.includes(matchedProgramId)
            ? "Yes"
            : "No";
        });

        tempArrayForExport.push(studentExportData);
      }
    );

    const arrayForExport = convertToCSV(tempArrayForExport);
    setStudentsAllForExport(arrayForExport);
  }, [
    fromAllSchoolsToggle,
    allEnrolledDistrictStudents,
    allEnrolledGroupStudents,
    setStudentsAllForExport,
    matchedPrograms,
  ]);

  useEffect(() => {
    if (loggedInStaff) {
      if (loggedInStaff.userType >= 4) {
        setAvailableFromAllSchoolsToggle(true);
      }
    }
  }, [loggedInStaff]);

  return {
    favoriteOnlyToggle,
    setFavoriteOnlyToggle,
    availableFromAllSchoolsToggle,
    fromAllSchoolsToggle,
    setFromAllSchoolsToggle,
    studentsAllForExport,
    setStudentsAllForExport,
    sortBy,
    setSortBy,
    allEnrolledGroupStudents,
    allMatchedIds,
    uniqueMatchedIds,
    uniqueProviderIds,
    matchedPrograms,
  };
};

export default useStudentsByProgramContainer;
