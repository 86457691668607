import { useEffect } from "react";
import { Container } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "../recoil/userAtoms";
import { PageRoute, UserType } from "../types/enums";
import { useAuth } from "../contexts/AuthContext";

const CleverOAuth = () => {
  let location = useLocation();
  const { currentAuthUser } = useAuth();
  const userType = useRecoilValue(userTypeAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userType && !currentAuthUser) return;
    if (!userType) navigate(PageRoute.Student_Dashboard);
    if (userType && userType === UserType.ProviderUser) navigate(PageRoute.Provider_Dashboard);
    if (userType && userType > 1) navigate(PageRoute.Staff_Dashboard);
    if (userType && userType <= 1) navigate(PageRoute.Student_Dashboard);
  }, [currentAuthUser, navigate, userType]);

  let params = new URLSearchParams(location?.search);

  let code = params.get("code");

  if (!code) {
    // Users should not be at this route without a code param.
    // Redirect to login with an error of some type if they arrive here without a code.
  }

  useEffect(() => {
    // This URL needs to change depending on env
    // Should be your cloud function URL or if local your local cloud function hosting URL
    if (!code || currentAuthUser) return;
    fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/api/clever`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: code,
      }),
    })
      .then((data) => {
        data.json().then(({ token, userDetailInfo }) => {
          let auth = getAuth();
          signInWithCustomToken(auth, token);
        });
      })
      .catch((err) => {
        console.error("Error from OAuth Page:", err);
        // Probably redirect to login w/ an error message if the code exchange does not work for any reason.
      });
  }, [code, currentAuthUser]);

  return (
    <Container maxWidth={false} sx={{ minHeight: "100VH", backgroundColor: "rgb(27,186,155)" }}>
      <div>Waiting...</div>
    </Container>
  );
};

export default CleverOAuth;
