import { createContext, useContext } from "react";

type StudentDataContextType = {
  loading: boolean;
  sqlLoading: boolean;
};

const defaultValue: StudentDataContextType = {
  loading: false,
  sqlLoading: false,
};

export const StudentDataContext = createContext<StudentDataContextType>(defaultValue);

export const useStudentData = () => {
  return useContext(StudentDataContext);
};
