import React, { useState, Dispatch, SetStateAction, useEffect, ChangeEvent } from "react";
import { QuestionRecord, AnswerTypeForm } from "../../types/types";
import { Alert, Box, TextField, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { studentAnswersAtom } from "../../recoil/answersAtoms";
import { BLANK_ANSWER_TYPE_FORM } from "../../libraries/blankForms";

type Props = {
  question: QuestionRecord;
  setAnswer: Dispatch<SetStateAction<AnswerTypeForm>>;
  answer: AnswerTypeForm;
  setValid?: Dispatch<SetStateAction<boolean>>;
};

const NumberQuestion = ({ question, setAnswer, answer, setValid }: Props) => {
  const answers = useRecoilValue(studentAnswersAtom);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!answers) return;
    const currentAnswer = answers.find((ans) => ans.questionId === question.id);
    if (!currentAnswer) {
      setAnswer({ ...BLANK_ANSWER_TYPE_FORM });
      return;
    }
    setAnswer((pV) => ({ ...BLANK_ANSWER_TYPE_FORM, number: currentAnswer.number }));
  }, [answers, question.id, setAnswer]);

  const handleTextChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setValid && setValid(true);
    if (question.id === "sat" && (Number(value) < 400 || Number(value) > 1600) && value !== "") {
      setError("Your SAT scores must be a number between 400 and 1600.");
      setValid && setValid(false);
    }
    if (question.id === "act" && (Number(value) < 1 || Number(value) > 36) && value !== "") {
      setError("Your ACT scores must be a number between 1 and 36.");
      setValid && setValid(false);
    }
    setAnswer((pV) => ({
      ...pV,
      number: Number(value),
    }));
  };
  return (
    <>
      <Typography variant="h5" textAlign={"center"}>
        {question.question}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" textAlign={"center"}>
        {question.helperText}
      </Typography>

      <Box sx={{ mt: 2 }}>
        <TextField
          fullWidth
          type="number"
          value={answer.number === 0 ? "" : answer.number}
          onChange={handleTextChange}
        />
      </Box>
      {error && (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
    </>
  );
};

export default NumberQuestion;
