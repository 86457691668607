import { useSetRecoilState } from "recoil";
import { allEnrolledSchoolStudentsAtom, selectedStudentAtom } from "../recoil/userAtoms";
import { StudentRecord } from "../types/types";

type UpdateProps = {
  key: string;
  value: any;
  studentId: string;
};

const useUpdateStudentState = () => {
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);
  const setAllEnrolledSchoolStudents = useSetRecoilState(allEnrolledSchoolStudentsAtom);

  const updateStudentState = ({ key, value, studentId }: UpdateProps) => {
    setSelectedStudent((pV) =>
      pV
        ? {
            ...pV,
            [key as keyof StudentRecord]: value,
          }
        : null
    );

    setAllEnrolledSchoolStudents((pV) => {
      return pV.map((student) => {
        if (student.id === studentId) {
          return {
            ...student,
            [key as keyof StudentRecord]: value,
          };
        }
        return student;
      });
    });
  };
  return { updateStudentState };
};

export default useUpdateStudentState;
