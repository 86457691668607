import React, { Dispatch, SetStateAction, useEffect } from "react";
import { QuestionRecord, AnswerTypeForm } from "../../types/types";
import { Autocomplete, Checkbox, TextField, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { studentAnswersAtom } from "../../recoil/answersAtoms";
import { BLANK_ANSWER_TYPE_FORM } from "../../libraries/blankForms";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  question: QuestionRecord;
  setAnswer: Dispatch<SetStateAction<AnswerTypeForm>>;
  answer: AnswerTypeForm;
};

const cities = [
  "New York - NY",
  "Los Angeles - CA",
  "Chicago - IL",
  "Houston - TX",
  "Phoenix - AZ",
  "Philadelphia - PA",
  "San Antonio - TX",
  "San Diego - CA",
  "Dallas - TX",
  "San Jose - CA",
  "Austin - TX",
  "Jacksonville - FL",
  "Fort Worth - TX",
  "Columbus - OH",
  "Denver - CO",
  "Charlotte - NC",
  "Indianapolis - IN",
  "Seattle - WA",
  "Boston - MA",
  "El Paso - TX",
  "Detroit - MI",
  "Nashville - TN",
];

const GeographyQuestion = ({ question, setAnswer, answer }: Props) => {
  const answers = useRecoilValue(studentAnswersAtom);

  useEffect(() => {
    if (!answers) return;
    const currentAnswer = answers.find((ans) => ans.questionId === question.id);
    if (!currentAnswer) {
      setAnswer({ ...BLANK_ANSWER_TYPE_FORM });
      return;
    }
    setAnswer((pV) => ({ ...BLANK_ANSWER_TYPE_FORM, ms: currentAnswer.ms }));
  }, [answers, question.id, setAnswer]);

  const handleSelect = (event: any, values: (string | number)[]) => {
    setAnswer((pV) => ({
      ...pV,
      ms: values,
    }));
  };

  return (
    <>
      <Typography variant="h5" textAlign={"center"}>
        {question.question}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" textAlign={"center"}>
        {question.helperText}
      </Typography>
      <>
        <Autocomplete
          multiple
          id="city-select"
          options={cities}
          disableCloseOnSelect
          onChange={handleSelect}
          fullWidth
          value={answer.ms as string[]}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Select City" placeholder="Selected Cities" fullWidth />
          )}
        />
      </>
    </>
  );
};

export default GeographyQuestion;
