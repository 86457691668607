import React from "react";
import AboutYou from "./AboutYou";
import AboutYourOrganization from "./AboutYourOrganization";
import SpecialPopulations from "./SpecialPopulations";
import AboutYourStudents from "./AboutYourStudents";
import { Provider, ProviderUser } from "../../../shared/types/types";
import { Grid } from "@mui/material";
import useEditProviderForm from "../../hooks/providerForm/useEditProviderForm";

type Props = {
  userForm: ProviderUser;
  providerForm: Provider;
  setUserForm: React.Dispatch<React.SetStateAction<ProviderUser>>;
  setProviderForm: React.Dispatch<React.SetStateAction<Provider>>;
  onValidateChange: (validated: boolean) => void;
}

const EditProviderForm = ({ userForm, providerForm, setUserForm, setProviderForm, onValidateChange }: Props) => {
  const {
    validation,
    disabledPercentages,
    handleTextChange,
    handleSelectChange,
    handleSwitchChange,
    handleNumberChange,
    handleSliderChange,
    handleSetAddress,
    handleBlur,
    handleDisabledPercentagesChange,
  } = useEditProviderForm({ userForm, providerForm, setUserForm, setProviderForm, onValidateChange });

  return (
    <Grid container spacing={3}>
      <AboutYou
        validation={validation}
        providerUserForm={userForm}
        handleTextChange={handleTextChange}
        handleBlur={handleBlur}
      />
      <AboutYourOrganization
        validation={validation}
        providerForm={providerForm}
        handleTextChange={handleTextChange}
        handleSetAddress={handleSetAddress}
        handleSelectChange={handleSelectChange}
        handleBlur={handleBlur}
      />
      <SpecialPopulations
        providerForm={providerForm}
        handleTextChange={handleTextChange}
        handleSwitchChange={handleSwitchChange}
      />
      <AboutYourStudents
        validation={validation}
        providerForm={providerForm}
        disabledPercentages={disabledPercentages}
        handleNumberChange={handleNumberChange}
        handleSliderChange={handleSliderChange}
        handleDisabledPercentagesChange={handleDisabledPercentagesChange}
      />
    </Grid>
  );
};

export default EditProviderForm;
