import React, { useState } from "react";
import Navbar from "../../shared/components/navigation/Navbar";
import { Box } from "@mui/material";
import Sidebar from "../../shared/components/navigation/Sidebar";
import useWindowDimensions from "../../shared/hooks/useWindowDimensions";
import BottomNavigationMenu from "../../shared/components/navigation/BottomNavigationMenu";
import AlmaContainer from "../components/alma/AlmaContainer";

const Alma = () => {
  const [open, setOpen] = useState(false);
  const { width } = useWindowDimensions();
  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
        {width > 900 && <Sidebar open={open} setOpen={setOpen} />}
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Navbar open={open} title="Alma AI Counselor" />
          <Box sx={{ minHeight: "calc(100VH-64px)" }}>
            <AlmaContainer />
          </Box>
        </Box>
        {width <= 900 && <BottomNavigationMenu />}
      </Box>
    </>
  );
};

export default Alma;
