import React from "react";

type Props = {
  fillColor?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
  icon: string;
  className?: string;
};

const Clipboard = () => (
  <>
    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
  </>
);

const Message = () => (
  <>
    <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
  </>
);

const Download = () => (
  <>
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
    <polyline points="7 10 12 15 17 10"></polyline>
    <line x1="12" y1="15" x2="12" y2="3"></line>
  </>
);

const DB = () => (
  <>
    <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
    <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
    <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
  </>
);

const Filter = () => (
  <>
    <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
  </>
);

const Search = () => (
  <>
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </>
);

const Close = () => (
  <>
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="15" y1="9" x2="9" y2="15"></line>
    <line x1="9" y1="9" x2="15" y2="15"></line>
  </>
);

const List = () => (
  <>
    <line x1="8" y1="6" x2="21" y2="6"></line>
    <line x1="8" y1="12" x2="21" y2="12"></line>
    <line x1="8" y1="18" x2="21" y2="18"></line>
    <line x1="3" y1="6" x2="3.01" y2="6"></line>
    <line x1="3" y1="12" x2="3.01" y2="12"></line>
    <line x1="3" y1="18" x2="3.01" y2="18"></line>
  </>
);

const Settings = () => (
  <>
    <circle cx="12" cy="12" r="3"></circle>
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
  </>
);

const AddIcon = () => (
  <>
    <path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1"></path>
  </>
);

const EditIcon = () => (
  <>
    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
  </>
);

const PhoneOff = () => (
  <>
    <path d="M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91"></path>
    <line x1="23" y1="1" x2="1" y2="23"></line>
  </>
);

const Star = () => (
  <>
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
  </>
);

const Slash = () => (
  <>
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line>
  </>
);

const Clock = () => (
  <>
    <circle cx="12" cy="12" r="10"></circle>
    <polyline points="12 6 12 12 16 14"></polyline>
  </>
);

const Wallet = () => (
  <>
    <path d="m14.2 7q0.2 0.2 0.2 0.4v3.1q0 0.3-0.2 0.4-0.2 0.2-0.4 0.2v1.6q-0.1 0.2-0.1 0.5-0.1 0.2-0.3 0.4-0.2 0.2-0.4 0.3-0.3 0.1-0.5 0.1h-10.6q-0.2 0-0.5-0.1-0.2-0.1-0.4-0.3-0.2-0.2-0.3-0.4-0.1-0.3-0.1-0.5v-7.5q0-0.2 0.1-0.4 0.1-0.3 0.3-0.5 0.2-0.1 0.4-0.2 0.3-0.1 0.5-0.1h0.7l0.9-2.4q0.1-0.1 0.2-0.3 0.2-0.1 0.3-0.2 0.2-0.1 0.4-0.1 0.2 0 0.4 0.1l6.8 2.9h0.9q0.2 0 0.5 0.1 0.2 0.1 0.4 0.2 0.2 0.2 0.3 0.5 0 0.2 0.1 0.4v1.6q0.2 0 0.4 0.2zm-10.9-3h0.7l0.4-1.1q0-0.1 0.2-0.2 0.1 0 0.2 0l3.7 1.3h1.5l-5.5-2.3q-0.1-0.1-0.2 0-0.1 0-0.1 0.1-0.1 0-0.1 0.1zm1.6-0.6l-0.2 0.6h1.9zm8.2 7.7h-2.8c-0.6 0-1.1-0.2-1.6-0.6-0.4-0.5-0.6-1-0.6-1.6 0-0.6 0.2-1.1 0.6-1.5 0.5-0.4 1-0.7 1.6-0.7h2.8v-1.5q0-0.3-0.2-0.5-0.2-0.2-0.5-0.2h-10.5q-0.3 0-0.5 0.2-0.2 0.2-0.2 0.5v7.4q0 0.3 0.2 0.5 0.2 0.2 0.5 0.2h10.5q0.3 0 0.5-0.2 0.2-0.2 0.2-0.5zm0.6-3.7h-3.4c-0.4 0-0.8 0.1-1.1 0.4-0.3 0.3-0.5 0.7-0.5 1.1 0 0.5 0.2 0.8 0.5 1.1 0.3 0.3 0.7 0.5 1.1 0.5h3.4q0 0 0 0 0 0 0 0z" />
    <path d="m11 8.3q0.2 0.3 0.3 0.7-0.1 0.2-0.2 0.5-0.2 0.2-0.4 0.3-0.3 0.1-0.6 0.1-0.2-0.1-0.5-0.3-0.1-0.2-0.2-0.5-0.1-0.2 0-0.5 0.2-0.3 0.4-0.4 0.2-0.2 0.5-0.2 0.4 0 0.7 0.3zm-0.5 0.9q0.1-0.1 0.1-0.2 0-0.1 0-0.2-0.1-0.1-0.2-0.1-0.1-0.1-0.1 0-0.1 0-0.2 0-0.1 0.1-0.1 0.2 0 0.1 0 0.2 0.1 0.1 0.1 0.1 0.1 0.1 0.2 0.1 0.1 0 0.2-0.1z" />
    <path d="m4.4 6.1q0 0.2-0.1 0.3-0.1 0.1-0.2 0.1h-1.3q-0.1 0-0.2-0.1-0.1-0.1-0.1-0.3 0-0.1 0.1-0.2 0.1-0.1 0.2-0.1h1.3q0.1 0 0.2 0.1 0.1 0.1 0.1 0.2z" />
    <path d="m5.6 7.4q0 0.1-0.1 0.2-0.1 0.1-0.2 0.1h-2.5q-0.1 0-0.2-0.1-0.1-0.1-0.1-0.2 0-0.1 0.1-0.2 0.1-0.1 0.2-0.1h2.5q0.1 0 0.2 0.1 0.1 0.1 0.1 0.2z" />
  </>
);

const Trash = () => (
  <>
    <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>
  </>
);

const Heart = () => (
  <>
    <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
  </>
);

const Info = () => (
  <>
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="16" x2="12" y2="12"></line>
    <line x1="12" y1="8" x2="12.01" y2="6"></line>
  </>
);

const ThumbsUp = () => (
  <>
    <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
  </>
);

const X = () => (
  <>
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </>
);

const AI = () => (
  <>
    <path d="M12.0682 13.2098C12.0682 13.2098 11.674 13.0783 11.1264 12.8959C9.23742 12.2663 7.73371 10.7626 7.1041 8.87379C6.92172 8.32602 6.79016 7.93176 6.79016 7.93176C6.71039 7.69258 6.48648 7.53125 6.23434 7.53125C5.98219 7.53125 5.75832 7.69262 5.67852 7.9318C5.67852 7.9318 5.54703 8.32605 5.36469 8.87363C4.73504 10.7626 3.23137 12.2663 1.3425 12.8959C0.794766 13.0783 0.400508 13.2098 0.400508 13.2098C0.161328 13.2896 0 13.5135 0 13.7657C0 14.0178 0.161367 14.2417 0.400547 14.3215C0.400547 14.3215 0.794805 14.453 1.34234 14.6353C3.23133 15.265 4.73504 16.7686 5.36465 18.6575C5.54703 19.2052 5.67852 19.5995 5.67852 19.5995C5.75832 19.8386 5.98215 20 6.23434 20H6.23438C6.48652 20 6.71043 19.8387 6.79019 19.5995C6.79019 19.5995 6.92176 19.2052 7.1041 18.6576C7.73375 16.7687 9.23742 15.265 11.1263 14.6354C11.674 14.453 12.0682 14.3215 12.0682 14.3215C12.3074 14.2417 12.4688 14.0179 12.4688 13.7657C12.4688 13.5135 12.3074 13.2896 12.0682 13.2098ZM7.81184 15.3431C7.14832 16.0066 6.61059 16.7973 6.23438 17.6577C5.85816 16.7974 5.32047 16.0066 4.65688 15.3431C3.99336 14.6795 3.20258 14.1418 2.3423 13.7656C3.20254 13.3894 3.99328 12.8517 4.65688 12.1881C5.32039 11.5246 5.85813 10.7339 6.23434 9.87355C6.61055 10.7338 7.14824 11.5245 7.81184 12.1881C8.47535 12.8516 9.26609 13.3894 10.1264 13.7656C9.26617 14.1418 8.47547 14.6795 7.81184 15.3431Z" />
    <path d="M15.0209 9.95801C15.2843 9.95801 15.5071 9.7841 15.5809 9.5448C16.2113 7.66672 17.7062 6.17125 19.5839 5.54C19.8247 5.46723 19.9999 5.24359 19.9999 4.97906C19.9999 4.71562 19.826 4.49281 19.5867 4.41902C17.7086 3.78863 16.2131 2.29375 15.5819 0.416016C15.5091 0.175234 15.2855 0 15.0209 0C14.7575 0 14.5346 0.173945 14.4609 0.413203C13.8305 2.29129 12.3356 3.7868 10.4578 4.41805C10.2171 4.49082 10.0419 4.71445 10.0419 4.97898C10.0419 5.24242 10.2158 5.46523 10.4551 5.53902C12.333 6.16937 13.8285 7.66414 14.4598 9.54168C14.5325 9.78266 14.7562 9.95801 15.0209 9.95801ZM12.0775 4.97902C12.6925 4.63828 13.2605 4.21207 13.7573 3.71535C14.254 3.21863 14.6802 2.65059 15.0209 2.03555C15.3617 2.65059 15.7879 3.21863 16.2846 3.71535C16.7813 4.21207 17.3494 4.63828 17.9644 4.97902C17.3494 5.31977 16.7813 5.74598 16.2846 6.2427C15.7879 6.73941 15.3617 7.30746 15.0209 7.9225C14.6802 7.30746 14.254 6.73941 13.7573 6.2427C13.2605 5.74598 12.6925 5.31977 12.0775 4.97902Z" />
    <path d="M0.412695 3.65609C1.40695 3.9918 2.19895 4.7832 2.53543 5.77703C2.6084 6.01746 2.83188 6.19242 3.09617 6.19242C3.35941 6.19242 3.58211 6.01875 3.65605 5.77973C3.99176 4.78551 4.78312 3.99355 5.77695 3.65699C6.01742 3.58402 6.19238 3.36055 6.19238 3.09625C6.19238 2.83301 6.01871 2.61031 5.77969 2.53637C4.78547 2.20066 3.99352 1.4093 3.65695 0.415469C3.58398 0.174961 3.36051 0 3.09621 0C2.83297 0 2.61027 0.173672 2.53637 0.412695C2.2007 1.40688 1.4093 2.19887 0.41543 2.53543C0.174961 2.6084 0 2.83188 0 3.09621C0 3.35945 0.173711 3.58215 0.412695 3.65609ZM3.09621 1.89094C3.41895 2.36379 3.82859 2.77348 4.30145 3.09617C3.82859 3.41891 3.41891 3.82855 3.09621 4.30141C2.77352 3.82855 2.36383 3.41887 1.89098 3.09617C2.36383 2.77348 2.77352 2.36383 3.09621 1.89094Z" />
    <path d="M19.5873 16.3439C18.593 16.0082 17.8011 15.2168 17.4646 14.223C17.3916 13.9826 17.1681 13.8076 16.9038 13.8076C16.6406 13.8076 16.4179 13.9813 16.3439 14.2203C16.0082 15.2145 15.2169 16.0065 14.223 16.343C13.9826 16.416 13.8076 16.6395 13.8076 16.9038C13.8076 17.167 13.9813 17.3897 14.2203 17.4637C15.2145 17.7994 16.0065 18.5907 16.343 19.5846C16.416 19.825 16.6395 20 16.9038 20C17.167 20 17.3897 19.8263 17.4636 19.5873C17.7993 18.5931 18.5907 17.8011 19.5846 17.4646C19.825 17.3916 20 17.1681 20 16.9038C20 16.6406 19.8263 16.4179 19.5873 16.3439ZM16.9038 18.1091C16.5811 17.6363 16.1714 17.2266 15.6986 16.9039C16.1714 16.5811 16.5811 16.1715 16.9038 15.6986C17.2265 16.1715 17.6362 16.5812 18.109 16.9039C17.6362 17.2266 17.2265 17.6362 16.9038 18.1091Z" />
  </>
);

const Person = () => (
  <>
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
  </>
);

const Home = () => (
  <>
    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
    <polyline points="9 22 9 12 15 12 15 22"></polyline>
  </>
);

const FeatherIcon = ({
  fillColor = "none",
  width = "24",
  height = "24",
  viewBox = "0 0 24 24",
  strokeColor = "currentColor",
  icon,
  className = "",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill={icon === "ai" || icon === "wallet" ? strokeColor : fillColor}
      stroke={icon === "ai" || icon === "wallet" ? "none" : strokeColor}
      strokeWidth="2"
    >
      {icon === "clipboard" && <Clipboard />}
      {icon === "message-circle" && <Message />}
      {icon === "list" && <List />}
      {icon === "settings" && <Settings />}
      {icon === "add" && <AddIcon />}
      {icon === "edit-2" && <EditIcon />}
      {icon === "phone-off" && <PhoneOff />}
      {icon === "star" && <Star />}
      {icon === "slash" && <Slash />}
      {icon === "clock" && <Clock />}
      {icon === "trash" && <Trash />}
      {icon === "heart" && <Heart />}
      {icon === "search" && <Search />}
      {icon === "info" && <Info />}
      {icon === "close" && <Close />}
      {icon === "filter" && <Filter />}
      {icon === "download" && <Download />}
      {icon === "thumbs-up" && <ThumbsUp />}
      {icon === "x" && <X />}
      {icon === "ai" && <AI />}
      {icon === "db" && <DB />}
      {icon === "wallet" && <Wallet />}
      {icon === "person" && <Person />}
      {icon === "home" && <Home />}
    </svg>
  );
};

export default FeatherIcon;
