import { Box, Divider, Grid, Typography } from "@mui/material";
import StageSummaryBar from "./StageSummaryBar";
import useStageSummary from "../../hooks/dashboard/useStageSummary";

const StageSummary = () => {
  const { stageArray, stages } = useStageSummary();

  return (
    <>
      <Box sx={{ mt: 0 }}>
        <Grid container spacing={3} columns={4}>
          {stageArray.map((stageId) => (
            <Grid
              item
              xs={1}
              key={`${stageId}l`}
              sx={{
                textAlign: "center",
                margin: "0 auto",
              }}
            >
              <Box
                sx={{
                  height: 140,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  margin: "0 auto",
                  pl: 1,
                  pr: 1,
                  pt: 2,
                  pb: 0,
                }}
              >
                <StageSummaryBar stageId={stageId} />
              </Box>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ mt: 0 }} />
        <Grid container spacing={3} columns={5} sx={{ pt: "2px" }}>
          {stageArray.map((stageId) => (
            <Grid
              item
              xs={1}
              key={`${stageId}l`}
              sx={{
                textAlign: "center",
                margin: "0 auto",
              }}
            >
              <Typography sx={{ fontSize: 12, mt: "4px" }} variant="body2">
                {stages.find((stage) => stage.id === stageId)?.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default StageSummary;
