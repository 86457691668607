import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { AppResult, AppStatus, Collection, Color } from "../../../shared/types/enums";
import { WriteInProgram, WriteInProgramRecord } from "../../../shared/types/types";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useAddDoc from "../../../shared/hooks/useAddDoc";
import { allWriteInProgramsAtom, studentWriteInProgramsAtom } from "../../../shared/recoil/providerAtoms";
import FeatherIcon from "../../../shared/components/FeatherIcon";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const BLANK_WRITE_IN_FORM: WriteInProgram = {
  providerName: "",
  programName: "",
  status: AppStatus.NotStarted,
  result: AppResult.NoResponse,
  studentId: "",
  schoolId: "",
  districtId: "",
};

const WriteInDialog = ({ open, setOpen }: Props) => {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const { sendRequest: addDoc } = useAddDoc();
  const [writeInForm, setWriteInForm] = useState<WriteInProgram>({ ...BLANK_WRITE_IN_FORM });
  const [valid, setValid] = useState<boolean>(false);
  const setStudentWriteInPrograms = useSetRecoilState(studentWriteInProgramsAtom);
  const setAllWriteInPrograms = useSetRecoilState(allWriteInProgramsAtom);

  const handleChange = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>) => {
    setWriteInForm({ ...writeInForm, [name]: value });
  };

  const validForm = () => {
    if (writeInForm.providerName.trim().length === 0) {
      return false;
    }

    if (writeInForm.programName.trim().length === 0) {
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    if (!selectedStudent) return;
    const data = {
      ...writeInForm,
      studentId: selectedStudent.id,
      schoolId: selectedStudent.schoolId,
      districtId: selectedStudent.districtId,
    };
    const results = await addDoc({
      col: Collection.WriteInPrograms,
      data: data,
    });
    setAllWriteInPrograms((pV) => [...pV, { ...results, ...data } as WriteInProgramRecord]);
    setStudentWriteInPrograms((pV) => [...pV, { ...results, ...data } as WriteInProgramRecord]);
    setWriteInForm({ ...BLANK_WRITE_IN_FORM });
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setWriteInForm({ ...BLANK_WRITE_IN_FORM });
  };

  useEffect(() => {
    const isValid = validForm();
    setValid(isValid);
  }, [writeInForm]);

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle variant="h4">Add A Program To Apply To</DialogTitle>
        <DialogContent>
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>Provider Name</Typography>
          <Typography sx={{ fontSize: 12 }}>
            The name of the university or business that offers the program you're applying to, like
            University of Colorado or Cross Purpose.
          </Typography>
          <TextField
            fullWidth
            sx={{ mt: 1 }}
            required
            value={writeInForm.providerName}
            onChange={handleChange}
            name="providerName"
          />
          <Typography sx={{ fontSize: 16, mt: 2, fontWeight: 600 }}>Program Name</Typography>
          <Typography sx={{ fontSize: 12 }}>
            The name of the specific major or program you want to focus on or enroll in, like
            Electrical Engineering or Architecture.
          </Typography>
          <TextField
            fullWidth
            sx={{ mt: 1 }}
            required
            value={writeInForm.programName}
            onChange={handleChange}
            name="programName"
          />
          <Typography sx={{ fontSize: 16, mt: 2, fontWeight: 600 }}>
            Your Application's Status
          </Typography>
          <RadioGroup row value={writeInForm.status} onChange={handleChange} name="status">
            <FormControlLabel
              value={AppStatus.NotStarted}
              control={<Radio />}
              label={AppStatus.NotStarted}
            />
            <FormControlLabel
              value={AppStatus.InProgress}
              control={<Radio />}
              label={AppStatus.InProgress}
            />
            <FormControlLabel
              value={AppStatus.Completed}
              control={<Radio />}
              label={AppStatus.Completed}
            />
          </RadioGroup>
          {writeInForm.status === AppStatus.Completed && (
            <>
              <Typography sx={{ fontSize: 16, mt: 2, fontWeight: 600 }}>
                The Results From Your Application
              </Typography>
              <RadioGroup row value={writeInForm.result} onChange={handleChange} name="result">
                <FormControlLabel
                  value={AppResult.NoResponse}
                  control={<Radio />}
                  label={AppResult.NoResponse}
                />
                <FormControlLabel
                  value={AppResult.Waitlisted}
                  control={<Radio />}
                  label={AppResult.Waitlisted}
                />
                <FormControlLabel
                  value={AppResult.Declined}
                  control={<Radio />}
                  label={AppResult.Declined}
                />
                <FormControlLabel
                  value={AppResult.Accepted}
                  control={<Radio />}
                  label={AppResult.Accepted}
                />
              </RadioGroup>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#fff" }}>
          <Button variant="contained" disabled={!valid} onClick={handleSave}>
            <IconButton sx={{ color: Color.Chalkboard }} disableRipple>
              <FeatherIcon icon="thumbs-up" width="16px" />
            </IconButton>
            Save
          </Button>
          <Button variant="outlined" onClick={handleCancel}>
            <IconButton sx={{ color: Color.Chalkboard }} disableRipple>
              <FeatherIcon icon="x" width="16px" />
            </IconButton>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WriteInDialog;
