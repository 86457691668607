import { Collection } from "../types/enums";
import { AnswerRecord, DistrictRecord, SchoolRecord } from "../types/types";
import { parseSchoolResponse } from "./parsers";
import { getFBDocs } from "./queries";

type GetSchoolsProps = {
  selectedDistrict: DistrictRecord;
  schoolId: string;
};

type GetAnswersProps = {
  studentId: string;
  questionId?: string;
};

export const getDistricts = async (userDistrictId: string) => {
  const response = await getFBDocs<DistrictRecord>({
    col: "districts",
  });
  const selectedDistrict = userDistrictId
    ? response.find((district) => district.id === userDistrictId)
    : response[0];

  if (!selectedDistrict) {
    console.error(
      "[StaffDatabaseProvider] the selected district Id for the user was not found in the districts collection"
    );
    throw new Error("District not found in district collection");
  }
  return {
    districts: response,
    selectedDistrict: selectedDistrict,
  };
};

export const getSchools = async ({ selectedDistrict, schoolId }: GetSchoolsProps) => {
  const response = await getFBDocs<SchoolRecord>({
    col: "schools",
    config: { where: ["districtId", "==", selectedDistrict.id] },
  });

  const selectedSchool = schoolId ? response.find((school) => school.id === schoolId) : response[0];

  if (!selectedSchool) {
    console.error(
      "[StaffDatabaseProvider] the selected school Id for the user was not found in the schools collection"
    );
    throw new Error("School not found in school collection");
  }

  return {
    schools: parseSchoolResponse(response),
    selectedSchool: selectedSchool,
  };
};

export const getAnswers = async ({ studentId, questionId }: GetAnswersProps) => {
  const response = await getFBDocs<AnswerRecord>({
    col: Collection.Answers,
    config: { where: ["studentId", "==", studentId] },
  });

  const selectedAnswer = questionId
    ? response.find((answer) => answer.questionId === questionId)
    : response[0];

  if (!selectedAnswer) {
    console.error(
      "[StaffDatabaseProvider] the selected question Id for the answer was not found in the answers collection"
    );
  }

  return {
    answers: response,
    selectedAnswer: selectedAnswer,
  };
};

export const generateRandomId = () => {
  const length = 24;
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
