import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { Color } from "../../../shared/types/enums";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import DisplayBox from "../../../shared/components/DisplayBox";
import PreferencesDetails from "./PreferenceDetails";

const Preferences = () => {
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DisplayBox title="My Preferences">
          <PreferencesDetails />
        </DisplayBox>
      </Box>
    </>
  );
};

export default Preferences;
