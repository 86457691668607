import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { Color } from "../../../shared/types/enums";
import { useRecoilValue } from "recoil";
import { stagesAtom } from "../../../shared/recoil/stageAtoms";
import DisplayBox from "../../../shared/components/DisplayBox";

type Props = {
  stageId: string;
};

const StageProgressBar = ({ stageId }: Props) => {
  const stages = useRecoilValue(stagesAtom);
  const currentStage = stages.find((stage) => stage.id === stageId);
  const currentStageIndex = stages.findIndex((stage) => stage.id === stageId);

  type FormatColorProps = {
    index: number;
    currentStageIndex: number;
  };

  const formatDotColor = ({ index, currentStageIndex }: FormatColorProps) => {
    if (index === currentStageIndex) {
      return Color.Chalkboard;
    } else if (index < currentStageIndex) {
      return Color.Mint;
    } else {
      return "#999";
    }
  };

  const formatLineColor = ({ index, currentStageIndex }: FormatColorProps) => {
    if (index === currentStageIndex) {
      return "#999";
    } else if (index < currentStageIndex) {
      return Color.Mint;
    } else {
      return "#999";
    }
  };
  return (
    <>
      <DisplayBox title="Current Stage">
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 2 }}>
          {stages.map((stage, index) => (
            <Box key={stage.id} sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip key={stage.id} title={stage.name} placement="top">
                <CircleIcon
                  sx={{
                    fontSize: 18,
                    color: formatDotColor({ index, currentStageIndex }),
                  }}
                />
              </Tooltip>
              {index !== stages.length - 1 && (
                <div
                  style={{
                    height: 0,
                    borderTop: "1px solid",
                    borderColor: formatLineColor({
                      index: index,
                      currentStageIndex,
                    }),
                    width: 44,
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        {currentStage && (
          <>
            <Typography textAlign="center" variant="h3" sx={{ mt: 1, fontSize: 22, pb: 2 }}>
              {currentStage.name}
            </Typography>
          </>
        )}
      </DisplayBox>
    </>
  );
};

export default StageProgressBar;
