import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  setProviderNameSearch: React.Dispatch<React.SetStateAction<string>>;
  providerNameSearch: string;
};

type ProviderObject = {
  providerName: string;
};

export default function ProviderSearch({ setProviderNameSearch, providerNameSearch }: Props) {
  const [options, setOptions] = React.useState<readonly { providerName: string }[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    (async () => {
      await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/searchProviderNames`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ providerNameSearch: "" }),
      })
        .then((res) => res.json())
        .then((data: { rows: ProviderObject[] }) => {
          const originalArray = data.rows;
          const uniqueProviders = Array.from(new Set(originalArray.map((obj) => obj.providerName)))
            .map((providerName) => originalArray.find((obj) => obj.providerName === providerName))
            .filter(Boolean) as ProviderObject[];
          setOptions(uniqueProviders);
          setLoading(false);
        });
    })();
  }, []);

  return (
    <Autocomplete
      id="provider-search"
      sx={{mt: 2}}
      getOptionLabel={(option) => option.providerName}
      options={options}
      onChange={(event: any, newValue: ProviderObject | null) => {
        setProviderNameSearch(newValue?.providerName || "");
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search For Institutions"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
