import React, { useEffect, useState } from "react";
import { StaffRecord, StudentRecord } from "../../../shared/types/types";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { Collection } from "../../../shared/types/enums";
import { Box, Button } from "@mui/material";
import useFetch from "../../../shared/hooks/useFetch";

const AddUIDUsers = () => {
  const { getFromAPI } = useFetch();

  const handleClick = async () => {
    const studentDocRef = collection(db, Collection.Students);
    const staffDocRef = collection(db, Collection.Staff);
    const studentsSnapshot = await getDocs(studentDocRef);
    const staffSnapshot = await getDocs(staffDocRef);
    const staffArray: StaffRecord[] = [];
    const studentsArray: StudentRecord[] = [];
    studentsSnapshot.forEach((doc) => {
      studentsArray.push({ ...(doc.data() as StudentRecord), id: doc.id });
    });
    staffSnapshot.forEach((doc) => {
      staffArray.push({ ...(doc.data() as StaffRecord), id: doc.id });
    });
    studentsArray.forEach(async (student, i) => {
      const uid = await getFromAPI<{ uid: string } | null>({
        url: "/auth/getUID",
        body: { email: student.schoolEmail },
      });
      if (!uid) return;
      console.log("UID", uid);

      setDoc(doc(db, Collection.Students, uid.uid), {
        ...student,
        uidId: true,
        oldId: student.id,
      });
    });

    staffSnapshot.forEach((doc) => {
      staffArray.push({ ...(doc.data() as StaffRecord), id: doc.id });
    });
    staffArray.forEach(async (staff, i) => {
      const uid = await getFromAPI<{ uid: string } | null>({
        url: "/auth/getUID",
        body: { email: staff.email },
      });
      if (!uid) return;
      console.log("UID", uid);

      setDoc(doc(db, Collection.Staff, uid.uid), {
        ...staff,
        uidId: true,
        oldId: staff.id,
      });
    });
  };
  return (
    <Box sx={{ mt: 2 }}>
      <Button onClick={handleClick} variant="contained">
        Add UID Users
      </Button>
    </Box>
  );
};

export default AddUIDUsers;
