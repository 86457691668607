import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import useGetDocs from "../../hooks/useGetDocs";
import { CareerRecord } from "../../types/types";
import { Collection } from "../../types/enums";

const Overview = () => {
  const willowSubIndustryId = "1001";
  const { sendRequest: getDocs } = useGetDocs();
  useEffect(() => {
    const getCareers = async () => {
      const response = await getDocs<CareerRecord>({
        col: Collection.SOC_CIP,
        config: { where: ["willowSubIndustryId", "==", willowSubIndustryId] },
      });
    };
    getCareers();
  });
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Explore Careers: Artists and Designers
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          The art and design world is bursting with creativity! People in these fields use their
          artistic talents and design sense to visually communicate ideas. This could involve
          crafting illustrations for a children's book, designing user-friendly websites, or even
          creating special effects for movies. If you have a passion for art, design, and
          innovation, this field offers a vast array of exciting career paths.
        </Typography>
      </Box>
    </>
  );
};

export default Overview;
