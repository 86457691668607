import React, { useCallback, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import { Box, Chip, Divider, IconButton, Typography } from "@mui/material";
import { questionsAtom } from "../../../shared/recoil/questionAtoms";
import EditDetailsDialog from "./EditDetailsDialog";
import { allEnrolledSchoolStudentsAtom, selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import ConsentToContactDialog from "./ConsentToContactDialog";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import FeatherIcon from "../../../shared/components/FeatherIcon";
import { Collection, Color } from "../../../shared/types/enums";

// TODO - use other modal for edit details

const PreferencesDetails = () => {
  const answers = useRecoilValue(studentAnswersAtom);
  const questions = useRecoilValue(questionsAtom);
  const [editQuestionId, setEditQuestionId] = useState<string | null>(null);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const setAllEnrolledSchoolStudents = useSetRecoilState(allEnrolledSchoolStudentsAtom);

  const formatOnline = () => {
    if (!answers) return;
    const answer = answers.find((answer) => answer.questionId === "online")?.mc ?? 0;
    const question = questions?.find((question) => question.id === "online");
    if (!question) return "";
    const answerText = question.options.find((a) => a.id === Number(answer))?.text;
    return <Typography>{answerText ?? ""}</Typography>;
  };

  const formatFinancialAid = useCallback(() => {
    if (!answers) return;
    const financialAidAnswers =
      answers.find((answer) => answer.questionId === "financial_aid")?.ms ?? [];
    const question = questions?.find((question) => question.id === "financial_aid");
    if (!question) return "";
    return question.options.map((option) =>
      financialAidAnswers.includes(option.id) ? (
        <Chip
          sx={{
            ml: 1,
            mb: 1,
            height: "auto",
            "& .MuiChip-label": {
              display: "block",
              whiteSpace: "normal",
              padding: 1,
            },
            mt: 1,
          }}
          color="surfaceGreen"
          key={option.id}
          label={<Typography key={option.id}>{option.text}</Typography>}
        />
      ) : (
        ""
      )
    );
  }, [answers, questions]);

  const formatGeography = useCallback(() => {
    if (!answers) return;
    const geographyAnswers = answers.find((answer) => answer.questionId === "geography")?.ms ?? [];
    const question = questions?.find((question) => question.id === "geography");
    if (!question) return "";
    return geographyAnswers.map((option) => (
      <Chip
        sx={{
          ml: 1,
          mb: 1,
          height: "auto",
          "& .MuiChip-label": {
            display: "block",
            whiteSpace: "normal",
            padding: 1,
          },
          mt: 1,
        }}
        color="surfaceGreen"
        key={option}
        label={<Typography key={option}>{option}</Typography>}
      />
    ));
  }, [answers, questions]);

  const formatPreferredProgram = () => {
    if (!answers) return;
    const answer = answers.find((answer) => answer.questionId === "certification")?.mc ?? 0;
    const question = questions?.find((question) => question.id === "certification");
    if (!question) return "";
    const answerText = question.options.find((a) => a.id === answer)?.text;
    return <Typography>{answerText ?? ""}</Typography>;
  };

  const handleEditClick = (questionId: string) => {
    setEditQuestionId(questionId);
  };

  const handleConsent = (consent: string) => {
    if (!selectedStudent) return;
    updateDoc({
      col: Collection.Students,
      id: selectedStudent.id,
      data: {
        consentToContact: consent,
      },
    });
    setSelectedStudent((pV) => (pV ? { ...pV, consentToContact: consent } : null));
    setAllEnrolledSchoolStudents((pV) =>
      pV.map((student) =>
        student.id === selectedStudent.id ? { ...student, consentToContact: consent } : student
      )
    );
    setEditQuestionId(null);
  };

  return (
    <>
      <Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
            }}
          >
            <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
              Are you open to online programs?
            </Typography>
            <IconButton
              sx={{ color: Color.Standard_Gray }}
              disableRipple
              onClick={() => handleEditClick("online")}
            >
              <FeatherIcon width="20px" icon="edit-2" />
            </IconButton>
          </Box>
          <Chip color="surfaceGreen" sx={{ ml: 1, mb: 1 }} label={formatOnline()} />
          <Divider />
          <Box
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}
          >
            <Typography sx={{ fontWeight: "800" }} variant="body1">
              Do you qualify for financial aid?
            </Typography>
            <IconButton
              sx={{ color: Color.Standard_Gray }}
              disableRipple
              onClick={() => handleEditClick("financial_aid")}
            >
              <FeatherIcon width="20px" icon="edit-2" />
            </IconButton>
          </Box>
          {formatFinancialAid()}
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
            }}
          >
            <Typography sx={{ fontWeight: "800" }} variant="body1">
              Preferred program type:
            </Typography>
            <IconButton
              sx={{ color: Color.Standard_Gray }}
              disableRipple
              onClick={() => handleEditClick("certification")}
            >
              <FeatherIcon width="20px" icon="edit-2" />
            </IconButton>
          </Box>
          <Chip
            color="surfaceGreen"
            sx={{
              ml: 1,
              mb: 1,
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
                padding: 1,
              },
            }}
            label={formatPreferredProgram()}
          />
          <Divider />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
            }}
          >
            <Typography sx={{ fontWeight: "800" }} variant="body1">
              Location
            </Typography>
            <IconButton
              sx={{ color: Color.Standard_Gray }}
              disableRipple
              onClick={() => handleEditClick("geography")}
            >
              <FeatherIcon width="20px" icon="edit-2" />
            </IconButton>
          </Box>
          {formatGeography()}
          <Divider />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
            }}
          >
            <Typography sx={{ fontWeight: "800" }} variant="body1">
              Can programs you've hearted reach out to you?
            </Typography>
            <IconButton
              sx={{ color: Color.Standard_Gray }}
              disableRipple
              onClick={() => handleEditClick("consent-to-contact")}
            >
              <FeatherIcon width="20px" icon="edit-2" />
            </IconButton>
          </Box>
          <Chip
            color="surfaceGreen"
            sx={{
              ml: 1,
              mb: 1,
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
                padding: 1,
              },
            }}
            label={selectedStudent?.consentToContact ?? ""}
          />
        </Box>
      </Box>
      <EditDetailsDialog editQuestionId={editQuestionId} setEditQuestionId={setEditQuestionId} />
      <ConsentToContactDialog
        modalOpen={editQuestionId === "consent-to-contact"}
        handleConsent={handleConsent}
      />
    </>
  );
};

export default PreferencesDetails;
