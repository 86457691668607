import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { Typography } from "@mui/material";
import { Color } from "../types/enums";
import { useAuth } from "../contexts/AuthContext";

const LoadingPage = () => {
  const { width } = useWindowDimensions();
  const { currentAuthUser } = useAuth();
  const generateIconWidth = () => {
    if (width < 600) {
      return "100VW";
    } else if (width < 960) {
      return "80VW";
    } else {
      return "40VW";
    }
  };

  const generateLogoWidth = () => {
    if (width < 600) {
      return "50%";
    } else if (width < 960) {
      return "40%";
    } else {
      return "30%";
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20VH",
        }}
      >
        {currentAuthUser && (
          <Typography textAlign={"center"} variant="h2" sx={{ mb: 2, color: Color.Mint }}>
            Loading All Your Willow Data
          </Typography>
        )}
        {!currentAuthUser && (
          <Typography textAlign={"center"} variant="h2" sx={{ mb: 2, color: Color.Mint }}>
            Getting You Logged In
          </Typography>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Player
          autoplay={true}
          loop={true}
          src="https://lottie.host/c682eafd-d74c-4b11-81eb-66b219887f46/eVvbmdFgTE.json"
          style={{ width: generateIconWidth() }}
        ></Player>
        {/* <img src="../../inline-mint.png" alt="logo" width={generateLogoWidth()} /> */}
      </div>
    </>
  );
};

export default LoadingPage;
