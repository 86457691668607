import { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import useFetch from "../useFetch";

type Props = {
  setOpen: (open: boolean) => void;
};

type Return = {
  email: string;
  resetError: string;
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleReset: () => void;
  handleClose: () => void;
  isValidEmail: (email: string) => boolean;
};

const useResetPasswordDialog = ({ setOpen }: Props): Return => {
  const { getFromAPI } = useFetch();
  const { resetPassword } = useAuth();
  const [email, setEmail] = useState("");
  const [resetError, setResetError] = useState("");

  const handleClose = () => {
    setEmail("");
    setOpen(false);
  };

  const handleReset = async () => {
    const results = await getFromAPI({ url: "/auth/getUID", body: { email } });
    if (results === null) {
      setResetError("Your email doesn't exist");
    } else {
      setResetError("");
      resetPassword(email);
      setEmail("");
      setOpen(false);
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return {
    email,
    resetError,
    handleEmailChange,
    handleReset,
    handleClose,
    isValidEmail,
  };
};

export default useResetPasswordDialog;
