import { ChangeEvent, Dispatch, useCallback, useEffect, useState } from "react";
import { GroupRecord, StaffRecord, StudentRecord } from "../../../shared/types/types";
import useAddDoc from "../../../shared/hooks/useAddDoc";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { SelectChangeEvent } from "@mui/material";
import { groupsAtom, selectedSchoolAtom } from "../../../shared/recoil/districtAtoms";
import { allEnrolledSchoolStudentsAtom, staffAtom } from "../../../shared/recoil/userAtoms";

type Props = {
  groupForm: GroupRecord;
  setGroupForm: Dispatch<React.SetStateAction<GroupRecord>>;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const useManageGroupDialog = ({ groupForm, setGroupForm, open, setOpen }: Props) => {
  const { sendRequest: addDoc } = useAddDoc();
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setGroups = useSetRecoilState(groupsAtom);
  const selectedSchool = useRecoilValue(selectedSchoolAtom);
  const students = useRecoilValue(allEnrolledSchoolStudentsAtom);
  const staff = useRecoilValue(staffAtom);
  const [selectedStudents, setSelectedStudents] = useState<StudentRecord[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<StaffRecord[]>([]);

  useEffect(() => {
    if (!students || !staff) return;
    setSelectedStaff(staff.filter((s) => groupForm.staffIds.includes(s.id)));
    setSelectedStudents(students.filter((student) => groupForm.studentIds.includes(student.id)));
  }, [groupForm.staffIds, groupForm.studentIds, staff, students]);

  const handleStudentSelect = useCallback(
    (event: any, values: StudentRecord[]) => {
      const currentSelectedStudents: StudentRecord[] = [];
      const currentSelectedStudentIds: string[] = [];
      values.forEach((s) => {
        currentSelectedStudents.push(s);
        currentSelectedStudentIds.push(s.id);
      });
      setSelectedStudents(currentSelectedStudents);
      setGroupForm((pV) => {
        return { ...pV, studentIds: currentSelectedStudentIds };
      });
    },
    [setGroupForm]
  );

  const handleStaffSelect = useCallback(
    (event: any, values: StaffRecord[]) => {
      const currentSelectedStaff: StaffRecord[] = [];
      const currentSelecteStaffIds: string[] = [];
      values.forEach((s) => {
        currentSelectedStaff.push(s);
        currentSelecteStaffIds.push(s.id);
      });
      setSelectedStaff(currentSelectedStaff);
      setGroupForm((pV) => {
        return { ...pV, staffIds: currentSelecteStaffIds };
      });
    },
    [setGroupForm]
  );

  const handleTextChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setGroupForm((pV) => {
      return { ...pV, [name as keyof StudentRecord]: value };
    });
  };

  const handleSelectChange = ({ target: { name, value } }: SelectChangeEvent) => {
    setGroupForm((pV) => {
      return { ...pV, [name as keyof StudentRecord]: value };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    updateDoc({ col: "groups", id: groupForm.id, data: { active: false } });
    setGroups((pV) => pV.filter((group) => group.id !== groupForm.id));
  };

  const handleSave = async () => {
    if (!selectedSchool) return;
    setOpen(false);
    if (groupForm.id === "") {
      const response = await addDoc({
        col: "groups",
        data: {
          ...groupForm,
          schoolId: selectedSchool.id,
        },
      });
      if (!response) return;
      setGroups((pV) => {
        const newArray = [
          ...pV,
          {
            ...groupForm,
            id: response.id,
            createdAt: response.createdAt,
            lastUpdatedAt: response.lastUpdatedAt,
            schoolId: selectedSchool.id,
          },
        ];
        newArray.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        return newArray;
      });
    } else {
      updateDoc({
        col: "groups",
        id: groupForm.id,
        data: {
          ...groupForm,
        },
      });
      setGroups((pV) => {
        const newArray = pV.map((group) => {
          if (group.id === groupForm.id) {
            return {
              ...group,
              ...groupForm,
            };
          } else {
            return group;
          }
        });
        newArray.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        return newArray;
      });
    }
  };
  return {
    handleTextChange,
    handleClose,
    handleSave,
    handleSelectChange,
    handleDelete,
    students,
    handleStudentSelect,
    selectedStudents,
    handleStaffSelect,
    selectedStaff,
    staff,
  };
};

export default useManageGroupDialog;
