import React from "react";
import Navbar from "../../shared/components/navigation/Navbar";
import { Box } from "@mui/material";
import ProviderDashboardContainer from "../components/dashboard/ProviderDashboardContainer";

const Dashboard = () => {
  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Navbar open={false} title="Dashboard" />
          <Box sx={{ minHeight: "calc(100VH-64px)", pt: 1, pr: 4, pl: 4 }}>
            <ProviderDashboardContainer />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
