import { useEffect, useState } from "react";
import { Program, Provider } from "../../../shared/types/types";
import { BLANK_PROGRAM_FORM } from "../../../shared/libraries/blankForms";
import { selectedProgramAtom } from "../../../shared/recoil/providerAtoms";
import { useSetRecoilState } from "recoil";

type Props = {
  provider: Provider;
  program?: Program | null;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const useManageProgramDialog = ({ provider, program, setOpen }: Props) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<Program>(BLANK_PROGRAM_FORM);
  const setSelectedProgram = useSetRecoilState(selectedProgramAtom);

  useEffect(() => {
    if (program) {
      setForm({
        id: program.id || "",
        providerId: program.providerId || "",
        durationYears:
          program.durationYears !== undefined && program.durationYears !== null
            ? program.durationYears
            : null,
        durationMonths:
          program.durationMonths !== undefined && program.durationMonths !== null
            ? program.durationMonths
            : null,
        cipCode: program.cipCode || "",
        willowIndustryId: program.willowIndustryId || "",
        willowSubIndustryId: program.willowSubIndustryId || "",
        credentialLevel: program.credentialLevel || "",
        programName: program.programName || "",
        programShortDescription: program.programShortDescription || "",
        programDescription: program.programDescription || "",
        averageTotalCost:
          program.averageTotalCost !== undefined && program.averageTotalCost !== null
            ? program.averageTotalCost
            : null,
        commitment: program.commitment || "",
        modality: program.modality || "",
        onlineOnly: program.onlineOnly || false,
        completionRateIn6Years:
          program.completionRateIn6Years !== undefined && program.completionRateIn6Years !== null
            ? program.completionRateIn6Years
            : null,
        startingSalary:
          program.startingSalary !== undefined && program.startingSalary !== null
            ? program.startingSalary
            : null,
        willowROI:
          program.willowROI !== undefined && program.willowROI !== null ? program.willowROI : null,
        applyURL: program.applyURL || "",
        rateOfEmployment:
          program.rateOfEmployment !== undefined && program.rateOfEmployment !== null
            ? program.rateOfEmployment
            : null,
        departmentLink: program.departmentLink || "",
        programIsActive: program.programIsActive || false,
        inAlgorithm: program.inAlgorithm || false,
        autoAdmit: program.autoAdmit || false,
        autoScholarship: program.autoScholarship || false,
        willowWeight:
          program.willowWeight !== undefined && program.willowWeight !== null
            ? program.willowWeight
            : 0,
      });
    } else {
      setForm({ ...BLANK_PROGRAM_FORM });
    }
  }, [program]);

  const handleSave = async () => {
    setLoading(true);
    const COMP_EARNINGS = 30000;
    const lifetmieDiffInPay = (form?.startingSalary ?? 0 - COMP_EARNINGS) * 42;
    const compoundedEarnings = lifetmieDiffInPay / 3.46;
    const programCostsAndLostWages =
      ((form?.averageTotalCost ?? 0) + COMP_EARNINGS) *
      (form?.durationYears ?? 0 + (form?.durationMonths ?? 0) / 12);
    form.willowROI =
      (compoundedEarnings - programCostsAndLostWages) * (form?.completionRateIn6Years ?? 0);

    try {
      if (program) {
        await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/updateColumns`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tableName: "programs",
            idColumn: "id",
            id: program.id,
            data: [form],
          }),
        });
      } else {
        const credentialLevel =
          form.credentialLevel === "Associate's Degree"
            ? 2
            : form.credentialLevel === "Bachelor's Degree"
            ? 3
            : 1;
        await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/createRows`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tableName: "programs",
            data: [
              {
                ...form,
                providerId: provider.id,
                id: provider.id + "_" + form.cipCode + "_" + credentialLevel,
              },
            ],
          }),
        });
      }
    } catch (error) {
      console.error("Upload error:", error);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({ ...BLANK_PROGRAM_FORM });
    setSelectedProgram(null);
  };
  return { form, setForm, handleSave, loading, handleClose };
};

export default useManageProgramDialog;
