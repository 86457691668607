import React from "react";
import Navbar from "../../shared/components/navigation/Navbar";
import { Container } from "@mui/material";
import DistrictSettingsContainer from "../components/settings/DistrictSettingsContainer";

const DistrictSettingsPage = () => {
  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ minHeight: "calc(100VH-64px)", mt: 8, pt: 2 }}>
        <DistrictSettingsContainer />
      </Container>
    </>
  );
};

export default DistrictSettingsPage;
