import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../shared/contexts/AuthContext";
import { useUser } from "../../../shared/contexts/UserContext";
import { useRecoilValue } from "recoil";
import { loggedInMentorAtom } from "../../../shared/recoil/userAtoms";
import { useEffect } from "react";
import { PageRoute } from "../../../shared/types/enums";
import { User } from "firebase/auth";

type Return = {
  currentAuthUser: User | null;
};

const useMentorLoginContainer = (): Return => {
  const navigate = useNavigate();
  const { currentAuthUser, setLoginError, logout } = useAuth();
  const { loading: userLoading } = useUser();
  
  const loggedInMentor = useRecoilValue(loggedInMentorAtom);

  useEffect(() => {
    if (!currentAuthUser || userLoading) return;
    if (loggedInMentor) {
      navigate(PageRoute.Mentor_Dashboard);
      return;
    } else {
      setLoginError(
        "You do not have an account with us. In order to obtain an account, a student must first add you from their account."
      );
      logout();
      return;
    }
  }, [loggedInMentor, navigate, currentAuthUser, userLoading]);

  return {
    currentAuthUser,
  };
}

export default useMentorLoginContainer;