import { DataGridPro, GridRowParams, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { Avatar, Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { StudentRecord } from "../../../shared/types/types";
import { allEnrolledSchoolStudentsAtom } from "../../../shared/recoil/userAtoms";
import { Dispatch, useEffect, useState } from "react";
import useGetImage from "../../../shared/hooks/useGetImage";
import { formatPhoneNumber } from "../../../shared/utils/functions";
// import { staffColumns } from "../../libraries/columns";
const KEY = process.env.REACT_APP_DATATABLE_KEY ?? "";
LicenseInfo.setLicenseKey(KEY);

type Props = {
  setManageStudentOpen: Dispatch<React.SetStateAction<boolean>>;
  setStudentForm: Dispatch<React.SetStateAction<StudentRecord>>;
};

const StudentsTable = ({ setManageStudentOpen, setStudentForm }: Props) => {
  const students = useRecoilValue(allEnrolledSchoolStudentsAtom);
  const [rows, setRows] = useState<StudentRecord[]>([]);
  const { getImage } = useGetImage();

  useEffect(() => {
    const rowsSetter = async () => {
      if (!students) return;
      const newRows = await Promise.all(
        students.map(async (student) => {
          const avatarURL = student.avatar.length > 5 ? await getImage(student.avatar) : "";
          return { ...student, avatarURL };
        })
      );
      setRows(newRows);
    };
    rowsSetter();
  }, [getImage, students]);

  const studentColumns = [
    {
      field: "avatarURL",
      headerName: "",
      width: 80,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      renderCell: (params: GridRenderCellParams<StudentRecord>) => (
        <Avatar
          alt={params.row.firstName + " " + params.row.lastName}
          src={params.row.avatarURL ? params.row.avatarURL : ""}
          sx={{ width: 50, height: 50, mt: 1, mb: 1 }}
        />
      ),
    },
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 100,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 100,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      flex: 1,
    },
    {
      field: "schoolEmail",
      headerName: "Email",
      minWidth: 100,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 100,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      flex: 1,
      renderCell: (params: GridRenderCellParams<StudentRecord>) => (
        <>
          {params.row.phone !== "null"
            ? formatPhoneNumber(params.row.phone)
            : "No Phone Number Added"}
        </>
      ),
    },
  ];

  const onRowClick = (row: StudentRecord) => {
    setStudentForm(row);
    setManageStudentOpen(true);
  };
  return (
    <>
      {students && (
        <Box
          sx={{
            height: students.length > 5 ? 500 : students.length * 100 + 165,
            width: "100%",
          }}
          id="app-manager-table"
        >
          <div
            style={{
              display: "flex",
              height: "100%",
            }}
          >
            <div style={{ flexGrow: 1, maxWidth: "100%" }}>
              <DataGridPro
                rows={rows}
                columns={studentColumns}
                rowHeight={60}
                onRowClick={(data: GridRowParams) => onRowClick(data.row)}
                getRowId={(row) => row.id}
                disableColumnMenu
                disableColumnResize
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
                }
                sx={{ fontSize: 12, border: "none", height: "100%" }}
              />
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default StudentsTable;
