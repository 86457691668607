import {
  formatPhoneNumber as formatPhoneNumberLocal,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { Color, HollandType } from "../types/enums";
import { AnswerRecord, QuestionRecord, StudentRecord, StudentsAllForExport } from "../types/types";
import { mostCommonPasswords } from "../libraries/static";

type DetermineHollandResultsProps = {
  answers: AnswerRecord[];
  questions: QuestionRecord[];
};

type MostInterestedProps = {
  questions: QuestionRecord[];
  studentAnswers: AnswerRecord[];
  studentId: string;
};

export const validatePassword = (password: string) => {
  // Check if the password is at least 8 characters long.
  if (password.length < 8) {
    return false;
  }

  // Check if the password contains at least one uppercase letter.
  if (!/[A-Z]/.test(password)) {
    return false;
  }

  // Check if the password contains at least one lowercase letter.
  if (!/[a-z]/.test(password)) {
    return false;
  }

  // Check if the password contains at least one number.
  if (!/[0-9]/.test(password)) {
    return false;
  }

  // Check if the password does not contain any common words or easily guessed patterns.
  for (const word of mostCommonPasswords) {
    if (password === word) {
      return false;
    }
  }

  // The password is valid.
  return true;
};

export const determineHollandResults = ({ answers, questions }: DetermineHollandResultsProps) => {
  if (questions.length === 0 || answers.length === 0) return;
  const points: { [key: string]: number } = {
    [HollandType.Realistic]: 0,
    [HollandType.Investigative]: 0,
    [HollandType.Artistic]: 0,
    [HollandType.Social]: 0,
    [HollandType.Enterprising]: 0,
    [HollandType.Conventional]: 0,
  };
  answers.forEach((answer) => {
    const question = questions.find((q) => q.id === answer.questionId);
    if (!question) return;
    const answerChoice = question.options.find((o) => o.id === answer.mc);
    points[answerChoice?.hollandType ?? ""] += 1;
  });
  //TODO: need to track down why this is happening
  delete points[""];
  const max = Math.max(...Object.values(points));
  const results = Object.entries(points).filter(([_, value]) => value === max);
  if (results.length === 0) return;
  return results[0][0] as HollandType;
};

export const formatCredentialCard = (credential: string | null) => {
  switch (credential) {
    case "Certificate":
      return "lightPink";
    case "Certification":
      return "lightPink";
    case "Credential":
      return "lightPink";
    case "Apprenticeship":
      return "lightPurple";
    case "Training":
      return "lightOrange";
    case "Associate's Degree":
      return "lightGreen";
    case "Bachelor's Degree":
      return "lightBlue";
    case "Skill":
      return "lightOrange";
    case "Training & License":
      return "lightOrange";
    case "Undergraduate Certificate or Diploma":
      return "lightPink";
    default:
      return "lightPink";
  }
};

export const formatCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const formatPercentage = (num: number) => {
  return `${Math.round(num * 100)}%`;
};

export const mostInterested = ({ questions, studentAnswers, studentId }: MostInterestedProps) => {
  const pathwayQuestion = questions.find((question) => question.id === "pathways");
  if (!pathwayQuestion) return [0];
  const mostIntersestedHeaderId = pathwayQuestion.matrixHeaders.find(
    (header) => header.text === "Most Interested"
  )?.id;
  if (!mostIntersestedHeaderId) return [0];
  const pathwayAnswer = studentAnswers.find((answer) => answer.questionId === "pathways");
  if (!pathwayAnswer) return [0];
  const pathwayOptions = [...pathwayQuestion.options];
  const mostInterested = pathwayAnswer.matrix.filter(
    (answer) => answer.headerId === mostIntersestedHeaderId
  );
  const mostInterestedOptionIds = mostInterested.map(
    (answer) => pathwayOptions.find((option) => option.id === answer.optionId)?.id ?? 0
  );
  return mostInterestedOptionIds;
};

export const calculateBarWidth = ({
  filteredStudents,
  allEnrolledGroupStudents,
}: {
  filteredStudents: StudentRecord[];
  allEnrolledGroupStudents: StudentRecord[];
}) => {
  return filteredStudents.length > 0
    ? filteredStudents.length / allEnrolledGroupStudents.length
    : 0;
};

export const numberToCurrency = (number: number) => {
  return formatCurrency.format(number).slice(0, -3);
};

export const numberToPercent = (number: number) => {
  return `${Math.round(number * 100)}%`;
};

export const numberToRound = (number: number) => {
  return Math.round(number).toLocaleString();
};

export const convertToCSV = (arr: any[]) => {
  let csv = "";
  for (let row = 0; row < arr.length; row++) {
    let keysAmount = Object.keys(arr[row]).length;
    let keysCounter = 0;

    // If this is the first row, generate the headings
    if (row === 0) {
      // Loop each property of the object
      for (let key in arr[row]) {
        // This is to not add a comma at the last cell
        // The '\r\n' adds a new line
        csv += key + (keysCounter + 1 < keysAmount ? "," : "\r\n");
        keysCounter++;
      }
    } else {
      for (let key in arr[row]) {
        csv += arr[row][key] + (keysCounter + 1 < keysAmount ? "," : "\r\n");
        keysCounter++;
      }
    }

    keysCounter = 0;
  }
  return csv;
};
export const generateStudentExportBlankObj = (student: StudentRecord) => {
  const studentsExportData: StudentsAllForExport = {
    firstName: student.firstName,
    lastName: student.lastName,
    schoolEmail: student.schoolEmail,
    providerName1: "",
    programName1: "",
    program1hearted: "No",
    providerName2: "",
    programName2: "",
    program2hearted: "No",
    providerName3: "",
    programName3: "",
    program3hearted: "No",
    providerName4: "",
    programName4: "",
    program4hearted: "No",
    providerName5: "",
    programName5: "",
    program5hearted: "No",
    providerName6: "",
    programName6: "",
    program6hearted: "No",
  };
  return studentsExportData;
};

type FormatColorProps = {
  index: number;
  currentStageIndex: number;
};

export const formatDotColor = ({ index, currentStageIndex }: FormatColorProps) => {
  if (index === currentStageIndex) {
    return Color.Chalkboard;
  } else if (index < currentStageIndex) {
    return Color.Mint;
  } else {
    return "#999";
  }
};

export const formatLineColor = ({ index, currentStageIndex }: FormatColorProps) => {
  if (index === currentStageIndex) {
    return "#999";
  } else if (index < currentStageIndex) {
    return Color.Mint;
  } else {
    return "#999";
  }
};

export const formatMessageTime = (timestamp: string, shortTime: boolean = false): string => {
  const messageDate = new Date(timestamp);
  const now = new Date();

  const formattedDate = messageDate.toLocaleString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const formattedDateWithoutDate = messageDate.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const timeDiff = now.getTime() - messageDate.getTime();
  const dayDiff = timeDiff / (1000 * 3600 * 24);

  if (dayDiff < 1) {
    return shortTime ? "Today" : "Today " + formattedDateWithoutDate;
  } else if (dayDiff < 2) {
    return shortTime ? "Yesterday" : "Yesterday " + formattedDateWithoutDate;
  } else if (dayDiff < 7) {
    return shortTime ? Math.floor(dayDiff) + " days ago" : formattedDate;
  } else {
    const weeksAgo = Math.floor(dayDiff / 7);
    const weeksAgoStr = weeksAgo === 1 ? "1 week ago" : weeksAgo + " weeks ago";
    return shortTime ? weeksAgoStr : formattedDate;
  }
};

export const formatBirthday = (birthday: string): string => {
  const dateObject = new Date(birthday);

  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  const year = dateObject.getFullYear();

  const formattedBirthday = `${month}/${day}/${year}`;

  return formattedBirthday;
};

export const verifyPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber) {
    return isValidPhoneNumber("+" + phoneNumber, "US");
  }
  return false;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber) {
    return formatPhoneNumberLocal("+" + phoneNumber);
  } else {
    return "";
  }
};
