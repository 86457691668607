/**
 * Represents a dialog component for the student dashboard.
 * @param {boolean} open - Determines whether the dialog is open or not.
 * @param {Dispatch<React.SetStateAction<boolean>>} setOpen - Function to set the open state of the dialog.
 * @returns {JSX.Element} The rendered StudentDashboardDialog component.
 */

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Dispatch } from "react";
import StudentOverviewContainer from "../studentOverview/StudentOverviewContainer";
import useWindowDimensions from "../../../shared/hooks/useWindowDimensions";
import { useSetRecoilState } from "recoil";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";

const BGCOLOR = "#fbfbfb";

type Props = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const StudentDashboardDialog = ({ open, setOpen }: Props) => {
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);
  const handleClose = () => {
    setOpen(false);
    setSelectedStudent(null);
  };
  const { width } = useWindowDimensions();

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      PaperProps={{ className: width < 900 ? "mobileDialog" : "" }}
    >
      <DialogContent sx={{ backgroundColor: BGCOLOR, padding: width < 900 ? 1 : 2, margin: 0 }}>
        <StudentOverviewContainer />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StudentDashboardDialog;
