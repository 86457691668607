import { Box, Button, Chip, Grid, IconButton, Paper, Typography } from "@mui/material";
import { formatCredentialCard, formatCurrency, numberToPercent } from "../../utils/functions";
import { Dispatch, SetStateAction, useState } from "react";
import { Color, SelectedProgramSource } from "../../types/enums";
import WhatIsROIDialog from "../whatIdROI/WhatIsROIDialog";
import { Provider_Program } from "../../types/types";
import useProgramCard from "../../hooks/programCard/useProgramCard";
import AutoAdmit from "./AutoAdmit";
import Reactions from "./Reactions";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../recoil/userAtoms";
import ProgramDescription from "./ProgramDescription";
import FeatherIcon from "../FeatherIcon";
import WhatIsRTSSchool from "../whatIsRTSSchool/WhatIsRTSSchool";

type Props = {
  program: Provider_Program;
  source: SelectedProgramSource;
  showDeleteButton?: boolean;
  isEditable?: boolean;
  setSelectedProgram?: Dispatch<SetStateAction<Provider_Program | null>>;
  setRecommendOpen?: Dispatch<SetStateAction<boolean>>;
  setSelectedLearnMoreProgram?: Dispatch<SetStateAction<Provider_Program | null>>;
};

const ProgramCard = ({
  program,
  source,
  showDeleteButton = true,
  isEditable = true,
  setSelectedLearnMoreProgram,
  setRecommendOpen,
  setSelectedProgram,
}: Props) => {
  const { selectedStudent, renderDuration, lowIncome, schoolType } = useProgramCard(program);
  const [whatIsROIOpen, setWhatIsROIOpen] = useState(false);
  const [whatIsRTSSchoolOpen, setWhatIsRTSSchoolOpen] = useState(false);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  const handleLearnMore = () => {
    if (!setSelectedLearnMoreProgram) return;
    setSelectedLearnMoreProgram(program);
  };

  const handleRecommendOpen = () => {
    if (!setSelectedProgram || !setRecommendOpen) return;
    setSelectedProgram(program);
    setRecommendOpen(true);
  };

  return (
    <>
      {program && (source === SelectedProgramSource.Explore || selectedStudent) && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          {/* Custom boarder & messsage around programs that have auto-admit turned on */}
          <AutoAdmit program={program}>
            <Paper
              sx={{
                backgroundColor: Color.White,
                color: Color.Chalkboard,
                pt: 4,
                maxWidth: 475,
                borderRadius: 6,
                margin: "8px auto 0 auto",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Chip
                  color={formatCredentialCard(program.credentialLevel)}
                  label={program.credentialLevel}
                  sx={{ fontSize: 11, ml: 1 }}
                />
                <Reactions program={program} source={source} showDeleteButton={showDeleteButton} isEditable={isEditable} />
              </Box>
              <Box sx={{ padding: 2, ml: 2, mr: 2 }}>
                <Typography
                  component="h6"
                  variant="cardProviderName"
                >{`${program.providerName}`}</Typography>
                <Typography
                  component="div"
                  variant="cardLocation"
                >{`${program.city}, ${program.state}`}</Typography>
                <Typography variant="cardProgramName">{program.programName}</Typography>
                {program.programShortDescription && (
                  <Typography variant="body1" sx={{ color: Color.Chalkboard }}>
                    <ProgramDescription description={program.programShortDescription} />
                  </Typography>
                )}
              </Box>
              <Box sx={{ pl: 2, pr: 2, pb: 1, ml: 2, mr: 2 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 600 }} variant="h6">
                      {lowIncome && program.autoScholarship
                        ? "Free"
                        : program.averageTotalCost
                        ? formatCurrency.format(program.averageTotalCost)
                        : "Unknown"}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: "-2px" }}>
                      Estimated Cost
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 600 }} variant="h6">
                      {program.startingSalary
                        ? formatCurrency.format(program.startingSalary)
                        : "Unknown"}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: "-2px" }}>
                      Average starting salary
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 600 }} variant="h6">
                      {renderDuration({
                        months: program?.durationMonths ?? 0,
                        years: program?.durationYears ?? 0,
                      })}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: "-2px" }}>
                      Length of program
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 600 }} variant="h6">
                      {program.undergradTotalEnrollment ?? "Unknown"}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: "-2px" }}>
                      Total Enrollment
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 600 }} variant="h6">
                      {program.completionRateIn6Years
                        ? numberToPercent(program.completionRateIn6Years)
                        : "Unknown"}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: "-2px" }}>
                      Graduation Rate
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 600 }} variant="h6">
                      {program.admissionPercent
                        ? numberToPercent(program.admissionPercent)
                        : "Unknown"}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: "-2px" }}>
                      Admission Rate
                    </Typography>
                  </Grid>
                  {selectedStudent &&
                    (!loggedInStaff || source !== SelectedProgramSource.Explore) && (
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontWeight: 600 }} variant="h6">
                          {schoolType}
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <Typography variant="body1">
                            Reach, Target, or Safety Program For You
                          </Typography>
                          <IconButton onClick={() => setWhatIsRTSSchoolOpen(true)} disableRipple>
                            <FeatherIcon icon="info" width="12px" />
                          </IconButton>
                        </Box>
                      </Grid>
                    )}
                </Grid>
              </Box>
              <Box sx={{ backgroundColor: "#EDFCF9", textAlign: "center" }}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography sx={{ fontWeight: 400, fontSize: 18, pt: 1 }} variant="h5">
                    Expected Return On Investment (ROI)
                  </Typography>
                  <IconButton onClick={() => setWhatIsROIOpen(true)} disableRipple>
                    <FeatherIcon icon="info" width="12px" />
                  </IconButton>
                </Box>
                <Typography sx={{ fontWeight: 600, fontSize: 34 }} variant="h6">
                  {program.willowROI ? formatCurrency.format(program.willowROI) : "Not Assigned"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  py: 2,
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Button sx={{ px: 1, py: 0.5 }} variant="outlined" onClick={handleLearnMore}>
                    Learn More
                  </Button>
                </Box>
                {loggedInStaff && source === SelectedProgramSource.Explore && (
                  <Box sx={{ textAlign: "center" }}>
                    <Button
                      sx={{ px: 1, py: 0.5 }}
                      variant="outlined"
                      onClick={handleRecommendOpen}
                      color="secondary"
                    >
                      Recommend
                    </Button>
                  </Box>
                )}
              </Box>
            </Paper>
          </AutoAdmit>
        </Box>
      )}

      <WhatIsROIDialog open={whatIsROIOpen} setOpen={setWhatIsROIOpen} />
      <WhatIsRTSSchool open={whatIsRTSSchoolOpen} setOpen={setWhatIsRTSSchoolOpen} />
    </>
  );
};

export default ProgramCard;
