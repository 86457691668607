import { FormControlLabel, Grid, Slider, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { Provider } from "../../../shared/types/types";
import { validationType } from "../../hooks/providerForm/useEditProviderForm";

type Props = {
  validation: validationType;
  providerForm: Provider;
  disabledPercentages: boolean;
  handleNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSliderChange: (event: Event, newValue: number | number[], name: string) => void;
  handleDisabledPercentagesChange: () => void;
};

const AboutYourStudents = ({
  validation,
  providerForm,
  disabledPercentages,
  handleNumberChange,
  handleSliderChange,
  handleDisabledPercentagesChange,
}: Props) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          About Your Students
        </Typography>
        <Typography variant="body1">
          The information below is not required; however, filling it out will help us ensure your
          programs are showing up for as many students on our platform as possible.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          type="number"
          onChange={handleNumberChange}
          name="undergradTotalEnrollment"
          label="Number of Active Students In Your Programs"
          value={providerForm?.undergradTotalEnrollment ?? ""}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>Admission Percent</Typography>
        <Slider
          aria-label="Admission Percent"
          value={providerForm?.admissionPercent ?? 0}
          valueLabelDisplay="auto"
          defaultValue={15}
          step={5}
          marks
          min={0}
          onChange={(event, newValue) => handleSliderChange(event, newValue, "admissionPercent")}
          name="admissionPercent"
          max={100}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Switch
              name="disabledPercentages"
              onChange={handleDisabledPercentagesChange}
              checked={disabledPercentages}
            />
          }
          label="Prefer not to share demographics"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>Percent American Indian and Alaska Native</Typography>
        <Slider
          aria-label="Percent American Indian and Alaska Native"
          defaultValue={15}
          valueLabelDisplay="auto"
          value={providerForm?.percentAIAN ?? 0}
          step={5}
          marks
          min={0}
          max={100}
          onChange={(event, newValue) => handleSliderChange(event, newValue, "percentAIAN")}
          name="percentAIAN"
          disabled={disabledPercentages}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>Percent Asian</Typography>
        <Slider
          aria-label="Percent Asian"
          value={providerForm?.percentAsian ?? 0}
          valueLabelDisplay="auto"
          defaultValue={15}
          step={5}
          marks
          min={0}
          onChange={(event, newValue) => handleSliderChange(event, newValue, "percentAsian")}
          name="percentAsian"
          max={100}
          disabled={disabledPercentages}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>Percent Black</Typography>
        <Slider
          aria-label="Percent Black"
          value={providerForm?.percentBlack ?? 0}
          valueLabelDisplay="auto"
          defaultValue={15}
          step={5}
          marks
          min={0}
          onChange={(event, newValue) => handleSliderChange(event, newValue, "percentBlack")}
          name="percentBlack"
          max={100}
          disabled={disabledPercentages}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>Percent Hispanic</Typography>
        <Slider
          aria-label="Percent Hispanic"
          value={providerForm?.percentHispanic ?? 0}
          valueLabelDisplay="auto"
          defaultValue={15}
          step={5}
          marks
          min={0}
          onChange={(event, newValue) => handleSliderChange(event, newValue, "percentHispanic")}
          name="percentHispanic"
          max={100}
          disabled={disabledPercentages}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>Percent Native Hawaiian and Pacific Islander</Typography>
        <Slider
          aria-label="Percent Native Hawaiian and Pacific Islander"
          value={providerForm?.percentNHPI ?? 0}
          valueLabelDisplay="auto"
          defaultValue={15}
          step={5}
          marks
          min={0}
          onChange={(event, newValue) => handleSliderChange(event, newValue, "percentNHPI")}
          name="percentNHPI"
          max={100}
          disabled={disabledPercentages}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>Percent Two or More</Typography>
        <Slider
          aria-label="Percent Two or More"
          value={providerForm?.percentTwoOrMore ?? 0}
          valueLabelDisplay="auto"
          defaultValue={15}
          step={5}
          marks
          min={0}
          onChange={(event, newValue) => handleSliderChange(event, newValue, "percentTwoOrMore")}
          name="percentTwoOrMore"
          max={100}
          disabled={disabledPercentages}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>Percent White</Typography>
        <Slider
          aria-label="Percent White"
          value={providerForm?.percentWhite ?? 0}
          valueLabelDisplay="auto"
          defaultValue={15}
          step={5}
          marks
          min={0}
          onChange={(event, newValue) => handleSliderChange(event, newValue, "percentWhite")}
          name="percentWhite"
          max={100}
          disabled={disabledPercentages}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>Percent Low Income</Typography>
        <Slider
          aria-label="Percent Low Income"
          value={providerForm?.percentPell ?? 0}
          valueLabelDisplay="auto"
          defaultValue={15}
          step={5}
          marks
          min={0}
          onChange={(event, newValue) => handleSliderChange(event, newValue, "percentPell")}
          name="percentPell"
          max={100}
          disabled={disabledPercentages}
        />
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      {!validation.sumPercent.validated && (
        <Grid item xs={12} sm={12}>
          <Typography color="#d32f2f">{validation.sumPercent.message}</Typography>
        </Grid>
      )}
      <Grid item xs={6} sm={4}>
        <TextField
          fullWidth
          type="number"
          onChange={handleNumberChange}
          name="satReadingMid"
          label="Median Student SAT Reading Score"
          value={providerForm?.satReadingMid ?? ""}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextField
          fullWidth
          type="number"
          onChange={handleNumberChange}
          name="satMathMid"
          label="Median Student SAT Math Score"
          value={providerForm?.satMathMid ?? ""}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextField
          fullWidth
          type="number"
          onChange={handleNumberChange}
          name="actMid"
          label="Median Student ACT Score"
          value={providerForm?.actMid ?? ""}
        />
      </Grid>
    </>
  );
};

export default AboutYourStudents;
