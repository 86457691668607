import React, { useState } from "react";
import Navbar from "../../shared/components/navigation/Navbar";
import { Box } from "@mui/material";
import Sidebar from "../../shared/components/navigation/Sidebar";
import StudentsByProgramContainer from "../components/StudentsByProgram/StudentsByProgramContainer";

const StudentsByProgram = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
        <Sidebar open={open} setOpen={setOpen} />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Navbar open={open} title="Students By Program" />
          <Box sx={{ minHeight: "calc(100VH-64px)" }}>
            <StudentsByProgramContainer />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StudentsByProgram;
