import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Dispatch } from "react";
import EditDetailForm from "./EditDetailsForm";
import { Color } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/components/FeatherIcon";
import useEditDetailsDialog from "../../hooks/studentOverview/useEditDetailsDialog";

const BGCOLOR = "#fbfbfb";

type Props = {
  editQuestionId: string | null;
  setEditQuestionId: Dispatch<React.SetStateAction<string | null>>;
};

const EditDetailsDialog = ({ editQuestionId, setEditQuestionId }: Props) => {
  const { handleSave, handleClose, answer, setAnswer, subAnswers, setSubAnswers, valid, setDetailValid, loading } =
    useEditDetailsDialog({
      editQuestionId,
      setEditQuestionId,
    });

  return (
    <Dialog
      open={editQuestionId !== "consent-to-contact" && Boolean(editQuestionId)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        variant="h4"
        sx={{ fontSize: 44, textAlign: "center", backgroundColor: BGCOLOR }}
      >
        Manage Question
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: BGCOLOR }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress size={60} />
          </Box>
        ) : (
          <EditDetailForm
            editQuestionId={editQuestionId}
            answer={answer}
            setAnswer={setAnswer}
            subAnswers={subAnswers}
            setSubAnswers={setSubAnswers}
            setValid={setDetailValid}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: BGCOLOR }}>
        <Button variant="contained" disabled={!valid || loading} onClick={handleSave}>
          <IconButton sx={{ color: Color.Chalkboard }} disableRipple>
            <FeatherIcon icon="thumbs-up" width="16px" />
          </IconButton>
          Save
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          <IconButton sx={{ color: Color.Chalkboard }} disableRipple>
            <FeatherIcon icon="x" width="16px" />
          </IconButton>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDetailsDialog;
