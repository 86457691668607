import { Typography } from "@mui/material";
import React from "react";
import { useRecoilValue } from "recoil";
import { studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";

const NextStepsMessage = () => {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const answers = useRecoilValue(studentAnswersAtom);
  const nextStepsAnswer = answers ? answers.find((ans) => ans.questionId === "next_steps") : null;
  return (
    <>
      {nextStepsAnswer && nextStepsAnswer.mc === 1699730293481 && (
        <>
          <Typography variant="h5">
            Thanks {selectedStudent?.firstName}, sounds like you are trying to get into your best
            option.
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            Students like you often experience a lot of stress! Try to relax through the process.
            Rankings aren't all they're cracked up to be. Try to define what's most important for
            you in a program, and what are non-starters. We'll help you broaden your choices of
            quality options that are a good fit for you!
          </Typography>
        </>
      )}
      {nextStepsAnswer && nextStepsAnswer.mc === 1699730297765 && (
        <>
          <Typography variant="h5">
            Thanks {selectedStudent?.firstName}, sounds like you're trying to do what's expected of
            you.
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            You may be feeling like you need to satisfy or obey others in your life. Or that you may
            need to take the next step. Or that you don't have or can't see other options besides
            school.
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            We recommend you get to know yourself and think about what you need from an educational
            experience. We'll help you find matches that are a good fit for you.
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            Finally, think about whether you want to try to get into your best quality educational
            option, or whether you want to step it up and focus more on how to elevate your career,
            quickly.
          </Typography>
        </>
      )}
      {nextStepsAnswer && nextStepsAnswer.mc === 1699730301898 && (
        <>
          <Typography variant="h5">
            Thanks {selectedStudent?.firstName}, sounds like you might be trying to get away.
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            Are you feeling like you may need to get out of a job, role, or habit? Or do you feel
            like you need to get away from your home, community, family, or a relationship? And/or
            get a break from the daily grind?
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            It seems like circumstances in your life may be pushing you to take your next step. That
            is valid! If you don't know exactly what you want to do, we recommend you use your next
            step to learn about your strengths/passions AND your weaknesses and what you dislike. It
            may be a good time to take a smaller step, that is lower commitment before you commit a
            lot of time and money.
          </Typography>
        </>
      )}
      {nextStepsAnswer && nextStepsAnswer.mc === 1699730307498 && (
        <>
          <Typography variant="h5">
            Thanks {selectedStudent?.firstName}, sounds like you are trying to step it up!
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            Are you feeling like what you're doing isn't you, and it's time to get out of your job,
            role, or habit? Do you know it's time, and you can do better? Do you think some
            specific, practical skills or certifications can help?
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            If you're feeling this way, take stock of your strengths, weaknesses, likes, dislikes
            and purpose. We will help recommend programs and matches that are a good fit for you and
            your goals. For your goals, we will bias towards shorter, specific programs that lead to
            good jobs.
          </Typography>
        </>
      )}
      {nextStepsAnswer && nextStepsAnswer.mc === 1699730314014 && (
        <>
          <Typography variant="h5">
            Thanks {selectedStudent?.firstName}, sounds like you are trying to extend yourself!
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            Are you looking to learn more and challenge yourself? Pursue a clear vision for
            yourself? Earn specific, practical skills or certifications. And ready, with the time
            and money to get more education?
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            Then you are likely looking for programs to help you extend yourself. Go for it! We will
            help you find programs that are a great fit, are high-quality so you can have confidence
            in your selection.
          </Typography>
        </>
      )}
    </>
  );
};

export default NextStepsMessage;
