import { AppResult, AppStatus, UserType } from "../types/enums";
import {
  AlmaChatRecord,
  AnswerRecord,
  ApplicationDataRecord,
  CIPRecord,
  CareerRecord,
  DistrictRecord,
  GroupRecord,
  Provider_Program,
  ProviderUserRecord,
  QuestionRecord,
  RemovedMatchRecord,
  SchoolRecord,
  StaffRecord,
  StageRecord,
  StageTaskRecord,
  StudentRecord,
  StudentTaskRecord,
  WillowIndustryRecord,
  WillowSubIndustryRecord,
  WriteInProgramRecord,
  Provider,
  StaffAlmaChatRecord,
  MentorUserRecord,
} from "../types/types";

export const parseStaffResponse = (response: StaffRecord[]): StaffRecord[] =>
  response.map((staff: StaffRecord) => ({
    id: staff?.id ?? "",
    firstName: staff?.firstName ?? "",
    lastName: staff?.lastName ?? "",
    email: staff?.email ?? "",
    createdAt: staff?.createdAt ?? null,
    lastUpdatedAt: staff?.lastUpdatedAt ?? null,
    schoolId: staff?.schoolId ?? null,
    avatar: staff?.avatar ?? "",
    jobTitle: staff?.jobTitle ?? "",
    userType: staff?.userType ?? UserType.Student,
    districtId: staff?.districtId ?? null,
    enrolled: staff?.enrolled ?? true,
    initialPasswordReset: staff?.initialPasswordReset ?? false,
    smsNumber: staff?.smsNumber ?? "",
    klaviyoProfileId: staff?.klaviyoProfileId ?? "",
    uidId: staff?.uidId ?? false,
    oldId: staff?.oldId ?? "",
  }));

export const parseSingleStaffResponse = (staff: StaffRecord): StaffRecord => ({
  id: staff?.id ?? "",
  firstName: staff?.firstName ?? "",
  lastName: staff?.lastName ?? "",
  email: staff?.email ?? "",
  createdAt: staff?.createdAt ?? null,
  lastUpdatedAt: staff?.lastUpdatedAt ?? null,
  schoolId: staff?.schoolId ?? null,
  avatar: staff?.avatar ?? "",
  jobTitle: staff?.jobTitle ?? "",
  userType: staff?.userType ?? UserType.Student,
  districtId: staff?.districtId ?? null,
  enrolled: staff?.enrolled ?? true,
  initialPasswordReset: staff?.initialPasswordReset ?? false,
  smsNumber: staff?.smsNumber ?? "",
  klaviyoProfileId: staff?.klaviyoProfileId ?? "",
  uidId: staff?.uidId ?? false,
  oldId: staff?.oldId ?? "",
});

export const parseDistrictResponse = (response: DistrictRecord[]): DistrictRecord[] =>
  response.map((response: DistrictRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    name: response?.name ?? null,
    domains: response?.domains ?? [],
  }));

export const parseGroupResponse = (response: GroupRecord[]): GroupRecord[] =>
  response.map((response: GroupRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    name: response?.name ?? null,
    schoolId: response?.schoolId ?? null,
    studentIds: response?.studentIds ?? [],
    staffIds: response?.staffIds ?? [],
    oldStudentIds: response?.oldStudentIds ?? [],
    oldStaffIds: response?.oldStaffIds ?? [],
  }));

export const parseSchoolResponse = (response: SchoolRecord[]): SchoolRecord[] =>
  response.map((response: SchoolRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    name: response?.name ?? null,
    historicId: response?.historicId ?? null,
    primaryEmail: response?.primaryEmail ?? "",
    primaryPhone: response?.primaryPhone ?? "",
    avatar: response?.avatar ?? "",
    primaryContact: response?.primaryContact ?? "",
    districtId: response?.districtId ?? null,
  }));

export const parseStudentResponse = (response: StudentRecord[]): StudentRecord[] =>
  response.map((response: StudentRecord) => ({
    id: response?.id ?? "",
    firstName: response?.firstName ?? "",
    lastName: response?.lastName ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    schoolId: response?.schoolId ?? null,
    districtId: response?.districtId ?? null,
    stageId: response?.stageId ?? "stageOne",
    avatar: response?.avatar ?? "",
    gradeLevel: response?.gradeLevel ?? "",
    userType: response?.userType ?? UserType.Student,
    birthday: response?.birthday ?? null,
    graduationYear: response?.graduationYear ?? "",
    phone: response?.phone ?? "",
    lastQuestionId: response?.lastQuestionId ?? null,
    quizComplete: response?.quizComplete ?? false,
    setupComplete: response?.setupComplete ?? false,
    lastActivity: response?.lastActivity ?? "",
    historicId: response?.historicId ?? null,
    favoriteProviderIds: response?.favoriteProviderIds ?? [],
    optInTextMessages: response?.optInTextMessages ?? false,
    agreeTermsOfService: response?.agreeTermsOfService ?? false,
    enrolled: response?.enrolled ?? true,
    personalEmail: response?.personalEmail ?? "",
    schoolEmail: response?.schoolEmail ?? "",
    email: response?.email ?? "",
    matchedProgramIds: response?.matchedProgramIds ?? [],
    hollandType: response?.hollandType ?? null,
    topCareers: response?.topCareers ?? [],
    consentToContact: response?.consentToContact ?? "Not Asked",
    staffRecommendedProgramIds: response?.staffRecommendedProgramIds ?? [],
    favoriteRecommendedProgramIds: response?.favoriteRecommendedProgramIds ?? [],
    klaviyoProfileId: response?.klaviyoProfileId ?? "",
    uidId: response?.uidId ?? false,
    oldId: response?.oldId ?? "",
    mentorIds: response?.mentorIds ?? [],
  }));

export const parseSingleStudentResponse = (response: StudentRecord): StudentRecord => ({
  id: response?.id ?? "",
  firstName: response?.firstName ?? "",
  lastName: response?.lastName ?? "",
  createdAt: response?.createdAt ?? null,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  schoolId: response?.schoolId ?? null,
  districtId: response?.districtId ?? null,
  stageId: response?.stageId ?? "stageOne",
  avatar: response?.avatar ?? "",
  gradeLevel: response?.gradeLevel ?? "",
  userType: response?.userType ?? UserType.Student,
  birthday: response?.birthday ?? null,
  graduationYear: response?.graduationYear ?? "",
  phone: response?.phone ?? "",
  lastQuestionId: response?.lastQuestionId ?? null,
  quizComplete: response?.quizComplete ?? false,
  setupComplete: response?.setupComplete ?? false,
  lastActivity: response?.lastActivity ?? "",
  historicId: response?.historicId ?? null,
  favoriteProviderIds: response?.favoriteProviderIds ?? [],
  optInTextMessages: response?.optInTextMessages ?? false,
  agreeTermsOfService: response?.agreeTermsOfService ?? false,
  enrolled: response?.enrolled ?? true,
  personalEmail: response?.personalEmail ?? "",
  schoolEmail: response?.schoolEmail ?? "",
  email: response?.email ?? "",
  matchedProgramIds: response?.matchedProgramIds ?? [],
  hollandType: response?.hollandType ?? null,
  topCareers: response?.topCareers ?? [],
  consentToContact: response?.consentToContact ?? "Not Asked",
  staffRecommendedProgramIds: response?.staffRecommendedProgramIds ?? [],
  favoriteRecommendedProgramIds: response?.favoriteRecommendedProgramIds ?? [],
  klaviyoProfileId: response?.klaviyoProfileId ?? "",
  uidId: response?.uidId ?? false,
  oldId: response?.oldId ?? "",
  mentorIds: response?.mentorIds ?? [],
});

export const parseSingleProviderUserResponse = (
  response: ProviderUserRecord
): ProviderUserRecord => ({
  id: response?.id ?? "",
  firstName: response?.firstName ?? "",
  lastName: response?.lastName ?? "",
  createdAt: response?.createdAt ?? null,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  userType: response?.userType ?? UserType.Student,
  setupComplete: response?.setupComplete ?? false,
  email: response?.email ?? "",
  klaviyoProfileId: response?.klaviyoProfileId ?? "",
  providerId: response?.providerId ?? "",
  phone: response?.phone ?? "",
});

export const parseSingleMentorUserResponse = (
  response: MentorUserRecord
): MentorUserRecord => ({
  id: response?.id ?? "",
  firstName: response?.firstName ?? "",
  lastName: response?.lastName ?? "",
  avatar: response?.avatar ?? "",
  avatarURL: response?.avatarURL ?? "",
  createdAt: response?.createdAt ?? null,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  userType: response?.userType ?? UserType.Student,
  email: response?.email ?? "",
  klaviyoProfileId: response?.klaviyoProfileId ?? "",
  studentIds: response?.studentIds ?? [],
});

export const parseQuestionResponse = (response: QuestionRecord[]): QuestionRecord[] =>
  response.map((response: QuestionRecord) => ({
    id: response?.id ?? "",
    order: response?.order ?? 0,
    question: response?.question ?? "",
    type: response?.type ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    options: response?.options ?? [],
    matrixHeaders: response?.matrixHeaders ?? [],
    helperText: response?.helperText ?? "",
    required: response?.required ?? true,
  }));

export const parseAnswerResponse = (response: AnswerRecord[]): AnswerRecord[] =>
  response.map((response: AnswerRecord) => ({
    id: response?.id ?? "",
    studentId: response?.studentId ?? "",
    questionId: response?.questionId ?? 0,
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    mc: response?.mc ?? 0,
    ms: response?.ms ?? [],
    or: response?.or ?? "",
    matrix: response?.matrix ?? [],
    gpa: response?.gpa ?? [0, 0],
    number: response?.number ?? 0,
    schoolId: response?.schoolId ?? "",
    districtId: response?.districtId ?? "",
  }));

export const parseStageResponse = (response: StageRecord[]): StageRecord[] =>
  response.map((response: StageRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    name: response?.name ?? "",
    order: response?.order ?? 0,
    graphic: response?.graphic ?? "",
    description: response?.description ?? "",
  }));

export const parseStageTaskResponse = (response: StageTaskRecord[]): StageTaskRecord[] =>
  response.map((response: StageTaskRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    name: response?.name ?? "",
    order: response?.order ?? 0,
    stageId: response?.stageId ?? "",
    description: response?.description ?? "",
  }));

export const parseRemovedMatchResponse = (response: RemovedMatchRecord[]): RemovedMatchRecord[] =>
  response.map((response: RemovedMatchRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    studentId: response?.studentId ?? "",
    providerId: response?.providerId ?? "",
    reasons: response?.reasons ?? [],
  }));

export const parseStudentTaskResponse = (response: StudentTaskRecord[]): StudentTaskRecord[] =>
  response.map((response: StudentTaskRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    name: response?.name ?? "",
    order: response?.order ?? 0,
    dueDate: response?.dueDate ?? "",
    stageTaskId: response?.stageTaskId ?? "",
    description: response?.description ?? "",
    studentId: response?.studentId ?? "",
    completed: response?.completed ?? false,
  }));

export const parseSingleProviderResponse = (response: Provider): Provider => ({
  id: response?.id ?? "",
  providerName: response?.providerName ?? "",
  providerDescription: response?.providerDescription ?? "",
  city: response?.city ?? "",
  state: response?.state ?? "",
  zip: response?.zip ?? "",
  region: response?.region ?? "",
  homeURL: response?.homeURL ?? "",
  ownership: response?.ownership ?? "",
  locale: response?.locale ?? "",
  lat: response?.lat ?? null,
  lon: response?.lon ?? null,
  hbcu: response.hbcu ?? false,
  menOnly: response?.menOnly ?? false,
  womenOnly: response?.womenOnly ?? false,
  religiousAffiliation: response?.religiousAffiliation ?? "",
  admissionPercent: response?.admissionPercent ?? null,
  satReadingMid: response?.satReadingMid ?? null,
  satMathMid: response?.satMathMid ?? null,
  actMid: response?.actMid ?? null,
  undergradTotalEnrollment: response?.undergradTotalEnrollment ?? null,
  percentWhite: response?.percentWhite ?? null,
  percentBlack: response?.percentBlack ?? null,
  percentHispanic: response?.percentHispanic ?? null,
  percentAsian: response?.percentAsian ?? null,
  percentAIAN: response?.percentAIAN ?? null,
  percentNHPI: response?.percentNHPI ?? null,
  percentTwoOrMore: response?.percentTwoOrMore ?? null,
  percentNonCitizens: response?.percentNonCitizens ?? null,
  currentlyOperating: response?.currentlyOperating ?? false,
  percentPell: response?.percentPell ?? null,
  address: response?.address ?? "",
  providerIsActive: response?.providerIsActive ?? false,
});

//create a parser for each key in the Provicer_Program type
export const parseProgramResponse = (response: Provider_Program[]): Provider_Program[] =>
  response.map((response: Provider_Program) => ({
    id: response?.id ?? "",
    providerName: response?.providerName ?? "",
    providerDescription: response?.providerDescription ?? "",
    city: response?.city ?? "",
    state: response?.state ?? "",
    zip: response?.zip ?? "",
    region: response?.region ?? "",
    homeURL: response?.homeURL ?? "",
    ownership: response?.ownership ?? "",
    locale: response?.locale ?? "",
    lat: Number(response?.lat) ?? null,
    lon: Number(response?.lon) ?? null,
    hbcu: Boolean(response?.hbcu) ?? false,
    menOnly: Boolean(response?.menOnly) ?? false,
    womenOnly: Boolean(response?.womenOnly) ?? false,
    religiousAffiliation: response?.religiousAffiliation ?? "",
    admissionPercent: Number(response?.admissionPercent) ?? null,
    satReadingMid: Number(response?.satReadingMid) ?? null,
    satMathMid: Number(response?.satMathMid) ?? null,
    actMid: Number(response?.actMid) ?? null,
    undergradTotalEnrollment: Number(response?.undergradTotalEnrollment) ?? null,
    percentWhite: Number(response?.percentWhite) ?? null,
    percentBlack: Number(response?.percentBlack) ?? null,
    percentHispanic: Number(response?.percentHispanic) ?? null,
    percentAsian: Number(response?.percentAsian) ?? null,
    percentAIAN: Number(response?.percentAIAN) ?? null,
    percentNHPI: Number(response?.percentNHPI) ?? null,
    percentTwoOrMore: Number(response?.percentTwoOrMore) ?? null,
    percentNonCitizens: Number(response?.percentNonCitizens) ?? null,
    currentlyOperating: Boolean(response?.currentlyOperating) ?? false,
    percentPell: Number(response?.percentPell) ?? null,
    address: response?.address ?? "",
    providerIsActive: Boolean(response?.providerIsActive) ?? false,
    providerId: response?.providerId ?? null,
    durationYears: Number(response?.durationYears) ?? null,
    durationMonths: Number(response?.durationMonths) ?? null,
    cipCode: response?.cipCode ?? "",
    willowIndustryId: response?.willowIndustryId ?? "",
    willowSubIndustryId: response?.willowSubIndustryId ?? "",
    credentialLevel: response?.credentialLevel ?? "",
    programName: response?.programName ?? "",
    programShortDescription: response?.programShortDescription ?? "",
    programDescription: response?.programDescription ?? "",
    averageTotalCost: Number(response?.averageTotalCost) ?? null,
    commitment: response?.commitment ?? "",
    modality: response?.modality ?? "",
    onlineOnly: Boolean(response?.onlineOnly) ?? null,
    completionRateIn6Years: Number(response?.completionRateIn6Years) ?? null,
    startingSalary: Number(response?.startingSalary) ?? null,
    willowROI: Number(response?.willowROI) ?? null,
    applyURL: response?.applyURL ?? "",
    rateOfEmployment: response?.rateOfEmployment ?? null,
    departmentLink: response?.departmentLink ?? "",
    programIsActive: Boolean(response?.programIsActive) ?? false,
    inAlgorithm: Boolean(response?.inAlgorithm) ?? false,
    autoAdmit: Boolean(response?.autoAdmit) ?? false,
    autoScholarship: Boolean(response?.autoScholarship) ?? false,
    willowWeight: Number(response?.willowWeight) ?? 0,
  }));

export const parseApplicationDataResponse = (
  response: ApplicationDataRecord[]
): ApplicationDataRecord[] =>
  response.map((response: ApplicationDataRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    studentId: response?.studentId ?? "",
    schoolId: response?.schoolId ?? "",
    districtId: response?.districtId ?? "",
    programId: response?.programId ?? "",
    status: response?.status ?? "",
    result: response?.result ?? "",
  }));

export const parseWriteInProgramsResponse = (
  response: WriteInProgramRecord[]
): WriteInProgramRecord[] =>
  response.map((response: WriteInProgramRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    studentId: response?.studentId ?? "",
    schoolId: response?.schoolId ?? "",
    districtId: response?.districtId ?? "",
    providerName: response?.providerName ?? "",
    programName: response?.programName ?? "",
    status: response?.status ?? AppStatus.NotStarted,
    result: response?.result ?? AppResult.NoResponse,
  }));

export const parseWillowIndustryResponse = (
  response: WillowIndustryRecord[]
): WillowIndustryRecord[] =>
  response.map((response: WillowIndustryRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    iconURL: response?.iconURL ?? "",
    name: response?.name ?? "",
  }));

export const parseWillowSubIndustryResponse = (
  response: WillowSubIndustryRecord[]
): WillowSubIndustryRecord[] =>
  response.map((response: WillowSubIndustryRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    name: response?.name ?? "",
    industryId: response?.industryId ?? "",
  }));

export const parseCIPsResponse = (response: CIPRecord[]): CIPRecord[] =>
  response.map((response: CIPRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    cipCode: response?.cipCode ?? "",
    cipTitle: response?.cipTitle ?? "",
  }));

export const parseCareersResponse = (response: CareerRecord[]): CareerRecord[] =>
  response.map((response: CareerRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    cipCode: response?.cipCode ?? "",
    programName: response?.programName ?? "",
    socCode: response?.socCode ?? "",
    socTitle: response?.socTitle ?? "",
    growth: response?.growth ?? "",
    newOpenings: response?.newOpenings ?? 0,
    willowSubIndustryId: response?.willowSubIndustryId ?? "",
  }));

export const parseAlmaChatResponse = (response: AlmaChatRecord[]): AlmaChatRecord[] =>
  response.map((response: AlmaChatRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    studentId: response?.studentId ?? "",
    chatHistory: response?.chatHistory ?? [],
    schoolId: response?.schoolId ?? "",
    districtId: response?.districtId ?? "",
    name: response?.name ?? "",
    current: response?.current ?? "",
  }));

export const parseStaffAlmaChatResponse = (
  response: StaffAlmaChatRecord[]
): StaffAlmaChatRecord[] =>
  response.map((response: StaffAlmaChatRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    staffId: response?.staffId ?? "",
    chatHistory: response?.chatHistory ?? [],
    schoolId: response?.schoolId ?? "",
    districtId: response?.districtId ?? "",
    name: response?.name ?? "",
    current: response?.current ?? "",
  }));

export const parseProviderUser = (response: ProviderUserRecord): ProviderUserRecord => ({
  id: response?.id ?? "",
  firstName: response?.firstName ?? "",
  lastName: response?.lastName ?? "",
  email: response?.email ?? "",
  phone: response?.phone ?? "",
  providerId: response?.providerId ?? "",
  userType: response?.userType ?? UserType.Student,
  klaviyoProfileId: response?.klaviyoProfileId ?? "",
  setupComplete: response?.setupComplete ?? false,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  createdAt: response?.createdAt ?? null,
});

export const parseMentorUsersResponse = (response: MentorUserRecord[]): MentorUserRecord[] =>
  response.map((response: MentorUserRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    firstName: response?.firstName ?? "",
    lastName: response?.lastName ?? "",
    avatar: response?.avatar ?? "",
    avatarURL: response?.avatarURL ?? "",
    userType: response?.userType ?? UserType.MentorUser,
    email: response?.email ?? "",
    klaviyoProfileId: response?.klaviyoProfileId ?? "",
    studentIds: response?.studentIds ?? [],
  }));