import { Box, Typography } from "@mui/material";
import React from "react";
import { Provider } from "../../types/types";
import { Color } from "../../types/enums";

type Props = {
  provider: Provider;
};

const formatURL = (url: string) => {
  if (url.includes("http://") || url.includes("https://")) {
    return url;
  } else {
    return `http://${url}`;
  }
};

const ProviderGeneralCard = ({ provider }: Props) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 3,
          borderRadius: 2,
          backgroundColor: Color.Chalkboard,
          color: Color.Chalk,
        }}
      >
        <Box sx={{ display: "flex", gap: 3, alignItems: "end" }}>
          <Typography variant="h4">{provider.providerName}</Typography>
        </Box>
        <Typography sx={{ mt: 1, textAlign: "left" }}>
          {`${provider.city}, ${provider.state}`}
        </Typography>
        {provider.homeURL && (
          <Typography sx={{ mt: 1, textAlign: "left" }}>
            <a
              style={{ color: Color.Chalk, textDecoration: "none" }}
              target="_blank"
              href={formatURL(provider.homeURL)}
              rel="noreferrer"
            >
              {provider.homeURL}
            </a>
          </Typography>
        )}
      </Box>
    </>
  );
};

export default ProviderGeneralCard;
