import React from "react";
import { Container } from "@mui/material";
import ProviderLoginContainer from "../components/login/ProviderLoginContainer";

const Login = () => {
  return (
    <Container maxWidth={false} sx={{ minHeight: "100VH", backgroundColor: "rgb(27,186,155)" }}>
      <ProviderLoginContainer />
    </Container>
  );
};

export default Login;
