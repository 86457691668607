import React from "react";
import Navbar from "../../shared/components/navigation/Navbar";
import { Container } from "@mui/material";
import TakeQuizContainer from "../components/initialQuiz/TakeQuizContainer";

const InitialQuiz = () => {
  return (
    <>
      <Navbar />
      <Container maxWidth="md" sx={{ minHeight: "calc(100VH-64px)", mt: 8, pt: 2, pr: 0, pl: 0 }}>
        <TakeQuizContainer />
      </Container>
    </>
  );
};

export default InitialQuiz;
