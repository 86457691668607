import React from "react";
import Navbar from "../../shared/components/navigation/Navbar";
import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useUpdateDoc from "../../shared/hooks/useUpdateDoc";
import { useRecoilState } from "recoil";
import { selectedStudentAtom } from "../../shared/recoil/userAtoms";

const Congratulations = () => {
  const navigate = useNavigate();
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const handleClick = async () => {
    if (!selectedStudent) return;
    navigate("/student-dashboard");
  };
  return (
    <>
      <Navbar />
      <Container
        maxWidth="lg"
        sx={{ minHeight: "calc(100VH-64px)", mt: 8, pt: 2, textAlign: "center" }}
      >
        <>
          <Typography variant="h2" textAlign={"center"} sx={{ mt: 8 }}>
            🎉You Did It!🎉
          </Typography>
          <Box>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleClick}
              sx={{ mt: 2, p: 2, fontSize: 18 }}
            >
              Take Me On to Stage Two: Reviewing My Recommended College & Career Programs
            </Button>
          </Box>

          <img
            src="../../celebrate.gif"
            style={{ width: "570px", marginTop: "24px", maxWidth: "95%" }}
            alt="celebration"
          />
        </>
      </Container>
    </>
  );
};

export default Congratulations;
