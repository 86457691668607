const useProgramTableColumns = () => {
  const providerColumns = [
    {
      field: "programName",
      headerName: "Program Name",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 200,
    },
		{
      field: "programDescription",
      headerName: "Program Description",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 250,
			flex: 1,
    },
		{
      field: "credentialLevel",
      headerName: "Credential Level",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 200,
    },
		{
      field: "durationYears",
      headerName: "Duration Years",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 150,
    },
		{
      field: "durationMonths",
      headerName: "Duration Months",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 150,
    },
		{
      field: "cipCode",
      headerName: "CipCode",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 150,
    },
  ];
  return { providerColumns };
};

export default useProgramTableColumns;
