import React, { useEffect } from "react";

import { useAuth } from "../../../shared/contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import ProviderSignupAuth from "./ProviderSignupAuth";
import { PageRoute } from "../../../shared/types/enums";

const ProviderSignupContainer = () => {
  const { currentAuthUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentAuthUser) {
      navigate(PageRoute.Provider_Form);
    }
  }, [currentAuthUser, navigate]);

  return (
    <>
      <ProviderSignupAuth />
    </>
  );
};

export default ProviderSignupContainer;
