import React, { useState } from "react";
import { Box } from "@mui/system";
import Navbar from "../components/navigation/Navbar";
import Sidebar from "../components/navigation/Sidebar";
import ExploreCareersContainer from "../components/exploreCareers/ExploreCareersContainer";

const ExploreCareers = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
        <Sidebar open={open} setOpen={setOpen} />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Navbar open={open} title="Settings" />
          <Box sx={{ minHeight: "calc(100VH-64px)" }}>
            <ExploreCareersContainer />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ExploreCareers;
