import React, { Dispatch, SetStateAction, useEffect } from "react";
import { QuestionRecord, AnswerTypeForm } from "../../types/types";
import { Box, Typography } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { BLANK_ANSWER_TYPE_FORM } from "../../libraries/blankForms";

import { useRecoilValue } from "recoil";
import { studentAnswersAtom } from "../../recoil/answersAtoms";

type Props = {
  question: QuestionRecord;
  setAnswer: Dispatch<SetStateAction<AnswerTypeForm>>;
  answer: AnswerTypeForm;
};

const MCQuestion = ({ question, setAnswer, answer }: Props) => {
  const answers = useRecoilValue(studentAnswersAtom);

  useEffect(() => {
    if (!answers) return;
    const currentAnswer = answers.find((ans) => ans.questionId === question.id);
    if (!currentAnswer) {
      setAnswer({ ...BLANK_ANSWER_TYPE_FORM });
      return;
    }
    setAnswer((pV) => ({ ...BLANK_ANSWER_TYPE_FORM, mc: currentAnswer.mc }));
  }, [answers, question.id, setAnswer]);

  const handleSelect = (id: number | string) => {
    setAnswer((pV) => ({
      ...pV,
      mc: id,
    }));
  };
  return (
    <>
      <Typography variant="h5" textAlign={"center"}>
        {question.question}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" textAlign={"center"}>
        {question.helperText}
      </Typography>
      <>
        {question.options.map((option) => (
          <Box
            key={option.id}
            onClick={() => handleSelect(option.id)}
            sx={{
              borderRadius: 2,
              border: "1px solid rgb(152, 152, 152)",
              p: 2,
              mt: 2,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: answer.mc === option.id ? "rgb(29, 188, 157)" : "white",
            }}
          >
            {answer.mc === option.id ? (
              <RadioButtonCheckedIcon sx={{ mr: 2 }} />
            ) : (
              <RadioButtonUncheckedIcon sx={{ mr: 2 }} />
            )}
            <Typography key={option.id} variant="body1">
              {option.text}
            </Typography>
          </Box>
        ))}
      </>
    </>
  );
};

export default MCQuestion;
