import { useCallback } from "react";
import { AnswerRecord, AnswerTypeForm, QuestionRecord, StudentRecord } from "../types/types";
import useAddDoc from "./useAddDoc";
import useUpdateDoc from "./useUpdateDoc";
import { PathwayHeader, Collection, QuestionType, WillowIndustryId } from "../types/enums";

type QuestionAnswerProps = {
  question: QuestionRecord;
  answer: AnswerTypeForm;
  answers: AnswerRecord[];
  selectedStudent: StudentRecord;
};

const useQuiz = () => {
  const { sendRequest: addDoc } = useAddDoc();
  const { sendRequest: updateDoc } = useUpdateDoc();

  const submitAnswer = useCallback(
    async ({ question, answer, answers, selectedStudent }: QuestionAnswerProps) => {
      if (!selectedStudent) return;
      const existingAnswer = answers.find((a) => a.questionId === question.id);
      if (existingAnswer) {
        updateDoc({ col: Collection.Answers, id: existingAnswer.id, data: answer });
        return { ...existingAnswer, ...answer } as AnswerRecord;
      } else {
        const response = await addDoc({
          col: Collection.Answers,
          data: {
            ...answer,
            questionId: question.id,
            studentId: selectedStudent.id,
            schoolId: selectedStudent.schoolId,
            districtId: selectedStudent.districtId,
          },
        });
        return {
          ...answer,
          questionId: question.id,
          studentId: selectedStudent.id,
          schoolId: selectedStudent.schoolId,
          districtId: selectedStudent.districtId,
          id: response?.id || "",
        } as AnswerRecord;
      }
    },
    [addDoc, updateDoc]
  );

  const validateAnswer = useCallback(({ question, answer }: QuestionAnswerProps) => {
    if (question.type === QuestionType.MultipleChoice) {
      return answer.mc !== 0;
    }
    if (question.type === QuestionType.MultipleSelect) {
      return answer.ms.length !== 0;
    }
    if (question.type === QuestionType.Geography) {
      return answer.ms.length !== 0;
    }
    if (question.type === QuestionType.OpenResponse) {
      return answer.or.trim().length > 0;
    }
    if (question.type === QuestionType.Matrix) {
      const keys = Object.keys(answer.matrix);
      return keys.length === question.options.length;
    }
    if (question.type === QuestionType.GPA) {
      return answer.gpa.every((g) => g > 0);
    }
    if (question.type === QuestionType.Number) {
      return answer.number > 0;
    }
    return false;
  }, []);

  type FilterMatrixQuestionsProps = {
    answers: AnswerRecord[];
    questions: QuestionRecord[];
  };

  const filterMatrixQuestions = useCallback(
    ({ answers, questions }: FilterMatrixQuestionsProps) => {
      const matrixAnswers = answers.find((ans) => ans.questionId === "pathways")?.matrix;
      if (!matrixAnswers) {
        return questions;
      } else {
        const tempQuestions = [...questions];
        matrixAnswers.forEach((ans) => {
          if (ans.optionId === WillowIndustryId.Business) {
            const index = tempQuestions.findIndex((q) => q.id === "business_jobs");
            if (index !== -1) {
              tempQuestions.splice(index, 1);
            }
          }
          if (ans.optionId === WillowIndustryId.SocialServices) {
            const index = tempQuestions.findIndex((q) => q.id === "healthcare_jobs");
            if (index !== -1) {
              tempQuestions.splice(index, 1);
            }
          }
          if (ans.optionId === WillowIndustryId.SkilledTrade) {
            const index = tempQuestions.findIndex((q) => q.id === "trade_jobs");
            if (index !== -1) {
              tempQuestions.splice(index, 1);
            }
          }
          if (ans.optionId === WillowIndustryId.Sciences) {
            const index = tempQuestions.findIndex((q) => q.id === "tech_jobs");
            if (index !== -1) {
              tempQuestions.splice(index, 1);
            }
          }
          if (ans.optionId === WillowIndustryId.Arts) {
            const index = tempQuestions.findIndex((q) => q.id === "liberal_arts_jobs");
            if (index !== -1) {
              tempQuestions.splice(index, 1);
            }
          }
        });
        return tempQuestions;
      }
    },
    []
  );

  type HandleUpdateAnswersProps = {
    answers: AnswerRecord[];
    newAnswer: AnswerRecord;
  };

  const handleUpdateAnswers = useCallback(({ answers, newAnswer }: HandleUpdateAnswersProps) => {
    const newAnswers = [...answers];
    const index = newAnswers.findIndex((ans) => ans.questionId === newAnswer.questionId);
    if (index === -1) {
      newAnswers.push(newAnswer);
    } else {
      newAnswers[index] = newAnswer;
    }
    return newAnswers;
  }, []);

  return {
    submitAnswer,
    validateAnswer,
    filterMatrixQuestions,
    handleUpdateAnswers,
  };
};

export default useQuiz;
