import { useCallback, useState } from "react";
import useFetch from "../../../shared/hooks/useFetch";
import { ChatEntry } from "../../../shared/types/types";

type getAlmaResponseProps = {
  chatHistoryForAI: ChatEntry[];
  newEntry: ChatEntry;
  studentContext?: string;
};

const useAIInteractions = () => {
  const { getFromAPI } = useFetch();
  const [loading, setLoading] = useState(false);

  //helper that gets alma's reply and returns it as a chat entry type
  const getAlmaResponse = useCallback(
    async ({ chatHistoryForAI, newEntry, studentContext = "" }: getAlmaResponseProps) => {
      setLoading(true);
      //chat history is just all the history of the convo,
      //message is the text of a new netry (which has the text key because in the future there will be video and images able to be added),
      //and studentcontext is optional if a student is selected
      // const response = await getFromAPI<ChatResponse>({
      //   url: "/runAlma",
      //   body: {
      //     chatHistory: chatHistoryForAI,
      //     message: newEntry.parts[0].text,
      //     contextToAdd: studentContext,
      //   },
      // });
      const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/runAlma`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chatHistory: chatHistoryForAI,
          message: newEntry.parts[0].text,
          contextToAdd: studentContext,
        }),
      });
      const responseJson = await response.json();
      //all Gemnini entries must be model or user
      const responseToSave: ChatEntry = {
        role: "model",
        timestamp: new Date().toISOString(),
        parts: [
          {
            text: responseJson
              ? responseJson.text
              : "I'm sorry, I'm not able to respond to that right now. Please try again later.",
          },
        ],
      };
      return responseToSave;
    },
    [getFromAPI]
  );
  return { getAlmaResponse, loading };
};

export default useAIInteractions;
