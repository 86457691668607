import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { QuestionRecord, AnswerTypeForm, QuestionOption, Answer } from "../../types/types";
import { Box, Grid, Typography } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useRecoilValue } from "recoil";
import { studentAnswersAtom } from "../../recoil/answersAtoms";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { BLANK_ANSWER_TYPE_FORM } from "../../libraries/blankForms";
import SubIndustryQuestion from "./SubIndustryQuestion";
import { PathwayHeader, SubIndustryDBName } from "../../types/enums";
import MatrixDialog from "./MatrixDialog";

type Props = {
  question: QuestionRecord;
  answer: AnswerTypeForm;
  setAnswer: Dispatch<SetStateAction<AnswerTypeForm>>;
  subAnswers?: Answer[];
  setSubAnswers?: React.Dispatch<React.SetStateAction<Answer[]>>;
};

type HandleClickProps = {
  headerId: number;
  optionId: number | string;
  checked: boolean;
};

const MatrixQuestion = ({ question, answer, setAnswer, subAnswers = [], setSubAnswers }: Props) => {
  const answers = useRecoilValue(studentAnswersAtom);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!answers) return;
    const currentAnswer = answers.find((ans) => ans.questionId === question.id);
    if (!currentAnswer) {
      setAnswer({ ...BLANK_ANSWER_TYPE_FORM });
      return;
    }
    setAnswer((pV) => ({ ...BLANK_ANSWER_TYPE_FORM, matrix: currentAnswer?.matrix ?? [] }));

    if (!setSubAnswers) return;
    const currentsubAnswers = answers.filter(
      (ans) =>
        ans.questionId === SubIndustryDBName.Business ||
        ans.questionId === SubIndustryDBName.Arts ||
        ans.questionId === SubIndustryDBName.Sciences ||
        ans.questionId === SubIndustryDBName.SkilledTrade ||
        ans.questionId === SubIndustryDBName.SocialServices
    );
    if (!currentsubAnswers) {
      setSubAnswers([]);
      return;
    }
    setSubAnswers(currentsubAnswers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question.id, setAnswer]);

  const renderOptions = (option: QuestionOption) => {
    return question.matrixHeaders.map((header) => {
      const checked = answer.matrix.find(
        (answer) => answer.headerId === header.id && answer.optionId === option.id
      ) ? true : false;

      return (
        <Grid sx={{ textAlign: "center" }} item key={header.id} xs={4} sm={3}>
          <Box
            onClick={() => {
              handleClick({ headerId: header.id, optionId: option.id, checked: checked });
            }}
          >
            {checked ? (
              <RadioButtonCheckedIcon sx={{ mr: 2 }} />
            ) : (
              <RadioButtonUncheckedIcon sx={{ mr: 2 }} />
            )}
          </Box>
        </Grid>
      );
    });
  }

  const handleClick = ({ headerId, optionId, checked }: HandleClickProps) => {
    const notInterested = answer.matrix.filter(
      (ans) => ans.headerId === PathwayHeader.NotInterested
    );
    if (notInterested.length >= 3 && headerId === PathwayHeader.NotInterested && checked === false) {
      setMessage("You can only select up to 3 not interested options");
      return;
    }
    const prevAnswer = answer.matrix.find((ans) => ans.optionId === optionId);
    if (!prevAnswer) {
      setAnswer((pV) => ({ ...pV, matrix: [...answer.matrix, { optionId, headerId }] }));
      return;
    } else {
      const prevAnswers = [...answer.matrix];
      const index = prevAnswers.indexOf(prevAnswer);
      if (index === -1) {
        prevAnswers.push({ optionId, headerId });
      } else {
        if (prevAnswers[index].headerId === headerId) {
          prevAnswers.splice(index, 1);
          setAnswer((pV) => ({
            ...pV,
            matrix: prevAnswers,
          }));
          return;
        }
        prevAnswers.splice(index, 1);
        prevAnswers.push({ optionId, headerId });
      }
      setAnswer((pV) => ({
        ...pV,
        matrix: prevAnswers,
      }));
    }
  };

  return (
    <>
      <Typography variant="h5" textAlign={"center"}>
        {question.question}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" textAlign={"center"}>
        {question.helperText}
      </Typography>
      <Grid container spacing={2} alignItems={"center"} columns={13}>
        <Grid item xs={13} sm={4}></Grid>
        {question.matrixHeaders.map((header) => (
          <Grid key={header.id} item xs={4} sm={3}>
            <Typography
              key={header.id}
              sx={{ fontWeight: 700 }}
              variant="body1"
              textAlign={"center"}
            >
              {header.text}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {question.options.map((option) => (
        <React.Fragment key={option.id}>
          <Box
            sx={{
              borderRadius: 2,
              border: "1px solid rgb(152, 152, 152)",
              p: 2,
              mt: 2,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Grid container spacing={2} alignItems={"center"} columns={13}>
              <Grid item xs={13} sm={4}>
                <Typography key={option.id} variant="body1">
                  {option.text}
                </Typography>
                <Typography sx={{ fontSize: 10 }} variant="body1">
                  {option.helperText ? `(${option.helperText})` : ""}
                </Typography>
              </Grid>
              { renderOptions(option) }
            </Grid>
          </Box>
          {answer.matrix.find(
            (ans) => ans.optionId === option.id && ans.headerId === PathwayHeader.MostInterested
          ) && <SubIndustryQuestion optionId={option.id} subAnswers={subAnswers} setSubAnswers={setSubAnswers} />}
        </React.Fragment>
      ))}
      <MatrixDialog message={message} setMessage={setMessage} />
    </>
  );
};

export default MatrixQuestion;
