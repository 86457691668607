import { useState, useCallback, useEffect } from "react";
import { Box, Toolbar, IconButton, Menu, Typography, Avatar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useRecoilValue } from "recoil";
import { styled } from "@mui/material/styles";

import { loggedInMentorAtom, loggedInProviderAtom, loggedInStaffAtom, selectedStudentAtom, userTypeAtom } from "../../recoil/userAtoms";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import StaffMenuDropdown from "./StaffMenuDropdown";
import StudentMenuDropdown from "./StudentMenuDropdown";
import ProviderMenuDropdown from "./ProviderMenuDropdown";
import { Color, UserType } from "../../types/enums";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import MentorMenuDropdown from "./MentorMenuDropdown";

type Props = {
  open?: boolean;
  title?: string;
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 221;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Navbar = ({ open, title = "Willow" }: Props) => {
  const storage = getStorage();
  const [avatarURL, setAvatarURL] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const userType = useRecoilValue(userTypeAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const loggedInProvider = useRecoilValue(loggedInProviderAtom);
  const loggedInMentor = useRecoilValue(loggedInMentorAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const { width } = useWindowDimensions();

  useEffect(() => {
    const getImageURL = async () => {
      const fileName = loggedInStaff?.avatar || loggedInMentor?.avatar || selectedStudent?.avatar || "";
      if (fileName.length > 5) {
        let url = "";
        try {
          try {
            url = await getDownloadURL(ref(storage, `${fileName}_200x200`));
          } catch (error) {
            url = await getDownloadURL(ref(storage, `${fileName}`));
          }
        } catch (error) {
          console.error(error);
        }
        setAvatarURL(url);
      }
    };
    getImageURL();
  }, [loggedInStaff, loggedInMentor, selectedStudent, storage]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  return (
    <AppBar position="sticky" sx={{ width: "100%", ml: "1px", zIndex: 1 }} open={open}>
      <Toolbar sx={{ backgroundColor: "#fff" }}>
        <Box sx={{ flexGrow: 1, pt: "2px", pb: "2px" }}>
          <Box
            sx={{
              display: "flex",
              alignContent: "flex-start",
              alignItems: "center",
              gap: 2,
              padding: 1,
            }}
          >
            <img
              style={{ width: "200px" }}
              src="../../assets/branding/inline-chalkboard.svg"
              alt="Willow"
            />
          </Box>
        </Box>
        {width > 900 && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ color: "#333" }}>
              {userType && userType >= UserType.Staff && userType !== UserType.ProviderUser && userType !== UserType.MentorUser && (
                <>{loggedInStaff?.email ?? ""}</>
              )}
              {userType && userType === UserType.Student && (
                <>{selectedStudent?.email ?? ""}</>
              )}
              {userType && userType === UserType.ProviderUser && (
                <>{loggedInProvider?.email ?? ""}</>
              )}
              {userType && userType === UserType.MentorUser && (
                <>{loggedInMentor?.email ?? ""}</>
              )}
            </Typography>
            <Avatar
              alt={loggedInStaff?.avatar || loggedInMentor?.avatar || selectedStudent?.avatar || ""}
              src={avatarURL ? avatarURL : ""}
              sx={{ ml: 2, backgroundColor: "#F4F0DC", color: "#00362E" }}
            />
          </Box>
        )}
        <>
          <IconButton
            size="medium"
            edge="start"
            aria-label="menu"
            sx={{ ml: 1, color: Color.Chalkboard }}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            slotProps={{ paper: { style: { width: 400 } } }}
          >
            {userType && userType >= UserType.Staff && userType !== UserType.ProviderUser && userType !== UserType.MentorUser && (
              <StaffMenuDropdown handleClose={handleClose} />
            )}
            {userType && userType === UserType.Student && (
              <StudentMenuDropdown handleClose={handleClose} />
            )}
            {userType && userType === UserType.ProviderUser && (
              <ProviderMenuDropdown handleClose={handleClose} />
            )}
            {userType && userType === UserType.MentorUser && (
              <MentorMenuDropdown />
            )}
          </Menu>
        </>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
