import React from "react";
import VideoDisplay from "./VideoDisplay";
import { Grid } from "@mui/material";
import Overview from "./Overview";
import Programs from "./Programs";
import CareerTable from "./CareerTable";

const ExploreCareersContainer = () => {
  return (
    <>
      <Grid container spacing={2} columns={24} sx={{ p: 1 }}>
        <Grid item xs={24} sm={12} md={14} lg={16}>
          <Overview />
          <CareerTable />
          <Programs />
        </Grid>
        <Grid item xs={24} sm={12} md={10} lg={8}>
          <VideoDisplay />
        </Grid>
      </Grid>
    </>
  );
};

export default ExploreCareersContainer;
