import { Button } from "@mui/material";
import React from "react";

type Props = {
  setProviderId: React.Dispatch<React.SetStateAction<string | null>>;
};

const useAlmaDisplay = ({ setProviderId }: Props) => {
  function processStringForJSX(text: string) {
    // const pattern = /\(\d{6}\)/g;
    // const jsxElements = [];
    // let lastMatchIndex = 0; // Keep track of where the last match occurred

    // // Loop to find and process each match
    // let match;
    // while ((match = pattern.exec(text)) !== null) {
    //   const textBeforeMatch = text.substring(lastMatchIndex, match.index);
    //   if (textBeforeMatch) {
    //     jsxElements.push(textBeforeMatch);
    //   }

    //   const numberMatch = match[0].replace(/\(|\)/g, ""); // The full matched pattern (e.g., "(123456)")
    //   jsxElements.push(
    //     <Button key={`${match.index}_${numberMatch}`} onClick={() => setProviderId(numberMatch)}>
    //       click here
    //     </Button>
    //   );

    //   lastMatchIndex = match.index + match[0].length; // Update for the next iteration
    // }

    // // Add the remaining text after the last match
    // const remainingText = text.substring(lastMatchIndex);
    // if (remainingText) {
    //   jsxElements.push(<p key={lastMatchIndex}>{remainingText}</p>);
    // }

    // return jsxElements;
    return <>{text}</>;
  }

  const formatResponse = (gemtext: string): React.ReactElement[] => {
    const lines = gemtext.split("\n");
    const formattedElements: React.ReactElement[] = [];

    for (const line of lines) {
      if (line.trim()) {
        if (line.startsWith("* ")) {
          const strippedLine = line.slice(2).trim().replace(/\*\*/g, "");
          // Bullet point (assuming specific format)
          formattedElements.push(
            <ul key={line}>
              <li key={line}>
                {processStringForJSX(strippedLine)}
                <br /> {/* Remove "* " and bold formatting, add line break */}
              </li>
            </ul>
          );
        } else if (line.startsWith("*")) {
          // Handle potential other bullet points
          if (line.startsWith("**") && line.endsWith("**")) {
            formattedElements.push(
              <strong key={line}>{processStringForJSX(line.slice(2, -2))}</strong>
            );
          } else {
            formattedElements.push(<p key={line}>{processStringForJSX(line)}</p>);
          }
        } else if (line.startsWith("=>")) {
          // Links
          const [url, description] = line.slice(2).trim().split(" ", 2);
          formattedElements.push(
            <a key={line} href={url} target="_blank" rel="noopener noreferrer">
              {description || url}
            </a>
          );
        } else if (line.startsWith(">")) {
          // Blockquote
          formattedElements.push(
            <blockquote key={line}>{processStringForJSX(line.substring(1).trim())}</blockquote>
          );
        } else {
          // Regular text, possible bold

          formattedElements.push(<p key={line}>{processStringForJSX(line)}</p>);
        }
      }
    }

    return formattedElements;
  };
  return { formatResponse };
};

export default useAlmaDisplay;
