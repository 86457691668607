import { useCallback, useState } from "react";
import { Provider_Program } from "../../types/types";

type QueryProps = {
  sizes: string[];
  regions: string[];
  states: string[];
  industries: string[];
  locales: string[];
  hbcu: boolean;
  religiousAffiliation: string | null;
  admissionPercent: string | null;
  providerNameSearch: string;
  workforceOnly: boolean;
  city: string;
};

const useExplorePrograms = () => {
  const [loading, setLoading] = useState(false);

  const queryDB = useCallback(
    async ({
      sizes,
      regions,
      states,
      industries,
      locales,
      hbcu,
      religiousAffiliation,
      admissionPercent,
      city,
      providerNameSearch,
      workforceOnly,
    }: QueryProps) => {
      setLoading(true);
      const results = await fetch(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getExploreProgramsSearch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            states: states,
            regions: regions,
            industries: industries,
            sizes: sizes,
            locales: locales,
            hbcu: hbcu,
            religiousAffiliation: religiousAffiliation,
            admissionPercent: admissionPercent,
            providerNameSearch: providerNameSearch,
            workforceOnly: workforceOnly,
            city: city,
          }),
        }
      );
      const data = (await results.json()) as { rows: Provider_Program[] };
      setLoading(false);
      return data.rows;
    },
    []
  );
  return { queryDB };
};

export default useExplorePrograms;
