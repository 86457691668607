export const articles = [
  {
    title: "Exploring Willow Recommendations: A Counselor's Guide to List-Building and Exploration",
    date: "September 14, 2016",
    description: `While Willow recommends quality programs likely to be a good fit for students, don’t overlook the importance of the "Program Review" conversation and phase! It's where students evaluate and select the most interesting programs.`,

    image:
      "https://static.wixstatic.com/media/a39dcd_b965364beea44f60ad96a3ffd8d9f9a5~mv2.png/v1/fill/w_360,h_360,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/a39dcd_b965364beea44f60ad96a3ffd8d9f9a5~mv2.png",
    imgText: "main image description",
    link: "https://www.willowed.org/post/exploring-willow-recommendations-a-counselor-s-guide-to-list-building-and-exploration",
  },
  {
    title: `"College isn't for me", How do we best support this group of students`,
    date: "September 20, 2023",
    description:
      "Legacy college counseling and navigation platforms do exactly that, navigate college. By definition, they leave behind the half of students who don’t go to college. We believe that all students deserve high-quality next steps after graduation that lead to meaningful work and thriving wages.",
    image:
      "https://static.wixstatic.com/media/a39dcd_2339a36e84014c6c85d24264d6b6e28f~mv2.png/v1/fill/w_556,h_556,fp_0.50_0.50,q_95,enc_auto/a39dcd_2339a36e84014c6c85d24264d6b6e28f~mv2.png",
    imgText: "'College isn't for me', How do we best support this group of students",
    link: "https://www.willowed.org/post/college-isn-t-for-me-how-do-we-best-support-these-students",
  },
  {
    title: "What is Return on Investment (ROI)? And Why does it Matter?",
    date: "June 17, 2022",
    description:
      "Return on Investment (ROI) is a metric used to evaluate the financial return you get from investing money, time, or other resources into something. Especially, with major investments, such as a college degree or professional credential, you need to understand if it's worth it.",
    image:
      "https://static.wixstatic.com/media/a39dcd_b784f04771fb460ca06abbe62dfadba6~mv2.png/v1/fill/w_556,h_556,fp_0.50_0.50,q_95,enc_auto/a39dcd_b784f04771fb460ca06abbe62dfadba6~mv2.png",
    imgText: "counselor helping student",
    link: "https://www.willowed.org/post/__roi",
  },
];

export const mostCommonPasswords = [
  "123456",
  "password",
  "12345678",
  "qwerty",
  "123456789",
  "111111",
  "12345",
  "iloveyou",
  "1234567",
  "dragon",
  "baseball",
  "football",
  "letmein",
  "admin",
  "shadow",
  "master",
  "123123",
  "1234",
  "superman",
  "welcome",
  "michael",
  "assword",
  "qwertyuiop",
  "123qwerty",
  "123abc",
  "1234567890",
  "letmein1",
  "monkey",
  "batman",
  "654321",
  "password1",
  "dragon1",
  "12345678901",
  "qwertyuiop1",
  "1qaz2wsx",
  "123456789012",
  "1234567890123",
  "12345678901234",
  "123456789012345",
  "1234567890123456",
  "12345678901234567",
  "123456789012345678",
  "1234567890123456789",
  "12345678901234567890",
  "123456789012345678901",
  "1234567890123456789012",
  "12345678901234567890123",
  "123456789012345678901234",
  "1234567890123456789012345",
  "12345678901234567890123456",
  "123456789012345678901234567",
  "1234567890123456789012345678",
  "12345678901234567890123456789",
];
