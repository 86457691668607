import React, { useEffect, useState } from "react";
import { Provider_Program, StudentRecord } from "../../../shared/types/types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { allAnswersAtom, studentAnswersAtom } from "../../../shared/recoil/answersAtoms";

type Return = {
  selectedStudent: StudentRecord | null;
  selectedProgram: Provider_Program | null;
  setSelectedProgram: React.Dispatch<React.SetStateAction<Provider_Program | null>>;
};

const useDashboardContainer = (): Return => {
  const [selectedProgram, setSelectedProgram] = useState<Provider_Program | null>(null);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const setStudentAnswers = useSetRecoilState(studentAnswersAtom);
  const allAnswers = useRecoilValue(allAnswersAtom);

  useEffect(() => {
    setStudentAnswers(allAnswers.filter((answer) => answer.studentId === selectedStudent?.id));
  }, [allAnswers, selectedStudent?.id, setStudentAnswers]);

  return {
    selectedStudent,
    selectedProgram,
    setSelectedProgram
  }
}

export default useDashboardContainer;