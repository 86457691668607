import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";
import { BLANK_STUDENT_FORM } from "../../../shared/libraries/blankForms";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import SelectSchool from "./SelectSchool";
import { Collection, GradeLevel, PageRoute, Stage, UserType } from "../../../shared/types/enums";
import { Student, StudentRecord } from "../../../shared/types/types";
import { useAuth } from "../../../shared/contexts/AuthContext";
import useSetDoc from "../../../shared/hooks/useSetDoc";
import { useNavigate } from "react-router";
import { selectedStudentAtom, userTypeAtom } from "../../../shared/recoil/userAtoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { parseSingleStudentResponse } from "../../../shared/utils/parsers";
import {
  ListType,
  addUserToKlaviyoList,
  createKlaviyoProfile,
} from "../../../shared/utils/klaviyoFunctions";
import { verifyPhoneNumber } from "../../../shared/utils/functions";
import PhoneNumber from "../../../shared/components/PhoneNumber";

const GRADES = [GradeLevel.NINTH, GradeLevel.TENTH, GradeLevel.ELEVENTH, GradeLevel.TWELFTH];

const OnboardingContainer = () => {
  const [onboardingForm, setOnboardingForm] = useState<Student>({ ...BLANK_STUDENT_FORM });
  const { sendRequest: setDoc } = useSetDoc();
  const { currentAuthUser, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const setUserType = useSetRecoilState(userTypeAtom);
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [demoLoading, setDemoLoading] = useState(false);

  useEffect(() => {
    console.log(verifyPhoneNumber(onboardingForm.phone), "is phone number valid");
    if (
      onboardingForm.firstName &&
      onboardingForm.lastName &&
      onboardingForm.schoolEmail &&
      onboardingForm.gradeLevel &&
      onboardingForm.birthday &&
      verifyPhoneNumber(onboardingForm.phone) &&
      onboardingForm.schoolId
    ) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [
    onboardingForm.birthday,
    onboardingForm.firstName,
    onboardingForm.gradeLevel,
    onboardingForm.lastName,
    onboardingForm.phone,
    onboardingForm.schoolEmail,
    onboardingForm.schoolId,
  ]);

  useEffect(() => {
    if (!currentAuthUser) return;
    if (currentAuthUser.email?.substring(0, 17) === "demo-student-user") {
      setDemoLoading(true);
      const submitDemoUser = async () => {
        try {
          const resultRecord = await setDoc<StudentRecord>({
            col: Collection.Students,
            data: {
              firstName: "Demo Willow User",
              lastName: "Account",
              email: currentAuthUser.email ?? "",
              avatar: "",
              schoolId: "5FE90ayJe44pzrf69GeV",
              districtId: "f2ek4E1P3lnQCwP2T5Hj",
              gradeLevel: GradeLevel.TWELFTH,
              userType: UserType.Student,
              birthday: null,
              graduationYear: "",
              phone: "222-222-2222",
              stageId: Stage.Stage1,
              optInTextMessages: false,
              agreeTermsOfService: true,
              lastQuestionId: "",
              favoriteProviderIds: [],
              quizComplete: false,
              lastActivity: "",
              id: "",
              createdAt: "",
              lastUpdatedAt: "",
              historicId: null,
              enrolled: true,
              schoolEmail: currentAuthUser.email ?? "",
              personalEmail: "",
              matchedProgramIds: [],
              hollandType: null,
              topCareers: [],
              consentToContact: "Not Asked",
              staffRecommendedProgramIds: [],
              favoriteRecommendedProgramIds: [],
              uidId: true,
              oldId: "",
              schoolIds: [],
              districtIds: [],
              klaviyoProfileId: "",
              setupComplete: true,
            },
            id: currentAuthUser.uid,
          });
          if (!resultRecord) return;
          setSelectedStudent(parseSingleStudentResponse(resultRecord));
          setUserType(UserType.Student);
          setLoading(false);
          navigate(PageRoute.Student_Dashboard);
        } catch (e) {
          console.error(e);
        }
        setDemoLoading(false);
      };
      submitDemoUser();
    }
    setOnboardingForm((pV) => ({ ...pV, schoolEmail: currentAuthUser.email ?? "" }));
  }, [currentAuthUser, navigate, setDoc, setSelectedStudent, setUserType]);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOnboardingForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
  };

  const handleDateChange = (value: DateTime | null) => {
    setOnboardingForm((pV) => ({ ...pV, birthday: value?.toISO() ?? "" }));
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    setOnboardingForm((pV) => ({ ...pV, gradeLevel: e.target.value as GradeLevel }));
  };

  const handleSave = async (event: FormEvent) => {
    event.preventDefault();
    if (!currentAuthUser) return;
    setLoading(true);

    var klaviyoProfileId = "";
    const result = await createKlaviyoProfile({
      email: onboardingForm.personalEmail,
      first_name: onboardingForm.firstName,
      last_name: onboardingForm.lastName,
    });
    console.log(result, "is result from Create Klavio in Onboarding Container");

    if (result.success) {
      if (result.data.id) {
        klaviyoProfileId = `${result.data.id}`;

        await addUserToKlaviyoList({
          listType: ListType.StudentList,
          userId: `${result.data.id}`,
        });
      }
    }

    try {
      const resultRecord = await setDoc<StudentRecord>({
        col: Collection.Students,
        data: { ...onboardingForm, klaviyoProfileId, setupComplete: true },
        id: currentAuthUser.uid,
      });
      if (!resultRecord) return;
      setSelectedStudent(parseSingleStudentResponse(resultRecord));
      setUserType(UserType.Student);
      setLoading(false);
      navigate(PageRoute.Student_Dashboard);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {!demoLoading && (
        <Box sx={{ p: 2, mt: 2 }}>
          <Typography textAlign={"center"} variant="h4">
            Welcome To Willow! We need a little info from you to get started.
          </Typography>
          <form onSubmit={handleSave}>
            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={onboardingForm.firstName}
                  name="firstName"
                  onChange={handleTextChange}
                  fullWidth
                  required
                  label="First Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={onboardingForm.lastName}
                  name="lastName"
                  onChange={handleTextChange}
                  fullWidth
                  label="Last Name"
                  required
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={onboardingForm.schoolEmail}
                  name="schoolEmail"
                  onChange={handleTextChange}
                  fullWidth
                  required
                  label="School Email"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={onboardingForm.personalEmail}
                  name="personalEmail"
                  onChange={handleTextChange}
                  fullWidth
                  label="Personal Email"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneNumber
                  label="Phone"
                  name="phone"
                  value={onboardingForm.phone}
                  onChange={handleTextChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl required fullWidth>
                  <InputLabel shrink={true} id="grade-label">
                    Grade
                  </InputLabel>
                  <Select
                    labelId="grade-label"
                    id="grade-select"
                    value={onboardingForm.gradeLevel}
                    required
                    notched
                    label="Grade"
                    onChange={handleSelectChange}
                    fullWidth={true}
                  >
                    {GRADES.map((grade) => (
                      <MenuItem key={grade} value={grade}>
                        {grade}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    label="Select Your Birthday"
                    sx={{ width: "100%" }}
                    value={
                      onboardingForm.birthday ? DateTime.fromISO(onboardingForm.birthday) : null
                    }
                    onChange={handleDateChange}
                    slotProps={{
                      textField: {
                        InputLabelProps: { shrink: true },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectSchool
                  onboardingForm={onboardingForm}
                  setOnboardingForm={setOnboardingForm}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {!loading ? (
                  <Button
                    disabled={!validate}
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                ) : (
                  <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <CircularProgress />
                  </Box>
                )}
                <Typography variant="caption" textAlign="center">
                  By clicking submit, you agree to Willow's Terms of Service linked{" "}
                  <a
                    href="https://app.willowed.org/terms_of_service.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};

export default OnboardingContainer;
