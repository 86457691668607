import { Box, Button, CircularProgress, Typography } from "@mui/material";
import useProviderFormContainer from "../../hooks/providerForm/useProviderFormContainer";
import { Provider, ProviderUser } from "../../../shared/types/types";
import EditProviderForm from "./EditProviderForm";

type Props = {
  user?: ProviderUser;
  provider?: Provider;
};

const ProviderFormContainer = ({ user, provider }: Props) => {
  const {
    loading,
    validated,
    userForm,
    providerForm,
    setUserForm,
    setProviderForm,
    handleValidateChange,
    handleSave,
  } = useProviderFormContainer({ user, provider });

  return (
    <Box sx={{ p: 2, mt: 2 }}>
      <Typography textAlign={"center"} variant="h3">
        Welcome To Willow! We need a little info from you to get started.
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
        <EditProviderForm userForm={userForm} providerForm={providerForm} setUserForm={setUserForm} setProviderForm={setProviderForm} onValidateChange={handleValidateChange} />
        {!loading ? (
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            disabled={!validated}
            onClick={handleSave}
          >
            Submit
          </Button>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Typography variant="caption" textAlign="center">
          By clicking submit, you agree to Willow's Terms of Service linked{" "}
          <a
            href="https://app.willowed.org/terms_of_service.pdf"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </Typography>
      </Box>
    </Box>
  );
};

export default ProviderFormContainer;
