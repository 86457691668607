import { Box, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";

import { Dispatch, SetStateAction } from "react";
import { Provider_Program } from "../../../shared/types/types";
import { allMatchedProgramsAtom } from "../../../shared/recoil/providerAtoms";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import DisplayBox from "../../../shared/components/DisplayBox";
import ProgramCard from "../../../shared/components/programCard/ProgramCard";
import { SelectedProgramSource } from "../../../shared/types/enums";

type Props = {
  setSelectedProgram: Dispatch<SetStateAction<Provider_Program | null>>;
};

const MatchedPrograms = ({ setSelectedProgram }: Props) => {
  const allMatchedPrograms = useRecoilValue(allMatchedProgramsAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);

  const matches = allMatchedPrograms.filter((match) =>
    selectedStudent?.matchedProgramIds.includes(match.id)
  );
  const sortedPrograms = [...matches];

  sortedPrograms.sort((a, b) => {
    //sort programs by favorite
    const aFavorited =
      selectedStudent?.favoriteRecommendedProgramIds.includes(a.id) ||
      selectedStudent?.favoriteProviderIds.includes(a.id);
    const bFavorited =
      selectedStudent?.favoriteRecommendedProgramIds.includes(b.id) ||
      selectedStudent?.favoriteProviderIds.includes(b.id);

    // If only one of the programs is favorited, prioritize it
    if (aFavorited && !bFavorited) {
      return -1;
    } else if (!aFavorited && bFavorited) {
      return 1;
    }

    //sort programs by willowROI
    if (a.willowROI && b.willowROI) {
      return b.willowROI - a.willowROI;
    } else {
      return 0;
    }
  });

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DisplayBox title="Matched Programs" boxPadding={0}>
          <Box
            sx={{
              maxHeight: "80VH",
              overflow: "scroll",
              overflowX: "hidden",
              backgroundColor: "#fafafa",
            }}
          >
            <Grid container spacing={2}>
              {sortedPrograms.map((match) => (
                <Grid key={match.id} item xs={12} sm={12} md={12}>
                  <ProgramCard
                    program={match}
                    source={SelectedProgramSource.Matched}
                    setSelectedLearnMoreProgram={setSelectedProgram}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </DisplayBox>
      </Box>
    </>
  );
};

export default MatchedPrograms;
