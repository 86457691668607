import React, { useState, useEffect, useCallback } from "react";
import {
  AlmaChatRecord,
  AnswerRecord,
  ApplicationDataRecord,
  DistrictRecord,
  MentorUserRecord,
  Provider_Program,
  QuestionRecord,
  SchoolRecord,
  StaffRecord,
  StageRecord,
  StageTaskRecord,
  WillowSubIndustryRecord,
  WriteInProgramRecord,
} from "../../shared/types/types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  districtsAtom,
  schoolsAtom,
  selectedDistrictAtom,
  selectedSchoolAtom,
} from "../../shared/recoil/districtAtoms";
import {
  parseAlmaChatResponse,
  parseAnswerResponse,
  parseApplicationDataResponse,
  parseDistrictResponse,
  parseMentorUsersResponse,
  parseProgramResponse,
  parseQuestionResponse,
  parseSchoolResponse,
  parseStaffResponse,
  parseStageResponse,
  parseStageTaskResponse,
  parseWillowSubIndustryResponse,
  parseWriteInProgramsResponse,
} from "../../shared/utils/parsers";
import { allStudentApplicationDataAtom, selectedStudentAtom, staffAtom } from "../../shared/recoil/userAtoms";
import { getFBDocs } from "../../shared/utils/queries";
import { Collection } from "../../shared/types/enums";
import { stageTasksAtom, stagesAtom } from "../../shared/recoil/stageAtoms";
import { questionsAtom } from "../../shared/recoil/questionAtoms";
import { studentAnswersAtom } from "../../shared/recoil/answersAtoms";
import {
  mentorUsersAtom,
  programsAtom,
  studentWriteInProgramsAtom,
  willowSubIndustriesAtom,
} from "../../shared/recoil/providerAtoms";
import { StudentDataContext } from "../contexts/StudentDataContext";
import { studentAlmaChatsAtom } from "../../shared/recoil/almaAtoms";
import { siteLoadingAtom } from "../../shared/recoil/loadingAtoms";

type Props = {
  children: JSX.Element;
};

export const StudentDBProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [sqlLoading, setSQLLoading] = useState(true);
  const setSelectedSchool = useSetRecoilState(selectedSchoolAtom);
  const setSelectedDistrict = useSetRecoilState(selectedDistrictAtom);
  const setDistricts = useSetRecoilState(districtsAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const setSchools = useSetRecoilState(schoolsAtom);
  const setStages = useSetRecoilState(stagesAtom);
  const setStageTasks = useSetRecoilState(stageTasksAtom);
  const setQuestions = useSetRecoilState(questionsAtom);
  const setStaff = useSetRecoilState(staffAtom);
  const setStudentAnswers = useSetRecoilState(studentAnswersAtom);
  const setPrograms = useSetRecoilState(programsAtom);
  const setStudentWriteInPrograms = useSetRecoilState(studentWriteInProgramsAtom);
  const setSelectedStudentApplicationData = useSetRecoilState(allStudentApplicationDataAtom);
  const setWillowSubIndustries = useSetRecoilState(willowSubIndustriesAtom);
  const setStudentAlmaChats = useSetRecoilState(studentAlmaChatsAtom);
  const setMentorUsers = useSetRecoilState(mentorUsersAtom);
  const setSiteLoading = useSetRecoilState(siteLoadingAtom);

  useEffect(() => {
    /*this function loads promises of all the data needed for when students log in into dataLoaderPromises
    and then awaits for all the data to be loaded and then sets the data into the recoil state*/

    const loadData = async () => {
      if (!selectedStudent?.districtId || !selectedStudent?.schoolId) return;
      const dataLoaderPromises: [
        Promise<DistrictRecord[]>,
        Promise<SchoolRecord[]>,
        Promise<QuestionRecord[]>,
        Promise<StageRecord[]>,
        Promise<StageTaskRecord[]>,
        Promise<StaffRecord[]>,
        Promise<AnswerRecord[]>,
        Promise<ApplicationDataRecord[]>,
        Promise<WriteInProgramRecord[]>,
        Promise<WillowSubIndustryRecord[]>,
        Promise<AlmaChatRecord[]>,
        Promise<MentorUserRecord[]>
      ] = [
        getFBDocs<DistrictRecord>({
          col: Collection.Districts,
        }),
        getFBDocs<SchoolRecord>({
          col: Collection.Schools,
          config: { where: ["districtId", "==", selectedStudent.districtId] },
        }),
        getFBDocs<QuestionRecord>({
          col: Collection.Questions,
          config: { orderBy: ["order", "asc"] },
        }),
        getFBDocs<StageRecord>({
          col: Collection.Stages,
          config: { orderBy: ["order", "asc"] },
        }),
        getFBDocs<StageTaskRecord>({
          col: Collection.StageTasks,
        }),
        getFBDocs<StaffRecord>({
          col: Collection.Staff,
          config: { where: ["schoolId", "==", selectedStudent.schoolId] },
        }),
        getFBDocs<AnswerRecord>({
          col: Collection.Answers,
          config: { where: ["studentId", "==", selectedStudent.id] },
        }),
        getFBDocs<ApplicationDataRecord>({
          col: Collection.ApplicationData,
          config: { where: ["schoolId", "==", selectedStudent.schoolId] },
        }),
        getFBDocs<WriteInProgramRecord>({
          col: Collection.WriteInPrograms,
          config: { where: ["studentId", "==", selectedStudent.id] },
        }),
        getFBDocs<WillowSubIndustryRecord>({
          col: Collection.WillowSubIndustries,
        }),
        getFBDocs<AlmaChatRecord>({
          col: Collection.AlmaChats,
          config: { where: ["studentId", "==", selectedStudent.id] },
        }),
        getFBDocs<MentorUserRecord>({
          col: Collection.MentorUsers,
          config: { where: ["studentIds", "array-contains", selectedStudent.id] },
        }),
      ];
      const [
        districts,
        schools,
        questions,
        stages,
        stageTasks,
        staff,
        answers,
        applicationData,
        studentWriteInPrograms,
        willowSubIndustries,
        studentAlmaChats,
        mentorUsers,
      ] = await Promise.all(dataLoaderPromises);
      setDistricts(parseDistrictResponse(districts));
      setSchools(parseSchoolResponse(schools));
      setStages(parseStageResponse(stages));
      setStageTasks(parseStageTaskResponse(stageTasks));
      setQuestions(parseQuestionResponse(questions));
      setStaff(parseStaffResponse(staff));
      setSelectedStudentApplicationData(parseApplicationDataResponse(applicationData));
      //TODO: selectedDistrict and selectedSchool should never be null, but I need to look at error handling acorss the site if that case happens before just removing it as a possibility from the type
      const userDistrict = districts.find((district) => district.id === selectedStudent.districtId);
      const userSchool = schools.find((school) => school.id === selectedStudent.schoolId);
      setSelectedDistrict(userDistrict ? userDistrict : null);
      setSelectedSchool(userSchool ? userSchool : null);
      setStudentAnswers(parseAnswerResponse(answers));
      setStudentWriteInPrograms(parseWriteInProgramsResponse(studentWriteInPrograms));
      setWillowSubIndustries(parseWillowSubIndustryResponse(willowSubIndustries));
      setStudentAlmaChats(parseAlmaChatResponse(studentAlmaChats));
      setMentorUsers(parseMentorUsersResponse(mentorUsers));
      setLoading(false);

      //   secondary loader
    };
    loadData();
  }, [
    selectedStudent,
    setStudentAnswers,
    setDistricts,
    setPrograms,
    setQuestions,
    setSchools,
    setSelectedDistrict,
    setSelectedSchool,
    setStaff,
    setStageTasks,
    setStages,
    setSelectedStudentApplicationData,
    setStudentWriteInPrograms,
    setWillowSubIndustries,
    setStudentAlmaChats,
    setMentorUsers,
  ]);

  const getMatchedPrograms = useCallback(async (uniqueProgramIds) => {
    try {
      const results = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getListOfPrograms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          programIds: uniqueProgramIds,
        }),
      });
      const { rows } = (await results.json()) as { rows: Provider_Program[] };
      return rows;
    } catch (error) {
      console.error(error);
      return [];
    }
  }, []);

  useEffect(() => {
    if (!selectedStudent || loading || !selectedStudent.districtId || !selectedStudent.schoolId)
      return;
    const getAllMatchedPrograms = async () => {
      const programIds = [
        selectedStudent.staffRecommendedProgramIds,
        selectedStudent.favoriteRecommendedProgramIds,
        selectedStudent.matchedProgramIds,
        selectedStudent.favoriteProviderIds,
      ].flat();
      const uniqueProgramIds = Array.from(new Set(programIds));
      const results = await getMatchedPrograms(uniqueProgramIds);
      setPrograms(parseProgramResponse(results));
      setSQLLoading(false);
    };
    getAllMatchedPrograms();
  }, [getMatchedPrograms, loading, selectedStudent, setPrograms]);

  useEffect(() => {
    setSiteLoading(loading || sqlLoading);
  }, [loading, setSiteLoading, sqlLoading]);

  return (
    <StudentDataContext.Provider value={{ loading, sqlLoading }}>
      {children}
    </StudentDataContext.Provider>
  );
};
