import React, { useState, useEffect, useCallback } from "react";
import {
  AnswerRecord,
  ApplicationDataRecord,
  CIPRecord,
  DistrictRecord,
  GroupRecord,
  Provider_Program,
  QuestionRecord,
  SchoolRecord,
  StaffAlmaChatRecord,
  StaffRecord,
  StageRecord,
  StageTaskRecord,
  StudentRecord,
  WillowIndustryRecord,
  WillowSubIndustryRecord,
  WriteInProgramRecord,
} from "../../shared/types/types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  districtsAtom,
  groupsAtom,
  schoolsAtom,
  selectedDistrictAtom,
  selectedGroupAtom,
  selectedSchoolAtom,
} from "../../shared/recoil/districtAtoms";
import {
  parseAnswerResponse,
  parseApplicationDataResponse,
  parseCIPsResponse,
  parseDistrictResponse,
  parseGroupResponse,
  parseProgramResponse,
  parseQuestionResponse,
  parseSchoolResponse,
  parseStaffAlmaChatResponse,
  parseStaffResponse,
  parseStageResponse,
  parseStageTaskResponse,
  parseStudentResponse,
  parseWillowIndustryResponse,
  parseWillowSubIndustryResponse,
  parseWriteInProgramsResponse,
} from "../../shared/utils/parsers";
import {
  allEnrolledDistrictStudentsAtom,
  allEnrolledGroupStudentsAtom,
  allEnrolledSchoolStudentsAtom,
  allStudentApplicationDataAtom,
  loggedInStaffAtom,
  staffAtom,
} from "../../shared/recoil/userAtoms";
import { getFBDocs } from "../../shared/utils/queries";
import { Collection } from "../../shared/types/enums";
import { stageTasksAtom, stagesAtom } from "../../shared/recoil/stageAtoms";
import { questionsAtom } from "../../shared/recoil/questionAtoms";
import { allAnswersAtom } from "../../shared/recoil/answersAtoms";
import {
  allWriteInProgramsAtom,
  cipsAtom,
  willowIndustriesAtom,
  willowSubIndustriesAtom,
} from "../../shared/recoil/providerAtoms";
import { allMatchedProgramsAtom } from "../../shared/recoil/providerAtoms";
import { useUser } from "../../shared/contexts/UserContext";
import { useAuth } from "../../shared/contexts/AuthContext";
import { StaffDataContext } from "../contexts/StaffDataContext";
import { staffAlmaChatsAtom } from "../../shared/recoil/almaAtoms";
import { siteLoadingAtom } from "../../shared/recoil/loadingAtoms";

type Props = {
  children: JSX.Element;
};

export const StaffDBProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [sqlLoading, setSQLLoading] = useState(true);
  const [startQuery] = useState(new Date().getTime());
  const { loading: userLoading } = useUser();
  const { loading: authLoading } = useAuth();
  const setSelectedSchool = useSetRecoilState(selectedSchoolAtom);
  const setSelectedDistrict = useSetRecoilState(selectedDistrictAtom);
  const setDistricts = useSetRecoilState(districtsAtom);
  const setSchools = useSetRecoilState(schoolsAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const setAllEnrolledDistrictStudents = useSetRecoilState(allEnrolledDistrictStudentsAtom);
  const setAllEnrolledSchoolStudents = useSetRecoilState(allEnrolledSchoolStudentsAtom);
  const setStages = useSetRecoilState(stagesAtom);
  const setStageTasks = useSetRecoilState(stageTasksAtom);
  const setQuestions = useSetRecoilState(questionsAtom);
  const setGroups = useSetRecoilState(groupsAtom);
  const setSelectedGroup = useSetRecoilState(selectedGroupAtom);
  const setStaff = useSetRecoilState(staffAtom);
  const setAllAnswers = useSetRecoilState(allAnswersAtom);
  const setAllStudentApplicationData = useSetRecoilState(allStudentApplicationDataAtom);
  const setAllWriteInPrograms = useSetRecoilState(allWriteInProgramsAtom);
  const setWillowIndustries = useSetRecoilState(willowIndustriesAtom);
  const setWillowSubIndustries = useSetRecoilState(willowSubIndustriesAtom);
  const setAllMatchedPrograms = useSetRecoilState(allMatchedProgramsAtom);
  const allEnrolledGroupStudents = useRecoilValue(allEnrolledGroupStudentsAtom);
  const setCIPs = useSetRecoilState(cipsAtom);
  const setStaffAlmaChats = useSetRecoilState(staffAlmaChatsAtom);
  const setSiteLoading = useSetRecoilState(siteLoadingAtom);
  const [uniqueProgramIds, setUniqueProgramIds] = useState<string[]>([]);

  useEffect(() => {
    if (userLoading || authLoading) return;
    const loadData = async () => {
      if (!loggedInStaff || !loggedInStaff.districtId || !loggedInStaff.schoolId) {
        setLoading(false);
        return;
      }
      setLoading(true);
      const studentRecords = await getFBDocs<StudentRecord>({
        col: Collection.Students,
        config: { where: ["districtId", "==", loggedInStaff.districtId] },
      });
      const parsedStudents = parseStudentResponse(studentRecords);
      const parsedFilteredStudents = parsedStudents.filter(
        (student) => student.uidId && student.enrolled
      );
      setAllEnrolledSchoolStudents(parseStudentResponse(parsedFilteredStudents));

      const dataLoaderPromises: [
        Promise<DistrictRecord[]>,
        Promise<SchoolRecord[]>,
        Promise<StudentRecord[]>,
        Promise<QuestionRecord[]>,
        Promise<StageRecord[]>,
        Promise<StageTaskRecord[]>,
        Promise<GroupRecord[]>,
        Promise<StaffRecord[]>,
        Promise<AnswerRecord[]>,
        Promise<ApplicationDataRecord[]>,
        Promise<WriteInProgramRecord[]>,
        Promise<WillowIndustryRecord[]>,
        Promise<WillowSubIndustryRecord[]>,
        Promise<StaffAlmaChatRecord[]>
      ] = [
        getFBDocs<DistrictRecord>({
          col: Collection.Districts,
        }),
        getFBDocs<SchoolRecord>({
          col: Collection.Schools,
          config: { where: ["districtId", "==", loggedInStaff.districtId] },
        }),

        getFBDocs<StudentRecord>({
          col: Collection.Students,
          config: { where: ["schoolId", "==", loggedInStaff.schoolId] },
        }),
        getFBDocs<QuestionRecord>({
          col: Collection.Questions,
          config: { orderBy: ["order", "asc"] },
        }),
        getFBDocs<StageRecord>({
          col: Collection.Stages,
          config: { orderBy: ["order", "asc"] },
        }),
        getFBDocs<StageTaskRecord>({
          col: Collection.StageTasks,
        }),
        getFBDocs<GroupRecord>({
          col: Collection.Groups,
          config: { where: ["schoolId", "==", loggedInStaff.schoolId] },
        }),
        getFBDocs<StaffRecord>({
          col: Collection.Staff,
          config: { where: ["schoolId", "==", loggedInStaff.schoolId] },
        }),
        getFBDocs<AnswerRecord>({
          col: Collection.Answers,
          config: { where: ["schoolId", "==", loggedInStaff.schoolId] },
        }),
        getFBDocs<ApplicationDataRecord>({
          col: Collection.ApplicationData,
          config: { where: ["schoolId", "==", loggedInStaff.schoolId] },
        }),
        getFBDocs<WriteInProgramRecord>({
          col: Collection.WriteInPrograms,
          config: { where: ["schoolId", "==", loggedInStaff.schoolId] },
        }),
        getFBDocs<WillowIndustryRecord>({
          col: Collection.WillowIndustries,
        }),
        getFBDocs<WillowSubIndustryRecord>({
          col: Collection.WillowSubIndustries,
        }),
        getFBDocs<StaffAlmaChatRecord>({
          col: Collection.StaffAlmaChats,
          config: { where: ["staffId", "==", loggedInStaff.id] },
        }),
      ];
      const [
        districts,
        schools,
        districtAllStudents,
        questions,
        stages,
        stageTasks,
        groups,
        staff,
        answers,
        applicationData,
        writeInPrograms,
        willowIndustries,
        willowSubIndustries,
        staffAlmaChats,
      ] = await Promise.all(dataLoaderPromises);
      const students = parseStudentResponse(districtAllStudents);
      const filteredStudents = students.filter((student) => student.uidId && student.enrolled);
      setAllEnrolledDistrictStudents(parseStudentResponse(filteredStudents));
      setDistricts(parseDistrictResponse(districts));
      setSchools(parseSchoolResponse(schools));
      setStages(parseStageResponse(stages));
      setStageTasks(parseStageTaskResponse(stageTasks));
      setQuestions(parseQuestionResponse(questions));
      const parsedStaff = staff.filter((staff) => staff.uidId);
      setStaff(parseStaffResponse(parsedStaff));
      setGroups(parseGroupResponse(groups));
      const userDistrict = districts.find((district) => district.id === loggedInStaff.districtId);
      const userSchool = schools.find((school) => school.id === loggedInStaff.schoolId);
      setSelectedDistrict(userDistrict ? userDistrict : null);
      setSelectedSchool(userSchool ? userSchool : null);
      setAllAnswers(parseAnswerResponse(answers));
      setAllStudentApplicationData(parseApplicationDataResponse(applicationData));
      setAllWriteInPrograms(parseWriteInProgramsResponse(writeInPrograms));
      setWillowIndustries(parseWillowIndustryResponse(willowIndustries));
      setWillowSubIndustries(parseWillowSubIndustryResponse(willowSubIndustries));
      setStaffAlmaChats(parseStaffAlmaChatResponse(staffAlmaChats));
      setLoading(false);
    };
    loadData();
  }, [
    authLoading,
    loggedInStaff,
    setAllAnswers,
    setAllEnrolledDistrictStudents,
    setAllEnrolledSchoolStudents,
    setAllStudentApplicationData,
    setAllWriteInPrograms,
    setDistricts,
    setGroups,
    setQuestions,
    setSchools,
    setSelectedDistrict,
    setSelectedGroup,
    setSelectedSchool,
    setStaff,
    setStageTasks,
    setStages,
    setWillowIndustries,
    setWillowSubIndustries,
    startQuery,
    userLoading,
    setStaffAlmaChats,
  ]);

  const getMatchedPrograms = useCallback(async (uniqueProgramIds: string[]) => {
    const results = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getListOfPrograms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        programIds: uniqueProgramIds,
      }),
    });
    const { rows } = (await results.json()) as { rows: Provider_Program[] };
    return rows;
  }, []);

  // I had to put this in place to prevent a retrigger of the sql query every time any part of the enrolled student list changed (like hearting a program for example+_)
  useEffect(() => {
    const programIds = allEnrolledGroupStudents
        .map((student) => student.matchedProgramIds)
        .reduce((acc, current) => acc.concat(current), []),
      reducedUniqueProgramIds = Array.from(new Set(programIds));
    if (uniqueProgramIds.length === reducedUniqueProgramIds.length) return;
    setUniqueProgramIds(reducedUniqueProgramIds);
  }, [allEnrolledGroupStudents, uniqueProgramIds]);

  useEffect(() => {
    if (allEnrolledGroupStudents.length === 0) {
      setSQLLoading(false);
      return;
    }
    setSQLLoading(true);
    const getAllMatchedPrograms = async () => {
      const programs = await getMatchedPrograms(uniqueProgramIds);
      setAllMatchedPrograms(parseProgramResponse(programs));
      setSQLLoading(false);
    };
    getAllMatchedPrograms();
  }, [
    allEnrolledGroupStudents.length,
    getMatchedPrograms,
    setAllMatchedPrograms,
    startQuery,
    uniqueProgramIds,
  ]);

  useEffect(() => {
    if (!allEnrolledGroupStudents || loading) return;

    const loadSecondaryData = async () => {
      const dataLoaderPromises: [Promise<CIPRecord[]>] = [
        getFBDocs<CIPRecord>({
          col: Collection.CIP,
        }),
      ];
      const [cips] = await Promise.all(dataLoaderPromises);
      setCIPs(parseCIPsResponse(cips));
    };
    loadSecondaryData();
  }, [allEnrolledGroupStudents, loading, setCIPs]);

  useEffect(() => {
    setSiteLoading(loading || sqlLoading);
  }, [loading, setSiteLoading, sqlLoading]);

  return (
    <StaffDataContext.Provider value={{ loading, sqlLoading }}>
      {children}
    </StaffDataContext.Provider>
  );
};
