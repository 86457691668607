import React, { Dispatch, SetStateAction, ChangeEvent, useEffect } from "react";
import { QuestionRecord, AnswerTypeForm } from "../../types/types";
import { Box, TextField, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { studentAnswersAtom } from "../../recoil/answersAtoms";
import { BLANK_ANSWER_TYPE_FORM } from "../../libraries/blankForms";

type Props = {
  question: QuestionRecord;
  setAnswer: Dispatch<SetStateAction<AnswerTypeForm>>;
  answer: AnswerTypeForm;
};

const OpenResponseQuestion = ({ question, setAnswer, answer }: Props) => {
  const answers = useRecoilValue(studentAnswersAtom);

  useEffect(() => {
    if (!answers) return;
    const currentAnswer = answers.find((ans) => ans.questionId === question.id);
    if (!currentAnswer) {
      setAnswer({ ...BLANK_ANSWER_TYPE_FORM });
      return;
    }
    setAnswer((pV) => ({ ...BLANK_ANSWER_TYPE_FORM, or: currentAnswer.or }));
  }, [answers, question.id, setAnswer]);

  const handleTextChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setAnswer((pV) => ({
      ...pV,
      or: value,
    }));
  };
  return (
    <>
      <Typography variant="h5" textAlign={"center"}>
        {question.question}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" textAlign={"center"}>
        {question.helperText}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <TextField fullWidth multiline value={answer.or} onChange={handleTextChange} />
      </Box>
    </>
  );
};

export default OpenResponseQuestion;
