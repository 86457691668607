import React from "react";
import { Paper, Container, Box } from "@mui/material";
import useWindowDimensions from "../../../shared/hooks/useWindowDimensions";
import Copyright from "../../../shared/components/login/Copyright";
import MentorLoginForm from "./MentorLoginForm";
import useMentorLoginContainer from "../../hooks/login/useMentorLoginContainer";

const MentorLoginContainer = () => {
  const { width } = useWindowDimensions();
  const { currentAuthUser } = useMentorLoginContainer();

  return (
    <Container maxWidth="sm" sx={{ pt: width > 900 ? 8 : 2 }}>
      <>
        {!currentAuthUser && (
          <>
            <Box sx={{ margin: "0 auto", textAlign: "center" }}>
              <img
                style={{ width: "60%" }}
                src="../willow-logo.svg"
                alt="Willow"
              />
            </Box>
            <Paper
              sx={{
                mt: width > 900 ? 4 : 2,
                pt: width > 900 ? 4 : 1,
                pl: 4,
                pr: 4,
                pb: width > 900 ? 4 : 3,
                backgroundColor: "rgb(244,240,220)",
                borderRadius: 8,
              }}
            >
              <MentorLoginForm />
            </Paper>
          </>
        )}
      </>
      <Box sx={{ mt: width > 900 ? 8 : 2 }}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default MentorLoginContainer;
