import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Answer, AnswerTypeForm, QuestionRecord } from "../../../shared/types/types";
import { BLANK_ANSWER_TYPE_FORM } from "../../../shared/libraries/blankForms";
import { allAnswersAtom, studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import {
  allEnrolledSchoolStudentsAtom,
  selectedStudentAtom,
} from "../../../shared/recoil/userAtoms";
import useUpdateMatches from "../../../shared/hooks/useUpdateMatches";
import { Collection, QuestionType } from "../../../shared/types/enums";
import useAddDoc from "../../../shared/hooks/useAddDoc";
import { questionsAtom } from "../../../shared/recoil/questionAtoms";

type Props = {
  editQuestionId: string | null;
  setEditQuestionId: Dispatch<React.SetStateAction<string | null>>;
};

type Return = {
  handleSave: () => void;
  handleClose: () => void;
  answer: AnswerTypeForm;
  setAnswer: Dispatch<SetStateAction<AnswerTypeForm>>;
  subAnswers: Answer[];
  setSubAnswers: Dispatch<SetStateAction<Answer[]>>;
  valid: boolean;
  setValid: Dispatch<SetStateAction<boolean>>;
  detailValid: boolean;
  setDetailValid: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
}

type QuestionAnswerProps = {
  question: QuestionRecord;
  answer: AnswerTypeForm;
};

const useEditDetailsDialog = ({ editQuestionId, setEditQuestionId }: Props): Return => {
  const [valid, setValid] = useState(true);
  const [detailValid, setDetailValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const setAllAnswers = useSetRecoilState(allAnswersAtom);
  const setAllEnrolledSchoolStudents = useSetRecoilState(allEnrolledSchoolStudentsAtom);
  const { updateMatchPrograms } = useUpdateMatches();
  const [answer, setAnswer] = useState<AnswerTypeForm>({
    ...BLANK_ANSWER_TYPE_FORM,
  });
  const [subAnswers, setSubAnswers] = useState<Answer[]>([]);
  const questions = useRecoilValue(questionsAtom);
  const [studentAnswers, setStudentAnswers] = useRecoilState(studentAnswersAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: addDoc } = useAddDoc();

  const validateAnswer = useCallback(({ question, answer }: QuestionAnswerProps) => {
    if (question.type === QuestionType.MultipleChoice) {
      return answer.mc !== 0;
    }
    if (question.type === QuestionType.MultipleSelect || question.type === QuestionType.Geography) {
      return answer.ms.length !== 0;
    }
    if (question.type === QuestionType.OpenResponse) {
      return answer.or.trim().length > 0;
    }
    if (question.type === QuestionType.Matrix) {
      const keys = Object.keys(answer.matrix);
      return keys.length === question.options.length;
    }
    if (question.type === QuestionType.GPA) {
      return answer.gpa.every((g) => g > 0);
    }
    if (question.type === QuestionType.Number) {
      return answer.number >= 0;
    }
    return false;
  }, []);

  useEffect(() => {
    const currentQuestion = questions?.find((question) => question.id === editQuestionId);
    if (!currentQuestion) return;
    setValid(
      validateAnswer({
        question: currentQuestion,
        answer,
      }) && detailValid
    );
  }, [answer, detailValid, setValid, validateAnswer]);

  const handleSave = async () => {
    const currentAnswer = studentAnswers.find((ans) => ans.questionId === editQuestionId);
    if (!answer || !selectedStudent || !editQuestionId) return;
    setLoading(true);
    const programIds = await updateMatchPrograms({
      studentAnswers,
      selectedStudent,
      newAnswer: answer,
      editQuestionId,
    });
    if (currentAnswer) {
      await updateDoc({ col: Collection.Answers, id: currentAnswer.id, data: answer });
    } else {
      const record = await addDoc({
        col: Collection.Answers,
        data: {
          ...answer,
          questionId: editQuestionId,
          studentId: selectedStudent.id,
          schoolId: selectedStudent.schoolId,
          districtId: selectedStudent.districtId,
        },
      });
      if (!record) {
        setLoading(false);
        return;
      }
      setStudentAnswers((pV) => [
        ...pV,
        {
          ...answer,
          ...record,
          questionId: editQuestionId,
          studentId: selectedStudent.id,
          schoolId: selectedStudent?.schoolId ?? "",
          districtId: selectedStudent?.districtId ?? "",
        },
      ]);
      setAllAnswers((pV) => [
        ...pV,
        {
          ...answer,
          ...record,
          questionId: editQuestionId,
          studentId: selectedStudent.id,
          schoolId: selectedStudent?.schoolId ?? "",
          districtId: selectedStudent?.districtId ?? "",
        },
      ]);
    }
    subAnswers.map(async (subAnswer) => {
      if (subAnswer.id) {
        await updateDoc({ col: Collection.Answers, id: subAnswer.id, data: subAnswer });

        setAllAnswers((pV) =>
          pV.map((ans) =>
            ans.questionId === subAnswer.questionId
              ? { ...ans, ...subAnswer }
              : ans
          )
        );
      } else {
        const record = await addDoc({
          col: Collection.Answers,
          data: subAnswer,
        });
        if (!record) {
          setLoading(false);
          return;
        }
        setStudentAnswers((pV) => [
          ...pV,
          {
            ...subAnswer,
            ...record,
          },
        ]);
        setAllAnswers((pV) => [
          ...pV,
          {
            ...subAnswer,
            ...record,
          },
        ]);
      }
    });
    await updateDoc({
      col: "students",
      id: selectedStudent.id,
      data: { matchedProgramIds: programIds },
    });
    setSelectedStudent((pV) => pV && { ...pV, matchedProgramIds: programIds });
    setAllEnrolledSchoolStudents((pV) =>
      pV.map((student) =>
        student.id === selectedStudent.id ? { ...student, matchedProgramIds: programIds } : student
      )
    );
    if (currentAnswer) {
      setStudentAnswers((pV) =>
        pV.map((ans) => (ans.questionId === editQuestionId ? { ...ans, ...answer } : ans))
      );
      setAllAnswers((pV) =>
        pV.map((ans) =>
          ans.questionId === editQuestionId && ans.studentId === selectedStudent.id
            ? { ...ans, ...answer }
            : ans
        )
      );
    }

    setEditQuestionId(null);
    setLoading(false);
  };

  const handleClose = () => {
    setEditQuestionId(null);
  };

  return {
    handleSave,
    handleClose,
    answer,
    setAnswer,
    subAnswers,
    setSubAnswers,
    valid,
    setValid,
    detailValid,
    setDetailValid,
    loading,
  };
};

export default useEditDetailsDialog;
