import { Box, Typography } from "@mui/material";
import { Message } from "../../../shared/types/types";
import { formatMessageTime } from "../../../shared/utils/functions";

type Props = {
  message: Message;
};

const ReceiverMessage = ({ message }: Props) => {
  return (
    <>
      <Box className="yours messages">
        <Box className="message last">
          <Typography sx={{ fontSize: 14, whiteSpace: "pre-line" }} variant="body1">
            {message?.message || (message as any)?.body}
          </Typography>
          <Box sx={{ display: "flex", gap: 1, justifyContent: "start" }}>
            {message.timestamp && (
              <Typography sx={{ fontSize: 12, fontWeight: 500, color: "#656767" }} variant="body2">
                {formatMessageTime(message.timestamp)}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReceiverMessage;
