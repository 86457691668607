import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import useResetPasswordDialog from "../../hooks/login/useResetPasswordDialog";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ResetPasswordDialog = ({ open, setOpen }: Props) => {
  const { email, resetError, handleEmailChange, handleReset, handleClose, isValidEmail } = useResetPasswordDialog({ setOpen });

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle variant="h4">Reset Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText variant="h6">
            Please enter the email address you used to create your account and instructions for how
            to reset your password will be emailed to that address.
          </DialogContentText>
          <TextField
            fullWidth
            label="Email Address"
            sx={{ mt: 2 }}
            value={email}
            onChange={handleEmailChange}
          />
          {resetError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {resetError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleReset} color="primary" disabled={!isValidEmail(email)}>
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResetPasswordDialog;
