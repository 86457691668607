import React, { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AppResult, AppStatus, Collection } from "../../../shared/types/enums";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { allWriteInProgramsAtom, studentWriteInProgramsAtom } from "../../../shared/recoil/providerAtoms";
import { WriteInProgramRecord } from "../../../shared/types/types";

type Props = {
  writeInProgram: WriteInProgramRecord;
};

type Return = {
  status: AppStatus;
  result: AppResult;
  handleResults: (newResult: AppResult) => Promise<void>;
  handleStatus: (newStatus: AppStatus) => Promise<void>;
};

const useWriteInCard = ({ writeInProgram }: Props): Return => {
  const [status, setStatus] = React.useState<AppStatus>(AppStatus.Completed);
  const [result, setResult] = React.useState<AppResult>(AppResult.NoResponse);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const setStudentWriteInPrograms = useSetRecoilState(studentWriteInProgramsAtom);
  const setAllWriteInPrograms = useSetRecoilState(allWriteInProgramsAtom);

  useEffect(() => {
    if (!selectedStudent || !selectedStudent.schoolId || !selectedStudent.districtId) return;
    const fetchApplicationData = async () => {
      if (writeInProgram) {
        setStatus(writeInProgram.status);
        setResult(writeInProgram.result);
      }
    };
    fetchApplicationData();
  }, [writeInProgram, selectedStudent]);

  const handleStatus = async (newStatus: AppStatus) => {
    if (!selectedStudent) return;
    setStatus(newStatus);
    await updateDoc({
      col: Collection.WriteInPrograms,
      id: writeInProgram.id,
      data: { status: newStatus },
    });
    setStudentWriteInPrograms((pV) =>
      pV.map((data) => {
        if (data.id === writeInProgram.id) {
          return { ...data, status: newStatus };
        }
        return data;
      })
    );
    setAllWriteInPrograms((pV) =>
      pV.map((data) => {
        if (data.id === writeInProgram.id) {
          return { ...data, status: newStatus };
        }
        return data;
      })
    );
  };

  const handleResults = async (newResult: AppResult) => {
    if (!selectedStudent) return;
    setResult(newResult);
    await updateDoc({
      col: Collection.WriteInPrograms,
      id: writeInProgram.id,
      data: { result: newResult },
    });
    setStudentWriteInPrograms((pV) =>
      pV.map((data) => {
        if (data.id === writeInProgram.id) {
          return { ...data, result: newResult };
        }
        return data;
      })
    );
    setAllWriteInPrograms((pV) =>
      pV.map((data) => {
        if (data.id === writeInProgram.id) {
          return { ...data, result: newResult };
        }
        return data;
      })
    );
  };

  return {
    status,
    result,
    handleResults,
    handleStatus,
  };
}

export default useWriteInCard;