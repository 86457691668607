import React, { useState } from "react";
import Navbar from "../../shared/components/navigation/Navbar";
import { Box } from "@mui/material";
import Sidebar from "../../shared/components/navigation/Sidebar";
import ProgramDBManagementContainer from "../components/programDBManagement/ProgramDBManagementContainer";

const ProgramDBManagement = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
        <Sidebar open={open} setOpen={setOpen} />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Navbar open={open} title="Program Database Management" />
          <Box sx={{ minHeight: "calc(100VH-64px)" }}>
            <ProgramDBManagementContainer />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProgramDBManagement;
