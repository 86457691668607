import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { Dispatch } from "react";
import { GroupRecord } from "../../../shared/types/types";
import useManageGroupDialog from "../../hooks/settings/useManageGroupDialog";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  groupForm: GroupRecord;
  setGroupForm: Dispatch<React.SetStateAction<GroupRecord>>;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const ManageGroupDialog = ({ groupForm, setGroupForm, open, setOpen }: Props) => {
  const {
    handleTextChange,
    handleSave,
    handleClose,
    handleDelete,
    students,
    selectedStudents,
    handleStudentSelect,
    staff,
    handleStaffSelect,
    selectedStaff,
  } = useManageGroupDialog({
    groupForm,
    setGroupForm,
    setOpen,
    open,
  });
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle variant="h4" sx={{ fontSize: 44, textAlign: "center" }}>
        Manage Group
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        ></Box>
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={groupForm.name}
              name="name"
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              multiple
              id="students-to-group"
              options={students ? students : []}
              disableCloseOnSelect
              onChange={handleStudentSelect}
              fullWidth
              value={selectedStudents}
              getOptionLabel={(option) => option.firstName + " " + option.lastName}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.firstName + " " + option.lastName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Assigned Students"
                  placeholder="Assigned Students"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              multiple
              id="staff-to-group"
              options={staff ? staff : []}
              disableCloseOnSelect
              fullWidth
              onChange={handleStaffSelect}
              value={selectedStaff}
              getOptionLabel={(option) => option.firstName + " " + option.lastName}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.firstName + " " + option.lastName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Assigned Staff"
                  placeholder="Assigned Staff"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={groupForm.name.length === 0} onClick={handleSave}>Save</Button>
        <Button onClick={handleClose}>Cancel</Button>
        {groupForm.id && <Button color="error" onClick={handleDelete}>
          Delete
        </Button>}
      </DialogActions>
    </Dialog>
  );
};

export default ManageGroupDialog;
