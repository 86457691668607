import { Box, IconButton, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { Color } from "../../../shared/types/enums";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import DisplayBox from "../../../shared/components/DisplayBox";
import EditDetailsDialog from "./EditDetailsDialog";
import FeatherIcon from "../../../shared/components/FeatherIcon";

const MyWhy = () => {
  const answers = useRecoilValue(studentAnswersAtom);
  const [editQuestionId, setEditQuestionId] = useState<string | null>(null);
  const handleEditClick = (questionId: string) => {
    setEditQuestionId(questionId);
  };
  const whyAnswer = answers?.find((answer) => answer.questionId === "your_why")?.or;
  return (
    <Box sx={{ mt: 2 }}>
      <DisplayBox showEditButton onClickEdit={() => handleEditClick("your_why")} title="My Why">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Typography sx={{ padding: 2, color: "#000" }} variant="body1">
            {whyAnswer}
          </Typography>
        </Box>
      </DisplayBox>
      <EditDetailsDialog editQuestionId={editQuestionId} setEditQuestionId={setEditQuestionId} />
    </Box>
  );
};

export default MyWhy;
