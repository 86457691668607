import { RecoilRoot } from "recoil";
import AppBootstrap from "./AppBootstrap";
import AuthProvider from "./shared/providers/AuthProvider";
import * as Sentry from "@sentry/react";
import { UserProvider } from "./shared/providers/UserProvider";
import ErrorProvider from "./shared/providers/ErrorProvider";

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: "https://df89b24c1424b19ec49f4aeaf1296413@o4506384235495424.ingest.sentry.io/4506384237199360",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    // new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //
  // Session Replay
  // replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  return (
    <>
      <RecoilRoot>
        <AuthProvider>
          <UserProvider>
            <ErrorProvider>
              <AppBootstrap />
            </ErrorProvider>
          </UserProvider>
        </AuthProvider>
      </RecoilRoot>
    </>
  );
}

export default App;
