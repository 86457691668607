import React, { Dispatch, SetStateAction } from "react";
import {
  ApplicationDataRecord,
  Provider_Program,
  StudentRecord,
} from "../../../shared/types/types";
import { Box, Button, Grid, Typography } from "@mui/material";
import DisplayBox from "../../../shared/components/DisplayBox";
import { AppStatus } from "../../../shared/types/enums";
import { SetterOrUpdater } from "recoil";
import ApplicationProgressBar from "./ApplicationProgressBar";
import ApplicationResults from "./ApplicationResults";
import useApplicationCard from "../../hooks/dashboard/useApplicationCard";

type Props = {
  provider: Provider_Program;
  applicationData: ApplicationDataRecord[];
  setApplicationData: SetterOrUpdater<ApplicationDataRecord[]>;
  selectedStudent: StudentRecord;
  setSelectedLearnMoreProgram: Dispatch<SetStateAction<Provider_Program | null>>;
};

const ApplicationCard = ({
  provider,
  applicationData,
  setApplicationData,
  selectedStudent,
  setSelectedLearnMoreProgram,
}: Props) => {
  const { status, result, handleLearnMore } = useApplicationCard({
    provider,
    applicationData,
    setApplicationData,
    selectedStudent,
    setSelectedLearnMoreProgram,
  });

  return (
    <Box key={provider.id} sx={{ mt: 1 }}>
      <DisplayBox title={`${provider.programName} at ${provider.providerName}`}>
        <Box sx={{ p: 2, position: "relative" }}>
          <Typography sx={{ fontSize: 12 }}>{provider.programShortDescription}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                sx={{
                  mt: 2,
                  textTransform: "none",
                  fontWeight: 400,
                  fontFamily: "Poppins",
                  fontSize: 14,
                }}
                onClick={handleLearnMore}
              >
                Learn More
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Application Progress</Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ApplicationProgressBar status={status} />
            </Box>
          </Box>
          {status === AppStatus.Completed && (
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Application Results</Typography>
              <ApplicationResults result={result} />
            </Box>
          )}
        </Box>
      </DisplayBox>
    </Box>
  );
};

export default ApplicationCard;
