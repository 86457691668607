import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import FeatherIcon from "../../../shared/components/FeatherIcon";
import { Color } from "../../../shared/types/enums";

const BGCOLOR = "#fbfbfb";

type Props = {
  modalOpen: boolean;
  handleConsent: (consent: string) => void;
};

const ConsentToContactDialog = ({ modalOpen, handleConsent }: Props) => {
  return (
    <Dialog open={modalOpen} fullWidth maxWidth="sm">
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            src="../../assets/graphics/confirm.svg"
            alt="confirm"
            style={{ width: "200px", height: "auto" }}
          />
        </Box>
        <Typography variant="h6" sx={{ fontSize: 16 }}>
          Are you okay with us sharing your contact information with programs that you have liked?
        </Typography>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Typography variant="body1" sx={{ fontSize: 14 }}>
          It’s so that they can help you learn more about the program. We promise we’ll never share
          your information with anyone else or spam you.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: BGCOLOR }}>
        <Button variant="contained" onClick={() => handleConsent("Yes")}>
          <IconButton
            sx={{
              color: Color.Chalkboard,
            }}
            disableRipple
          >
            <FeatherIcon icon="thumbs-up" width="16px" />
          </IconButton>
          Yes
        </Button>
        <Button variant="outlined" onClick={() => handleConsent("No")}>
          <IconButton sx={{ color: Color.Chalkboard }} disableRipple>
            <FeatherIcon icon="x" width="16px" />
          </IconButton>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentToContactDialog;
