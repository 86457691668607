import { useRecoilState, useSetRecoilState } from "recoil";
import useUpdateDoc from "../useUpdateDoc";
import { allEnrolledSchoolStudentsAtom, selectedStudentAtom } from "../../recoil/userAtoms";
import { useState } from "react";
import { Collection, SelectedProgramSource } from "../../types/enums";
import { Provider_Program } from "../../types/types";

const useReactions = (program: Provider_Program) => {
  // Recoil state for managing selected student and modifying the list of enrolled students
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const setAllEnrolledSchoolStudents = useSetRecoilState(allEnrolledSchoolStudentsAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();

  // State for managing modal visibility
  const [modalOpen, setModalOpen] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);

  // Functions to open and close modals (clear and self-explanatory)
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // Handles opening the remove confirmation modal or directly removing recommended programs
  const handleRemoveClick = (source: SelectedProgramSource) => {
    if (source === SelectedProgramSource.Matched) {
      setRemoveOpen(true);
    } else if (source === SelectedProgramSource.Recommended) {
      removeRecommendedProgram();
    }
  };

  // Check if the program is favorited by the selected student
  const favorited =
    selectedStudent?.favoriteRecommendedProgramIds.includes(program.id) ||
    selectedStudent?.favoriteProviderIds.includes(program.id);

  const handleConsent = (consent: string) => {
    if (!selectedStudent) return;
    updateDoc({
      col: Collection.Students,
      id: selectedStudent.id,
      data: {
        consentToContact: consent,
      },
    });
    setSelectedStudent((pV) => (pV ? { ...pV, consentToContact: consent } : null));
    handleModalClose();
  };

  const handleAddHeart = async (source: SelectedProgramSource) => {
    if (!selectedStudent || !program) return;
    const updateKey =
      source === SelectedProgramSource.Matched
        ? "favoriteProviderIds"
        : "favoriteRecommendedProgramIds";
    await updateDoc({
      col: Collection.Students,
      id: selectedStudent.id,
      data: {
        [updateKey]: [...selectedStudent[updateKey], program.id],
      },
    });
    setSelectedStudent((pV) =>
      pV
        ? {
            ...pV,
            [updateKey]: [...pV[updateKey], program.id],
          }
        : null
    );

    setAllEnrolledSchoolStudents((pV) => {
      return pV.map((student) => {
        if (student.id === selectedStudent.id) {
          return {
            ...student,
            [updateKey]: [...student[updateKey], program.id],
          };
        }
        return student;
      });
    });
    if (selectedStudent.consentToContact === "Not Asked") {
      handleModalOpen();
    }
  };

  const handleRemoveHeart = async (source: SelectedProgramSource) => {
    if (!selectedStudent) return;
    const updateKey =
      source === SelectedProgramSource.Matched
        ? "favoriteProviderIds"
        : "favoriteRecommendedProgramIds";
    await updateDoc({
      col: Collection.Students,
      id: selectedStudent.id,
      data: {
        [updateKey]: selectedStudent[updateKey].filter((id) => id !== program.id),
      },
    });
    setSelectedStudent((pV) =>
      pV
        ? {
            ...pV,
            [updateKey]: selectedStudent[updateKey].filter((id) => id !== program.id),
          }
        : null
    );
    setAllEnrolledSchoolStudents((pV) => {
      return pV.map((student) => {
        if (student.id === selectedStudent.id) {
          return {
            ...student,
            [updateKey]: selectedStudent[updateKey].filter((id) => id !== program.id),
          };
        }
        return student;
      });
    });
  };

  const removeRecommendedProgram = async () => {
    if (!selectedStudent || !program) return;
    await updateDoc({
      col: Collection.Students,
      id: selectedStudent.id,
      data: {
        staffRecommendedProgramIds: selectedStudent.staffRecommendedProgramIds.filter(
          (id) => id !== program.id
        ),
      },
    });
    setSelectedStudent((pV) =>
      pV
        ? {
            ...pV,
            staffRecommendedProgramIds: selectedStudent.staffRecommendedProgramIds.filter(
              (id) => id !== program.id
            ),
          }
        : null
    );
    setAllEnrolledSchoolStudents((pV) => {
      return pV.map((student) => {
        if (student.id === selectedStudent.id) {
          return {
            ...student,
            staffRecommendedProgramIds: selectedStudent.staffRecommendedProgramIds.filter(
              (id) => id !== program.id
            ),
          };
        }
        return student;
      });
    });
  };
  return {
    handleAddHeart,
    handleRemoveHeart,
    removeRecommendedProgram,
    handleConsent,
    handleRemoveClick,
    modalOpen,
    removeOpen,
    favorited,
    setRemoveOpen,
  };
};

export default useReactions;
