import React from "react";
import { Container } from "@mui/material";
import DemoLoginContainer from "../components/login/DemoLoginContainer";
import { Color } from "../types/enums";

const DemoLogin = () => {
  return (
    <Container maxWidth={false} sx={{ minHeight: "100VH", backgroundColor: Color.Chalkboard }}>
      <DemoLoginContainer />
    </Container>
  );
};

export default DemoLogin;
