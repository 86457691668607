import React from "react";
import OpenResponseQuestion from "../../../shared/components/questionTypes/OpenResponseQuestion";
import { Answer, AnswerTypeForm } from "../../../shared/types/types";
import { QuestionType } from "../../../shared/types/enums";
import MCQuestion from "../../../shared/components/questionTypes/MCQuestion";
import MSQuestion from "../../../shared/components/questionTypes/MSQuestion";
import MatrixQuestion from "../../../shared/components/questionTypes/MatrixQuestion";
import GPAQuestion from "../../../shared/components/questionTypes/GPAQuestion";
import NumberQuestion from "../../../shared/components/questionTypes/NumberQuestion";
import useEditDetailForm from "../../hooks/dashboard/useEditDetailForm";
import GeographyQuestion from "../../../shared/components/questionTypes/GeographyQuestion";

type Props = {
  editQuestionId: string | null;
  answer: AnswerTypeForm;
  setAnswer: React.Dispatch<React.SetStateAction<AnswerTypeForm>>;
  subAnswers: Answer[];
  setSubAnswers: React.Dispatch<React.SetStateAction<Answer[]>>;
  setValid: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditDetailForm = ({ editQuestionId, answer, setAnswer, subAnswers, setSubAnswers, setValid }: Props) => {
  const { currentQuestion } = useEditDetailForm({ editQuestionId, answer, setAnswer });
  return (
    <>
      {currentQuestion && currentQuestion.type === QuestionType.MultipleChoice && (
        <MCQuestion question={currentQuestion} answer={answer} setAnswer={setAnswer} />
      )}
      {currentQuestion && currentQuestion.type === QuestionType.OpenResponse && (
        <OpenResponseQuestion question={currentQuestion} answer={answer} setAnswer={setAnswer} />
      )}
      {currentQuestion && currentQuestion.type === QuestionType.MultipleSelect && (
        <MSQuestion question={currentQuestion} answer={answer} setAnswer={setAnswer} />
      )}
      {currentQuestion && currentQuestion.type === QuestionType.Geography && (
        <GeographyQuestion question={currentQuestion} answer={answer} setAnswer={setAnswer} />
      )}
      {currentQuestion && currentQuestion.type === QuestionType.Matrix && (
        <MatrixQuestion
          question={currentQuestion}
          answer={answer}
          setAnswer={setAnswer}
          subAnswers={subAnswers}
          setSubAnswers={setSubAnswers}
        />
      )}
      {currentQuestion && currentQuestion.type === QuestionType.GPA && (
        <GPAQuestion
          question={currentQuestion}
          answer={answer}
          setAnswer={setAnswer}
          setValid={setValid}
        />
      )}
      {currentQuestion && currentQuestion.type === QuestionType.Number && (
        <NumberQuestion
          question={currentQuestion}
          answer={answer}
          setAnswer={setAnswer}
          setValid={setValid}
        />
      )}
    </>
  );
};

export default EditDetailForm;
