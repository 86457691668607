import { atom } from "recoil";
import { StageRecord, StageTaskRecord } from "../types/types";

export const stagesAtom = atom<StageRecord[]>({
  key: "stages",
  default: [],
});

export const stageTasksAtom = atom<StageTaskRecord[]>({
  key: "stageTasks",
  default: [],
});
