import { useState, useEffect, useCallback } from "react";
import useFetch from "../../../shared/hooks/useFetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { generateStartingPrompt } from "../../../shared/utils/aiUtils";
import { studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import { schoolsAtom } from "../../../shared/recoil/districtAtoms";
import { questionsAtom } from "../../../shared/recoil/questionAtoms";
import { AlmaChat, AlmaChatRecord, BaseRecord, ChatEntry, StudentRecord } from "../../../shared/types/types";
import { currentStudentAlmaChatAtom, studentAlmaChatsAtom } from "../../../shared/recoil/almaAtoms";
import useAddDoc from "../../../shared/hooks/useAddDoc";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import { Collection } from "../../../shared/types/enums";

type ChatResponse = {
  text: string;
  blockReason?: string;
};

const useChatContainer = () => {
  const [chatHistory, setChatHistory] = useState<ChatEntry[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState("");
  const { getFromAPI } = useFetch();
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const studentAnswers = useRecoilValue(studentAnswersAtom);
  const schools = useRecoilValue(schoolsAtom);
  const questions = useRecoilValue(questionsAtom);
  const currentStudentAlmaChat = useRecoilValue(currentStudentAlmaChatAtom);
  const setStudentAlmaChats = useSetRecoilState(studentAlmaChatsAtom);
  const { sendRequest: addDoc } = useAddDoc();
  const { sendRequest: updateDoc } = useUpdateDoc();

  const resetChat = async () => {
    if (!currentStudentAlmaChat) return;
    await updateDoc({
      col: Collection.AlmaChats,
      id: currentStudentAlmaChat.id,
      data: {
        current: false,
      },
    });
    setStudentAlmaChats((prev) =>
      prev.map((chat) =>
        chat.id === currentStudentAlmaChat.id ? { ...chat, current: false } : chat
      )
    );
  };

  useEffect(() => {
    if (!selectedStudent || !studentAnswers || !questions || !schools) return;
    //load into chatHistory (whish is presented) the chatHistory from the currentStudentAlmaChat or generate a new one
    setChatHistory(
      currentStudentAlmaChat
        ? currentStudentAlmaChat.chatHistory
        : generateStartingPrompt({ selectedStudent, studentAnswers, schools, questions })
    );
  }, [currentStudentAlmaChat, questions, schools, selectedStudent, studentAnswers]);

  //helper that turns a message into a new chat entrys
  const buildChatEntry = (newMessage: string) => {
    const entry: ChatEntry = {
      role: "user",
      parts: [{ text: newMessage }],
      timestamp: new Date().toISOString(),
    };
    return entry;
  };

  type getAlmaResponseProps = {
    chatHistoryForAI: ChatEntry[];
    newEntry: ChatEntry;
  };

  //helper that gets alma's reply and returns it as a chat entry type
  const getAlmaResponse = useCallback(
    async ({ chatHistoryForAI, newEntry }: getAlmaResponseProps) => {
      const response = await getFromAPI<ChatResponse>({
        url: "/runAlma",
        body: { chatHistory: chatHistoryForAI, message: newEntry.parts[0].text },
      });
      const responseToSave: ChatEntry = {
        role: "model",
        timestamp: new Date().toISOString(),
        parts: [
          {
            text: response
              ? response.text
              : "I'm sorry, I'm not able to respond to that right now. Please try again later.",
          },
        ],
      };
      return responseToSave;
    },
    [getFromAPI]
  );

  type NewChatRecordProps = {
    chatHistory: ChatEntry[];
    newEntry: ChatEntry;
    responseToSave: ChatEntry;
    selectedStudent: StudentRecord;
  };

  //helper adds a new chat record if one doesn't exist (first time chatting or after a reset)
  const addNewChatRecord = useCallback(
    async ({ chatHistory, newEntry, responseToSave, selectedStudent }: NewChatRecordProps) => {
      if (chatHistory.length < 2) return;
      const startingChats = [chatHistory[0], chatHistory[1]];
      const now = new Date().toDateString();
      const data: AlmaChat = {
        chatHistory: [...startingChats, newEntry, responseToSave],
        studentId: selectedStudent.id,
        current: true,
        schoolId: selectedStudent?.schoolId ?? "",
        districtId: selectedStudent?.districtId ?? "",
        name: now,
      };
      const meta: BaseRecord | null = await addDoc({
        col: Collection.AlmaChats,
        data: data,
      });
      if (!meta) return;
      return { ...data, ...meta };
    },
    [addDoc]
  );

  type UpdateChatProps = {
    currentStudentAlmaChat: AlmaChatRecord;
    newEntry: ChatEntry;
    responseToSave: ChatEntry;
  };

  //helper updates existing chat record if one already exists (they already started a chat)
  const updateNewChatRecord = useCallback(
    async ({ currentStudentAlmaChat, newEntry, responseToSave }: UpdateChatProps) => {
      const data = {
        chatHistory: [...currentStudentAlmaChat.chatHistory, newEntry, responseToSave],
      };

      await updateDoc({
        col: Collection.AlmaChats,
        id: currentStudentAlmaChat.id,
        data: data,
      });
      return data;
    },
    [updateDoc]
  );

  const handleSubmit = useCallback(
    async (newMessage: string) => {
      if (!selectedStudent) return;
      const chatHistoryForAI = [...chatHistory];
      const newEntry = buildChatEntry(newMessage);
      setChatHistory((prev) => [...prev, newEntry]);
      setIsLoading(true);
      try {
        const responseToSave = await getAlmaResponse({ chatHistoryForAI, newEntry });
        setChatHistory((prev) => [...prev, responseToSave]);
        if (!currentStudentAlmaChat || !selectedStudent) {
          const record = await addNewChatRecord({
            chatHistory,
            newEntry,
            responseToSave,
            selectedStudent,
          });
          if (!record) return;
          setStudentAlmaChats([record]);
        } else {
          const data = await updateNewChatRecord({
            currentStudentAlmaChat,
            newEntry,
            responseToSave,
          });
          setStudentAlmaChats((prev) =>
            prev.map((chat) =>
              chat.id === currentStudentAlmaChat.id
                ? { ...chat, chatHistory: data.chatHistory }
                : chat
            )
          );
        }
        setError(null);
        setMessage("");
      } catch (err) {
        setError("Error communicating with the API");
      } finally {
        setIsLoading(false);
        setMessage("");
      }
    },
    [
      addNewChatRecord,
      chatHistory,
      currentStudentAlmaChat,
      getAlmaResponse,
      selectedStudent,
      setStudentAlmaChats,
      updateNewChatRecord,
    ]
  );
  return { chatHistory, isLoading, error, message, setMessage, handleSubmit, resetChat };
};

export default useChatContainer;
