import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { loggedInStaffAtom } from "../../recoil/userAtoms";
import { Program, Provider, Provider_Program } from "../../types/types";

type Props = {
  providerId: string;
};

const useProviderDetailsContainer = ({ providerId }: Props) => {
  const navigate = useNavigate();
  const [currentProvider, setCurrentProvider] = useState<Provider | null>(null);
  const [programsByProvider, setProgramsByProvider] = useState<Provider_Program[]>([]);
  const [editProviderOpen, setEditProviderOpen] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [getProviderTrigger, setGetProviderTrigger] = useState(false);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  useEffect(() => {
    if (!loggedInStaff) return;
    if (loggedInStaff.userType > 4) {
      setCanEdit(true);
    }
  }, [loggedInStaff]);

  useEffect(() => {
    const getProvider = async () => {
      const results = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getProvider`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          providerId: providerId,
        }),
      });
      const { provider } = (await results.json()) as {
        provider: Provider;
      };
      setCurrentProvider(provider ? provider : null);
    };

    const getProgramsByProviderId = async () => {
      const results = await fetch(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getProgramsByProviderId`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            providerId: providerId,
          }),
        }
      );
      const { rows } = (await results.json()) as { rows: Provider_Program[] };
      setProgramsByProvider(rows ? rows : []);
    };

    getProvider();
    getProgramsByProviderId();
  }, [providerId, getProviderTrigger]);

  const navigateBack = () => {
    navigate(-1);
  };

  return {
    currentProvider,
    programsByProvider,
    navigateBack,
    editProviderOpen,
    setEditProviderOpen,
    canEdit,
    setGetProviderTrigger,
  };
};

export default useProviderDetailsContainer;
