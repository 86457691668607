import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { StaffDashboardStudentRow } from "../../../shared/types/types";
import { useRecoilValue } from "recoil";
import { questionsAtom } from "../../../shared/recoil/questionAtoms";
import StudentTableStage from "../../components/dashboard/StudentTableStage";
import { AvatarCell } from "../../components/dashboard/AvatarCell";
import { GradeLevel } from "../../../shared/types/enums";
//only move to a library if I'm going to reuse it

const useStudentTableColumns = () => {
  const questions = useRecoilValue(questionsAtom);
  const pathways = questions?.find((question) => question.id === "pathways");

  const formatGPA = (gpa: [number, number] | null) => {
    if (gpa) {
      return `${gpa[0]} out of ${gpa[1]}`;
    } else {
      return "-";
    }
  };

  const formatPersonalityType = (type: string) => {
    switch (type) {
      case "Realistic":
        return "Practical Problem-Solver";
      case "Investigative":
        return "Analytical Explorer";
      case "Artistic":
        return "Creative Visionary";
      case "Social":
        return "Empathetic Connector";
      case "Enterprising":
        return "Dynamic Leader";
      case "Conventional":
        return "Organizational Wizard";
      default:
        return "Quiz Not Completed";
    }
  };

  const staffColumns = [
    {
      field: "avatar",
      headerName: "",
      headerClassName: "tableHeader",
      sortable: false,
      width: 80,
      cellClassName: "profilePictureCell",
      renderCell: (params: GridRenderCellParams) => <AvatarCell params={params} />,
    },
    {
      field: "firstName",
      headerName: "First Name",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 100,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 100,
    },
    {
      field: "gpa",
      headerName: "GPA",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 120,
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        return formatGPA(params.row.gpa);
      },
      valueGetter: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        if (params.row.gpa) {
          return params.row.gpa[0] / params.row.gpa[1];
        } else {
          return 0;
        }
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 120,
      valueGetter: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        if (params.row.grade === GradeLevel.NINTH) {
          return 9;
        } else if (params.row.grade === GradeLevel.TENTH) {
          return 10;
        } else if (params.row.grade === GradeLevel.ELEVENTH) {
          return 11;
        } else if (params.row.grade === GradeLevel.TWELFTH) {
          return 12;
        }
        return 0;
      },
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        return params.row.grade;
      },
    },
    {
      field: "stageId",
      headerName: "Stage",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      sortable: false,
      minWidth: 160,
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        return <StudentTableStage stageId={params.row.stageId} />;
      },
    },
    {
      field: "personalityType",
      headerName: "Personality Type",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 180,
      valueGetter: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        return formatPersonalityType(params.row.personalityType);
      },
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        return formatPersonalityType(params.row.personalityType);
      },
    },
    {
      field: "mostInterestedPathways",
      headerName: "Most Interested Pathways",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        return (
          <Box sx={{ display: "flex" }}>
            {params.row.mostInterestedPathways.map((pathwayId: string) => {
              return (
                <Box key={pathwayId}>
                  {pathways?.options.find((option) => option.id === pathwayId) && (
                    <Tooltip
                      title={pathways?.options.find((option) => option.id === pathwayId)?.text}
                    >
                      <Avatar
                        key={pathwayId}
                        variant="square"
                        id={pathwayId.toString()}
                        sx={{ width: 25, height: 25, mr: 1 }}
                        imgProps={{ style: { objectFit: "contain" } }}
                        data-name={`../${
                          pathways?.options.find((option) => option.id === pathwayId)?.iconURL
                        }`}
                        src={`../${
                          pathways?.options.find((option) => option.id === pathwayId)?.iconURL
                        }`}
                      />
                    </Tooltip>
                  )}
                </Box>
              );
            })}
          </Box>
        );
      },
    },
    {
      field: "heartedPrograms",
      headerName: "# Hearted",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "appliedPrograms",
      headerName: "# Applied",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "acceptedPrograms",
      headerName: "# Accepted",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "lastActivity",
      headerName: "Last Activity",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 100,
      flex: 1,
      valueGetter: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        return params.row.lastActivity;
      },
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        const lastActivityDate = new Date(params.row.lastActivity);
        const days = Math.floor((Date.now() - lastActivityDate.getTime()) / 1000 / 60 / 60 / 24);
        return (
          <Typography sx={{ fontSize: 10, color: days > 7 ? "red" : "black" }} variant="body2">
            {`${days} days ago`}
          </Typography>
        );
      },
    },
    // {
    //   field: "messages",
    //   headerName: "",
    //   headerClassName: "tableHeader",
    //   sortalbe: false,
    //   cellClassName: "hoverPointer",
    //   width: 20,
    //   flex: 0.5,
    //   renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
    //     const randomNumber = Math.floor(Math.random() * 10);
    //     return randomNumber > 5 ? (
    //       <ChatBubbleOutlineOutlinedIcon />
    //     ) : (
    //       <>
    //         <Box>
    //           <Box sx={{ display: "block" }}>
    //             <ChatBubbleIcon />
    //           </Box>
    //           <Box sx={{ display: "block" }}>
    //             <Typography
    //               sx={{ fontSize: 10, color: Color.Mint }}
    //               variant="body2"
    //             >
    //               1 New Message
    //             </Typography>
    //           </Box>
    //         </Box>
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   headerClassName: "tableHeader",
    //   cellClassName: "hoverPointer",
    //   minWidth: 100,
    //   flex: 1,
    // }
  ];
  return { staffColumns };
};

export default useStudentTableColumns;
