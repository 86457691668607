import { useRecoilState, useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../recoil/userAtoms";
import { studentAnswersAtom } from "../recoil/answersAtoms";
import { FinancialAid } from "../types/enums";

type Props = {
  admit: boolean;
  scholarship: boolean;
};

const useAutoAdmitMessage = ({ admit, scholarship }: Props) => {
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const answers = useRecoilValue(studentAnswersAtom);
  const financialAidAnswer =
    answers.find((answer) => answer.questionId === "financial_aid")?.ms ?? [];
  const lowIncome =
    financialAidAnswer.includes(FinancialAid.PellGrant) ||
    financialAidAnswer.includes(FinancialAid.FRL);
  const autoAdmitText = `Based on your profile, you are pre-admitted to the program ${
    lowIncome && scholarship ? "with a scholarship" : ""
  } pending you complete the following:`;

  return {
    autoAdmitText,
    selectedStudent,
    lowIncome,
  };
};

export default useAutoAdmitMessage;
