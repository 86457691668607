import React, { useEffect } from "react";

import { useAuth } from "../../../shared/contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import SignupAuth from "./SignupAuth";

const SignupContainer = () => {
  const { currentAuthUser, loading: authLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authLoading && currentAuthUser) {
      navigate("/");
    }
  }, [authLoading, currentAuthUser, navigate]);

  return (
    <>
      <SignupAuth />
    </>
  );
};

export default SignupContainer;
