import { QuestionType, Stage, UserType } from "../types/enums";
import {
  AnswerTypeForm,
  GroupRecord,
  MentorUser,
  Message,
  Program,
  Provider,
  ProviderUser,
  QuestionRecord,
  SchoolRecord,
  StaffRecord,
  StudentRecord,
} from "../types/types";

export const BLANK_STUDENT_FORM: StudentRecord = {
  firstName: "",
  email: "",
  lastName: "",
  avatar: "",
  schoolId: null,
  districtId: null,
  gradeLevel: "",
  userType: UserType.Student,
  birthday: null,
  graduationYear: "",
  phone: "",
  stageId: Stage.Stage1,
  optInTextMessages: false,
  agreeTermsOfService: true,
  lastQuestionId: "",
  favoriteProviderIds: [],
  quizComplete: false,
  setupComplete: false,
  lastActivity: "",
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
  historicId: null,
  enrolled: true,
  schoolEmail: "",
  personalEmail: "",
  matchedProgramIds: [],
  hollandType: null,
  topCareers: [],
  consentToContact: "Not Asked",
  staffRecommendedProgramIds: [],
  favoriteRecommendedProgramIds: [],
  klaviyoProfileId: "",
  uidId: true,
  oldId: "",
  mentorIds: [],
};

export const BLANK_PROVIDERUSER_FORM: ProviderUser = {
  firstName: "",
  lastName: "",
  userType: UserType.ProviderUser,
  email: "",
  setupComplete: false,
  klaviyoProfileId: "",
  providerId: "",
  phone: "",
};

export const BLANK_QUESTION_FORM: QuestionRecord = {
  order: 0,
  question: "",
  type: QuestionType.MultipleChoice,
  options: [],
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
  matrixHeaders: [],
  helperText: "",
  required: true,
};

export const BLANK_ANSWER_TYPE_FORM: AnswerTypeForm = {
  mc: 0,
  ms: [],
  or: "",
  matrix: [],
  gpa: [0, 0],
  number: 0,
};

export const BLANK_SCHOOL_FORM: SchoolRecord = {
  name: "",
  avatar: "",
  primaryContact: "",
  primaryEmail: "",
  primaryPhone: "",
  historicId: "",
  districtId: "",
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
};

export const BLANK_STAFF_FORM: StaffRecord = {
  firstName: "",
  lastName: "",
  email: "",
  avatar: "",
  schoolId: null,
  enrolled: true,
  districtId: null,
  userType: UserType.SchoolAdmin,
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
  jobTitle: "",
  initialPasswordReset: false,
  smsNumber: "",
  klaviyoProfileId: "",
  uidId: true,
  oldId: "",
};

export const BLANK_GROUP_FORM: GroupRecord = {
  name: "",
  schoolId: "",
  studentIds: [],
  staffIds: [],
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
  oldStudentIds: [],
  oldStaffIds: [],
};

export const BLANK_MESSAGE_FORM: Message = {
  message: "",
  timestamp: "",
  staffId: "",
  studentId: "",
  studentPhoneNumber: "",
  authorId: "",
  staffPhoneNumber: "",
};

export const BLANK_PROVIDER_FORM: Provider = {
  id: "",
  providerName: "",
  providerDescription: "",
  city: "",
  state: "",
  region: "",
  zip: "",
  homeURL: "",
  ownership: "",
  locale: "",
  lat: null,
  lon: null,
  hbcu: false,
  menOnly: false,
  womenOnly: false,
  religiousAffiliation: null,
  admissionPercent: null,
  satReadingMid: null,
  satMathMid: null,
  actMid: null,
  undergradTotalEnrollment: null,
  percentWhite: null,
  percentBlack: null,
  percentHispanic: null,
  percentAsian: null,
  percentAIAN: null,
  percentNHPI: null,
  percentTwoOrMore: null,
  percentNonCitizens: null,
  currentlyOperating: true,
  percentPell: null,
  address: "",
  providerIsActive: true,
};

export const BLANK_MENTOR_USER_FORM: MentorUser = {
  firstName: "",
  lastName: "",
  avatar: "",
  userType: UserType.MentorUser,
  email: "",
  klaviyoProfileId: "",
  studentIds: [],
};

export const BLANK_PROGRAM_FORM: Program = {
  id: "",
  providerId: "",
  durationYears: null,
  durationMonths: null,
  cipCode: "",
  willowIndustryId: "",
  willowSubIndustryId: "",
  credentialLevel: "",
  programName: "",
  programShortDescription: "",
  programDescription: "",
  averageTotalCost: null,
  commitment: "",
  modality: "",
  onlineOnly: false,
  completionRateIn6Years: null,
  startingSalary: null,
  willowROI: null,
  applyURL: "",
  rateOfEmployment: null,
  departmentLink: "",
  programIsActive: false,
  inAlgorithm: false,
  autoAdmit: false,
  autoScholarship: false,
  willowWeight: 0,
};
