import { useResetRecoilState, useSetRecoilState } from "recoil";
import {
  allEnrolledSchoolStudentsAtom,
  loggedInMentorAtom,
  loggedInProviderAtom,
  loggedInStaffAtom,
  selectedStudentAtom,
  studentsLoadedAtom,
} from "../recoil/userAtoms";
import {
  selectedDistrictAtom,
  selectedGroupAtom,
  selectedSchoolAtom,
} from "../recoil/districtAtoms";
import { auth } from "../../firebase";
import { ownProviderAtom, programsAtom } from "../recoil/providerAtoms";

const useLogout = () => {
  const setLoggedInStaff = useSetRecoilState(loggedInStaffAtom);
  const setLoggedInProvider = useSetRecoilState(loggedInProviderAtom);
  const setLoggedInMentor = useSetRecoilState(loggedInMentorAtom);
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);
  const setSelectedGroup = useSetRecoilState(selectedGroupAtom);
  const setSelectedSchool = useSetRecoilState(selectedSchoolAtom);
  const setSelectedDistrict = useSetRecoilState(selectedDistrictAtom);
  const resetLoggedInStaff = useResetRecoilState(loggedInStaffAtom);
  const resetStudentsLoaded = useResetRecoilState(studentsLoadedAtom);
  const resetAllEnrolledSchoolStudents = useResetRecoilState(allEnrolledSchoolStudentsAtom);
  const resetOwnProvider = useResetRecoilState(ownProviderAtom);
  const resetPrograms = useResetRecoilState(programsAtom);

  const logout = () => {
    setLoggedInStaff(null);
    setLoggedInProvider(null);
    setLoggedInMentor(null);
    setSelectedStudent(null);
    setSelectedGroup(null);
    setSelectedSchool(null);
    setSelectedDistrict(null);
    resetAllEnrolledSchoolStudents();
    resetLoggedInStaff();
    resetStudentsLoaded();
    resetOwnProvider();
    resetPrograms();
    return auth.signOut();
  };

  return { logout };
};

export default useLogout;
