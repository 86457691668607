import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useRecoilValue } from "recoil";
import { stagesAtom } from "../../../shared/recoil/stageAtoms";
import { formatDotColor, formatLineColor } from "../../../shared/utils/functions";

type Props = {
  stageId: string;
};

const StageProgressBar = ({ stageId }: Props) => {
  const stages = useRecoilValue(stagesAtom);
  const currentStage = stages.find((stage) => stage.id === stageId);
  const currentStageIndex = stages.findIndex((stage) => stage.id === stageId);

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {stages.map((stage, index) => (
            <Box key={stage.id} sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip key={stage.id} title={stage.name} placement="top">
                <CircleIcon
                  sx={{
                    fontSize: 18,
                    color: formatDotColor({ index, currentStageIndex }),
                  }}
                />
              </Tooltip>
              {index !== stages.length - 1 && (
                <div
                  style={{
                    height: 0,
                    borderTop: "1px solid",
                    borderColor: formatLineColor({
                      index: index,
                      currentStageIndex,
                    }),
                    width: 44,
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        {currentStage && (
          <>
            <Typography textAlign="center" variant="h3" sx={{ mt: 1, fontSize: 18 }}>
              {currentStage.name}
            </Typography>
            <Typography sx={{ mt: 2, pl: 2, pr: 2, pb: 2, fontSize: 12 }}>
              {currentStage.description}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

export default StageProgressBar;
