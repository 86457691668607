import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  allEnrolledDistrictStudentsAtom,
  allEnrolledGroupStudentsAtom,
} from "../../../shared/recoil/userAtoms";
import { useNavigate } from "react-router-dom";
import { Provider_Program, StudentRecord } from "../../../shared/types/types";
import { convertToCSV } from "../../../shared/utils/functions";

type StudentsForExport = {
  firstName: string;
  lastName: string;
  schoolEmail: string;
  providerName: string;
  programName: string;
  favorited: "Yes" | "No";
};

type Props = {
  programId: string;
  favoriteOnlyToggle: boolean;
  fromAllSchoolsToggle: boolean;
  matchedPrograms: Provider_Program[];
};

const useProgramList = ({
  programId,
  favoriteOnlyToggle,
  fromAllSchoolsToggle,
  matchedPrograms,
}: Props) => {
  const [studentsList, setStudentList] = useState<StudentRecord[]>([]);
  const [studentsForExport, setStudentsForExport] = useState("");
  const navigate = useNavigate();
  const allEnrolledGroupStudents = useRecoilValue(allEnrolledGroupStudentsAtom);
  const currentProvider = matchedPrograms.find((p) => p.id === programId);
  const allEnrolledDistrictStudents = useRecoilValue(allEnrolledDistrictStudentsAtom);

  useEffect(() => {
    const filteredStudents = (
      fromAllSchoolsToggle ? allEnrolledDistrictStudents : allEnrolledGroupStudents
    ).filter((s) => s.matchedProgramIds.includes(programId));
    const filteredFavoritedStudents = filteredStudents.filter((s) =>
      s.favoriteProviderIds.includes(programId)
    );
    setStudentList(favoriteOnlyToggle ? filteredFavoritedStudents : filteredStudents);
    const tempArrayForExport: StudentsForExport[] = [];
    if (favoriteOnlyToggle) {
      filteredFavoritedStudents.forEach((student) => {
        tempArrayForExport.push({
          firstName: student.firstName,
          lastName: student.lastName,
          schoolEmail: student.schoolEmail,
          providerName: currentProvider?.providerName ?? "",
          programName: currentProvider?.programName ?? "",
          favorited: student.favoriteProviderIds.includes(programId) === true ? "Yes" : "No",
        });
      });
    } else {
      filteredStudents.forEach((student) => {
        tempArrayForExport.push({
          firstName: student.firstName,
          lastName: student.lastName,
          schoolEmail: student.schoolEmail,
          providerName: currentProvider?.providerName ?? "",
          programName: currentProvider?.programName ?? "",
          favorited: student.favoriteProviderIds.includes(programId) === true ? "Yes" : "No",
        });
      });
    }
    const arrayForExport = convertToCSV(tempArrayForExport);
    setStudentsForExport(arrayForExport);
  }, [
    fromAllSchoolsToggle,
    allEnrolledGroupStudents,
    allEnrolledDistrictStudents,
    currentProvider,
    favoriteOnlyToggle,
    programId,
  ]);
  return { currentProvider, studentsList, studentsForExport };
};

export default useProgramList;
