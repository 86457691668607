import React, { Dispatch, SetStateAction } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Provider_Program, StudentRecord } from "../../types/types";
import useRecommendDialog from "../../hooks/exploreProggrams/useRecommendDialog";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  program: Provider_Program;
};

type StudentWithLabel = StudentRecord & { label: string };

const RecommendDialog = ({ open, setOpen, program }: Props) => {
  const { studentLabels, selectedStudents, setSelectedStudents, handleSave } = useRecommendDialog({
    setOpen,
    program,
  });

  const getLabel = (props: any, option: StudentWithLabel) => {
    return (
      <li {...props} key={option.id}>
        {option.label}
      </li>
    );
  };

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <DialogTitle variant="h4">Recommend This Program</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={studentLabels}
            multiple
            value={selectedStudents}
            onChange={(event: any, newValue: StudentWithLabel[] | null) => {
              newValue ? setSelectedStudents(newValue) : setSelectedStudents([]);
            }}
            sx={{ mt: 2 }}
            renderOption={getLabel}
            renderInput={(params) => <TextField {...params} label="Student" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={handleSave}
            disabled={selectedStudents.length === 0}
            color="secondary"
          >{`Make Recommendation${selectedStudents.length > 1 ? "s" : ""}`}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecommendDialog;
