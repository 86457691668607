import { BottomNavigationAction, Paper, BottomNavigation } from "@mui/material";
import React from "react";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Color, PageRoute } from "../../types/enums";
import { useLocation, useNavigate } from "react-router-dom";
import FeatherIcon from "../FeatherIcon";

const BottomNavigationMenu = () => {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        sx={{ backgroundColor: Color.Chalkboard }}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          value={PageRoute.Student_Stages}
          onClick={() => navigate(PageRoute.Student_Stages)}
          icon={
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="home"
              strokeColor={location.pathname === PageRoute.Student_Stages ? "#1DBC9D" : "#F4F0DC"}
            />
          }
        />
        <BottomNavigationAction
          value={PageRoute.Student_Profile}
          onClick={() => navigate(PageRoute.Student_Profile)}
          icon={
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="person"
              strokeColor={location.pathname === PageRoute.Student_Profile ? "#1DBC9D" : "#F4F0DC"}
            />
          }
        />
        <BottomNavigationAction
          value={PageRoute.Student_Profile}
          onClick={() => navigate(PageRoute.Explore_Programs)}
          icon={
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="search"
              strokeColor={location.pathname === PageRoute.Explore_Programs ? "#1DBC9D" : "#F4F0DC"}
            />
          }
        />
        <BottomNavigationAction
          value={PageRoute.Student_Profile}
          onClick={() => navigate(PageRoute.Alma)}
          icon={
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="ai"
              strokeColor={location.pathname === PageRoute.Alma ? Color.Mint : Color.Chalk}
            />
          }
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNavigationMenu;
