import React, { ChangeEvent, useState } from "react";
import { Collection, RemoveReason } from "../../types/enums";
import useAddDoc from "../useAddDoc";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  allEnrolledSchoolStudentsAtom,
  removedMatchesAtom,
  selectedStudentAtom,
} from "../../recoil/userAtoms";
import { studentAnswersAtom } from "../../recoil/answersAtoms";
import useUpdateDoc from "../useUpdateDoc";
import useUpdateMatches from "../useUpdateMatches";

const useRemoveMatchDialog = (programId: string) => {
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const setAllEnrolledSchoolStudents = useSetRecoilState(allEnrolledSchoolStudentsAtom);
  const setRemovedMatches = useSetRecoilState(removedMatchesAtom);
  const { updateMatchPrograms, loading } = useUpdateMatches();
  const answers = useRecoilValue(studentAnswersAtom);
  const { sendRequest: addDoc } = useAddDoc();
  const { sendRequest: updateDoc } = useUpdateDoc();
  const [reasons, setReasons] = useState({
    [RemoveReason.Too_Long]: false,
    [RemoveReason.Too_Expensive]: false,
    [RemoveReason.To_Far]: false,
    [RemoveReason.Not_Interested]: false,
    [RemoveReason.Admissions]: false,
    [RemoveReason.Better_Reputation]: false,
    [RemoveReason.Other]: false,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReasons({ ...reasons, [event.target.name]: event.target.checked });
  };

  const saveReason = async () => {
    if (!selectedStudent) return;
    const reasonsArray = Object.keys(reasons).filter((reason) => reasons[reason as RemoveReason]);
    const result = await addDoc({
      col: Collection.RemovedMatches,
      data: {
        studentId: selectedStudent.id,
        providerId: programId,
        reasons: reasonsArray,
      },
    });
    if (!result) return;
    setRemovedMatches((pV) => [
      ...pV,
      {
        studentId: selectedStudent.id,
        providerId: programId,
        reasons: reasonsArray,
        ...result,
      },
    ]);
    setReasons({
      [RemoveReason.Too_Long]: false,
      [RemoveReason.Too_Expensive]: false,
      [RemoveReason.To_Far]: false,
      [RemoveReason.Not_Interested]: false,
      [RemoveReason.Admissions]: false,
      [RemoveReason.Better_Reputation]: false,
      [RemoveReason.Other]: false,
    });
    const programMatchIds = await updateMatchPrograms({
      studentAnswers: answers,
      selectedStudent: selectedStudent,
      newRemovedId: programId,
    });

    await updateDoc({
      col: Collection.Students,
      id: selectedStudent.id,
      data: {
        matchedProgramIds: programMatchIds,
      },
    });
    setSelectedStudent((pV) => pV && { ...pV, matchedProgramIds: programMatchIds });
    setAllEnrolledSchoolStudents((pV) =>
      pV.map((student) =>
        student.id === selectedStudent.id
          ? { ...student, matchedProgramIds: programMatchIds }
          : student
      )
    );
  };
  return { loading, reasons, setReasons, handleChange, saveReason };
};

export default useRemoveMatchDialog;
