import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom, selectedStudentAtom, userTypeAtom } from "../../../shared/recoil/userAtoms";
import { Box, IconButton, ListItemIcon, ListItemText } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { Color, PageRoute } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/assets/icons/FeatherIcon";
type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const drawerWidth = 221;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  backgroundColor: Color.Chalkboard,
  color: Color.Chalk,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#00362E",
  color: "#F4F0DC",
  width: `calc(${theme.spacing(7)} + 0px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 0px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar({ open, setOpen }: Props) {
  const theme = useTheme();
  const location = useLocation();
  const storage = getStorage();
  const [avatarURL, setAvatarURL] = React.useState<string | null>(null);
  const navigate = useNavigate();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const userType = useRecoilValue(userTypeAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const getImageURL = async () => {
      const fileName = loggedInStaff ? loggedInStaff.avatar : selectedStudent?.avatar ?? "";
      if (fileName.length > 5) {
        let url = "";
        try {
          try {
            url = await getDownloadURL(ref(storage, `${fileName}_200x200`));
          } catch (error) {
            url = await getDownloadURL(ref(storage, `${fileName}`));
          }
        } catch (error) {
          console.error(error);
        }
        setAvatarURL(url);
      }
    };
    getImageURL();
  }, [loggedInStaff, selectedStudent?.avatar, storage]);

  return (
    <>
      <Drawer variant="permanent" open={open} color="primary" sx={{ zIndex: 999 }}>
        <DrawerHeader>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/")}
            >
              <Box sx={{ marginRight: open ? 3 : "auto" }}>
                <img width="24px" src="../../assets/branding/inline-chalk.svg" alt="Chalk Logo" />
              </Box>

              <ListItemText primary="Willow" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            height: "100VH",
          }}
        >
          <ListItem sx={{ display: "block" }}>
            {open ? (
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon
                  sx={{
                    justifyContent: "center",
                    color: Color.Chalk,
                  }}
                />
              </IconButton>
            ) : (
              <ListItemButton
                onClick={handleDrawerOpen}
                sx={{
                  justifyContent: "center",
                }}
              >
                <ChevronRightIcon />
              </ListItemButton>
            )}
          </ListItem>
          <Box id="user-specific-content">
            {/* Dashboard */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                disableRipple
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => navigate(PageRoute.Staff_Dashboard)}
              >
                <Box sx={{ marginRight: open ? 3 : "auto" }}>
                  <FeatherIcon
                    width="24px"
                    fillColor="none"
                    icon="clipboard"
                    strokeColor={
                      location.pathname === PageRoute.Staff_Dashboard ? "#1DBC9D" : "#F4F0DC"
                    }
                  />
                </Box>

                <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            {/* Messages */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                disableRipple
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => navigate(PageRoute.Messages)}
              >
                <Box sx={{ marginRight: open ? 3 : "auto" }}>
                  <FeatherIcon
                    width="24px"
                    fillColor="none"
                    icon="message-circle"
                    strokeColor={location.pathname === PageRoute.Messages ? "#1DBC9D" : "#F4F0DC"}
                  />
                </Box>
                <ListItemText primary="Messages" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            {/* School Settings */}
            {userType && userType > 1 && (
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  disableRipple
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => navigate(PageRoute.School_Settings)}
                >
                  <Box sx={{ marginRight: open ? 3 : "auto" }}>
                    <FeatherIcon
                      width="24px"
                      fillColor="none"
                      icon="settings"
                      strokeColor={
                        location.pathname === PageRoute.School_Settings ? "#1DBC9D" : "#F4F0DC"
                      }
                    />
                  </Box>
                  <ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            )}
            {/* Students By Program */}
            {userType && userType > 1 && (
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  disableRipple
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => navigate(PageRoute.Students_By_Program)}
                >
                  <Box sx={{ marginRight: open ? 3 : "auto" }}>
                    <FeatherIcon
                      width="24px"
                      fillColor="none"
                      icon="list"
                      strokeColor={
                        location.pathname === PageRoute.Students_By_Program ? "#1DBC9D" : "#F4F0DC"
                      }
                    />
                  </Box>
                  <ListItemText primary="Student By Program" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            )}
            {/* Search */}
            {userType && userType > 1 && (
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  disableRipple
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => navigate(PageRoute.Explore_Programs)}
                >
                  <Box sx={{ marginRight: open ? 3 : "auto" }}>
                    <FeatherIcon
                      width="24px"
                      fillColor="none"
                      icon="search"
                      strokeColor={
                        location.pathname === PageRoute.Explore_Programs ? "#1DBC9D" : "#F4F0DC"
                      }
                    />
                  </Box>
                  <ListItemText primary="Explore Programs" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            )}
            {/* DB Management */}
            {userType && userType >= 5 && (
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  disableRipple
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => navigate(PageRoute.Program_DB_Management)}
                >
                  <Box sx={{ marginRight: open ? 3 : "auto" }}>
                    <FeatherIcon
                      width="24px"
                      fillColor="none"
                      icon="db"
                      strokeColor={
                        location.pathname === PageRoute.Program_DB_Management
                          ? "#1DBC9D"
                          : "#F4F0DC"
                      }
                    />
                  </Box>
                  <ListItemText primary="Program Management" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => navigate(PageRoute.Staff_Alma)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: location.pathname === PageRoute.Staff_Alma ? Color.Mint : Color.Chalk,
                  }}
                >
                  <FeatherIcon
                    width="24px"
                    fillColor="none"
                    icon="ai"
                    strokeColor={
                      location.pathname === PageRoute.Staff_Alma ? Color.Mint : Color.Chalk
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Alma AI Advisory" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Box>
        </List>
      </Drawer>
    </>
  );
}
