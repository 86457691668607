import { Grid } from "@mui/material";
import React from "react";
import StudentCard from "./StudentCard";
import MyWhy from "./MyWhy";
import StudentStats from "./StudentStats";
import Preferences from "./Preferences";
import StudentHollandResults from "./StudentHollandResults";
import CareerPathways from "./CareerPathways";
import MyMentors from "./MyMentors";

const StudentProfileContainer = () => {
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1, px: 2 }}>
        <Grid item xs={12} md={5}>
          <StudentCard />
          <MyWhy />
          <CareerPathways />
          <StudentStats />
          <Preferences />
          <MyMentors />
        </Grid>
        <Grid item xs={12} md={7}>
          <StudentHollandResults />
          {/* <StudentFeelings /> */}
        </Grid>
      </Grid>
    </>
  );
};

export default StudentProfileContainer;
