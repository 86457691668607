import React, { ChangeEvent, useState } from "react";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Color } from "../../../shared/types/enums";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../shared/hooks/useFetch";
import ResetPasswordDialog from "../../../shared/components/login/ResetPasswordDialog";
import CreateAuthAccount from "./CreateAuthAccount";

const SignupAuth = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { getFromAPI } = useFetch();
  const [loading, setLoading] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [providerId, setProviderId] = useState<string | null>(null);
  const [emailValid, setEmailValid] = useState(false);

  function validateEmailBasic(email: string): boolean {
    const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return regex.test(email);
  }

  const backToLogin = () => {
    navigate("/login");
  };

  const checkIfUserExists = async () => {
    setLoading(true);
    const results = await getFromAPI({ url: "/auth/getUID", body: { email } });
    setEmailChecked(true);
    setLoading(false);
    if (results === null) {
      setUserExists(false);
    } else {
      const { userRecord } = results;
      if (userRecord && userRecord.providerData && userRecord.providerData.length > 0) {
        const providerId = userRecord.providerData[0].providerId;
        setProviderId(providerId);
      }
      setUserExists(true);
    }
  };

  const setCleanedEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const rawEmail = event.target.value;
    const cleanedEmail = rawEmail.trim().toLocaleLowerCase();
    setEmailValid(validateEmailBasic(cleanedEmail));
    setEmail(cleanedEmail);
  };

  return (
    <>
      <Box sx={{ mt: 8 }}>
        <Typography textAlign={"center"} sx={{ color: Color.Mint }} variant="h2">
          Hello and Welcome to Willow 👋
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{ color: Color.Mint, fontWeight: 300, mt: 2 }}
          variant="h4"
        >
          We're so excited to get you onboarded!
        </Typography>
        <Typography textAlign={"center"} sx={{ mt: 3 }} variant="h5">
          First, let's make sure you don't already have an account with us. What's your email?
        </Typography>
        <TextField
          fullWidth
          label="Email"
          sx={{ mt: 4 }}
          variant="outlined"
          type="email"
          value={email}
          onChange={setCleanedEmail}
        />
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {!emailChecked ? (
                <Button
                  disabled={!emailValid}
                  fullWidth
                  size="large"
                  variant="outlined"
                  onClick={checkIfUserExists}
                >
                  Continue
                </Button>
              ) : (
                <>
                  {userExists ? (
                    <>
                      <Box>
                        <Typography textAlign={"center"} sx={{ mt: 0 }} variant="h5">
                          It looks like you already have an account with us. Click below to log in.
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1 }}
                            onClick={backToLogin}
                          >
                            Back to the Login Page
                          </Button>
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box>
                        <Typography textAlign={"center"} sx={{ mt: 0 }} variant="h5">
                          Wonderful, now let's select a password that you'll remember.
                        </Typography>
                        <CreateAuthAccount email={email} />
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <ResetPasswordDialog open={resetOpen} setOpen={setResetOpen} />
    </>
  );
};

export default SignupAuth;
