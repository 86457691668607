import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { StudentRecord } from "../../../shared/types/types";
import CustomAvatar from "../../../shared/components/CustomAvatar";
import useManageStudentDialog from "../../hooks/settings/useManageStudentDialog";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PhoneNumber from "../../../shared/components/PhoneNumber";
import { verifyPhoneNumber } from "../../../shared/utils/functions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  studentForm: StudentRecord;
  setStudentForm: Dispatch<React.SetStateAction<StudentRecord>>;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const ManageStudentDialog = ({ studentForm, setStudentForm, open, setOpen }: Props) => {
  const [years, setCurrentYears] = useState<string[]>([]);

  const {
    handleTextChange,
    handleSave,
    handleClose,
    handleUnenroll,
    alert,
    groups,
    selectedGroups,
    handleGroupSelect,
  } = useManageStudentDialog({
    studentForm,
    setStudentForm,
    setOpen,
    open,
  });
  const [valid, setValid] = useState(false);

  const validForm = useCallback(() => {
    if (studentForm.firstName.trim().length === 0) {
      return false;
    }
    if (studentForm.lastName.trim().length === 0) {
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(studentForm.schoolEmail.trim())) {
      return false;
    }
    if (!verifyPhoneNumber(studentForm.phone)) {
      return false;
    }
    if (studentForm.graduationYear.length === 0) {
      return false;
    }

    return true;
  }, [studentForm]);

  useEffect(() => {
    const isValid = validForm();
    setValid(isValid);
  }, [studentForm, validForm]);

  useEffect(() => {
    const startYear = new Date().getFullYear();
    const endYear = new Date().getFullYear() + 4;
    let years = [];
    for (var i = startYear; i <= endYear; i++) {
      years.push(i.toString());
    }

    setCurrentYears(years);
  }, [studentForm]);

  const handleSelect = (event: SelectChangeEvent) => {
    setStudentForm((pV) => ({ ...pV, graduationYear: event.target.value }));
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle variant="h4" sx={{ fontSize: 44, textAlign: "center" }}>
        Manage Students
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CustomAvatar
            size={100}
            fileName={studentForm?.avatar ?? ""}
            collection="students"
            documentId={studentForm.id}
            formField="avatarURL"
            setForm={setStudentForm}
          />
        </Box>
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              variant="outlined"
              value={studentForm.firstName}
              name="firstName"
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              variant="outlined"
              value={studentForm.lastName}
              name="lastName"
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={studentForm.schoolEmail}
              name="email"
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneNumber
              label="Phone"
              name="phone"
              value={studentForm.phone}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="grade-select-label">Graduation Year</InputLabel>
              <Select
                fullWidth
                value={studentForm?.graduationYear ?? ""}
                onChange={handleSelect}
                variant="outlined"
                label="Graduation Year"
              >
                <MenuItem value={""}>Select A Year</MenuItem>
                {years.length &&
                  years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="groups-to-staff"
              options={groups}
              disableCloseOnSelect
              onChange={handleGroupSelect}
              fullWidth
              value={selectedGroups}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Assigned Groups"
                  placeholder="Assigned Groups"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Alert severity="error" sx={{ mt: 2, display: alert ? "flex" : "none" }}>
          {alert}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} disabled={!valid}>
          Save
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="error" onClick={handleUnenroll}>
          Unenroll
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageStudentDialog;
