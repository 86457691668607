import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  ApplicationData,
  ApplicationDataRecord,
  Provider_Program,
  StudentRecord,
} from "../../../shared/types/types";
import { Box, Button, Grid, Typography } from "@mui/material";
import DisplayBox from "../../../shared/components/DisplayBox";
import { AppResult, AppStatus, Collection, Color, PageRoute } from "../../../shared/types/enums";
import { SetterOrUpdater } from "recoil";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";

import useAddDoc from "../../../shared/hooks/useAddDoc";
import ApplicationProgressBar from "./ApplicationProgressBar";
import ApplicationResults from "./ApplicationResults";

type Props = {
  provider: Provider_Program;
  applicationData: ApplicationDataRecord[];
  setApplicationData: SetterOrUpdater<ApplicationDataRecord[]>;
  selectedStudent: StudentRecord;
  setSelectedLearnMoreProgram: Dispatch<SetStateAction<Provider_Program | null>>;
};

const ApplicationCard = ({
  provider,
  applicationData,
  setApplicationData,
  selectedStudent,
  setSelectedLearnMoreProgram
}: Props) => {
  //has the student submitted
  const [status, setStatus] = useState<AppStatus>(AppStatus.NotStarted);
  //has the student heard back
  const [result, setResult] = useState<AppResult>(AppResult.NoResponse);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: addDoc } = useAddDoc();
  const programData = applicationData.find(
    (data) => data.programId === provider.id && data.studentId === selectedStudent.id
  );
  const [applyLink, setApplyLink] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedStudent || !selectedStudent.schoolId || !selectedStudent.districtId) return;
    const fetchApplicationData = async () => {
      if (programData) {
        setStatus(programData.status);
        setResult(programData.result);
      }
    };
    fetchApplicationData();
  }, [programData, selectedStudent]);

  useEffect(() => {
    if (!provider) return;
    const { applyURL, homeURL } = provider;
    if (applyURL && applyURL.length > 3) {
      if (applyURL.includes("http")) {
        setApplyLink(applyURL);
      } else {
        setApplyLink(`https://${applyURL}`);
      }
    } else if (homeURL && homeURL.length > 3) {
      if (homeURL.includes("http")) {
        setApplyLink(homeURL);
      } else {
        setApplyLink(`https://${homeURL}`);
      }
    }
  }, [provider]);

  const handleStatus = async (newStatus: AppStatus) => {
    if (!selectedStudent) return;
    setStatus(newStatus);
    if (programData) {
      await updateDoc({
        col: Collection.ApplicationData,
        id: programData.id,
        data: { status: newStatus },
      });
      setApplicationData((pV) =>
        pV.map((data) => {
          if (data.id === programData.id) {
            return { ...data, status: newStatus };
          }
          return data;
        })
      );
    } else {
      const newDoc: ApplicationData = {
        programId: provider.id,
        status: newStatus,
        result: result,
        studentId: selectedStudent.id,
        schoolId: selectedStudent?.schoolId ?? "",
        districtId: selectedStudent?.districtId ?? "",
      };

      const record = await addDoc({
        col: Collection.ApplicationData,
        data: newDoc,
      });
      if (!record) return;
      setApplicationData((pV) => [...pV, { ...newDoc, ...record }]);
    }
  };

  const handleResults = async (newResult: AppResult) => {
    if (!selectedStudent) return;
    setResult(newResult);
    if (programData) {
      await updateDoc({
        col: Collection.ApplicationData,
        id: programData.id,
        data: { result: newResult },
      });
      setApplicationData((pV) =>
        pV.map((data) => {
          if (data.id === programData.id) {
            return { ...data, result: newResult };
          }
          return data;
        })
      );
    } else {
      const newDoc: ApplicationData = {
        programId: provider.id,
        status: status,
        result: newResult,
        studentId: selectedStudent.id,
        schoolId: selectedStudent?.schoolId ?? "",
        districtId: selectedStudent?.districtId ?? "",
      };

      const record = await addDoc({
        col: Collection.ApplicationData,
        data: newDoc,
      });
      if (!record) return;
      setApplicationData((pV) => [...pV, { ...newDoc, ...record }]);
    }
  };

  const handleLearnMore = () => {
    setSelectedLearnMoreProgram(provider);
  };

  return (
    <Box key={provider.id} sx={{ mt: 1 }}>
      <DisplayBox title={`${provider.programName} at ${provider.providerName}`}>
        <Box sx={{ p: 2, position: "relative" }}>
          <Typography sx={{ fontSize: 12 }}>{provider.programShortDescription}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {applyLink && (
                <Button
                  fullWidth
                  variant="contained"
                  href={applyLink}
                  sx={{
                    mt: 2,
                    textTransform: "none",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    fontSize: 14
                  }}
                  size="large"
                >
                  Apply Now
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                sx={{
                  mt: 2,
                  textTransform: "none",
                  fontWeight: 400,
                  fontFamily: "Poppins",
                  fontSize: 14
                }}
                onClick={handleLearnMore}
              >
                Learn More
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Application Progress</Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ApplicationProgressBar status={status} handleStatus={handleStatus} />
            </Box>
          </Box>
          {status === AppStatus.Completed && (
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Application Results</Typography>
              <ApplicationResults result={result} status={status} handleResults={handleResults} />
            </Box>
          )}
        </Box>
      </DisplayBox>
    </Box>
  );
};

export default ApplicationCard;
