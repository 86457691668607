import { createContext, useContext } from "react";

type StaffDataContextType = {
  loading: boolean;
  sqlLoading: boolean;
};

const defaultValue: StaffDataContextType = {
  loading: true,
  sqlLoading: true,
};

export const StaffDataContext = createContext<StaffDataContextType>(defaultValue);

export const useStaffData = () => {
  return useContext(StaffDataContext);
};
