import React, { Suspense, useState } from "react";
import { Divider, Typography } from "@mui/material";
import DistrictsTable from "../../../staff/components/settings/DistrictsTable";
import StaffTable from "../../../staff/components/settings/StaffTable";
import { UserType } from "../../../shared/types/enums";
import { StaffRecord } from "../../../shared/types/types";
import { BLANK_STAFF_FORM } from "../../../shared/libraries/blankForms";
import ManageStaffDialog from "../../../staff/components/settings/ManageStaffDialog";

const DeveloperSettingsContainer = () => {
  const [manageStaffOpen, setManageStaffOpen] = useState(false);
  const [staffForm, setStaffForm] = useState<StaffRecord>({ ...BLANK_STAFF_FORM });
  return (
    <>
      <Typography sx={{ fontSize: 50, mt: 2 }} variant="h1">
        Developer Settings
      </Typography>
      <Divider />
      <Typography sx={{ fontSize: 30, mt: 4 }} variant="h4">
        Districts
      </Typography>
      <DistrictsTable />
      <Suspense fallback={<div>Loading...</div>}>
        <Typography sx={{ fontSize: 30, mt: 4 }} variant="h4">
          Developer Staff
        </Typography>
        <StaffTable
          userType={UserType.Developer}
          setManageStaffOpen={setManageStaffOpen}
          setStaffForm={setStaffForm}
        />
      </Suspense>
      <ManageStaffDialog
        open={manageStaffOpen}
        setOpen={setManageStaffOpen}
        staffForm={staffForm}
        setStaffForm={setStaffForm}
      />
    </>
  );
};

export default DeveloperSettingsContainer;
