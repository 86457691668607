import { GetRecoilValue, atom, selector } from "recoil";
import { ProviderDashboardProgramRow } from "../types/types";
import { programsAtom } from "./providerAtoms";

type ProgramFilter = {
  search: string;
};

const programRowsGetter = ({ get }: { get: GetRecoilValue }) => {
  const programs = get(programsAtom);
  const programFilters = get(programFiltersAtom);

  if (!programs) return null;
	
  const programsForFilter = programs.filter((program) => {
    const { programName, programDescription } = program;
    const searchText = programFilters.search.toLowerCase();

    if (
      programFilters.search &&
      !(programName?.toLowerCase().includes(searchText) || programDescription?.toLowerCase().includes(searchText))
    ) {
      return false;
    }

		return true;
  });

  const parsedRows = programsForFilter.map((program) => {
    return {
      id: program.id,
      programName: program.programName,
			programDescription: program.programShortDescription,
			credentialLevel: program.credentialLevel,
			durationYears: program.durationYears,
			durationMonths: program.durationMonths,
			cipCode: program.cipCode,
    };
  });
  return parsedRows as ProviderDashboardProgramRow[];
};

export const programRowsAtom = selector({
  key: "programRows",
  get: programRowsGetter,
});

export const programFiltersAtom = atom<ProgramFilter>({
  key: "programFilters",
  default: {
    search: "",
  },
});
