import React from "react";
import { Box } from "@mui/material";
import DisplayBox from "../../../shared/components/DisplayBox";
import PreferencesDetails from "./PreferencesDetails";

const Preferences = () => {
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DisplayBox title="My Preferences">
          <PreferencesDetails />
        </DisplayBox>
      </Box>
    </>
  );
};

export default Preferences;
