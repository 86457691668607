import { useCallback } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

interface SendRequestProps {
  col: string;
  id: string;
}

type DBRecord = {
  id: string;
};

type SendRequest = <T extends DBRecord>(sendRequest: SendRequestProps) => Promise<T | null>;

type GetDocType = {
  sendRequest: SendRequest;
};

const useGetDoc = (): GetDocType => {
  const sendRequest = useCallback<SendRequest>(async <TS>({ col, id }: SendRequestProps) => {
    const docRef = doc(db, col, id);
    const results = await getDoc(docRef);

    if (!results.data()) {
      return null;
    } else {
      return { ...results.data(), id: results.id } as TS;
    }
  }, []);
  return { sendRequest };
};

export default useGetDoc;
