import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { questionsAtom } from "../../recoil/questionAtoms";
import { Box, FormControlLabel, Typography, FormGroup, Checkbox } from "@mui/material";
import { SubIndustryDBName, WillowIndustryId } from "../../types/enums";
import { Answer, QuestionOption } from "../../types/types";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import MatrixDialog from "./MatrixDialog";

type Props = {
  optionId: number | string;
  subAnswers?: Answer[];
  setSubAnswers?: React.Dispatch<React.SetStateAction<Answer[]>>;
};

const SubIndustryQuestion = ({ optionId, subAnswers, setSubAnswers }: Props) => {
  const questions = useRecoilValue(questionsAtom);
  const [subIndustries, setSubIndustries] = useState<QuestionOption[]>([]);
  const [checked, setChecked] = useState<(string | number)[]>([]);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const [message, setMessage] = useState<string | null>(null);

  const getAnswerOptions = useCallback(
    (optionId: string) => {
      if (!subAnswers) return [];
      switch (optionId) {
        case WillowIndustryId.Business:
          return subAnswers.find((a) => a.questionId === SubIndustryDBName.Business)?.ms ?? [];
        case WillowIndustryId.Arts:
          return subAnswers.find((a) => a.questionId === SubIndustryDBName.Arts)?.ms ?? [];
        case WillowIndustryId.Sciences:
          return subAnswers.find((a) => a.questionId === SubIndustryDBName.Sciences)?.ms ?? [];
        case WillowIndustryId.SkilledTrade:
          return subAnswers.find((a) => a.questionId === SubIndustryDBName.SkilledTrade)?.ms ?? [];
        case WillowIndustryId.SocialServices:
          return subAnswers.find((a) => a.questionId === SubIndustryDBName.SocialServices)?.ms ?? [];
        default:
          return [];
      }
    },
    [subAnswers]
  );

  const getAnswer = useCallback(
    (optionId: string) => {
      if (!subAnswers) return undefined;
      switch (optionId) {
        case WillowIndustryId.Business:
          return subAnswers.find((a) => a.questionId === SubIndustryDBName.Business);
        case WillowIndustryId.Arts:
          return subAnswers.find((a) => a.questionId === SubIndustryDBName.Arts);
        case WillowIndustryId.Sciences:
          return subAnswers.find((a) => a.questionId === SubIndustryDBName.Sciences);
        case WillowIndustryId.SkilledTrade:
          return subAnswers.find((a) => a.questionId === SubIndustryDBName.SkilledTrade);
        case WillowIndustryId.SocialServices:
          return subAnswers.find((a) => a.questionId === SubIndustryDBName.SocialServices);
        default:
          return undefined;
      }
    },
    [subAnswers]
  );

  const filterOptions = useCallback(
    (optionId: string) => {
      switch (optionId) {
        case WillowIndustryId.Business:
          return questions.find((q) => q.id === SubIndustryDBName.Business)?.options ?? [];
        case WillowIndustryId.Arts:
          return questions.find((q) => q.id === SubIndustryDBName.Arts)?.options ?? [];
        case WillowIndustryId.Sciences:
          return questions.find((q) => q.id === SubIndustryDBName.Sciences)?.options ?? [];
        case WillowIndustryId.SkilledTrade:
          return questions.find((q) => q.id === SubIndustryDBName.SkilledTrade)?.options ?? [];
        case WillowIndustryId.SocialServices:
          return questions.find((q) => q.id === SubIndustryDBName.SocialServices)?.options ?? [];
        default:
          return [];
      }
    },
    [questions]
  );

  const selectedDBName = useCallback((optionId: string) => {
    switch (optionId) {
      case WillowIndustryId.Business:
        return SubIndustryDBName.Business;
      case WillowIndustryId.Arts:
        return SubIndustryDBName.Arts;
      case WillowIndustryId.Sciences:
        return SubIndustryDBName.Sciences;
      case WillowIndustryId.SkilledTrade:
        return SubIndustryDBName.SkilledTrade;
      case WillowIndustryId.SocialServices:
        return SubIndustryDBName.SocialServices;
      default:
        return "";
    }
  }, []);

  useEffect(() => {
    const options = filterOptions(String(optionId));
    const selectedSubIndustries = getAnswerOptions(String(optionId));
    setChecked(selectedSubIndustries);
    setSubIndustries(options);
  }, [filterOptions, getAnswerOptions, optionId]);

  const handleChecked = async (id: string) => {
    if (!selectedStudent || !selectedStudent.schoolId || !selectedStudent.districtId || !subAnswers || !setSubAnswers) return;
    const answerLength = filterOptions(String(optionId)).length;
    const newChecked = checked.includes(id)
      ? checked.filter((currentId) => currentId !== id)
      : [...checked, id];
    if (answerLength - newChecked.length <= 1) {
      setMessage("You must leave at least 2 career paths selected");
      return;
    }
    setChecked(newChecked);
    const answer = getAnswer(String(optionId));
    if (!answer) {
      const newRecord: Answer = {
        questionId: selectedDBName(String(optionId)),
        ms: newChecked,
        studentId: selectedStudent.id,
        schoolId: selectedStudent.schoolId,
        districtId: selectedStudent.districtId,
        mc: "",
        or: "",
        matrix: [],
        gpa: [],
        number: 0,
      };
      setSubAnswers([...subAnswers, newRecord]);
    } else {
      setSubAnswers((pV) => {
        const newAnswers = pV.map((ans) => {
          if (ans.questionId === answer.questionId) {
            return { ...ans, ms: newChecked };
          }
          return ans;
        });
        return newAnswers;
      });
    }
  };

  return (
    <>
      <Box>
        <Typography variant="body1" sx={{ fontWeight: 600, mt: 2 }}>
          Deselect any careers you know you DO NOT want to pursue:
        </Typography>
        <FormGroup sx={{ ml: 2 }}>
          {subIndustries.map((subIndustry) => (
            <FormControlLabel
              key={subIndustry.id}
              control={
                <Checkbox
                  checked={checked.includes(String(subIndustry.id)) === false}
                  onChange={() => handleChecked(String(subIndustry.id))}
                />
              }
              label={
                <Typography
                  sx={
                    checked.includes(String(subIndustry.id))
                      ? { textDecoration: "line-through" }
                      : {}
                  }
                >
                  {subIndustry.text}
                </Typography>
              }
            />
          ))}
        </FormGroup>
      </Box>
      <MatrixDialog message={message} setMessage={setMessage} />
    </>
  );
};

export default SubIndustryQuestion;
