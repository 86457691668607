import React from "react";
import { AppStatus, Color } from "../../../shared/types/enums";
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Typography } from "@mui/material";

type Props = {
  status: string;
  handleStatus: (newStatus: AppStatus) => void;
};

const DISABLED = "#ccc";
const HOVER_GREEN = "#00896A";

const ApplicationProgressBar = ({ status, handleStatus }: Props) => {
  return (
    <>
      <Box sx={{ mt: 1 }}>
        {/* Not Started */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CircleIcon
            sx={{
              fontSize: 14,
              color: status === AppStatus.NoApply ? DISABLED : Color.Mint,
              ml: "3px",
            }}
          />
          <Typography
            sx={{ ml: 2, color: status === AppStatus.NoApply ? DISABLED : Color.Chalkboard }}
          >
            {AppStatus.NotStarted}
          </Typography>
        </Box>
        <div
          style={{
            height: 16,
            marginLeft: 9.5,
            borderLeft: "1px solid",
            borderColor: status === AppStatus.NoApply ? DISABLED : Color.Mint,
            width: 0,
            marginTop: -5,
          }}
        />
        {/* In Progress */}
        <Box sx={{ display: "flex", alignItems: "center", marginTop: "-3px" }}>
          {status === AppStatus.InProgress && (
            <CheckCircleIcon
              sx={{
                fontSize: 20,
                color: Color.Mint,
                cursor: "pointer",
                ":hover": { color: HOVER_GREEN },
              }}
              onClick={() => handleStatus(AppStatus.NotStarted)}
            />
          )}
          {status === AppStatus.Completed && (
            <CheckCircleIcon
              sx={{
                fontSize: 20,
                color: Color.Mint,
                cursor: "pointer",
                ":hover": { color: HOVER_GREEN },
              }}
              onClick={() => handleStatus(AppStatus.NotStarted)}
            />
          )}
          {status === AppStatus.NotStarted && (
            <RadioButtonUncheckedIcon
              sx={{
                fontSize: 20,
                color: Color.Mint,
                cursor: "pointer",
                ":hover": { color: HOVER_GREEN },
              }}
              onClick={() => handleStatus(AppStatus.InProgress)}
            />
          )}
          {status === AppStatus.NoApply && (
            <CircleIcon
              sx={{
                fontSize: 20,
                color: DISABLED,
                cursor: "pointer",
              }}
            />
          )}
          <Typography
            sx={{ ml: 2, color: status === AppStatus.NoApply ? DISABLED : Color.Chalkboard }}
          >
            {AppStatus.InProgress}
          </Typography>
        </Box>
        <div
          style={{
            height: 16,
            marginLeft: 9.5,
            borderLeft: "1px solid",
            borderColor: status === AppStatus.NoApply ? DISABLED : Color.Mint,
            width: 0,
            marginTop: -3,
          }}
        />
        {/* Completed */}
        <Box sx={{ display: "flex", alignItems: "center", marginTop: "-3px" }}>
          {status === AppStatus.Completed && (
            <CheckCircleIcon
              sx={{
                fontSize: 20,
                color: Color.Mint,
                cursor: "pointer",
                ":hover": { color: HOVER_GREEN },
              }}
              onClick={() => handleStatus(AppStatus.InProgress)}
            />
          )}
          {status === AppStatus.InProgress && (
            <RadioButtonUncheckedIcon
              sx={{
                fontSize: 20,
                color: Color.Mint,
                cursor: "pointer",
                ":hover": { color: HOVER_GREEN },
              }}
              onClick={() => handleStatus(AppStatus.Completed)}
            />
          )}
          {status === AppStatus.NotStarted && (
            <RadioButtonUncheckedIcon
              sx={{
                fontSize: 20,
                color: Color.Mint,
                cursor: "pointer",
                ":hover": { color: HOVER_GREEN },
              }}
              onClick={() => handleStatus(AppStatus.Completed)}
            />
          )}
          {status === AppStatus.NoApply && (
            <CircleIcon
              sx={{
                fontSize: 20,
                color: DISABLED,
                cursor: "pointer",
              }}
            />
          )}
          <Typography
            sx={{ ml: 2, color: status === AppStatus.NoApply ? DISABLED : Color.Chalkboard }}
          >
            {AppStatus.Completed}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
          {status !== AppStatus.NoApply ? (
            <RadioButtonUncheckedIcon
              sx={{
                fontSize: 20,
                color: "#aaa",
                cursor: "pointer",
                ":hover": { color: "#888" },
              }}
              onClick={() => handleStatus(AppStatus.NoApply)}
            />
          ) : (
            <CheckCircleIcon
              sx={{
                fontSize: 20,
                color: "#aaa",
                cursor: "pointer",
                ":hover": { color: "#888" },
              }}
              onClick={() => handleStatus(AppStatus.NotStarted)}
            />
          )}
          <Typography sx={{ ml: 2 }}>{AppStatus.NoApply}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default ApplicationProgressBar;
