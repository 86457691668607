import { Box } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { ChatEntry } from "../../../shared/types/types";
import { formatChatBackground } from "../../../shared/utils/aiUtils";
import useAlmaDisplay from "../../hooks/alma/useAlmaDisplay";

function extract_and_clean_numbers(text: string) {
  // Define the regular expression pattern to match six-digit numbers in parentheses.
  const pattern = /\(\d{6}\)/g;

  // Use re.findall() to find all occurrences of the pattern in the text.
  const matches = text.match(pattern);

  const matchesArray = matches ? matches : [];

  // Store the extracted numbers (without the parentheses)
  const numbers = matchesArray.map((match) => match.slice(1, -1));

  // Clean the text by removing the matched patterns.
  const cleanedString = text;
  return { numbers: numbers, cleaned_string: cleanedString };
}

type MessageProps = {
  index: number;
  entry: ChatEntry;
  setProviderId: Dispatch<SetStateAction<string | null>>;
};

const Message = ({ index, entry, setProviderId }: MessageProps) => {
  const { formatResponse } = useAlmaDisplay({ setProviderId: setProviderId });
  const { cleaned_string } = extract_and_clean_numbers(entry.parts[0].text);

  return (
    <Box
      key={index}
      sx={{ p: 1, display: "flex", justifyContent: entry.role === "model" ? "end" : "start" }}
    >
      <Box sx={formatChatBackground(entry.role)}>{formatResponse(cleaned_string)}</Box>
    </Box>
  );
};

export default Message;
