export enum ListType {
  StaffList = "StaffList",
  StudentList = "StudentList",
  ProviderUserList = "ProviderUserList",
  MentorList = "Mentor List",
  CompletedQuizList = "CompletedQuizList",
}

type AddUserToListProps = {
  listType: ListType;
  userId: string;
};

export const addUserToKlaviyoList = async ({ listType, userId }: AddUserToListProps) => {
  const url = `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/klaviyo/addUserToList`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ listType: listType.toString(), userId }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || `HTTP error! status: ${response.status}`);
    }
    return data;
  } catch (error: any) {
    console.error("Error adding user:", error.message);
  }
};

type CreateProfileProps = {
  email: string;
  first_name: string;
  last_name: string;
  student_first_name?: string;
  student_last_name?: string;
};

export const createKlaviyoProfile = async ({
  email,
  first_name,
  last_name,
}: CreateProfileProps) => {
  const url = `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/klaviyo/createProfile`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profileAttributes: { email, first_name, last_name },
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || `HTTP error! status: ${response.status}`);
    }
    return data;
  } catch (error: any) {
    console.error("Error adding profile:", error.message);
    return null;
  }
};
