import {
  collection,
  getDocs,
  where,
  orderBy,
  limit,
  query,
  WhereFilterOp,
  OrderByDirection,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Collection } from "../types/enums";

interface SendRequestProps {
  col: string;
  config?: {
    where?: [string, WhereFilterOp, any];
    orderBy?: [string, OrderByDirection?];
    limit?: number;
  };
}

type DBRecord = {
  id: string;
};

export const getSchoolStudents = async (schoolId: string) => {
  const colRef = collection(db, Collection.Students);
  const snapshot = await getDocs(
    query(colRef, where("districtId", "==", schoolId), where("active", "==", true))
  );
  const tempArray: any[] = [];
  snapshot.forEach((s) => {
    tempArray.push({ ...s.data(), id: s.id });
  });
  return tempArray;
};

export const getFBDocs = async <T extends DBRecord>({
  col,
  config,
}: SendRequestProps): Promise<T[]> => {
  const conditions: any = [where("active", "==", true)];
  if (config?.where) {
    conditions.push(where(config.where[0], config.where[1], config.where[2]));
  }
  if (config?.orderBy) {
    conditions.push(orderBy(config.orderBy[0], config.orderBy[1] ? config.orderBy[1] : "asc"));
  }
  if (config?.limit) {
    conditions.push(limit(config.limit));
  }
  const docRef = collection(db, col);
  let snapshot;
  try {
    if (conditions.length > 0) {
      snapshot = await getDocs(query(docRef, ...conditions));
    } else {
      snapshot = await getDocs(docRef);
    }
  } catch (err) {
    console.error(err);
  }
  const tempArray: T[] = [];
  if (snapshot) {
    snapshot.forEach((s) => {
      tempArray.push({ ...s.data(), id: s.id } as DBRecord as T);
    });
  }

  return tempArray;
};

type SendRequestDocProps = { col: string; id: string };

export const getFBDoc = async <T extends DBRecord>({
  col,
  id,
}: SendRequestDocProps): Promise<T | null> => {
  const docRef = doc(db, col, id);
  const results = await getDoc(docRef);

  if (!results.data()) {
    return null;
  } else {
    return { ...results.data(), id: results.id } as T;
  }
};
