import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ResetPasswordDialog from "./ResetPasswordDialog";
import { useAuth } from "../../contexts/AuthContext";
import { Color, PageRoute } from "../../types/enums";

const LoginForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetOpen, setResetOpen] = useState(false);
  const { loginError, emailSignIn, signInWithGoogle, signInWithClever } = useAuth();

  const handleCreateStudentAccount = () => {
    navigate(PageRoute.Signup);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.trim().toLocaleLowerCase());
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value.trim());
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    emailSignIn({ email, password });
  };

  const handleResetOpen = () => {
    setResetOpen(true);
  };
  return (
    <>
      <Typography variant="h3" sx={{ pt: 1, textAlign: "center", mt: 2 }}>
        Log In or Sign Up
      </Typography>
      {process.env.REACT_APP_FIREBASE_PROJECT_ID === "willow-demo-d3c85" && (
        <Alert severity="info">
          <p>To log in as a sample counselor use:</p> <p>email: demo-staff@willowed.org</p>
          <p>password:Password1</p>{" "}
          <p>
            To experience Willow as a student, use the Student Sign Up button below and select
            Willow High School as your school in the sign up form.
          </p>
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            onClick={signInWithGoogle}
            sx={{ mt: 4, p: 2, fontSize: 13 }}
          >
            <Box sx={{ width: 20, mr: 1 }}>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                style={{ display: "block" }}
              >
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                ></path>
                <path
                  fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                ></path>
                <path
                  fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                ></path>
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                ></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </Box>
            Continue With Google
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            onClick={signInWithClever}
            sx={{ mt: 4, p: 2, display: "flex", alignItems: "center" }}
          >
            <Box sx={{ mb: -1 }}>
              <img
                src="https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/2f/21/e9/2f21e920-d92e-f948-dc5b-fcc0b589f6ff/AppIcon-0-0-1x_U007emarketing-0-0-0-6-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.webp"
                width="20px"
                style={{ borderRadius: 4, marginRight: 4 }}
                alt="Clever Login"
              />
            </Box>
            Continue with Clever
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 3 }}>Or</Divider>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <FormControl fullWidth>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormLabel id="email-label">Your Email</FormLabel>
              <TextField
                name="email"
                required
                id="email"
                type="text"
                autoComplete="email"
                value={email}
                fullWidth
                variant="outlined"
                onChange={handleEmailChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel id="email-label">Your Password</FormLabel>
              <TextField
                name="password"
                autoComplete="password"
                required
                id="password"
                type="password"
                value={password}
                fullWidth
                variant="outlined"
                onChange={handlePasswordChange}
              />
            </Grid>
          </Grid>
          {loginError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {loginError}
            </Alert>
          )}

          <Button id="submit-login-form" type="submit" variant="contained" sx={{ mt: 2 }} fullWidth>
            Submit
          </Button>
        </FormControl>
      </form>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
        <Button color="primary" onClick={handleResetOpen} sx={{ p: 1 }}>
          Reset Your Password
        </Button>
      </Box>
      <Divider sx={{ mt: 1 }}>Don't have an account?</Divider>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={6}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            size="large"
            sx={{ mt: 4 }}
            onClick={handleCreateStaffAccount}
          >
            Staff Sign Up
          </Button>
        </Grid> */}
        <Grid item xs={12} sm={12}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            size="large"
            sx={{ mt: 3 }}
            onClick={handleCreateStudentAccount}
          >
            Student Sign Up
          </Button>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            sx={{ textAlign: "center", fontSize: 12, fontStyle: "italic" }}
            variant="body2"
          >
            If you are a staff member looking to create an account, please contact your school
            administrator to make you one.
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} sx={{ display: "flex", justifyContent: "center" }}>
          <Link to={PageRoute.Mentor_Login} style={{ textDecoration: "none", color: Color.Mint }}>
            Mentor Login{" "}
          </Link>
        </Grid>
        <Grid item xs={6} sm={6} sx={{ display: "flex", justifyContent: "center" }}>
          <Link to={PageRoute.Provider_Login} style={{ textDecoration: "none", color: Color.Mint }}>
            Provider Login{" "}
          </Link>
        </Grid>
      </Grid>
      <ResetPasswordDialog open={resetOpen} setOpen={setResetOpen} />
    </>
  );
};

export default LoginForm;
