import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { MentorUser, MentorUserRecord, StudentRecord } from "../../../shared/types/types";
import { BLANK_MENTOR_USER_FORM } from "../../../shared/libraries/blankForms";
import { mentorUsersAtom } from "../../../shared/recoil/providerAtoms";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import useSetDoc from "../../../shared/hooks/useSetDoc";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import useGetDocs from "../../../shared/hooks/useGetDocs";
import { Collection } from "../../../shared/types/enums";
import {
  addUserToKlaviyoList,
  createKlaviyoProfile,
  ListType,
} from "../../../shared/utils/klaviyoFunctions";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";

type Props = {
  mentorUser: MentorUserRecord | null;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
};

const useEditMentorDialog = ({ mentorUser, setOpen, open }: Props) => {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [mentorUserForm, setMentorUserForm] = useState<MentorUser>({
    ...BLANK_MENTOR_USER_FORM,
  });
  const setMentorUsers = useSetRecoilState(mentorUsersAtom);
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const { sendRequest: setDoc } = useSetDoc();
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: getDocs } = useGetDocs();

  useEffect(() => {
    if (mentorUser) {
      setMentorUserForm(mentorUser);
    } else {
      setMentorUserForm(BLANK_MENTOR_USER_FORM);
    }
  }, [mentorUser, open]);

  const validate = useCallback(() => {
    if (mentorUserForm.firstName === "") {
      setValidated(false);
      return;
    }
    if (mentorUserForm.lastName === "") {
      setValidated(false);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (mentorUserForm.email === "" || !emailRegex.test(mentorUserForm.email.trim())) {
      setValidated(false);
      return;
    }

    setValidated(true);
  }, [mentorUserForm.firstName, mentorUserForm.lastName, mentorUserForm.email]);

  useEffect(() => {
    validate();
  }, [mentorUserForm, validate]);

  const createEmailUser = async (email: string, password: string) => {
    const url = `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/auth/createUser`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }
      return data;
    } catch (error: any) {
      console.error("Error creating User:", error.message);
    }
  };

  type UpdateMentorUserProps = {
    mentorUser: MentorUserRecord;
    mentorUserForm: MentorUser;
  };

  const updateMentorUser = async ({ mentorUser, mentorUserForm }: UpdateMentorUserProps) => {
    // In the case the mentor is updated
    await updateDoc({
      col: Collection.MentorUsers,
      id: mentorUser.id,
      data: {
        ...mentorUserForm,
      },
    });
    setMentorUsers((pV) =>
      pV.map((item) => (item.id === mentorUser.id ? { ...item, ...mentorUserForm } : item))
    );
  };

  type UpdateKlaviyoProps = {
    mentorUserForm: MentorUser;
  };

  const updateKlaviyo = async ({ mentorUserForm }: UpdateKlaviyoProps) => {
    var klaviyoProfileId = "";
    const result = await createKlaviyoProfile({
      email: mentorUserForm.email,
      first_name: mentorUserForm.firstName,
      last_name: mentorUserForm.lastName,
    });

    if (result.success) {
      if (result.data.id) {
        klaviyoProfileId = `${result.data.id}`;

        await addUserToKlaviyoList({
          listType: ListType.MentorList,
          userId: `${result.data.id}`,
        });
      }
    }
    return klaviyoProfileId;
  };

  type CreateNewMentorUserProps = {
    selectedStudent: StudentRecord;
    mentorUserForm: MentorUser;
  };

  const createNewMentorUser = async ({
    selectedStudent,
    mentorUserForm,
  }: CreateNewMentorUserProps) => {
    // In the case the mentor is created
    const existMentors = await getDocs<MentorUserRecord>({
      col: Collection.MentorUsers,
      config: { where: ["email", "==", mentorUserForm.email] },
    });

    if (existMentors.length === 0) {
      // In the case the mentor doesn't exist
      const response = await createEmailUser(mentorUserForm.email, "Password1");

      if (response) {
        const klaviyoProfileId = await updateKlaviyo({ mentorUserForm });
        const promises = [];
        const nowISO = new Date().toISOString();

        promises.push(
          setDoc<MentorUserRecord>({
            col: Collection.MentorUsers,
            data: {
              ...mentorUserForm,
              klaviyoProfileId: klaviyoProfileId,
              studentIds: [selectedStudent.id],
              createdAt: nowISO,
              lastUpdatedAt: nowISO,
            },
            id: response.uid,
          })
        );
        promises.push(
          updateDoc({
            col: Collection.Students,
            id: selectedStudent.id,
            data: { mentorIds: [...selectedStudent.mentorIds, response.uid] },
          })
        );
        await Promise.all(promises);
        setMentorUsers((pV) => [
          ...pV,
          { ...mentorUserForm, id: response.uid, createdAt: nowISO, lastUpdatedAt: nowISO },
        ]);
        setSelectedStudent({
          ...selectedStudent,
          mentorIds: [...selectedStudent.mentorIds, response.uid],
        });
      }
    } else {
      // In the case the mentor already exists, just not for this student
      await addMentorToStudent({ existMentors, selectedStudent });
    }
  };

  type AddMentorToStudentProps = {
    existMentors: MentorUserRecord[];
    selectedStudent: StudentRecord;
  };

  const addMentorToStudent = async ({ existMentors, selectedStudent }: AddMentorToStudentProps) => {
    const promises: Promise<void | string | null>[] = [];
    promises.push(
      updateDoc({
        col: Collection.MentorUsers,
        id: existMentors[0].id,
        data: { studentIds: [...existMentors[0].studentIds, selectedStudent.id] },
      })
    );
    promises.push(
      updateDoc({
        col: Collection.Students,
        id: selectedStudent.id,
        data: { mentorIds: [...selectedStudent.mentorIds, existMentors[0].id] },
      })
    );
    await Promise.all(promises);
  };

  const handleSave = async () => {
    if (!selectedStudent) return;
    setLoading(true);
    if (mentorUser) {
      await updateMentorUser({ mentorUser, mentorUserForm });
    } else {
      await createNewMentorUser({ selectedStudent, mentorUserForm });
    }
    setLoading(false);
    setOpen(false);
    setMentorUserForm({ ...BLANK_MENTOR_USER_FORM });
  };

  const handleDelete = async () => {
    if (!selectedStudent || !mentorUser) return;

    setOpen(true);
    setLoading(true);

    const promises: Promise<void | string | null | Response>[] = [];

    promises.push(
      updateDoc({
        col: Collection.MentorUsers,
        id: mentorUser.id,
        data: {
          studentIds: [...mentorUser.studentIds.filter((item) => item !== selectedStudent.id)],
        },
      })
    );
    promises.push(
      updateDoc({
        col: Collection.Students,
        id: selectedStudent.id,
        data: {
          mentorIds: [...selectedStudent.mentorIds.filter((item) => item !== mentorUser.id)],
        },
      })
    );

    promises.push(deleteDoc(doc(db, Collection.MentorUsers, mentorUser.id)));

    promises.push(
      fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/auth/deleteAuthUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid: mentorUser.id }),
      })
    );
    setMentorUsers((pV) => pV.filter((item) => item.id !== mentorUser.id));
    setLoading(false);
    setOpen(false);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMentorUserForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
  };

  const handleClose = () => {
    setOpen(false);
    setMentorUserForm({ ...BLANK_MENTOR_USER_FORM });
  };

  return {
    loading,
    validated,
    mentorUserForm,
    handleSave,
    handleDelete,
    handleTextChange,
    handleClose,
  };
};

export default useEditMentorDialog;
