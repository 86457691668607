import React from "react";
import { Container } from "@mui/material";
import ProviderSignupNavbar from "../components/signup/ProviderSignupNavbar";
import ProviderSignupContainer from "../components/signup/ProviderSignupContainer";

const Signup = () => {
  return (
    <>
      <ProviderSignupNavbar />
      <Container maxWidth="sm" sx={{ pt: 8 }}>
        <ProviderSignupContainer />
      </Container>
    </>
  );
};

export default Signup;
