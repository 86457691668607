export const generateAuthError = (errorCode: string) => {
  switch (errorCode) {
    case "auth/user-not-found":
      return "Email not found";
    case "auth/wrong-password":
      return "Incorrect password, if you forgot your password, click RESET YOUR PASSWORD below";
    case "auth/invalid-credential":
      return "Incorrect password, if you forgot your password, click RESET YOUR PASSWORD below";
    case "auth/invalid-login-credentials":
      return "That email does not exist in our system";
    default:
      return "Unknown Error Logging In";
  }
};
