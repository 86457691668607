import React, { useState, useEffect, useCallback } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useUser } from "../../shared/contexts/UserContext";
import { useAuth } from "../../shared/contexts/AuthContext";
import {
  allEnrolledMentorStudentsAtom,
  allStudentApplicationDataAtom,
  loggedInMentorAtom,
  selectedStudentAtom,
} from "../../shared/recoil/userAtoms";
import { questionsAtom } from "../../shared/recoil/questionAtoms";
import { allAnswersAtom } from "../../shared/recoil/answersAtoms";
import { stagesAtom, stageTasksAtom } from "../../shared/recoil/stageAtoms";
import { allMatchedProgramsAtom, allWriteInProgramsAtom } from "../../shared/recoil/providerAtoms";
import { siteLoadingAtom } from "../../shared/recoil/loadingAtoms";
import {
  AnswerRecord,
  ApplicationDataRecord,
  Provider_Program,
  QuestionRecord,
  StageRecord,
  StageTaskRecord,
  StudentRecord,
  WriteInProgramRecord,
} from "../../shared/types/types";
import { getFBDocs } from "../../shared/utils/queries";
import { Collection } from "../../shared/types/enums";
import {
  parseAnswerResponse,
  parseApplicationDataResponse,
  parseProgramResponse,
  parseQuestionResponse,
  parseStageResponse,
  parseStageTaskResponse,
  parseStudentResponse,
  parseWriteInProgramsResponse,
} from "../../shared/utils/parsers";
import { MentorDataContext } from "../contexts/MentorDataContext";

type Props = {
  children: JSX.Element;
};

export const MentorDBProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [sqlLoading, setSQLLoading] = useState(false);
  const [startQuery] = useState(new Date().getTime());
  const { loading: userLoading } = useUser();
  const { loading: authLoading } = useAuth();
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);
  const loggedInMentor = useRecoilValue(loggedInMentorAtom);
  const [allEnrolledMentorStudents, setAllEnrolledMentorStudents] = useRecoilState(
    allEnrolledMentorStudentsAtom
  );
  const setQuestions = useSetRecoilState(questionsAtom);
  const setAllAnswers = useSetRecoilState(allAnswersAtom);
  const setStages = useSetRecoilState(stagesAtom);
  const setStageTasks = useSetRecoilState(stageTasksAtom);
  const setAllStudentApplicationData = useSetRecoilState(allStudentApplicationDataAtom);
  const setAllWriteInPrograms = useSetRecoilState(allWriteInProgramsAtom);
  const setAllMatchedPrograms = useSetRecoilState(allMatchedProgramsAtom);
  const setSiteLoading = useSetRecoilState(siteLoadingAtom);

  useEffect(() => {
    if (userLoading || authLoading) return;
    const loadData = async () => {
      if (!loggedInMentor) {
        setLoading(false);
        return;
      }
      setLoading(true);
      // console.log("Load Data Started");
      const dataLoaderPromises: [
        Promise<StudentRecord[]>,
        Promise<QuestionRecord[]>,
        Promise<AnswerRecord[]>,
        Promise<StageRecord[]>,
        Promise<StageTaskRecord[]>,
        Promise<ApplicationDataRecord[]>,
        Promise<WriteInProgramRecord[]>
      ] = [
        getFBDocs<StudentRecord>({
          col: Collection.Students,
          config: { where: ["mentorIds", "array-contains", loggedInMentor.id] },
        }),
        getFBDocs<QuestionRecord>({
          col: Collection.Questions,
          config: { orderBy: ["order", "asc"] },
        }),
        getFBDocs<AnswerRecord>({
          col: Collection.Answers,
          config: { where: ["studentId", "in", loggedInMentor.studentIds] },
        }),
        getFBDocs<StageRecord>({
          col: Collection.Stages,
          config: { orderBy: ["order", "asc"] },
        }),
        getFBDocs<StageTaskRecord>({
          col: Collection.StageTasks,
        }),
        getFBDocs<ApplicationDataRecord>({
          col: Collection.ApplicationData,
          config: { where: ["studentId", "in", loggedInMentor.studentIds] },
        }),
        getFBDocs<WriteInProgramRecord>({
          col: Collection.WriteInPrograms,
          config: { where: ["studentId", "in", loggedInMentor.studentIds] },
        }),
      ];
      const [
        mentorAllStudents,
        questions,
        answers,
        stages,
        stageTasks,
        applicationData,
        writeInPrograms,
      ] = await Promise.all(dataLoaderPromises);
      // console.log("Total Firebase Time", new Date().getTime() - startQuery);
      const students = parseStudentResponse(mentorAllStudents);
      const filteredStudents = students.filter((student) => student.uidId && student.enrolled);
      setAllEnrolledMentorStudents(parseStudentResponse(filteredStudents));
      setSelectedStudent(filteredStudents.length > 0 ? filteredStudents[0] : null);
      setQuestions(parseQuestionResponse(questions));
      setAllAnswers(parseAnswerResponse(answers));
      setStages(parseStageResponse(stages));
      setStageTasks(parseStageTaskResponse(stageTasks));
      setAllStudentApplicationData(parseApplicationDataResponse(applicationData));
      setAllWriteInPrograms(parseWriteInProgramsResponse(writeInPrograms));
      setLoading(false);
    };
    loadData();
  }, [
    authLoading,
    loggedInMentor,
    setAllAnswers,
    setAllEnrolledMentorStudents,
    setAllStudentApplicationData,
    setAllWriteInPrograms,
    setQuestions,
    setSelectedStudent,
    setStageTasks,
    setStages,
    startQuery,
    userLoading,
  ]);

  const getMatchedPrograms = useCallback(async (uniqueProgramIds) => {
    const results = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getListOfPrograms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        programIds: uniqueProgramIds,
      }),
    });
    const { rows } = (await results.json()) as { rows: Provider_Program[] };
    return rows;
  }, []);

  useEffect(() => {
    if (allEnrolledMentorStudents.length === 0) {
      setSQLLoading(false);
      return;
    }
    // console.log("Started SQL after", new Date().getTime() - startQuery);
    setSQLLoading(true);
    const getAllMatchedPrograms = async () => {
      const programIds = allEnrolledMentorStudents
          .map((student) => student.matchedProgramIds)
          .reduce((acc, current) => acc.concat(current), []),
        reducedUniqueProgramIds = Array.from(new Set(programIds));
      const programs = await getMatchedPrograms(reducedUniqueProgramIds);
      setAllMatchedPrograms(parseProgramResponse(programs));
      setSQLLoading(false);
      // console.log("Total SQL Time", new Date().getTime() - startQuery);
    };
    getAllMatchedPrograms();
  }, [
    allEnrolledMentorStudents,
    allEnrolledMentorStudents.length,
    getMatchedPrograms,
    setAllMatchedPrograms,
    startQuery,
  ]);

  useEffect(() => {
    setSiteLoading(loading || sqlLoading);
  }, [loading, setSiteLoading, sqlLoading]);

  return (
    <MentorDataContext.Provider value={{ loading, sqlLoading }}>
      {children}
    </MentorDataContext.Provider>
  );
};
