import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { StaffRecord } from "../../../shared/types/types";
import CustomAvatar from "../../../shared/components/CustomAvatar";
import useManageStaffDialog from "../../hooks/settings/useManageStaffDialog";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AuthType } from "../../../shared/types/enums";
import Loading from "../../../shared/components/Loading";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  staffForm: StaffRecord;
  setStaffForm: Dispatch<React.SetStateAction<StaffRecord>>;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const ManageStaffDialog = ({ staffForm, setStaffForm, open, setOpen }: Props) => {
  const {
    handleTextChange,
    handleSave,
    handleClose,
    handleUnenroll,
    newStaff,
    groups,
    selectedGroups,
    handleGroupSelect,
    password,
    authType,
    handlePasswordChange,
    handleShowPassword,
    passwordVisibility,
    handleAuthChange,
    loading,
  } = useManageStaffDialog({
    staffForm,
    setStaffForm,
    setOpen,
    open,
  });
  const [valid, setValid] = useState(false);

  const validForm = useCallback(() => {
    if (staffForm.firstName.trim().length === 0) {
      return false;
    }
    if (staffForm.lastName.trim().length === 0) {
      return false;
    }
    if (staffForm.jobTitle.trim().length === 0) {
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (newStaff && !emailRegex.test(staffForm.email.trim())) {
      return false;
    }
    if (newStaff && authType === AuthType.Email && password.trim().length === 0) {
      return false;
    }

    return true;
  }, [
    authType,
    password,
    staffForm.email,
    staffForm.firstName,
    staffForm.jobTitle,
    staffForm.lastName,
    newStaff,
  ]);

  useEffect(() => {
    const isValid = validForm();
    setValid(isValid);
  }, [staffForm, authType, password, validForm]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle variant="h4" sx={{ fontSize: 44, textAlign: "center" }}>
        {newStaff ? "Create" : "Edit"} Staff
      </DialogTitle>
      {loading ? (
        <Loading />
      ) : (
        <>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CustomAvatar
                size={100}
                fileName={staffForm?.avatar ?? ""}
                collection="staff"
                documentId={staffForm.id}
                formField="avatarURL"
                setForm={setStaffForm}
              />
            </Box>
            {!newStaff && (
              <Typography sx={{ textAlign: "center" }} variant="body2">
                {staffForm.email}
              </Typography>
            )}
            <Grid container sx={{ mt: 2 }} spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  value={staffForm.firstName}
                  name="firstName"
                  onChange={handleTextChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  value={staffForm.lastName}
                  name="lastName"
                  onChange={handleTextChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Job Title"
                  variant="outlined"
                  value={staffForm.jobTitle}
                  name="jobTitle"
                  onChange={handleTextChange}
                />
              </Grid>
              {newStaff && (
                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }} variant="body2">
                    How does your school log in to apps?
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <ToggleButtonGroup
                      color="secondary"
                      value={authType}
                      sx={{ mt: 1 }}
                      exclusive
                      onChange={handleAuthChange}
                      aria-label="Platform"
                    >
                      <ToggleButton value={AuthType.Google}>Google</ToggleButton>
                      <ToggleButton value={AuthType.Email}>Email</ToggleButton>
                      <ToggleButton value={AuthType.Clever}>Clever</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={authType === AuthType.Email ? 6 : 12}>
                {newStaff && (
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={staffForm.email}
                    name="email"
                    onChange={handleTextChange}
                  />
                )}
              </Grid>
              {newStaff && authType === AuthType.Email && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Temporary Password"
                    variant="outlined"
                    value={password}
                    name="password"
                    type={passwordVisibility ? "text" : "password"}
                    onChange={handlePasswordChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            edge="end"
                          >
                            {passwordVisibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="groups-to-staff"
                  options={groups}
                  disableCloseOnSelect
                  onChange={handleGroupSelect}
                  fullWidth
                  value={selectedGroups}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Assigned Groups"
                      placeholder="Assigned Groups"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave} disabled={!valid}>
              Save
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
            {!newStaff && (
              <Button color="error" onClick={handleUnenroll}>
                Unenroll
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ManageStaffDialog;
