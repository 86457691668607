import React, { useEffect, useState } from "react";
import { Provider_Program } from "../../types/types";
import ProgramCard from "../programCard/ProgramCard";
import { Box, Button, MobileStepper } from "@mui/material";
import { SelectedProgramSource } from "../../types/enums";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const Programs = () => {
  const [programList, setProgramList] = useState<Provider_Program[]>([]);

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = programList.length;

  useEffect(() => {
    const getPrograms = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getProgramsByIndustry`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ industryId: "1001" }),
        }
      );
      const data = await response.json();
      if (!data.rows) return;
      setProgramList(data.rows);
    };
    getPrograms();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
        <Box sx={{ maxWidth: 800, flexGrow: 1 }}>
          <Box sx={{ maxWidth: 800, width: "100%", p: 2 }}>
            <ProgramCard program={programList[activeStep]} source={SelectedProgramSource.Explore} />
          </Box>
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default Programs;
