export function estimateACTScoreFromGPA(gpa: number) {
  // Find the closest GPA in the conversion table
  const conversionTable: { [key: number]: number } = {
    4.0: 34,
    3.9: 33,
    3.8: 32,
    3.7: 30,
    3.6: 29,
    3.5: 28,
    3.4: 27,
    3.3: 26,
    3.2: 25,
    3.1: 24,
    3.0: 23,
    2.9: 22,
    2.8: 21,
    2.7: 20,
    2.6: 19,
    2.5: 18,
    2.4: 17,
    2.3: 16,
    2.2: 15,
    2.1: 14,
    2.0: 13,
  };

  let closestGPA = 4.0;
  for (const gpaKey in conversionTable) {
    const key = parseFloat(gpaKey);
    if (Math.abs(gpa * 4 - key) < Math.abs(gpa * 4 - closestGPA)) {
      closestGPA = parseFloat(gpaKey);
    }
  }

  return conversionTable[closestGPA];
}

type GetProviderTargetLevelProps = {
  providerId: string;
  GPA: number;
  admissionPercent: number | null;
};

//GPA must be a decimal between 0 and 1
export const getProviderTargetLevel = async ({
  providerId,
  GPA,
  admissionPercent,
}: GetProviderTargetLevelProps) => {
  const actRange = await fetch(
    process.env.REACT_APP_CLOUD_FUNCTION_URI + `/scorecard/getACTRange`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ providerId }),
    }
  );
  if (!actRange.ok && admissionPercent) {
    return determineSchoolType(admissionPercent, GPA);
  } else if (!actRange.ok) {
    return "Unknown";
  }
  const actRangeData = await actRange.json();
  if (!actRangeData) return "Unknown";
  const { percentile25, percentile75 } = actRangeData;
  const actScore = estimateACTScoreFromGPA(GPA);
  if (percentile25 - actScore > 2) {
    return "Out of Reach";
  } else if (actScore < percentile25) {
    return "Reach";
  } else if (actScore >= percentile25 && actScore <= percentile75) {
    return "Target";
  } else if (actScore > percentile75) {
    return "Safety";
  } else {
    return "Unknown";
  }
};

export const determineSchoolType = (admissionPercent: number, GPA: number): string => {
  if (GPA < 0.5) {
    if (admissionPercent >= 0.9) return "Safety";
    if (admissionPercent >= 0.75) return "Target";
    if (admissionPercent >= 0.5) return "Reach";
    return "Out of Reach";
  } else if (GPA < 0.625) {
    if (admissionPercent >= 0.8) return "Safety";
    if (admissionPercent >= 0.6) return "Target";
    if (admissionPercent >= 0.4) return "Reach";
    return "Out of Reach";
  } else if (GPA < 0.75) {
    if (admissionPercent >= 0.75) return "Safety";
    if (admissionPercent >= 0.4) return "Target";
    if (admissionPercent >= 0.25) return "Reach";
    return "Out of Reach";
  } else if (GPA < 0.875) {
    if (admissionPercent >= 0.6) return "Safety";
    if (admissionPercent >= 0.35) return "Target";
    if (admissionPercent >= 0.15) return "Reach";
    return "Out of Reach";
  } else if (GPA < 0.9375) {
    if (admissionPercent >= 0.6) return "Safety";
    if (admissionPercent >= 0.3) return "Target";
    if (admissionPercent >= 0.1) return "Reach";
    return "Out of Reach";
  } else {
    if (admissionPercent >= 0.6) return "Safety";
    if (admissionPercent >= 0.2) return "Target";
    if (admissionPercent >= 0.0) return "Reach";
    return "Unknown";
  }
};
