import { Alert, Box, Button, FormLabel, TextField } from "@mui/material";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { validatePassword } from "../../../shared/utils/functions";
import { useAuth } from "../../../shared/contexts/AuthContext";
import { loggedInProviderAtom } from "../../../shared/recoil/userAtoms";
import { useSetRecoilState } from "recoil";
import { BLANK_PROVIDERUSER_FORM } from "../../../shared/libraries/blankForms";
import useFetch from "../../../shared/hooks/useFetch";
import { ProviderUserRecord } from "../../../shared/types/types";
import { Collection } from "../../../shared/types/enums";
import useSetDoc from "../../../shared/hooks/useSetDoc";

type Props = {
  email: string;
};

const ProviderCreateAuthAccount = ({ email }: Props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<string | null>(null);
  const { createEmailUser } = useAuth();
  const { getFromAPI } = useFetch();
  const { sendRequest: setDoc } = useSetDoc();

  const setloggedInProvider = useSetRecoilState(loggedInProviderAtom);
  const [userExistsError, setUserExistsError] = useState<string | null>(null);
  const [touched, setTouched] = useState({
    password: false,
    confirmPassword: false,
  });

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value.trim());
    setTouched((pV) => ({ ...pV, password: true }));
    const valid = validatePassword(event.target.value.trim());
    if (!valid) {
      setPasswordError(
        "Password must contain at least one number, one uppercase letter, one lowercase letter, and be at least 8 characters long."
      );
    } else {
      setPasswordError(null);
    }
  };

  const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value.trim());
    setTouched((pV) => ({ ...pV, confirmPassword: true }));
    if (event.target.value.trim() !== password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError(null);
    }
  };

  const handleCreateAuthUser = async (event: FormEvent) => {
    const result = (await createEmailUser({ email, password })) as any;
    if (result) {
      setUserExistsError(result);
    } else {
      setUserExistsError(null);
    }
    const results = await getFromAPI({ url: "/auth/getUID", body: { email } });
    await setDoc<ProviderUserRecord>({
      col: Collection.ProviderUsers,
      data: {
        ...BLANK_PROVIDERUSER_FORM,
      },
      id: results.uid,
    });
    setloggedInProvider({
      ...BLANK_PROVIDERUSER_FORM,
      id: results.uid,
      createdAt: "",
      lastUpdatedAt: "",
    });
  };

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <FormLabel sx={{ mt: 2 }} id="email-label">
          Create a Password
        </FormLabel>
        <TextField
          name="password"
          autoComplete="newpassword"
          required
          id="password"
          type="password"
          value={password}
          fullWidth
          variant="outlined"
          onChange={handlePasswordChange}
        />
        {passwordError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {passwordError}
          </Alert>
        )}
        <FormLabel sx={{ mt: 2 }} id="email-label">
          Confirm Password
        </FormLabel>
        <TextField
          name="confirmPassword"
          autoComplete="newpassword"
          required
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          fullWidth
          variant="outlined"
          onChange={handleConfirmPasswordChange}
        />
        {confirmPasswordError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {confirmPasswordError}
          </Alert>
        )}

        <Button
          variant="contained"
          onClick={handleCreateAuthUser}
          sx={{ mt: 2 }}
          fullWidth
          disabled={
            Boolean(passwordError) ||
            Boolean(confirmPasswordError) ||
            !touched.password ||
            !touched.confirmPassword
          }
        >
          Next
        </Button>
        {userExistsError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {userExistsError}
          </Alert>
        )}
      </Box>
    </>
  );
};

export default ProviderCreateAuthAccount;
