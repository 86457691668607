import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import StudentCard from "./StudentCard";
import MyWhy from "./MyWhy";
import StudentStats from "./StudentStats";
import StudentHollandResults from "./StudentHollandResults";
import Preferences from "./Preferences";
import MatchedPrograms from "./MatchedPrograms";
import StageProgressBar from "./StageProgressBar";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { allAnswersAtom, studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import CareerPathways from "./CareerPathways";
import ApplicationTracker from "./ApplicationTracker";
import { Stage } from "../../../shared/types/enums";
import RecommendedPrograms from "./RecommendedPrograms";
import { Provider_Program } from "../../../shared/types/types";
import ProgramDetailsDialog from "../../../shared/components/programDetails/ProgramDetailsDialog";

const StudentOverviewContainer = () => {
  const [selectedProgram, setSelectedProgram] = useState<Provider_Program | null>(null);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const setStudentAnswers = useSetRecoilState(studentAnswersAtom);
  const allAnswers = useRecoilValue(allAnswersAtom);
  useEffect(() => {
    setStudentAnswers(allAnswers.filter((answer) => answer.studentId === selectedStudent?.id));
  }, [allAnswers, selectedStudent?.id, setStudentAnswers]);

  return (
    <>
      {selectedStudent && selectedStudent.quizComplete ? (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={5}>
            <StudentCard />
            <MyWhy />
            <CareerPathways />
            <StudentStats />
            <Preferences />
          </Grid>
          <Grid item xs={12} md={7}>
            <StageProgressBar stageId={selectedStudent?.stageId} />

            {selectedStudent?.stageId === Stage.Stage3 && (
              <Box sx={{ height: 450, overflow: "scroll", mt: 2 }}>
                <ApplicationTracker setSelectedProgram={setSelectedProgram} />
              </Box>
            )}
            {selectedStudent?.stageId === Stage.Stage2 && (
              <>
                <RecommendedPrograms setSelectedProgram={setSelectedProgram} />
                <MatchedPrograms setSelectedProgram={setSelectedProgram} />
              </>
            )}
            <Box sx={{ mt: 2 }}>
              <StudentHollandResults />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Typography variant="h4" textAlign={"center"}>
            {selectedStudent?.firstName} has not finished their quiz yet.
          </Typography>
        </Box>
      )}
      {selectedProgram && (
        <ProgramDetailsDialog
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
        />
      )}
    </>
  );
};

export default StudentOverviewContainer;
