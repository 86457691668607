import React from "react";
import { CssBaseline, Grid } from "@mui/material";
import StudentPanel from "./StudentPanel";
import MessagePanel from "./MessagePanel";

function MessagesContainer() {
  return (
    <>
      <CssBaseline />
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <StudentPanel />
        </Grid>
        <Grid item xs={9}>
          <MessagePanel />
        </Grid>
      </Grid>
    </>
  );
}

export default MessagesContainer;
