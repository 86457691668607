import { User } from "firebase/auth";
import { createContext, useContext } from "react";

type EmailPasswordProps = { email: string; password: string };

type AuthContextType = {
  loading: boolean;
  currentAuthUser: User | null;
  logout: () => void;
  createEmailUser: ({ email, password }: EmailPasswordProps) => Promise<string | null> | void;
  signInWithGoogle: () => void;
  signInWithClever: () => void;
  emailSignIn: ({ email, password }: EmailPasswordProps) => void;
  loginError: null | string;
  resetPassword: (email: string) => void;
  setLoginError: (error: string | null) => void;
};

const defaultValue: AuthContextType = {
  currentAuthUser: null,
  logout: () => {},
  loading: false,
  signInWithGoogle: async () => {
    return await { user: null, error: null };
  },
  signInWithClever: () => {},
  createEmailUser: () => {},
  emailSignIn: () => {},
  loginError: null,
  resetPassword: () => {},
  setLoginError: () => {},
};
export const AuthContext = createContext<AuthContextType>(defaultValue);

export const useAuth = () => {
  return useContext(AuthContext);
};
