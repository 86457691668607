import React, { ChangeEvent, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { studentRowsAtom } from "../../../shared/recoil/counselorDashboardAtoms";
import { putMessageInRTDB, sendSMS } from "../../../shared/utils/messageFunctions";
import { BLANK_MESSAGE_FORM } from "../../../shared/libraries/blankForms";
import { loggedInStaffAtom } from "../../../shared/recoil/userAtoms";
import { verifyPhoneNumber } from "../../../shared/utils/functions";

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const useMessageStudentsDialog = ({ setOpen }: Props) => {
  const students = useRecoilValue(studentRowsAtom);
  const [studentNamesWithBadNumbers, setStudentNamesWithBadNumbers] = useState<string[]>([]);
  const [message, setMessage] = useState({ ...BLANK_MESSAGE_FORM });
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  useEffect(() => {
    if (students) {
      const badNumbers = students
        .filter((student) => !verifyPhoneNumber(student.phone))
        .map((student) => student.firstName + " " + student.lastName);
      setStudentNamesWithBadNumbers(badNumbers);
    }
  }, [students]);

  const handleClose = () => {
    setOpen(false);
    setMessage({ ...BLANK_MESSAGE_FORM });
  };
  const handleSend = () => {
    if (!students || !loggedInStaff) return;
    students
      .filter((student) => verifyPhoneNumber(student.phone))
      .forEach((student) => {
        sendSMS({
          to: student.phone,
          body: message.message,
          staffId: loggedInStaff.id,
          studentId: student?.id,
          studentPhoneNumber: student?.phone,
          authorId: loggedInStaff.id,
          staffPhoneNumber: loggedInStaff.smsNumber,
        });

        putMessageInRTDB({
          message,
          staffId: loggedInStaff.id,
          studentId: student?.id,
          studentPhoneNumber: student?.phone,
          authorId: loggedInStaff.id,
          staffPhoneNumber: loggedInStaff.smsNumber,
        });
      });
    setMessage({ ...BLANK_MESSAGE_FORM });
    setOpen(false);
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage({ ...message, message: event.target.value });
  };
  return {
    students,
    studentNamesWithBadNumbers,
    message,
    setMessage,
    handleClose,
    handleSend,
    handleTextChange,
  };
};

export default useMessageStudentsDialog;
