import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Provider } from "../../types/types";
import Loading from "../Loading";
import EditProviderForm from "./EditProviderForm";
import useEditProviderDialog from "../../hooks/providerDetails/useEditProviderDialog";

type Props = {
  provider: Provider;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setGetProviderTrigger: Dispatch<SetStateAction<boolean>>;
};

const EditProviderDialog = ({ provider, open, setOpen, setGetProviderTrigger }: Props) => {
  const { handleSave, form, setForm, loading } = useEditProviderDialog({ provider });
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickSave = async () => {
    await handleSave();
    setOpen(false);
    setGetProviderTrigger((prev) => !prev);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ color: "black" }}>{`Edit ${provider.providerName} Provider`}</DialogTitle>
      <DialogContent sx={{ minHeight: 470 }}>
        {loading ? <Loading size={80} /> : <EditProviderForm form={form} setForm={setForm} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleClickSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProviderDialog;
