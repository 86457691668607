import { Box, Button, Typography } from "@mui/material";
import React, { SyntheticEvent, useState } from "react";
import Papa from "papaparse";
import { Review, ReviewRecord } from "../../../shared/types/types";
import { Collection } from "../../../shared/types/enums";
import useSetDoc from "../../../shared/hooks/useSetDoc";

type UploadDictionary = {
  [key in Column]: string;
};

enum Column {
  rating = "rating",
  grade = "grade",
  source = "source",
  reviewOne = "reviewOne",
  reviewTwo = "reviewTwo",
  reviewThree = "reviewThree",
  reviewFour = "reviewFour",
  providerId = "providerId",
}

type FormState = EventTarget & {
  name: string;
  value: string;
  files: File[];
};

const UploadReviews = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [loading, setLoading] = useState(false);
  const [last, setLast] = useState(false);
  const { sendRequest: setDoc } = useSetDoc();

  const changeHandler = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSelectedFile(formState.files[0]);
  };

  const parseNumber = (value: string) => {
    const parsed = parseInt(value);
    return isNaN(parsed) ? null : parsed;
  };

  const fromWebsite = async (value: UploadDictionary) => {
    const record = {
      rating: parseNumber(value[Column.rating]),
      grade: value[Column.grade],
      source: value[Column.source],
      reviewOne: value[Column.reviewOne],
      reviewTwo: value[Column.reviewTwo],
      reviewThree: value[Column.reviewThree],
      reviewFour: value[Column.reviewFour],
    };
    console.log(String(value[Column.providerId]));
    const results = await setDoc<ReviewRecord>({
      col: Collection.Reviews,
      data: record,
      id: value[Column.providerId],
    });

    return results?.id ?? "";
  };

  const handleSubmission = async () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      const promises: Array<Promise<string | null | undefined>> = [];
      setLoading(true);
      Papa.parse<UploadDictionary>(selectedFile, {
        complete: function (results) {
          const length = results.data.length;
          results.data.forEach((value, index) => {
            if (length === index + 1) {
              setLast(true);
            }
            const resultId = fromWebsite(value);
            promises.push(resultId);
          });
        },
        header: true,
      });
      await Promise.all(promises);
      setLoading(false);
    }
  };
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ mt: 3 }}>
          <input
            id="uploadReviewFile"
            type="file"
            name="file"
            style={{ display: "none" }}
            onChange={changeHandler}
          />
          <label htmlFor="uploadReviewFile">
            {" "}
            <Button variant="contained" sx={{ mt: 3 }} component="span">
              Upload Reviews
            </Button>
          </label>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 3, ml: 2 }}
            onClick={handleSubmission}
          >
            Submit
          </Button>
          {selectedFile && <Typography>{selectedFile.name}</Typography>}
        </Box>
      </Box>
    </>
  );
};

export default UploadReviews;
