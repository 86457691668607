import React from "react";
import { useRecoilValue } from "recoil";
import { studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import { Box, Divider, Typography } from "@mui/material";

const StatsDetails = () => {
  const answers = useRecoilValue(studentAnswersAtom);

  const formatGPA = () => {
    if (!answers) return;
    const answer = answers.find((answer) => answer.questionId === "gpa")?.gpa ?? [];
    return `${answer[0]} out of ${answer[1]}`;
  };

  const formatSAT = () => {
    if (!answers) return;
    const satAnswer = answers.find((answer) => answer.questionId === "sat")?.number ?? 0;
    return satAnswer > 0 ? satAnswer : "None Provided";
  };

  const formatACT = () => {
    if (!answers) return;
    const actAnswer = answers.find((answer) => answer.questionId === "act")?.number ?? 0;
    return actAnswer > 0 ? actAnswer : "None Provided";
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
              GPA:
            </Typography>
            <Typography component="span" variant="body1" sx={{ ml: 1 }}>
              {formatGPA()}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
              SAT:
            </Typography>
            <Typography component="span" variant="body1" sx={{ ml: 1 }}>
              {formatSAT()}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
              ACT:
            </Typography>
            <Typography component="span" variant="body1" sx={{ ml: 1 }}>
              {formatACT()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StatsDetails;
