import { createContext, useContext } from "react";

type ErrorContextType = {
  errorMessage: string | null;
  setErrorMessage: (message: string | null) => void;
};

const defaultValue: ErrorContextType = {
  errorMessage: null,
  setErrorMessage: () => {}, // Default no-op function
};
export const ErrorContext = createContext<ErrorContextType>(defaultValue);

export const useErrorContext = () => {
  return useContext(ErrorContext);
};
