import { ChangeEvent, Dispatch, useCallback, useEffect, MouseEvent, useState } from "react";
import { GroupRecord, Staff, StaffRecord } from "../../../shared/types/types";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { staffAtom } from "../../../shared/recoil/userAtoms";
import {
  groupsAtom,
  selectedDistrictAtom,
  selectedSchoolAtom,
} from "../../../shared/recoil/districtAtoms";
import { SelectChangeEvent } from "@mui/material";
import { AuthType, Collection } from "../../../shared/types/enums";
import useFetch from "../../../shared/hooks/useFetch";
import useSetDoc from "../../../shared/hooks/useSetDoc";

type Props = {
  staffForm: StaffRecord;
  setStaffForm: Dispatch<React.SetStateAction<StaffRecord>>;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const generatePassword = () => {
  const length = 8;
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

const useManageStaffDialog = ({ staffForm, setStaffForm, open, setOpen }: Props) => {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: setDoc } = useSetDoc();
  const setStaff = useSetRecoilState(staffAtom);
  const selectedSchool = useRecoilValue(selectedSchoolAtom);
  const selectedDistrict = useRecoilValue(selectedDistrictAtom);
  const [newStaff, setNewStaff] = useState(staffForm.id === "" ? true : false);
  const [groups, setGroups] = useRecoilState(groupsAtom);
  const [selectedGroups, setSelectedGroups] = useState<GroupRecord[]>([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [authType, setAuthType] = useState(AuthType.Email);
  const { getFromAPI } = useFetch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!groups || !open) return;
    setNewStaff(staffForm.id === "" ? true : false);
    if (staffForm.id === "") {
      setSelectedGroups([]);
    } else {
      setSelectedGroups(groups.filter((group) => group.staffIds.includes(staffForm.id)));
    }
  }, [open, groups, staffForm.id]);

  const handleGroupSelect = useCallback((event: any, values: GroupRecord[]) => {
    const currentSelectedGroups: GroupRecord[] = [];
    const currentSelectedGroupIds: string[] = [];
    values.forEach((s) => {
      currentSelectedGroups.push(s);
      currentSelectedGroupIds.push(s.id);
    });
    setSelectedGroups(currentSelectedGroups);
    setSelectedGroupIds(currentSelectedGroupIds);
  }, []);

  const handleTextChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    const trimValue =
      name === "firstName" || name === "lastName" || name === "email" ? value : value;
    setStaffForm((pV) => {
      return { ...pV, [name as keyof Staff]: trimValue };
    });
  };

  const handleClose = () => {
    setAuthType(AuthType.Email);
    setOpen(false);
  };

  const handleUnenroll = async () => {
    const promises = [];
    setLoading(true);
    promises.push(
      updateDoc({ col: Collection.Staff, id: staffForm.id, data: { enrolled: false, active: false } })
    );
    const groupsWithStaff = groups.filter((group) => group.staffIds.includes(staffForm.id));
    groupsWithStaff.forEach((group) => {
      const newStaffIds = group.staffIds.filter((id) => id !== staffForm.id);
      promises.push(
        updateDoc({
          col: Collection.Groups,
          id: group.id,
          data: { staffIds: newStaffIds },
        })
      );
    });
    //update each group that had the staff member in it to no longer have that staff member in the recoil state
    setGroups((pV) =>
      pV.map((group) =>
        groupsWithStaff.includes(group)
          ? { ...group, staffIds: group.staffIds.filter((id) => id !== staffForm.id) }
          : group
      )
    );
    setStaff((pV) =>
      pV.map((staff) => {
        if (staff.id === staffForm.id) {
          return { ...staff, enrolled: false };
        } else {
          return staff;
        }
      })
    );
    await Promise.all(promises);
    setLoading(false);
    setOpen(false);
  };

  const createEmailUser = async (email: string, password: string) => {
    const url = `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/auth/createUser`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }
      return data;
    } catch (error: any) {
      console.error("Error creating User:", error.message);
    }
  };

  const getTwilioNumber = () => {
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "willowed-dev") {
      return "18667682163";
    }
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "willow-production-428ab") {
      return "18667906413";
    }
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "willow-demo-d3c85") {
      return "18446091950";
    }
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "willowed-qa") {
      return "18556484270";
    }
    return "18667906413";
  };

  const handleSave = async () => {
    if (!selectedSchool || !selectedDistrict) return;
    setOpen(false);
    let formId = staffForm.id;
    if (staffForm.id === "") {
      const passwordToSubmit = authType === AuthType.Email ? password : generatePassword();
      await createEmailUser(staffForm.email, passwordToSubmit);
      const results = await getFromAPI({ url: "/auth/getUID", body: { email: staffForm.email } });
      if (!results || !results.uid) return;
      const response = await setDoc<StaffRecord>({
        col: "staff",
        data: {
          ...staffForm,
          schoolId: selectedSchool.id,
          districtId: selectedDistrict.id,
          smsNumber: getTwilioNumber(),
        },
        id: results.uid,
      });
      if (!response) return;
      formId = response.id;
      setStaff((pV) => {
        const newArray = [
          ...pV,
          {
            ...staffForm,
            id: response.id,
            createdAt: response.createdAt,
            lastUpdatedAt: response.lastUpdatedAt,
            schoolId: selectedSchool.id,
            districtId: selectedDistrict.id,
            smsNumber: getTwilioNumber(),
          },
        ];
        newArray.sort((a, b) => {
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;
          return 0;
        });
        return newArray;
      });
    } else {
      updateDoc({ col: Collection.Staff, id: staffForm.id, data: staffForm });
      setStaff((pV) => {
        const newArray = pV.map((staff) => {
          if (staff.id === staffForm.id) {
            return { ...staff, ...staffForm };
          } else {
            return staff;
          }
        });
        newArray.sort((a, b) => {
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;
          return 0;
        });
        return newArray;
      });
    }

    const selectedGroupIdsSet = new Set(selectedGroupIds);

    const updatedGroups = groups.map((group) => {
      const isInSelectedValues = selectedGroupIdsSet.has(group.id);
      const hasStaffId = group.staffIds.includes(formId);
      let newStaffIds = group.staffIds;

      if (isInSelectedValues && !hasStaffId) {
        newStaffIds = [...group.staffIds, formId];
      } else if (!isInSelectedValues && hasStaffId) {
        newStaffIds = group.staffIds.filter((id) => id !== formId);
      }

      if (newStaffIds !== group.staffIds) {
        updateDoc({
          col: "groups",
          id: group.id,
          data: {
            staffIds: newStaffIds,
          },
        });
      }

      return newStaffIds !== group.staffIds ? { ...group, staffIds: newStaffIds } : group;
    });

    setGroups(updatedGroups);
  };

  const handleUserTypeChange = (event: SelectChangeEvent) => {
    setStaffForm((pV) => {
      return { ...pV, userType: parseInt(event.target.value) };
    });
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleShowPassword = () => {
    setPasswordVisibility((pV) => !pV);
  };

  const handleAuthChange = (event: MouseEvent<HTMLElement>, authValue: string) => {
    setAuthType(authValue as AuthType);
  };
  return {
    handleTextChange,
    handleClose,
    handleSave,
    handleUnenroll,
    newStaff,
    groups,
    selectedGroups,
    handleGroupSelect,
    password,
    handleUserTypeChange,
    handlePasswordChange,
    passwordVisibility,
    handleShowPassword,
    authType,
    handleAuthChange,
    loading,
  };
};

export default useManageStaffDialog;
