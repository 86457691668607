import React from "react";
import useGetDocs from "../../../shared/hooks/useGetDocs";
import useSetDoc from "../../../shared/hooks/useSetDoc";
import { Collection } from "../../../shared/types/enums";
import { CIPRecord } from "../../../shared/types/types";
import { Button } from "@mui/material";

const UpdateCIPCodeCollection = () => {
  const { sendRequest: getDocs } = useGetDocs();
  const { sendRequest: setDoc } = useSetDoc();
  const [loading, setLoading] = React.useState(false);

  const updateCIPCodes = async () => {
    setLoading(true);
    const cipCodes = await getDocs<CIPRecord>({ col: Collection.CIP });
    const promises: any[] = [];
    cipCodes.forEach((cipCode) => {
      promises.push(setDoc({ col: Collection.cipCodes, data: cipCode, id: cipCode.cipCode }));
    });
    await Promise.all(promises);
    setLoading(false);
  };
  return (
    <div>
      {loading && <p>Loading...</p>}
      <Button variant="contained" sx={{ mt: 2 }} onClick={updateCIPCodes}>
        Get CIP Codes
      </Button>
    </div>
  );
};

export default UpdateCIPCodeCollection;
