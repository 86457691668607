export enum UserType {
  Student = 1,
  Staff = 2,
  SchoolAdmin = 3,
  DistrictAdmin = 4,
  Developer = 5,
  ProviderUser = 7,
  MentorUser = 8,
}

//Microsoft not currently integrated
export enum AuthType {
  Google = "Google",
  Email = "Email",
  Clever = "Clever",
  Microsoft = "Microsoft",
}

export enum Color {
  Charcoal = "#171918",
  Chalkboard = "#00362E",
  Mint = "#1DBC9D",
  Chalk = "#F4F0DC",
  White = "#FFFFFF",
  LightGray = "#F5F5F5",
  Black = "#000000",
  Electric_Blue = "#00A3FF",
  Standard_Gray = "#949D99",
  Surface_Green = "#EDFCF9",
}

export enum ProgramTypeColor {
  Certificate = "#FFDBF1",
  Bachelor = "#EBF8FF",
  Associates = "#EDFCF9",
  Apprenticeship = "#F4F0FF",
  Training = "#FFEBDB",
}

export enum PageRoute {
  Staff_Dashboard = "/staff-dashboard",
  Messages = "/staff-messages",
  Students_By_Program = "/students-by-program",
  Simulator = "/simulator",
  Login = "/login",
  Signup = "/signup",
  Student_Dashboard = "/student-dashboard",
  District_Settings = "/district-settings",
  School_Settings = "/school-settings",
  Student_Profile = "/student-profile",
  Student_Stages = "/student-stages",
  SQL = "/sql",
  Take_Quiz = "/take-quiz",
  Transfer_Uploads = "/transfer-uploads",
  Manage_Programs = "/manage-programs",
  Explore_Programs = "/explore-programs",
  Batch_Update_Programs = "/batch-update-programs",
  Alma = "/alma",
  Program_Details = "/program-details",
  Clever_Auth = "/auth/clever",
  Generate_Program_Description = "/generate-program-description",
  Developer_Settings = "/developer-settings",
  Congrats = "/congrats",
  Provider_Details = "/provider-details",
  Explore_Careers = "/explore-careers",
  Program_DB_Management = "/program-db-management",
  Student_Onboarding = "/student-onboarding",
  Provider_Form = "/provider-form",
  Provider_Dashboard = "/provider-dashboard",
  Provider_Profile = "/provider-profile",
  Demo_Login = "/demo-login",
  Staff_Alma = "/staff-alma",
  Provider_Login = "/provider-login",
  Provider_Signup = "/provider-signup",
  Mentor_Login = "/mentor-login",
  Mentor_Dashboard = "/mentor-dashboard",
  Student_Dashboard_2 = "/student-dashboard-2",
}

export enum Collection {
  Answers = "answers",
  Districts = "districts",
  Groups = "groups",
  OldProviders = "oldProviders",
  Providers = "providers",
  Questions = "questions",
  RemovedMatches = "removedMatches",
  Schools = "schools",
  Staff = "staff",
  ProviderUsers = "providerUsers",
  Students = "students",
  StageTasks = "stageTasks",
  Stages = "stages",
  StudentInteractions = "studentInteractions",
  Programs = "programs",
  ProviderPrograms = "providerPrograms",
  CIP = "cip",
  WillowIndustries = "willowIndustries",
  WillowSubIndustries = "willowSubIndustries",
  SOC_WillowIndustries = "soc_willowIndustries",
  Careers = "careers",
  ApplicationData = "applicationData",
  WriteInPrograms = "writeInPrograms",
  Reviews = "reviews",
  SOC_CIP = "soc_cip",
  AlmaChats = "almaChats",
  cipCodes = "cipCodes",
  StaffAlmaChats = "staffAlmaChats",
  MentorUsers = "mentorUsers",
}

export enum GradeLevel {
  NINTH = "9th Grade",
  TENTH = "10th Grade",
  ELEVENTH = "11th Grade",
  TWELFTH = "12th Grade",
}

export enum RemoveReason {
  Too_Long = "It's too long",
  Too_Expensive = "It's too expensive",
  To_Far = "It's too far away",
  Not_Interested = "I'm not interested in this type of program",
  Admissions = "I'm worried I won't get in",
  Better_Reputation = "I'm looking for a program with a better reputation",
  Other = "Other",
}

export enum LoginStage {
  Auth = 1,
  Name = 2,
  School = 3,
  Birthday = 4,
  GraduationYear = 5,
  Cell = 6,
  Terms = 7,
}

export enum Distance {
  FiftyMiles = "withinFiftyMiles",
  TenMiles = "withinTenMiles",
  InState = "anywhereInState",
  InCountry = "anywhereInCountry",
  InCity = "anywhereInCity",
}

export enum FinancialAid {
  PellGrant = "pellGrantEligible",
  FRL = "onFRL",
  NotEligible = "notEligibleForAid",
  NotSure = "notSure",
}

export enum CertificateAnswer {
  CollegeOnly = "collegeOnly",
  LeaningCollege = "leaningCollege",
  Neutral = "neutral",
  LeaningProfessional = "leaningProfessional",
  ProfessionalOnly = "professionalOnly",
}

export enum OnlineAnswer {
  Yes = 1699731348731,
  No = 1699731350905,
}

export enum PathwayHeader {
  MostInterested = 1699731988057,
  Interested = 1699731991049,
  NotInterested = 1699731994383,
}

export enum WillowIndustryId {
  Arts = "arts",
  Business = "business",
  SkilledTrade = "skilledTrade",
  SocialServices = "socialServices",
  Sciences = "sciences",
}

export enum QuestionType {
  MultipleChoice = "Multiple Choice",
  OpenResponse = "Open Response",
  MultipleSelect = "Multiple Select",
  Matrix = "Matrix",
  GraduationYear = "Graduation Year",
  GPA = "GPA",
  Number = "Number",
  Message = "Message",
  Geography = "Geography",
}

export enum HollandType {
  Realistic = "Realistic",
  Investigative = "Investigative",
  Artistic = "Artistic",
  Social = "Social",
  Enterprising = "Enterprising",
  Conventional = "Conventional",
}

export enum CredentialType {
  Certificate = "Certificate",
  Bachelor = "Bachelor's Degree",
  Associates = "Associate's Degree",
  Apprenticeship = "Apprenticeship",
  Credential = "Credential",
  Skill = "Skill",
  UndergradCert = "Undergraduate Certificate or Diploma",
}

export enum WillowSubIndustry {
  ArtistsAndDesigners = "1001",
  EntertainersAndPerformers = "1002",
  MediaAndCommunications = "1003",
  MediaAndCommunicationsEquipmentWorker = "1004",
  Manager = "2001",
  Financials = "2002",
  Legal = "2003",
  SalesAndAdvertising = "2004",
  OfficeWorks = "2005",
  FoodService = "3001",
  MaintenanceWorker = "3002",
  BuildingDesignAndEngineering = "3003",
  AgricultureAndFarming = "3004",
  ConstructionWorker = "3005",
  Manufacturing = "3006",
  Transportation = "3007",
  ProtectiveServices = "4001",
  SupportServices = "4002",
  Teacher = "4003",
  Healthcare = "4004",
  ComputerOccupations = "5001",
  MathematicalScienceOccupations = "5002",
  LifeSciences = "5003",
  PhysicalSciences = "5004",
  SocialScientist = "5005",
  ScienceTechnitian = "5006",
}

export enum SubIndustryDBName {
  Arts = "liberal_arts_jobs",
  Business = "business_jobs",
  SkilledTrade = "trade_jobs",
  SocialServices = "healthcare_jobs",
  Sciences = "tech_jobs",
}

export enum AppStatus {
  NotStarted = "Not Started",
  InProgress = "In Progress",
  Completed = "Completed",
  NoApply = "Will Not Apply",
}

export enum AppResult {
  NoResponse = "No Response",
  Declined = "Declined",
  Accepted = "Accepted",
  Waitlisted = "Waitlisted",
}

export enum Stage {
  Stage1 = "stageOne",
  Stage2 = "stageTwo",
  Stage3 = "stageThree",
  Stage4 = "stageFour",
  Stage5 = "stageFive",
}

export enum SelectedProgramSource {
  Recommended = "Recommended",
  Matched = "Matched",
  Explore = "Explore",
}

export enum Region {
  New_England = "New England",
  Mid_East = "Mid East",
  Great_Lakes = "Great Lakes",
  Mid_West = "Mid West",
  South = "South",
  South_West = "South West",
  Rocky_Mountains = "Rocky Mountains",
  Far_West = "Far West",
  Outlying = "Outlying Areas",
}

export enum Locale {
  Medium_City = "Medium Size City",
  Small_City = "Small City",
  Small_Town = "Small Town",
  Rural = "Rural",
  Suburban_Area = "Suburban Area",
  Large_City = "Large City",
}

export enum Modality {
  AllInPerson = "All In Person",
  Mix = "A Mix of In Person and Online",
  SomeInPerson = "Can all be done online, but some in person options are available",
  Unknown = "Unknown",
  AllOnline = "All Online",
}

export enum Commitment {
  FullTime = "Full-time",
  PartTime = "Part-time",
  Both = "Full-time or part-time",
  Unknown = "Unknown",
}
