import { Box, Tooltip, Typography } from "@mui/material";
import useStageSummaryBar from "../../hooks/dashboard/useStageSummaryBar";
import { Color } from "../../../shared/types/enums";

type Props = {
  stageId: string;
};

const StageSummaryBar = ({ stageId }: Props) => {
  const { filteredStudents, height } = useStageSummaryBar({
    stageId,
  });

  const StudentList = (name: string, i: number) => {
    return (
      <Typography key={name + i} sx={{ fontSize: 9 }}>
        {name}
      </Typography>
    );
  };

  return (
    <Tooltip
      title={filteredStudents.map((student, i) =>
        StudentList(`${student.firstName} ${student.lastName}`, i)
      )}
    >
      <Box
        sx={{
          backgroundColor: Color.Electric_Blue,
          height: height,
          width: "100%",
          display: "flex",
          margin: "0 auto",
          position: "relative",
          minHeight: filteredStudents.length > 0 ? 14 : 0,
        }}
      >
        {filteredStudents.length > 0 && (
          <Typography
            sx={{
              color: "#fff",
              fontSize: 12,
              textAlign: "center",
              width: "100%",
              position: "absolute",
              bottom: 1,
              lineHeight: 1,
            }}
            variant="body1"
          >
            {filteredStudents.length}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};

export default StageSummaryBar;
