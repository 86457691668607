import { Divider, Typography } from "@mui/material";
import React, { Suspense, useState } from "react";
import SchoolsTable from "./SchoolsTable";
import StaffTable from "./StaffTable";
import { UserType } from "../../../shared/types/enums";
import ManageSchoolDialog from "./ManageSchoolDialog";
import { BLANK_SCHOOL_FORM, BLANK_STAFF_FORM } from "../../../shared/libraries/blankForms";
import { SchoolRecord, StaffRecord } from "../../../shared/types/types";
import ManageStaffDialog from "./ManageStaffDialog";

const DistrictSettingsContainer = () => {
  const [manageSchoolOpen, setManageSchoolOpen] = useState(false);
  const [manageStaffOpen, setManageStaffOpen] = useState(false);
  const [staffForm, setStaffForm] = useState<StaffRecord>({ ...BLANK_STAFF_FORM });
  const [schoolForm, setSchoolForm] = useState<SchoolRecord>({ ...BLANK_SCHOOL_FORM });
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Typography sx={{ fontSize: 50, mt: 2 }} variant="h1">
          District Settings
        </Typography>
        <Divider />
        <Typography sx={{ fontSize: 30, mt: 4 }} variant="h4">
          Schools
        </Typography>
        <SchoolsTable setSchoolForm={setSchoolForm} setManageSchoolOpen={setManageSchoolOpen} />
        <Typography sx={{ fontSize: 30, mt: 4 }} variant="h4">
          District Staff
        </Typography>
        <StaffTable
          userType={UserType.DistrictAdmin}
          setStaffForm={setStaffForm}
          setManageStaffOpen={setManageStaffOpen}
        />
      </Suspense>
      <ManageSchoolDialog
        open={manageSchoolOpen}
        setOpen={setManageSchoolOpen}
        schoolForm={schoolForm}
        setSchoolForm={setSchoolForm}
      />
      <ManageStaffDialog
        open={manageStaffOpen}
        setOpen={setManageStaffOpen}
        staffForm={staffForm}
        setStaffForm={setStaffForm}
      />
    </>
  );
};

export default DistrictSettingsContainer;
