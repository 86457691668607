import { atom, selector } from "recoil";
import { AlmaChatRecord, StaffAlmaChatRecord } from "../types/types";

export const studentAlmaChatsAtom = atom<AlmaChatRecord[]>({
  key: "studentAlmaChats",
  default: [],
});

export const currentStudentAlmaChatAtom = selector<AlmaChatRecord | null>({
  key: "currentStudentAlmaChat",
  get: ({ get }) => {
    const studentAlmaChats = get(studentAlmaChatsAtom);
    return studentAlmaChats.find((chat) => chat.current === true) || null;
  },
});

export const staffAlmaChatsAtom = atom<StaffAlmaChatRecord[]>({
  key: "staffAlmaChats",
  default: [],
});

export const currentStaffAlmaChatAtom = selector<StaffAlmaChatRecord | null>({
  key: "currentStaffAlmaChat",
  get: ({ get }) => {
    const staffAlmaChats = get(staffAlmaChatsAtom);
    return staffAlmaChats.find((chat) => chat.current === true) || null;
  },
});
