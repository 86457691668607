import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { loggedInProviderAtom } from "../../../shared/recoil/userAtoms";
import { Color } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/components/FeatherIcon";
import EditProviderUserDialog from "./EditProviderUserDialog";

const ProviderUserCard = () => {
  const loggedInProvider = useRecoilValue(loggedInProviderAtom);
  const [open, setOpen] = useState(false);

  return (
    <>
      {loggedInProvider && (
        <>
          <Box
            sx={{
              backgroundColor: Color.Chalkboard,
              p: 2,
              borderRadius: 3,
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box>
              <Typography
                sx={{ color: Color.Chalk }}
                variant="h4"
              >{`${loggedInProvider.firstName} ${loggedInProvider.lastName}`}</Typography>
              <Box
                sx={{
                  display: "flex",
                  verticalAlign: "center",
                  color: Color.Chalk,
                  mt: 1,
                }}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  verticalAlign: "center",
                  color: Color.Chalk,
                  mt: 1,
                }}
              >
                <Typography sx={{ color: Color.Chalk }} variant="body1">
                  {loggedInProvider.email}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ position: "absolute", right: 10, top: 10 }}>
              <IconButton onClick={() => setOpen(true)} sx={{ color: Color.Chalk }}>
                <FeatherIcon width="20px" icon="edit-2" />
              </IconButton>
            </Box>
          </Box>
          <EditProviderUserDialog open={open} setOpen={setOpen} />
        </>
      )}
    </>
  );
};

export default ProviderUserCard;
