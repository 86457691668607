import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { loggedInProviderAtom } from "../../../shared/recoil/userAtoms";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import { Collection, Color } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/components/FeatherIcon";
const BGCOLOR = "#fbfbfb";

type Props = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const EditProviderUserDialog = ({ open, setOpen }: Props) => {
  const [loggedInProvider, setLoggedInProvider] = useRecoilState(
    loggedInProviderAtom
  );
  const [form, setForm] = useState({ firstName: "", lastName: "" });
  const [valid, setValid] = useState(false);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const handleClose = () => {
    setOpen(false);
  };
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!loggedInProvider) return;
    setForm({
      firstName: loggedInProvider.firstName,
      lastName: loggedInProvider.lastName,
    });
  }, [loggedInProvider, open]);

  useEffect(() => {
    setValid(
      form.firstName.trim().length > 0 && form.lastName.trim().length > 0
    );
  }, [form]);

  const handleSave = () => {
    if (!loggedInProvider) return;

    setError(null);

    setLoggedInProvider({ ...loggedInProvider, ...form });
    updateDoc({
      col: Collection.ProviderUsers,
      id: loggedInProvider.id,
      data: form,
    });
    setOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle
        variant="h3"
        sx={{ textAlign: "center", backgroundColor: BGCOLOR }}
      >
        Edit Personal Info
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: BGCOLOR }}>
        <Typography variant="body1">First Name</Typography>
        <TextField
          value={form.firstName}
          fullWidth
          variant="outlined"
          name="firstName"
          onChange={handleChange}
        />
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          Last Name
        </Typography>
        <TextField
          value={form.lastName}
          fullWidth
          variant="outlined"
          name="lastName"
          onChange={handleChange}
        />
        {error && (
          <Box sx={{ mt: 2 }}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: BGCOLOR }}>
        <Button variant="contained" disabled={!valid} onClick={handleSave}>
          <IconButton sx={{ color: Color.Chalkboard }} disableRipple>
            <FeatherIcon icon="thumbs-up" width="16px" />
          </IconButton>
          Save
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          <IconButton sx={{ color: Color.Chalkboard }} disableRipple>
            <FeatherIcon icon="x" width="16px" />
          </IconButton>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProviderUserDialog;
