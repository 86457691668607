import { Box, CircularProgress } from "@mui/material";
import React from "react";

const Loading = ({ size = 50 }: { size?: number }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={size} />
    </Box>
  );
};

export default Loading;
