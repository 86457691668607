import React, { useCallback, useEffect, useState } from "react";
import { AddressComponents, Provider, ProviderUser } from "../../../shared/types/types";
import { SelectChangeEvent } from "@mui/material";

export type validationType = {
  firstName: {
    show: boolean;
    validated: boolean;
    message: string;
  };
  lastName: {
    show: boolean;
    validated: boolean;
    message: string;
  };
  email: {
    show: boolean;
    validated: boolean;
    message: string;
  };
  providerName: {
    show: boolean;
    validated: boolean;
    message: string;
  };
  sumPercent: {
    show: boolean;
    validated: boolean;
    message: string;
  };
};

export const initialValidationState: validationType = {
  firstName: {
    show: false,
    validated: true,
    message: "",
  },
  lastName: {
    show: false,
    validated: true,
    message: "",
  },
  email: {
    show: false,
    validated: true,
    message: "",
  },
  providerName: {
    show: false,
    validated: true,
    message: "",
  },
  sumPercent: {
    show: true,
    validated: true,
    message: "",
  },
};

type Props = {
  userForm: ProviderUser;
  providerForm: Provider;
  setUserForm: React.Dispatch<React.SetStateAction<ProviderUser>>;
  setProviderForm: React.Dispatch<React.SetStateAction<Provider>>;
  onValidateChange: (validated: boolean) => void;
};

type Return = {
  validation: validationType;
  disabledPercentages: boolean;
  handleTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange:(e: SelectChangeEvent) => void;
  handleNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSliderChange: (event: Event, newValue: number | number[], name: string) => void;
  handleSwitchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSetAddress: ({ address, city, state, zip }: AddressComponents) => void;
  handleBlur: (fieldName: string) => void;
  handleDisabledPercentagesChange: () => void;
};

const useEditProviderForm = ({ userForm, providerForm, setUserForm, setProviderForm, onValidateChange }: Props): Return => {
  const [validated, setValidated] = useState(false);
  const [validation, setValidation] = useState<validationType>(initialValidationState);
  const [disabledPercentages, setDisabledPercentages] = useState(false);

  useEffect(() => {
    if (onValidateChange) {
      onValidateChange(validated);
    }
  }, [validated])

  const validate = useCallback(() => {
    setValidated(true);

    if (userForm.firstName === "") {
      setValidated(false);
      setValidation((prev) => ({
        ...prev,
        firstName: {
          show: prev.firstName.show,
          validated: false,
          message: "First Name is required",
        },
      }));
    } else {
      setValidation((prev) => ({
        ...prev,
        firstName: { show: prev.firstName.show, validated: true, message: "" },
      }));
    }
    if (userForm.lastName === "") {
      setValidated(false);
      setValidation((prev) => ({
        ...prev,
        lastName: { show: prev.lastName.show, validated: false, message: "Last Name is required" },
      }));
    } else {
      setValidation((prev) => ({
        ...prev,
        lastName: { show: prev.lastName.show, validated: true, message: "" },
      }));
    }
    if (userForm.email === "") {
      setValidated(false);
      setValidation((prev) => ({
        ...prev,
        email: { show: prev.email.show, validated: false, message: "Email is required" },
      }));
    } else {
      setValidation((prev) => ({
        ...prev,
        email: { show: prev.email.show, validated: true, message: "" },
      }));
    }
    if (providerForm.providerName === "") {
      setValidated(false);
      setValidation((prev) => ({
        ...prev,
        providerName: {
          show: prev.providerName.show,
          validated: false,
          message: "Provider Name is required",
        },
      }));
    } else {
      setValidation((prev) => ({
        ...prev,
        providerName: { show: prev.providerName.show, validated: true, message: "" },
      }));
    }

    const sumPercent =
      (Number(providerForm?.admissionPercent) || 0) +
      (Number(providerForm?.percentAIAN) || 0) +
      (Number(providerForm?.percentAsian) || 0) +
      (Number(providerForm?.percentBlack) || 0) +
      (Number(providerForm?.percentHispanic) || 0) +
      (Number(providerForm?.percentNHPI) || 0) +
      (Number(providerForm?.percentTwoOrMore) || 0) +
      (Number(providerForm?.percentWhite) || 0) +
      (Number(providerForm?.percentPell) || 0);

    if (sumPercent > 100) {
      setValidated(false);
      setValidation((prev) => ({
        ...prev,
        sumPercent: {
          show: true,
          validated: false,
          message: "The sum of percent values should not exceed 100",
        },
      }));
    } else {
      setValidation((prev) => ({
        ...prev,
        sumPercent: { show: true, validated: true, message: "" },
      }));
    }
  }, [
    userForm.firstName,
    userForm.lastName,
    userForm.phone,
    userForm.email,
    providerForm.providerName,
    providerForm?.admissionPercent,
    providerForm?.percentAIAN,
    providerForm?.percentAsian,
    providerForm?.percentBlack,
    providerForm?.percentHispanic,
    providerForm?.percentNHPI,
    providerForm?.percentTwoOrMore,
    providerForm?.percentWhite,
    providerForm?.percentPell,
  ]);

  useEffect(() => {
    validate();
  }, [userForm, providerForm, validate]);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.name === "firstName" ||
      e.target.name === "lastName" ||
      e.target.name === "email" ||
      e.target.name === "phone"
    ) {
      setUserForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
    } else {
      setProviderForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
    }
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    if (e.target.name === "firstName" || e.target.name === "lastName") {
      setUserForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
    } else {
      setProviderForm((pV) => ({ ...pV, [e.target.name]: e.target.value }));
    }
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setProviderForm((pV) => ({ ...pV, [e.target.name]: Number(e.target.value) }));
    } else {
      setProviderForm((pV) => ({ ...pV, [e.target.name]: null }));
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[], name: string) => {
    setProviderForm((pV) => ({ ...pV, [name]: newValue }));
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProviderForm((pV) => ({ ...pV, [e.target.name]: e.target.checked }));
  };

  type RegionMap = {
    [key: string]: string;
  };

  const stateToRegionMap: RegionMap = {
    CT: "New England",
    ME: "New England",
    MA: "New England",
    NH: "New England",
    RI: "New England",
    VT: "New England",

    DE: "Mid East",
    DC: "Mid East",
    MD: "Mid East",
    NJ: "Mid East",
    NY: "Mid East",
    PA: "Mid East",

    IL: "Great Lakes",
    IN: "Great Lakes",
    MI: "Great Lakes",
    OH: "Great Lakes",
    WI: "Great Lakes",

    IA: "Mid West",
    KS: "Mid West",
    MN: "Mid West",
    MO: "Mid West",
    NE: "Mid West",
    ND: "Mid West",
    SD: "Mid West",

    AL: "South",
    AR: "South",
    FL: "South",
    GA: "South",
    KY: "South",
    LA: "South",
    MS: "South",
    NC: "South",
    SC: "South",
    TN: "South",
    VA: "South",
    WV: "South",

    AZ: "South West",
    NM: "South West",
    OK: "South West",
    TX: "South West",

    CO: "Rocky Mountains",
    ID: "Rocky Mountains",
    MT: "Rocky Mountains",
    UT: "Rocky Mountains",
    WY: "Rocky Mountains",

    AK: "Far West",
    CA: "Far West",
    HI: "Far West",
    NV: "Far West",
    OR: "Far West",
    WA: "Far West",

    AS: "Outlying Areas",
    FM: "Outlying Areas",
    GU: "Outlying Areas",
    MH: "Outlying Areas",
    MP: "Outlying Areas",
    PR: "Outlying Areas",
    PW: "Outlying Areas",
    VI: "Outlying Areas",
  };

  function getRegionByState(stateInitial: string): string | undefined {
    const normalizedState = stateInitial.toUpperCase();
    return stateToRegionMap[normalizedState];
  }

  const handleSetAddress = ({ address, city, state, zip }: AddressComponents) => {
    const region = getRegionByState(state);
    setProviderForm((pV) => {
      return {
        ...pV,
        address,
        city,
        state,
        zip,
        region: region ? region : "",
      };
    });
  };

  const handleBlur = (fieldName: string) => {
    setValidation((prev: any) => ({
      ...prev,
      [fieldName]: {
        show: true,
        validated: prev[fieldName].validated,
        message: prev[fieldName].message,
      },
    }));
  };

  const handleDisabledPercentagesChange = () => {
    if (!disabledPercentages) {
      setProviderForm((pV) => ({
        ...pV,
        admissionPercent: null,
        percentAIAN: null,
        percentAsian: null,
        percentBlack: null,
        percentHispanic: null,
        percentNHPI: null,
        percentTwoOrMore: null,
        percentWhite: null,
        percentPell: null,
      }));
    }
    setDisabledPercentages(!disabledPercentages);
  };

  return {
    validation,
    disabledPercentages,
    handleTextChange,
    handleSelectChange,
    handleNumberChange,
    handleSliderChange,
    handleSwitchChange,
    handleSetAddress,
    handleBlur,
    handleDisabledPercentagesChange,
  };
};

export default useEditProviderForm;
