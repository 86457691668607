import { useRecoilValue } from "recoil";
import { stagesAtom } from "../../../shared/recoil/stageAtoms";

const useStageSummary = () => {
  const stages = useRecoilValue(stagesAtom);
  const stageArray = stages.map((stage) => stage.id);

  return { stageArray, stages };
};

export default useStageSummary;
