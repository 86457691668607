import { atom } from "recoil";
import {
  CIPRecord,
  MentorUserRecord,
  Program,
  Provider,
  ProviderUserRecord,
  Provider_Program,
  WillowIndustryRecord,
  WillowSubIndustryRecord,
  WriteInProgramRecord,
} from "../types/types";

export const allMatchedProgramsAtom = atom<Provider_Program[]>({
  key: "allMatchedPrograms",
  default: [],
});

export const programsAtom = atom<Provider_Program[]>({
  key: "programs",
  default: [],
});

export const studentWriteInProgramsAtom = atom<WriteInProgramRecord[]>({
  key: "studentWriteInPrograms",
  default: [],
});

export const allWriteInProgramsAtom = atom<WriteInProgramRecord[]>({
  key: "allWriteInPrograms",
  default: [],
});

export const willowIndustriesAtom = atom<WillowIndustryRecord[]>({
  key: "willowIndustries",
  default: [],
});

export const willowSubIndustriesAtom = atom<WillowSubIndustryRecord[]>({
  key: "willowSubIndustries",
  default: [],
});

export const studentProgramListAtom = atom<Provider_Program[]>({
  key: "studentProgramList",
  default: [],
});

export const cipsAtom = atom<CIPRecord[]>({
  key: "cips",
  default: [],
});

export const ownProviderAtom = atom<Provider | null>({
  key: "ownProvider",
  default: null,
});

export const selectedProgramAtom = atom<Program | null>({
  key: "selectedProgram",
  default: null,
});

export const providerUserAtom = atom<ProviderUserRecord | null>({
  key: "providerUser",
  default: null,
});

export const mentorUsersAtom = atom<MentorUserRecord[]>({
  key: "mentorUsers",
  default: [],
});