import React, { useState } from "react";
import Navbar from "../../shared/components/navigation/Navbar";
import { Box } from "@mui/material";
import Sidebar from "../../shared/components/navigation/Sidebar";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../shared/recoil/userAtoms";
import ProviderProfileContainer from "../components/profile/ProviderProfileContainer";
import { PageRoute } from "../../shared/types/enums";

const Profile = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  if (loggedInStaff) {
    navigate(PageRoute.Staff_Dashboard);
  }
  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
        <Sidebar open={open} setOpen={setOpen} />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Navbar open={open} title="Student Profile" />
          <Box sx={{ minHeight: "calc(100VH-64px)" }}>
            <ProviderProfileContainer />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Profile;
