import { useState, useCallback } from "react";
import { collection, addDoc, FirestoreError } from "firebase/firestore";
import { db } from "../../firebase";

interface SendRequestProps {
  col: string;
  data: {};
}

type SendRequestType = (
  props: SendRequestProps
) => Promise<{ id: string; createdAt: string; lastUpdatedAt: string } | null>;

type AddDocType = {
  isLoading: boolean;
  error: string | null;
  sendRequest: SendRequestType;
};

const useAddDoc = (): AddDocType => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const sendRequest = useCallback<SendRequestType>(async ({ col, data }) => {
    setIsLoading(true);
    setError(null);
    const now = new Date().toISOString();

    try {
      const finalData = { ...data, createdAt: now, lastUpdatedAt: now, active: true };
      const docRef = collection(db, col);
      const results = await addDoc(docRef, finalData);

      setIsLoading(false);
      if (!results.id) {
        setError("No firebase Id");
      }

      return { id: results.id, createdAt: now, lastUpdatedAt: now } || null;
    } catch (error: unknown) {
      const firebaseError = error as FirestoreError;
      setError(firebaseError.message);
      console.error("Error adding document: ", firebaseError);
      return null;
    }
  }, []);
  return { isLoading, error, sendRequest };
};

export default useAddDoc;
