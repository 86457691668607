import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import useProgramList from "../../hooks/studentsByProgram/useProgramList";
import { useNavigate } from "react-router-dom";
import { Provider_Program } from "../../../shared/types/types";
import { PageRoute } from "../../../shared/types/enums";
import { Dispatch, SetStateAction } from "react";
type Props = {
  programId: string;
  favoriteOnlyToggle: boolean;
  fromAllSchoolsToggle: boolean;
  matchedPrograms: Provider_Program[];
  setSelectedProgramDetail: Dispatch<SetStateAction<Provider_Program | null>>;
};

const ProgramList = ({
  programId,
  favoriteOnlyToggle,
  fromAllSchoolsToggle,
  matchedPrograms,
  setSelectedProgramDetail,
}: Props) => {
  const navigate = useNavigate();

  const { currentProvider, studentsList } = useProgramList({
    programId,
    favoriteOnlyToggle,
    fromAllSchoolsToggle,
    matchedPrograms,
  });

  const handleProgramDetails = () => {
    if (currentProvider) {
      setSelectedProgramDetail(currentProvider);
    }
  };

  return (
    <>
      {currentProvider && studentsList.length > 0 && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography sx={{ mt: 1 }} variant="h3">
                {currentProvider.programName}
              </Typography>
              <Typography variant="body1">{currentProvider.providerName}</Typography>
            </Box>
            <Box sx={{ minWidth: 140 }}>
              <Button onClick={handleProgramDetails} variant="contained">
                Program Details
              </Button>
            </Box>
          </Box>
          <Divider sx={{ mt: 1 }} />
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {studentsList.map((student) => (
              <Grid item key={`${student.id}_${programId}`} xs={12} sm={6} md={4} lg={3}>
                <Box sx={{ p: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body1">{`${student.firstName} ${student.lastName}`}</Typography>
                    {student.favoriteProviderIds.includes(programId) && (
                      <FavoriteIcon color="secondary" sx={{ fontSize: 12, ml: 1 }} />
                    )}
                  </Box>
                  <Typography sx={{ fontSize: 12 }} variant="body2">
                    {student.schoolEmail}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default ProgramList;
