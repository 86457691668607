import React, { useState } from "react";
import { UserType } from "../../shared/types/enums";
import { Box, Container } from "@mui/material";
import Navbar from "../../shared/components/navigation/Navbar";
import Sidebar from "../../shared/components/navigation/Sidebar";
import ManageProgramsContainer from "../../shared/components/managePrograms/ManageProgramsContainer";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "../../shared/recoil/userAtoms";

const ManagePrograms = () => {
  const userType = useRecoilValue(userTypeAtom);
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#fcfcfc" }}>
        <Navbar open={open} />
        <Sidebar open={open} setOpen={setOpen} />

        <Container maxWidth="xl" sx={{ minHeight: "calc(100VH-64px)", mt: 8, pt: 2 }}>
          {userType && userType >= UserType.Staff && <ManageProgramsContainer />}
        </Container>
      </Box>
    </>
  );
};

export default ManagePrograms;
