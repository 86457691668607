import { useState, useCallback, useEffect } from "react";
import { Box, Toolbar, IconButton, Menu, Typography, Avatar, Divider, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useRecoilValue } from "recoil";
import { styled } from "@mui/material/styles";

import { loggedInStaffAtom, selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import { getDownloadURL, getStorage, ref } from "firebase/storage";
import useWindowDimensions from "../../../shared/hooks/useWindowDimensions";
import { Color } from "../../../shared/types/enums";
import StaffSelectSection from "../../../shared/components/navigation/StaffSelectSection";
import { useAuth } from "../../../shared/contexts/AuthContext";

type Props = {
  open?: boolean;
  title?: string;
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 221;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const TopNavbar = ({ open, title = "Willow" }: Props) => {
  const storage = getStorage();
  const [avatarURL, setAvatarURL] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const { width } = useWindowDimensions();
  const { logout } = useAuth();

  useEffect(() => {
    const getImageURL = async () => {
      const fileName = loggedInStaff ? loggedInStaff.avatar : selectedStudent?.avatar ?? "";
      if (fileName.length > 5) {
        let url = "";
        try {
          try {
            url = await getDownloadURL(ref(storage, `${fileName}_200x200`));
          } catch (error) {
            url = await getDownloadURL(ref(storage, `${fileName}`));
          }
        } catch (error) {
          console.error(error);
        }
        setAvatarURL(url);
      }
    };
    getImageURL();
  }, [loggedInStaff, selectedStudent, storage]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  return (
    <AppBar position="sticky" sx={{ width: "100%", ml: "1px", zIndex: 1 }} open={open}>
      <Toolbar sx={{ backgroundColor: "#fff" }}>
        <Box sx={{ flexGrow: 1, pt: "2px", pb: "2px" }}>
          <Box
            sx={{
              display: "flex",
              alignContent: "flex-start",
              alignItems: "center",
              gap: 2,
              cursor: "pointer",
            }}
          >
            <Typography variant="h4" sx={{ color: Color.Charcoal }}>
              {title}
            </Typography>
          </Box>
        </Box>
        {width > 900 && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ color: "#333" }}>
              {loggedInStaff ? loggedInStaff.email : selectedStudent?.schoolEmail}
            </Typography>
            <Avatar
              alt={loggedInStaff?.avatar ?? ""}
              src={avatarURL ? avatarURL : ""}
              sx={{ ml: 2, backgroundColor: "#F4F0DC", color: "#00362E" }}
            />
          </Box>
        )}
        <>
          <IconButton
            size="medium"
            edge="start"
            aria-label="menu"
            sx={{ ml: 1, color: Color.Chalkboard }}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            slotProps={{ paper: { style: { width: 400 } } }}
          >
            <Box sx={{ p: 1 }}>
              <StaffSelectSection />
              <Divider />
              <Button
                sx={{ mt: 2, p: 2 }}
                variant="outlined"
                fullWidth
                id="logout-button"
                color="primary"
                onClick={() => logout()}
              >
                Logout
              </Button>
            </Box>
          </Menu>
        </>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavbar;
