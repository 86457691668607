import { DataGridPro, GridRowParams, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { Avatar, Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { SchoolRecord } from "../../../shared/types/types";
import { schoolsWithURLAtom } from "../../../shared/recoil/districtAtoms";
import { Dispatch } from "react";
// import { staffColumns } from "../../libraries/columns";
const KEY = process.env.REACT_APP_DATATABLE_KEY ?? "";
LicenseInfo.setLicenseKey(KEY);

type Props = {
  setManageSchoolOpen: Dispatch<React.SetStateAction<boolean>>;
  setSchoolForm: Dispatch<React.SetStateAction<SchoolRecord>>;
};

const SchoolsTable = ({ setManageSchoolOpen, setSchoolForm }: Props) => {
  const schools = useRecoilValue(schoolsWithURLAtom);

  const schoolColumns = [
    {
      field: "avatarURL",
      headerName: "",
      width: 80,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      renderCell: (params: GridRenderCellParams<SchoolRecord>) => (
        <Avatar
          alt={params.row.name}
          src={params.row.avatarURL ? params.row.avatarURL : ""}
          sx={{ width: 50, height: 50, mt: 1, mb: 1 }}
        />
      ),
    },
    {
      field: "name",
      headerName: "District Name",
      minWidth: 100,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      flex: 1,
    },
    {
      field: "primaryContact",
      headerName: "Primary Contact",
      minWidth: 100,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      flex: 1,
    },
    {
      field: "primaryPhone",
      headerName: "Phone",
      minWidth: 100,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      flex: 1,
    },
    {
      field: "primaryEmail",
      headerName: "Email",
      minWidth: 100,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      flex: 1,
    },
  ];

  const onRowClick = (row: SchoolRecord) => {
    setSchoolForm(row);
    setManageSchoolOpen(true);
  };
  return (
    <>
      <Box
        sx={{ height: schools.length > 5 ? 500 : schools.length * 100 + 165, width: "100%", mt: 1 }}
        id="app-manager-table"
      >
        <div
          style={{
            display: "flex",
            height: "calc(100% - 62px)",
          }}
        >
          <div style={{ flexGrow: 1, maxWidth: "100%" }}>
            <DataGridPro
              rows={schools}
              columns={schoolColumns}
              rowHeight={60}
              onRowClick={(data: GridRowParams) => onRowClick(data.row)}
              getRowId={(row) => row.id}
              disableColumnMenu
              disableColumnResize
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
              }
              sx={{ fontSize: 12, border: "none", height: "100%" }}
            />
          </div>
        </div>
      </Box>
    </>
  );
};

export default SchoolsTable;
