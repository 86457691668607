import React, { useState } from "react";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { useRecoilValue } from "recoil";
import { Box, Button, Container, Typography } from "@mui/material";
import StageDisplay from "./StageDisplay";
import MatchedPrograms from "./MatchedPrograms";
import ApplicationTracker from "./ApplicationTracker";
import RecommendedPrograms from "./RecommendedPrograms";
import { Provider_Program } from "../../../shared/types/types";
import ProgramDetailsDialog from "../../../shared/components/programDetails/ProgramDetailsDialog";
import { useNavigate } from "react-router-dom";
import { PageRoute } from "../../../shared/types/enums";

const StudentStagesContainer = () => {
  const navigate = useNavigate();
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const [learnMoreSelectedProgram, setLearnMoreSelectedProgram] = useState<Provider_Program | null>(
    null
  );

  return (
    <>
      {selectedStudent && (
        <Container maxWidth="lg" sx={{ pt: 2, pl: 0, pr: 0 }}>
          <Typography
            textAlign={"center"}
            variant="h3"
            sx={{ fontSize: 30 }}
          >{`Hello ${selectedStudent?.firstName}!`}</Typography>
          <Typography
            textAlign={"center"}
            variant="h4"
            sx={{ fontSize: 20, fontWeight: 300, mt: 2 }}
          >
            Ready To Find Your Perfect Fit Program?
          </Typography>
          <StageDisplay />
          {selectedStudent.stageId !== "stageOne" && (
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 1 }}
                size="medium"
                onClick={() => navigate(PageRoute.Explore_Programs)}
              >
                Want to see more programs, click here to explore
              </Button>
            </Box>
          )}
          {selectedStudent.stageId === "stageTwo" && (
            <>
              <RecommendedPrograms setLearnMoreSelectedProgram={setLearnMoreSelectedProgram} />
              <MatchedPrograms setLearnMoreSelectedProgram={setLearnMoreSelectedProgram} />
            </>
          )}
          {selectedStudent.stageId === "stageThree" && (
            <Container maxWidth="sm" sx={{ pt: 1, pl: 0, pr: 0 }}>
              <ApplicationTracker setSelectedProgram={setLearnMoreSelectedProgram} />
            </Container>
          )}
        </Container>
      )}
      {learnMoreSelectedProgram && (
        <ProgramDetailsDialog
          selectedProgram={learnMoreSelectedProgram}
          setSelectedProgram={setLearnMoreSelectedProgram}
        />
      )}
    </>
  );
};

export default StudentStagesContainer;
