import React from "react";
import { Provider_Program } from "../types/types";
import { Box, Grid, List, ListItemText, Typography } from "@mui/material";
import useAutoAdmitMessage from "../hooks/useAutoAdmitMessage";

type Props = {
  provider: Provider_Program;
};

const AutoAdmitMessage = ({ provider }: Props) => {
  const { autoAdmitText } = useAutoAdmitMessage({
    admit: provider.autoAdmit,
    scholarship: provider.autoScholarship,
  });
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={2} sx={{ textAlign: "center" }}>
          <img src="../celebrate-left.svg" width="80px" alt="celebrate" />
        </Grid>
        <Grid item xs={12} md={8} sx={{ textAlign: "center" }}>
          <Typography sx={{ fontWeight: 600 }} variant="h5">
            Congratulations!
          </Typography>
          <Typography>{autoAdmitText}</Typography>
          <List>
            <ListItemText>
              - Go though an interview with the {provider.providerName} team
            </ListItemText>
            <ListItemText>
              - Provide a recommendation from a trusted, non-family adult to show your level of
              commitment
            </ListItemText>
            <ListItemText>- Complete {provider.providerName}'s bridge program</ListItemText>
          </List>
        </Grid>
        <Grid item xs={12} md={2} sx={{ textAlign: "center" }}>
          <img src="../celebrate-right.svg" width="80px" alt="celebrate" />
        </Grid>
      </Grid>
    </>
  );
};

export default AutoAdmitMessage;
