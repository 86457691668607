import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Theme";
import { routes } from "./Routes";
import { useRecoilValue } from "recoil";
import { siteLoadingAtom } from "./shared/recoil/loadingAtoms";
import LoadingPage from "./shared/components/LoadingPage";

function NotLoggedInBootstrap() {
  const siteLoading = useRecoilValue(siteLoadingAtom);
  return (
    <ThemeProvider theme={theme}>
      {siteLoading ? (
        <LoadingPage />
      ) : (
        <Router>
          <main className="root">{routes}</main>
        </Router>
      )}
    </ThemeProvider>
  );
}

export default NotLoggedInBootstrap;
