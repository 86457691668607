import React from "react";
import { Button, Typography } from "@mui/material";
import ManageProgramsFilters from "./ManageProgramsFilters";
import useManageProgramsContainer from "../../hooks/managePrograms/useManageProgramsContainer";

const ManageProgramsContainer = () => {
  const {
    handleUpdateResults,
    filters,
    setFilters,
    handleCIPUpdate,
    handleUploadProviders,
    handleUploadPrograms,
  } = useManageProgramsContainer();

  return (
    <>
      <Typography variant="h2">Manage Programs</Typography>
      <ManageProgramsFilters
        handleUpdateResults={handleUpdateResults}
        setFilters={setFilters}
        filters={filters}
      />
      <Button onClick={handleCIPUpdate}>Update CIP Names</Button>
      <Button onClick={() => handleUploadProviders("../public/all_college_providers.csv")}>
        Upload College Providers
      </Button>
      <Button onClick={() => handleUploadProviders("../public/all_non_college_providers.csv")}>
        Upload Non-College Providers
      </Button>
      <Button onClick={() => handleUploadPrograms("../public/all_non_college_programs.csv")}>
        Upload Non-College Programs
      </Button>
      <Button
        sx={{ mt: 2 }}
        onClick={() => handleUploadPrograms("../public/all_college_programs.csv")}
      >
        Upload College Programs
      </Button>
    </>
  );
};

export default ManageProgramsContainer;
