import { Box, Divider, Grid, Typography } from "@mui/material";
import ProgramCard from "../../../shared/components/programCard/ProgramCard";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { SelectedProgramSource } from "../../../shared/types/enums";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Provider_Program } from "../../../shared/types/types";

type Props = {
  setLearnMoreSelectedProgram: Dispatch<SetStateAction<Provider_Program | null>>;
};

const RecommendedPrograms = ({ setLearnMoreSelectedProgram }: Props) => {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const [recommendedPrograms, setRecommendedPrograms] = useState<Provider_Program[]>([]);

  useEffect(() => {
    const getStaffRecommendedPrograms = async () => {
      if (!selectedStudent) return;
      const results = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getListOfPrograms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          programIds: [...selectedStudent.staffRecommendedProgramIds],
        }),
      });
      const { rows } = (await results.json()) as { rows: Provider_Program[] };
      setRecommendedPrograms(rows);
    };
    getStaffRecommendedPrograms();
  }, [selectedStudent]);

  return (
    <>
      {recommendedPrograms.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h4">Staff Recommended Programs</Typography>
          <Divider />
          <Box sx={{ maxHeight: "80VH", overflow: "scroll", overflowX: "hidden" }}>
            <Grid container spacing={2}>
              {recommendedPrograms.map((match) => (
                <Grid key={match.id} item xs={12} sm={12} md={12} lg={6}>
                  <ProgramCard
                    setSelectedLearnMoreProgram={setLearnMoreSelectedProgram}
                    program={match}
                    source={SelectedProgramSource.Recommended}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RecommendedPrograms;
