import { Box } from "@mui/material";
import React from "react";
import DisplayBox from "../../../shared/components/DisplayBox";
import HollandResultsContainer from "../../../shared/components/hollandResults/HollandResultsContainer";

const StudentHollandResults = () => {
  return (
    <>
      <Box>
        <DisplayBox title="My Personality Type">
          <HollandResultsContainer />
        </DisplayBox>
      </Box>
    </>
  );
};

export default StudentHollandResults;
