import { Grid } from "@mui/material";
import React from "react";
import ProviderUserCard from "./ProviderUserCard";
import ProviderDetailCard from "./ProviderDetailCard";

const ProviderProfileContainer = () => {
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1, px: 2 }}>
        <Grid item xs={12} md={5}>
          <ProviderUserCard />
        </Grid>
        <Grid item xs={12} md={7}>
          <ProviderDetailCard />
        </Grid>
      </Grid>
    </>
  );
};

export default ProviderProfileContainer;
