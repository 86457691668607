import React, { useEffect, useState } from "react";
import videoUrls from "./tiktokvideos.json";
import { Box } from "@mui/system";

const iframe: any = document.querySelector("iframe");

iframe?.addEventListener("ended", () => {
  iframe.currentTime = 0; // Reset video to the beginning
  iframe.play(); // Start playing again
});

const VideoDisplay = () => {
  const [embedUrls, setEmbedUrls] = useState<string[]>([]);

  useEffect(() => {
    const processUrls = (urls: string[]) => {
      return urls.map((url) => {
        const videoId = url.split("/").slice(-1)[0]; // Extract video ID
        return `https://www.tiktok.com/embed/${videoId}`;
      });
    };

    setEmbedUrls(processUrls(videoUrls.map((video) => video.url)));
  }, []);

  return (
    <div className="tiktok-feed" style={{ height: "150VH", overflowY: "scroll" }}>
      {embedUrls.map((embedUrl, index) => (
        <Box
          className="tiktok-video-container"
          key={index}
          sx={{ display: "flex", justifyContent: "center", padding: 2 }}
        >
          <iframe
            key={index}
            src={embedUrl}
            width="325" // adjust as needed
            height="750" // adjust as needed
            allow="autoplay; encrypted-media"
            title="Embedded TikTok"
            style={{ border: "none", overflow: "hidden" }}
          ></iframe>
        </Box>
      ))}
    </div>
  );
};

export default VideoDisplay;
