import React, { ChangeEvent } from "react";
import { TextField } from "@mui/material";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import "react-phone-number-input/style.css";

type Props = {
  label?: string;
  name: string;
  value?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
};

const PhoneNumber = ({
  label,
  name,
  value,
  error = false,
  helperText = "",
  onChange,
  required,
  onBlur,
}: Props) => {
  const handleChange = (value: any) => {
    const cleanedValue = value ? value.replace(/^\+/, "") : "";

    if (onChange) {
      const newEvent = {
        target: {
          name,
          value: cleanedValue,
        },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange(newEvent);
    }
  };

  const handleBlue = () => {
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <>
      <TextField
        fullWidth
        label={label}
        variant="outlined"
        required={required}
        sx={{ padding: 0 }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: PhoneInput as any,
          inputProps: {
            defaultCountry: "US",
            country: "US",
            maxLength: 15,
            sx: { width: 100 },
            value: value ? formatPhoneNumberIntl("+" + value) : "",
            onChange: handleChange,
          },
        }}
        error={error}
        helperText={helperText}
        onBlur={handleBlue}
      />
    </>
  );
};

export default PhoneNumber;
