import { Box, Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { Collection, Color, Stage } from "../../../shared/types/enums";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { stagesAtom } from "../../../shared/recoil/stageAtoms";
import DisplayBox from "../../../shared/components/DisplayBox";
import { allEnrolledSchoolStudentsAtom, selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";

type Props = {
  stageId: string;
};

const StageProgressBar = ({ stageId }: Props) => {
  const stages = useRecoilValue(stagesAtom);
  const currentStage = stages.find((stage) => stage.id === stageId);
  const currentStageIndex = stages.findIndex((stage) => stage.id === stageId);
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const setAllEnrolledSchoolStudents = useSetRecoilState(allEnrolledSchoolStudentsAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();

  const handleMoveToAppTracker = (stage: Stage) => {
    if (!selectedStudent) return;
    setSelectedStudent((pV) => (pV ? { ...pV, stageId: stage } : null));
    setAllEnrolledSchoolStudents((pV) => {
      return pV.map((student) => {
        if (student.id === selectedStudent.id) {
          return { ...student, stageId: stage };
        }
        return student;
      });
    });

    updateDoc({
      col: Collection.Students,
      id: selectedStudent.id,
      data: { stageId: stage },
    });
  };

  type FormatColorProps = {
    index: number;
    currentStageIndex: number;
  };

  const formatDotColor = ({ index, currentStageIndex }: FormatColorProps) => {
    if (index === currentStageIndex) {
      return Color.Chalkboard;
    } else if (index < currentStageIndex) {
      return Color.Mint;
    } else {
      return "#999";
    }
  };

  const formatLineColor = ({ index, currentStageIndex }: FormatColorProps) => {
    if (index === currentStageIndex) {
      return "#999";
    } else if (index < currentStageIndex) {
      return Color.Mint;
    } else {
      return "#999";
    }
  };
  return (
    <>
      <DisplayBox title="Current Stage">
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 2 }}>
          {stages.map((stage, index) => (
            <Box key={stage.id} sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip key={stage.id} title={stage.name} placement="top">
                <CircleIcon
                  sx={{
                    fontSize: 18,
                    color: formatDotColor({ index, currentStageIndex }),
                  }}
                />
              </Tooltip>
              {index !== stages.length - 1 && (
                <div
                  style={{
                    height: 0,
                    borderTop: "1px solid",
                    borderColor: formatLineColor({
                      index: index,
                      currentStageIndex,
                    }),
                    width: 44,
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        {currentStage && (
          <>
            <Typography textAlign="center" variant="h3" sx={{ mt: 1, fontSize: 22 }}>
              {currentStage.name}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
              {selectedStudent &&
                currentStage.id === Stage.Stage2 &&
                selectedStudent?.favoriteProviderIds.length > 1 && (
                  <Button
                    onClick={() => handleMoveToAppTracker(Stage.Stage3)}
                    variant="contained"
                    sx={{ mt: 2 }}
                  >
                    Move to Application Tracker
                  </Button>
                )}
              {selectedStudent &&
                currentStage.id === Stage.Stage2 &&
                selectedStudent?.favoriteProviderIds.length <= 1 && (
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Please heart at least 2 programs to move to the next stage.
                  </Typography>
                )}
              {currentStage.id === Stage.Stage3 && (
                <Button
                  onClick={() => handleMoveToAppTracker(Stage.Stage2)}
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  Go Back To Program Review
                </Button>
              )}
            </Box>
          </>
        )}
      </DisplayBox>
    </>
  );
};

export default StageProgressBar;
