import React, { useState, useEffect, useCallback } from "react";
import { Provider, Provider_Program } from "../../shared/types/types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { parseProgramResponse } from "../../shared/utils/parsers";
import { loggedInProviderAtom } from "../../shared/recoil/userAtoms";
import { ownProviderAtom, programsAtom } from "../../shared/recoil/providerAtoms";
import { ProviderDataContext } from "../contexts/ProviderDataContext";
import { siteLoadingAtom } from "../../shared/recoil/loadingAtoms";

type Props = {
  children: JSX.Element;
};

export const ProviderDBProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [sqlLoading, setSQLLoading] = useState(true);
  const [startQuery] = useState(new Date().getTime());
  const setOwnProvider = useSetRecoilState(ownProviderAtom);
  const setPrograms = useSetRecoilState(programsAtom);
  const loggedInProvider = useRecoilValue(loggedInProviderAtom);
  const setSiteLoading = useSetRecoilState(siteLoadingAtom);

  useEffect(() => {
    const loadData = async () => {
      setLoading(false);
    };
    loadData();
  });

  const getOwnPrograms = useCallback(async (providerId) => {
    const results = await fetch(
      `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getProgramsByProviderId`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          providerId: providerId,
        }),
      }
    );

    const { rows } = (await results.json()) as { rows: Provider_Program[] };
    return rows;
  }, []);

  const getOwnProvider = useCallback(async (providerId) => {
    const results = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getProvider`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        providerId: providerId,
      }),
    });

    const { provider } = (await results.json()) as { provider: Provider };

    return provider;
  }, []);

  useEffect(() => {
    if (!loggedInProvider) {
      setSQLLoading(false);
      return;
    }
    setSQLLoading(true);

    const getAllMatchedData = async () => {
      const provider = await getOwnProvider(loggedInProvider.providerId);
      setOwnProvider(provider);
      if (provider) {
        const programs = await getOwnPrograms(provider.id);
        setPrograms(parseProgramResponse(programs));
      }
      setSQLLoading(false);
    };
    getAllMatchedData();
  }, [
    getOwnProvider,
    getOwnPrograms,
    loading,
    startQuery,
    loggedInProvider,
    setOwnProvider,
    setPrograms,
  ]);

  useEffect(() => {
    setSiteLoading(loading || sqlLoading);
    console.log(loading, sqlLoading);
  }, [loading, setSiteLoading, sqlLoading]);

  return (
    <ProviderDataContext.Provider value={{ loading, sqlLoading }}>
      {children}
    </ProviderDataContext.Provider>
  );
};
