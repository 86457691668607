import React from "react";
import DisplayBox from "../../../shared/components/DisplayBox";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import StageProgressBar from "./StageProgressBar";
import { useRecoilState, useRecoilValue } from "recoil";
import { loggedInStaffAtom, selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { useNavigate } from "react-router-dom";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import { Collection, Color } from "../../../shared/types/enums";

const StageDisplay = () => {
  const navigate = useNavigate();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const favoritedProgramsLength = selectedStudent?.favoriteProviderIds.length ?? 0;
  const { sendRequest: updateDoc } = useUpdateDoc();
  const handleTakeQuiz = () => {
    if (!loggedInStaff) {
      navigate("/take-quiz");
    }
  };
  const handleMoveToAppTracker = () => {
    if (!selectedStudent) return;
    setSelectedStudent((pV) => (pV ? { ...pV, stageId: "stageThree" } : null));
    updateDoc({
      col: Collection.Students,
      id: selectedStudent.id,
      data: { stageId: "stageThree" },
    });
  };

  const handleGoToStageTwo = () => {
    if (!selectedStudent) return;
    setSelectedStudent((pV) => (pV ? { ...pV, stageId: "stageTwo" } : null));
    updateDoc({
      col: Collection.Students,
      id: selectedStudent.id,
      data: { stageId: "stageTwo" },
    });
  };
  return (
    <Container maxWidth="md" sx={{ mt: 2, p: 0 }}>
      {selectedStudent && (
        <DisplayBox title="My Current Stage">
          <Box sx={{ pt: 2, display: "flex", justifyContent: "center" }}>
            <StageProgressBar stageId={selectedStudent?.stageId} />
          </Box>
          <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
            {selectedStudent.stageId === "stageOne" && (
              <Button onClick={handleTakeQuiz} variant="contained" sx={{ mt: 2 }} size="large">
                {selectedStudent.lastQuestionId ? "Finish" : "Start"} the Willow Quiz
              </Button>
            )}
            {selectedStudent.stageId === "stageTwo" && (
              <Box>
                {favoritedProgramsLength < 2 && (
                  <Typography variant="body1" sx={{ fontSize: 10, textAlign: "center" }}>
                    Heart at least two programs to move on to the next stage
                  </Typography>
                )}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={handleMoveToAppTracker}
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1 }}
                    size="medium"
                    disabled={favoritedProgramsLength < 2 ? true : false}
                  >
                    Move On To The App Tracker
                  </Button>
                </Box>
              </Box>
            )}
            {selectedStudent.stageId === "stageThree" && (
              <Grid container>
                <Grid item xs={6}>
                  <Button
                    onClick={handleGoToStageTwo}
                    variant="contained"
                    color="gray"
                    sx={{ mt: 1 }}
                    size="small"
                    disabled={favoritedProgramsLength < 2 ? true : false}
                  >
                    Go Back
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </DisplayBox>
      )}
    </Container>
  );
};

export default StageDisplay;
