import React, { useRef, useState } from "react";
import { AppResult, AppStatus, Color } from "../../../shared/types/enums";
import { Box, Button } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import FeatherIcon from "../../../shared/components/FeatherIcon";

type Props = {
  result: AppResult;
  handleResults: (newResult: AppResult) => void;
  status: AppStatus;
};

const BUTTON_SX = {
  width: "20VW",
  textTransform: "none",
  fontSize: 10,
  padding: 0,
  pt: "2px",
  backgroundColor: "transparent",
  lineHeight: 1.5,
  fontWeight: 800,
  display: "block",
  minHeight: 60,
};

const DEFAULT = "#bbb";

const ApplicationResults = ({ result, handleResults, status }: Props) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const lottieRef = useRef<any>(null);

  const handleAccepted = () => {
    if (result === AppResult.Accepted) return;
    lottieRef.current?.play();
    setShowAnimation(true);
    handleResults(AppResult.Accepted);
  };
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2, gap: 1 }}>
        <Button
          variant="outlined"
          sx={{
            ...BUTTON_SX,
            color: result === AppResult.NoResponse ? Color.Mint : DEFAULT,
            borderColor:
              result === AppResult.NoResponse
                ? `${Color.Mint} !important`
                : `${DEFAULT} !important`,
          }}
          onClick={() => handleResults(AppResult.NoResponse)}
        >
          <Box sx={{ color: result === AppResult.NoResponse ? Color.Mint : DEFAULT }}>
            <FeatherIcon icon="clock" width="20px" />
          </Box>
          Waiting On Results
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...BUTTON_SX,
            color: result === AppResult.Waitlisted ? Color.Mint : DEFAULT,
            borderColor:
              result === AppResult.Waitlisted
                ? `${Color.Mint} !important`
                : `${DEFAULT} !important`,
          }}
          onClick={() => handleResults(AppResult.Waitlisted)}
        >
          <Box sx={{ color: result === AppResult.Waitlisted ? "#00A3FF" : DEFAULT }}>
            <FeatherIcon icon="clipboard" width="20px" />
          </Box>
          Waitlisted
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...BUTTON_SX,
            color: result === AppResult.Declined ? Color.Mint : DEFAULT,
            borderColor:
              result === AppResult.Declined ? `${Color.Mint} !important` : `${DEFAULT} !important`,
          }}
          onClick={() => handleResults(AppResult.Declined)}
        >
          <Box sx={{ color: result === AppResult.Declined ? "#666" : DEFAULT }}>
            <FeatherIcon icon="slash" width="20px" />
          </Box>
          Not Accepted
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...BUTTON_SX,
            position: "relative",
            color: result === AppResult.Accepted ? Color.Mint : DEFAULT,
            borderColor:
              result === AppResult.Accepted ? `${Color.Mint} !important` : `${DEFAULT} !important`,
          }}
          onClick={() => handleAccepted()}
        >
          <div
            style={{
              position: "absolute",
              top: -65,
              left: -35,
              width: "150%",
              display: showAnimation ? "block" : "none",
            }}
          >

            <Player
              autoplay={false}
              ref={lottieRef}
              onEvent={(event) => {
                if (event === "complete") setShowAnimation(false); // check event type and do something
              }}
              src="https://lottie.host/02a4d712-7c26-488e-8132-619ec290101c/AzkBcfly01.json"
            ></Player>
          </div>
          <Box sx={{ color: result === AppResult.Accepted ? "#FF9F1C" : DEFAULT }}>
            <FeatherIcon
              fillColor={result === AppResult.Accepted ? "#FF9F1C" : DEFAULT}
              icon="star"
              width="20px"
            />
          </Box>
          Accepted
        </Button>
      </Box>
    </>
  );
};

export default ApplicationResults;
