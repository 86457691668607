import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { allEnrolledMentorStudentsAtom, selectedStudentAtom } from "../../recoil/userAtoms";

const MentorSelectSection = () => {
  const allEnrolledMentorStudents = useRecoilValue(allEnrolledMentorStudentsAtom);
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);

  const handleStudentChange = ({ target: { value } }: SelectChangeEvent) => {
    setSelectedStudent(allEnrolledMentorStudents.find((student) => student.id === value) || null);
  };
  return (
    <>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="select-school-label">Select Student</InputLabel>
        <Select
          fullWidth
          value={selectedStudent?.id || "all"}
          onChange={handleStudentChange}
          label="Select Group"
          labelId="select-group-label"
          data-testid="select-group-dropdown"
        >
          {allEnrolledMentorStudents.map((student) => (
            <MenuItem key={student.id} value={student.id}>
              {student.firstName} {student.lastName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default MentorSelectSection;
