import { Box, Button, Typography } from "@mui/material";
import React, { SyntheticEvent, useState } from "react";
import Papa from "papaparse";
import { ReviewRecord } from "../../../shared/types/types";
import useFetch from "../../../shared/hooks/useFetch";

type UploadDictionary = {
  [key in Column]: string;
};
type Record = {
  id: string;
  willowROI: number | null;
};

enum Column {
  id = "id",
  willowROI = "willowROI",
}

type FormState = EventTarget & {
  name: string;
  value: string;
  files: File[];
};

const UpdateFields = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const { getFromAPI } = useFetch();

  const changeHandler = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSelectedFile(formState.files[0]);
  };

  const parseNumber = (value: string) => {
    const parsed = parseInt(value);
    return isNaN(parsed) ? null : parsed;
  };

  const handleSubmission = async () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      Papa.parse<UploadDictionary>(selectedFile, {
        complete: async function (results) {
          const length = results.data.length;
          const batchSize = 500;
          const batches: Record[][] = []; // Array of batches

          for (let index = 0; index < length; index++) {
            const value: UploadDictionary = results.data[index];

            const record: Record = {
              // More type safety possible here
              id: value[Column.id],
              willowROI: parseNumber(value[Column.willowROI]),
            };

            if (!batches[batches.length - 1] || batches[batches.length - 1].length === batchSize) {
              batches.push([]);
            }
            batches[batches.length - 1].push(record);
          }

          try {
            const promises = batches.map((batch) =>
              getFromAPI<ReviewRecord>({
                url: "/updateColumns",
                body: { tableName: "programs", idColumn: "id", data: batch },
              })
            );
            await Promise.all(promises);
          } catch (error) {
            console.error("Error during batch updates:", error);
          } finally {
          }
        },
        header: true,
      });
    }
  };

  //   const handleSubmission = async () => {
  //     if (selectedFile && selectedFile.type === "text/csv") {
  //       const promises: Array<Promise<any>> = [];
  //       setLoading(true);
  //       Papa.parse<UploadDictionary>(selectedFile, {
  //         complete: function (results) {
  //           const length = results.data.length;
  //           results.data.forEach(async(value, index) => {
  //             if (length === index + 1) {
  //               setLast(true);
  //             }
  //             // const resultId = fromWebsite(value);
  //             // promises.push(resultId);
  //             await new Promise((resolve) => setTimeout(resolve, 500));
  //             console.log("next")
  //           });
  //         },
  //         header: true,
  //       });
  //       await Promise.all(promises);
  //       setLoading(false);
  //     }
  //   };
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ mt: 3 }}>
          <input
            id="uploadFileToUpdate"
            type="file"
            name="file"
            style={{ display: "none" }}
            onChange={changeHandler}
          />
          <label htmlFor="uploadFileToUpdate">
            {" "}
            <Button variant="contained" sx={{ mt: 3 }} component="span">
              Upload Fields To Replace
            </Button>
          </label>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 3, ml: 2 }}
            onClick={handleSubmission}
          >
            Submit
          </Button>
          {selectedFile && <Typography>{selectedFile.name}</Typography>}
        </Box>
      </Box>
    </>
  );
};

export default UpdateFields;
