import {  DataGridPro, GridRowParams } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { DistrictRecord } from "../../../shared/types/types";
import { districtsAtom } from "../../../shared/recoil/districtAtoms";
// import { staffColumns } from "../../libraries/columns";
const KEY = process.env.REACT_APP_DATATABLE_KEY ?? "";
LicenseInfo.setLicenseKey(KEY);
const DistrictsTable = () => {
  const districts = useRecoilValue(districtsAtom);

  const districtsColumns = [
    {
      field: "name",
      headerName: "District Name",
      minWidth: 100,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      flex: 1,
    },
  ];

  const onRowClick = (row: DistrictRecord) => {};
  return (
    <>
      <Box
        sx={{
          height: districts.length > 5 ? 500 : districts.length * 100 + 165,
          width: "100%",
          mt: 1,
        }}
        id="app-manager-table"
      >
        <div
          style={{
            display: "flex",
            height: "calc(100% - 62px)",
          }}
        >
          <div style={{ flexGrow: 1, maxWidth: "100%" }}>
            <DataGridPro
              rows={districts}
              columns={districtsColumns}
              rowHeight={60}
              onRowClick={(data: GridRowParams) => onRowClick(data.row)}
              getRowId={(row) => row.id}
              disableColumnMenu
                disableColumnResize
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
                }
                sx={{ fontSize: 12, border: "none", height: "100%" }}
            />
          </div>
        </div>
      </Box>
    </>
  );
};

export default DistrictsTable;
