import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { Dispatch } from "react";
import { SchoolRecord } from "../../../shared/types/types";
import CustomAvatar from "../../../shared/components/CustomAvatar";
import useManageSchoolDialog from "../../hooks/settings/useManageSchoolDialog";

type Props = {
  schoolForm: SchoolRecord;
  setSchoolForm: Dispatch<React.SetStateAction<SchoolRecord>>;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const ManageSchoolDialog = ({ schoolForm, setSchoolForm, open, setOpen }: Props) => {
  const { handleTextChange, handleSave, handleClose } = useManageSchoolDialog({
    schoolForm,
    setSchoolForm,
    setOpen,
    open,
  });
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle variant="h4" sx={{ fontSize: 44, textAlign: "center" }}>
        Manage School
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CustomAvatar
            size={100}
            fileName={schoolForm?.avatar ?? ""}
            collection="schools"
            documentId={schoolForm.id}
            formField="avatarURL"
            setForm={setSchoolForm}
          />
        </Box>
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="School Name"
              variant="outlined"
              value={schoolForm.name}
              name="name"
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Primary Contact's Name"
              variant="outlined"
              value={schoolForm.primaryContact}
              name="primaryContact"
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Primary Contact's Phone"
              variant="outlined"
              value={schoolForm.primaryPhone}
              name="primaryPhone"
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={schoolForm.primaryEmail}
              name="primaryEmail"
              onChange={handleTextChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageSchoolDialog;
