import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { AnswerTypeForm, QuestionRecord } from "../../types/types";
import { Alert, Box, TextField, Typography } from "@mui/material";
import { BLANK_ANSWER_TYPE_FORM } from "../../libraries/blankForms";

import { useRecoilValue } from "recoil";
import { studentAnswersAtom } from "../../recoil/answersAtoms";

type Props = {
  question: QuestionRecord;
  setAnswer: Dispatch<SetStateAction<AnswerTypeForm>>;
  answer: AnswerTypeForm;
  setValid?: Dispatch<SetStateAction<boolean>>;
};

const GPAQuestion = ({ question, setAnswer, answer, setValid }: Props) => {
  const answers = useRecoilValue(studentAnswersAtom);
  const [gpaError, setGpaError] = useState<string | null>(null);

  useEffect(() => {
    if (!answers) return;
    const currentAnswer = answers.find((ans) => ans.questionId === question.id);
    if (!currentAnswer) {
      setAnswer({ ...BLANK_ANSWER_TYPE_FORM });
      return;
    }
    setAnswer((pV) => ({ ...BLANK_ANSWER_TYPE_FORM, gpa: currentAnswer.gpa }));
  }, [answers, question.id, setAnswer]);

  const handleFirstGPAChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const number = Number(value);
    setGpaError(null);
    setValid && setValid(true);
    if (number > answer.gpa[1]) {
      setGpaError("The first number must be less than or equal to the second number.");
      setValid && setValid(false);
    }
    if (number < 0 || answer.gpa[1] < 0 || number > 10 || answer.gpa[1] > 10) {
      setGpaError("The numbers must be greater than 0 and less than 10.");
      setValid && setValid(false);
    }
    setAnswer((pV) => ({
      ...pV,
      gpa: [number, pV.gpa[1]],
    }));
  };

  const handleSecondGPAChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const number = Number(value);
    setGpaError(null);
    setValid && setValid(true);
    if (answer.gpa[0] > number) {
      setGpaError("The first number must be less than or equal to the second number.");
      setValid && setValid(false);
    }
    if (answer.gpa[0] < 0 || number < 0 || answer.gpa[0] > 10 || number > 10) {
      setGpaError("The numbers must be greater than 0 and less than 10.");
      setValid && setValid(false);
    }
    setAnswer((pV) => ({
      ...pV,
      gpa: [pV.gpa[0], number],
    }));
  };
  return (
    <>
      <Typography variant="h5" textAlign={"center"}>
        {question.question}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" textAlign={"center"}>
        {question.helperText}
      </Typography>

      <Box sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <TextField
          type="number"
          value={answer.gpa[0] === 0 ? "" : answer.gpa[0]}
          onChange={handleFirstGPAChange}
        />
        <Typography sx={{ ml: 1, mr: 1 }}>out of</Typography>
        <TextField
          type="number"
          value={answer.gpa[1] === 0 ? "" : answer.gpa[1]}
          onChange={handleSecondGPAChange}
        />
      </Box>
      {gpaError && (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error">{gpaError}</Alert>
        </Box>
      )}
    </>
  );
};

export default GPAQuestion;
