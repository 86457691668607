import { Button, Box, Divider } from "@mui/material";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "../../recoil/userAtoms";
import StaffSelectSection from "./StaffSelectSection";
import { useAuth } from "../../contexts/AuthContext";
// import SelectOptions from "./SelectOptions";

type Props = {
  handleClose: () => void;
};

export default function StaffMenuDropdown({ handleClose }: Props) {
  const { logout } = useAuth();
  const userType = useRecoilValue(userTypeAtom);

  return (
    <>
      {/* can set permissions here */}
      {userType && (
        <>
          <Box sx={{ padding: 2, textAlign: "center" }}>
            {/* {userType >= UserType.Developer && (
              <Link to="/developer-settings" className="navLinkMenu">
                <MenuItem
                  sx={{ width: "100%", display: "flex", justifyContent: "center" }}
                  onClick={handleClose}
                >
                  Developer Settings
                </MenuItem>
              </Link>
            )}
            {userType >= UserType.DistrictAdmin && (
              <Link to="/district-settings" className="navLinkMenu">
                <MenuItem
                  sx={{ width: "100%", display: "flex", justifyContent: "center" }}
                  onClick={handleClose}
                >
                  District Settings
                </MenuItem>
              </Link>
            )}
            {userType >= UserType.SchoolAdmin && (
              <Link to="/school-settings" className="navLinkMenu">
                <MenuItem
                  sx={{ width: "100%", display: "flex", justifyContent: "center" }}
                  onClick={handleClose}
                >
                  School Settings
                </MenuItem>
              </Link>
            )}
            {userType >= UserType.Staff && (
              <Link to="/staff-dashboard" className="navLinkMenu">
                <MenuItem
                  sx={{ width: "100%", display: "flex", justifyContent: "center" }}
                  onClick={handleClose}
                >
                  Staff Dashboard
                </MenuItem>
              </Link>
            )}
            {userType === UserType.Developer && (
              <Link to="/upload-providers" className="navLinkMenu">
                <MenuItem
                  sx={{ width: "100%", display: "flex", justifyContent: "center" }}
                  onClick={handleClose}
                >
                  Upload Providers
                </MenuItem>
              </Link>
            )}
            <Divider />
            {userType >= UserType.Developer && (
              <Link to="/student-csv" className="navLinkMenu">
                <MenuItem
                  sx={{ width: "100%", display: "flex", justifyContent: "center" }}
                  onClick={handleClose}
                >
                  Student CSV
                </MenuItem>
              </Link>
            )}
            <Divider /> */}
            <StaffSelectSection />
            <Divider />
            <Button
              sx={{ mt: 2, p: 2 }}
              variant="outlined"
              fullWidth
              id="logout-button"
              color="primary"
              onClick={() => logout()}
            >
              Logout
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
