import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ChatEntry } from "../../../shared/types/types";
import Message from "./Message";
import ProviderDetailsDialog from "../../../shared/components/providerDetails/ProviderDetailsDialog";

type Props = {
  chatHistory: ChatEntry[];
};

const ChatDisplay = ({ chatHistory }: Props) => {
  const [providerId, setProviderId] = useState<string | null>(null);
  const [providerOpen, setProviderOpen] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const chatForDisplay = [...chatHistory];
  //remove the initial context prompt
  chatForDisplay.shift();

  // Scroll to the bottom of the chat on update
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [chatHistory]);

  useEffect(() => {
    if (providerId) {
      setProviderOpen(true);
    }
  }, [providerId]);

  return (
    <>
      <Box
        ref={scrollRef}
        sx={{
          p: 1,
          height: "80VH",
          overflow: "scroll",
        }}
      >
        {chatForDisplay.map((entry, index) => (
          <Message index={index} entry={entry} setProviderId={setProviderId} />
        ))}
      </Box>
      {providerId && (
        <ProviderDetailsDialog
          providerId={providerId}
          open={providerOpen}
          setOpen={setProviderOpen}
        />
      )}
    </>
  );
};

export default ChatDisplay;
