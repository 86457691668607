import React, { useEffect, useState } from "react";
import { SetterOrUpdater, useRecoilState, useRecoilValue } from "recoil";
import { allStudentApplicationDataAtom, loggedInMentorAtom, selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { allMatchedProgramsAtom, allWriteInProgramsAtom, programsAtom, studentWriteInProgramsAtom } from "../../../shared/recoil/providerAtoms";
import { ApplicationDataRecord, Provider_Program, StudentRecord, WriteInProgramRecord } from "../../../shared/types/types";

type Return = {
  writeInPrograms: WriteInProgramRecord[];
  selectedStudent: StudentRecord | null;
  favoritedPrograms: Provider_Program[];
  allApplicationData: ApplicationDataRecord[];
  setAppApplicationData: SetterOrUpdater<ApplicationDataRecord[]>;
};

const useApplicationTracker = (): Return => {
  const loggedInMentor = useRecoilValue(loggedInMentorAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const studentWriteInPrograms = useRecoilValue(studentWriteInProgramsAtom);
  const allWriteInPrograms = useRecoilValue(allWriteInProgramsAtom);
  const [writeInPrograms, setWriteInPrograms] = useState<WriteInProgramRecord[]>([]);
  const [allApplicationData, setAppApplicationData] = useRecoilState(allStudentApplicationDataAtom);
  const programs = useRecoilValue(programsAtom);
  const allMatchedPrograms = useRecoilValue(allMatchedProgramsAtom);
  const [favoritedPrograms, setFavoritedPrograms] = useState<Provider_Program[]>([]);

  useEffect(() => {
    const allPrograms = loggedInMentor ? allMatchedPrograms : programs;
    const studentFavoritePrograms = allPrograms.filter((program) =>
      selectedStudent?.favoriteProviderIds.includes(program.id)
    );
    const studentFavoriteRecommendedPrograms = programs.filter((program) =>
      selectedStudent?.favoriteRecommendedProgramIds.includes(program.id)
    );
    setFavoritedPrograms([...studentFavoritePrograms, ...studentFavoriteRecommendedPrograms]);
  }, [
    allMatchedPrograms,
    loggedInMentor,
    programs,
    selectedStudent?.favoriteProviderIds,
    selectedStudent?.favoriteRecommendedProgramIds,
  ]);

  useEffect(() => {
    if (loggedInMentor) {
      const filteredWriteInPrograms = allWriteInPrograms.filter(
        (writeInProgram) => writeInProgram.studentId === selectedStudent?.id
      );
      setWriteInPrograms(filteredWriteInPrograms);
    } else {
      setWriteInPrograms(studentWriteInPrograms);
    }
  }, [allWriteInPrograms, loggedInMentor, selectedStudent?.id, studentWriteInPrograms]);

  return {
    writeInPrograms,
    selectedStudent,
    favoritedPrograms,
    allApplicationData,
    setAppApplicationData,
  };
};

export default useApplicationTracker;
