import { Avatar, Badge, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useCallback,
  ChangeEvent,
} from "react";
import useUploadImage from "../hooks/useUploadImage";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { selectedStudentAtom } from "../recoil/userAtoms";
import { useSetRecoilState } from "recoil";
import { Collection } from "../types/enums";
import { mentorUsersAtom } from "../recoil/providerAtoms";

type Props = {
  fileName: string;
  documentId: string;
  collection: string;
  size: number;
  setForm?: Dispatch<SetStateAction<any>>;
  formField?: string;
  edit?: boolean;
  propAvatarURL?: string;
};

const CustomAvatar = ({
  fileName,
  documentId,
  collection,
  size,
  setForm,
  formField,
  edit,
  propAvatarURL,
}: Props) => {
  const { uploadImage } = useUploadImage();
  const storage = getStorage();
  const [avatarURL, setAvatarURL] = useState<string | null>(propAvatarURL ? propAvatarURL : null);
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);
  const setMentorUsers = useSetRecoilState(mentorUsersAtom);

  useEffect(() => {
    if (propAvatarURL) return;
    const getImageURL = async () => {
      let url = "";
      if (fileName.length > 5) {
        try {
          try {
            url = await getDownloadURL(ref(storage, `${fileName}_200x200`));
          } catch (error) {
            url = await getDownloadURL(ref(storage, `${fileName}`));
          }
        } catch (error) {
          console.error(error);
        }
      }

      setAvatarURL(url);
    };
    getImageURL();
  }, [fileName, propAvatarURL, storage]);

  const handleSelectImage = useCallback(
    async ({ currentTarget: { files } }: ChangeEvent<HTMLInputElement>) => {
      if (!files) return;
      const newFileName = await uploadImage({
        image: files[0],
        col: collection,
        id: documentId,
      });
      if (!newFileName) return;
      if (collection == Collection.Students) {
        setSelectedStudent((pV) => (pV ? { ...pV, avatar: newFileName } : null));
      }
      if (collection == Collection.MentorUsers) {
        setMentorUsers((pV) =>
          pV.map((pVM) =>
            pVM.id === documentId ? { ...pVM, avatar: newFileName } : pVM
          )
        );
      }
      setForm && setForm((pV: any) => ({ ...pV, avatar: newFileName }));
    },
    [uploadImage, collection, documentId, setForm, setSelectedStudent]
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        textAlign: "center",
      }}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={
          <>
            {true && (
              <label htmlFor={`id-${fileName}`}>
                <input
                  hidden
                  accept="image/*"
                  id={`id-${fileName}`}
                  type="file"
                  onChange={handleSelectImage}
                />
                {edit && (
                  <IconButton aria-label="upload picture" component="span">
                    <EditIcon sx={{ width: 15, height: 15 }} />
                  </IconButton>
                )}
              </label>
            )}
          </>
        }
      >
        <Avatar
          alt={fileName}
          src={avatarURL ? avatarURL : ""}
          sx={{ width: size, height: size }}
        />
      </Badge>
    </Box>
  );
};

export default CustomAvatar;
