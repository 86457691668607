import { useState } from "react";
import { useErrorContext } from "../contexts/ErrorContext";

type UseGetFromAPIProps = {
  url: string;
  body: {};
};
const useFetch = () => {
  const [loading, setLoading] = useState(false);
  const { setErrorMessage } = useErrorContext();

  const getFromAPI = async <T>({ url, body }: UseGetFromAPIProps) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      setLoading(false);
      return data;
    } catch (error: any) {
      console.error(error.message);
      if (error instanceof Error) {
        console.error(error.message);
        setErrorMessage(error.message);
      }
      console.error("Something went wrong");
      setErrorMessage("Something went wrong");
      setLoading(false);
      return null;
    }
  };
  return { getFromAPI, loading };
};

export default useFetch;
