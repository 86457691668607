import { useRecoilState, useRecoilValue } from "recoil";
import { loggedInStaffAtom, selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { Message, StudentRecord } from "../../../shared/types/types";
import { useEffect, useState } from "react";
import { getDatabase, onValue, ref, set } from "firebase/database";
import { updateMessages } from "../../../shared/utils/messageFunctions";
import { verifyPhoneNumber } from "../../../shared/utils/functions";

type Props = {
  student: StudentRecord;
};

export const useStudentPanelRow = ({ student }: Props) => {
  const db = getDatabase();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const [notifications, setNotifications] = useState(0);
  const [lastMessageTimestamp, setLastMessageTimestamp] = useState("");
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);

  //if the current student is the selected student (which means the user is looking at that students messages), reset the number of notifications of new messages to zero
  useEffect(() => {
    if (!loggedInStaff || !selectedStudent) return;
    if (selectedStudent.id === student.id) {
      const notificationRef = ref(
        db,
        `notifications/${loggedInStaff.smsNumber}_${selectedStudent.phone}`
      );
      set(notificationRef, 0);
    }
  }, [selectedStudent, loggedInStaff, db, notifications, student]);

  //subscribe to any new messages for this student
  useEffect(() => {
    if (!loggedInStaff) return;
    const notificationRef = ref(db, `notifications/${loggedInStaff.smsNumber}_${student.phone}`);
    const unsubscribeNotifications = onValue(notificationRef, (snapshot) => {
      const numberNewMessages = snapshot.val() ? parseInt(snapshot.val()) : 0;
      setNotifications(numberNewMessages);
    });

    const messagesRef = ref(db, `messages/${loggedInStaff.smsNumber}_${student.phone}`);
    const unsubscribeIncomingMessages = onValue(messagesRef, (snapshot) => {
      const newData = snapshot.val();
      const messages: Message[] = updateMessages({
        newMessages: newData,
        existingMessages: [],
        selectedStudent: student,
      });

      const lastMatchingMessage = messages.reverse().find((message) =>
        message.staffId === loggedInStaff?.id || message.studentId === student.id
      );
      const lastMatchingMessageTime = lastMatchingMessage ? lastMatchingMessage.timestamp : "";
      setLastMessageTimestamp(lastMatchingMessageTime);
    });
    
    return () => {
      unsubscribeNotifications();
      unsubscribeIncomingMessages();
    };
  }, [student, loggedInStaff, db]);

  const handleSelect = () => {
    setSelectedStudent(student);
  };

  const hasPhone = verifyPhoneNumber(student.phone);

  return { handleSelect, selectedStudent, notifications, hasPhone, lastMessageTimestamp };
};
