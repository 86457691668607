import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { AddressComponents, Provider } from "../../../shared/types/types";
import { Locale } from "../../../shared/types/enums";
import GooglePlacesAutocomplete from "../../../shared/components/GooglePlacesAutocomplete";
import { validationType } from "../../hooks/providerForm/useEditProviderForm";

type Props = {
  validation: validationType;
  providerForm: Provider;
  handleTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSetAddress: (addressComponents: AddressComponents) => void;
  handleSelectChange: (e: SelectChangeEvent) => void;
  handleBlur: (fieldName: string) => void;
};
declare global {
  interface Window {
    google: any;
  }
}

const AboutYourOrganization = ({
  validation,
  providerForm,
  handleTextChange,
  handleSetAddress,
  handleSelectChange,
  handleBlur,
}: Props) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          About Your Organization
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="providerName"
          fullWidth
          onChange={handleTextChange}
          label="Provider Name"
          value={providerForm.providerName}
          error={!validation.providerName.validated && validation.providerName.show}
          helperText={validation.providerName.show ? validation.providerName.message : ""}
          onBlur={() => handleBlur("providerName")}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="providerDescription"
          multiline
          rows={2}
          onChange={handleTextChange}
          label="Provider Description"
          value={providerForm.providerDescription}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <GooglePlacesAutocomplete
          name="address"
          onChange={handleSetAddress}
          label="Address"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          onChange={handleTextChange}
          name="homeURL"
          fullWidth
          label="Home URL"
          value={providerForm.homeURL}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="locale-select-label">Business Type</InputLabel>
          <Select
            fullWidth
            id="type-select"
            name="ownership"
            onChange={handleSelectChange}
            value={providerForm.ownership ?? ""}
            label="Buseinss Type"
          >
            <MenuItem value="For-Profit">For-Profit</MenuItem>
            <MenuItem value="Non-Profit">Non-Profit</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="locale-select-label">Setting</InputLabel>
          <Select
            fullWidth
            id="locale-select"
            name="locale"
            onChange={handleSelectChange}
            value={providerForm.locale ?? ""}
            label="Setting"
          >
            <MenuItem value={Locale.Medium_City}>{Locale.Medium_City}</MenuItem>
            <MenuItem value={Locale.Small_City}>{Locale.Small_City}</MenuItem>
            <MenuItem value={Locale.Small_Town}>{Locale.Small_Town}</MenuItem>
            <MenuItem value={Locale.Rural}>{Locale.Rural}</MenuItem>
            <MenuItem value={Locale.Suburban_Area}>{Locale.Suburban_Area}</MenuItem>
            <MenuItem value={Locale.Large_City}>{Locale.Large_City}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default AboutYourOrganization;
