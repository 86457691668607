import { Box, Typography } from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { Color } from "../../../shared/types/enums";
import { useRecoilValue } from "recoil";
import { stagesAtom } from "../../../shared/recoil/stageAtoms";

type Props = {
  stageId: string;
};

const StudentTableStage = ({ stageId }: Props) => {
  const stages = useRecoilValue(stagesAtom);
  const currentStage = stages.find((stage) => stage.id === stageId);
  const currentStageIndex = stages.findIndex((stage) => stage.id === stageId);

  type FormatColorProps = {
    index: number;
    currentStageIndex: number;
  };

  const formatDotColor = ({ index, currentStageIndex }: FormatColorProps) => {
    if (index === currentStageIndex) {
      return Color.Chalkboard;
    } else if (index < currentStageIndex) {
      return Color.Mint;
    } else {
      return "#999";
    }
  };

  const formatLineColor = ({ index, currentStageIndex }: FormatColorProps) => {
    if (index === currentStageIndex) {
      return "#999";
    } else if (index < currentStageIndex) {
      return Color.Mint;
    } else {
      return "#999";
    }
  };
  return (
    <>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {stages.map((stage, index) => (
            <Box key={stage.id} sx={{ display: "flex", alignItems: "center" }}>
              <CircleIcon
                sx={{
                  fontSize: 14,
                  color: formatDotColor({ index, currentStageIndex }),
                }}
              />
              {index !== stages.length - 1 && (
                <div
                  style={{
                    height: 0,
                    borderTop: "1px solid",
                    borderColor: formatLineColor({
                      index: index,
                      currentStageIndex,
                    }),
                    width: 24,
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        {currentStage && (
          <Typography variant="body1" sx={{ fontWeight: 400, fontSize: 12, mt: "2px" }}>
            {currentStage.name}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default StudentTableStage;
