import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import { useEffect, useState } from "react";
import useAutoAdmitMessage from "../useAutoAdmitMessage";
import { Provider_Program } from "../../types/types";
import { getAnswers } from "../../utils/dbProviderFunctions";
import { getProviderTargetLevel } from "../../utils/targetTypeUtils";

const useProgramCard = (program: Provider_Program) => {
  const [schoolType, setSchoolType] = useState("");
  const selectedStudent = useRecoilValue(selectedStudentAtom);

  const { lowIncome } = useAutoAdmitMessage({
    admit: program?.autoAdmit ?? false,
    scholarship: program?.autoScholarship ?? false,
  });

  useEffect(() => {
    const getSchoolType = async () => {
      if (!selectedStudent || !program) return;
      const response = await getAnswers({ studentId: selectedStudent.id, questionId: "gpa" });

      if (response.selectedAnswer) {
        const gpaPercentage = response.selectedAnswer.gpa[0] / response.selectedAnswer.gpa[1];
        const schoolTypeResult = await getProviderTargetLevel({
          providerId: program.providerId,
          GPA: gpaPercentage,
          admissionPercent: program.admissionPercent,
        });
        setSchoolType(schoolTypeResult);
      }
    };
    getSchoolType();
  }, [program, selectedStudent]);

  type RenderDurationProps = {
    months: number;
    years: number;
  };

  const renderDuration = ({ months, years }: RenderDurationProps) => {
    if (months) {
      return `${months} months`;
    } else if (years) {
      return `${years} years`;
    } else {
      return "Unknown";
    }
  };

  return {
    selectedStudent,
    renderDuration,
    lowIncome,
    schoolType,
  };
};

export default useProgramCard;
