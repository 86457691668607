import { Box, Divider, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import DisplayBox from "../../../shared/components/DisplayBox";
import { useRecoilValue } from "recoil";
import { studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import { questionsAtom } from "../../../shared/recoil/questionAtoms";
import { PathwayHeader } from "../../../shared/types/enums";
import EditDetailsDialog from "./EditDetailsDialog";

const CareerPathways = () => {
  const answers = useRecoilValue(studentAnswersAtom);
  const questions = useRecoilValue(questionsAtom);
  const [editQuestionId, setEditQuestionId] = useState<string | null>(null);
  const pathways = answers?.find((answer) => answer.questionId === "pathways")?.matrix;
  const mostInterestedPathways = pathways?.filter(
    (pathway) => pathway.headerId === PathwayHeader.MostInterested
  );
  const pathwayQuestion = questions.find((question) => question.id === "pathways");
  const formatPathway = (willowIndustryId: string) => {
    const industry = pathwayQuestion?.options?.find((option) => option.id === willowIndustryId);
    return industry?.text;
  };
  const handleEditClick = (questionId: string) => {
    setEditQuestionId(questionId);
  };
  return (
    <>
      {mostInterestedPathways && (
        <Box sx={{ mt: 2 }}>
          <DisplayBox
            title="My most interested pathways"
            showEditButton
            onClickEdit={() => handleEditClick("pathways")}
          >
            {mostInterestedPathways.map((pathway, index) => (
              <Box key={pathway.optionId}>
                {index !== 0 && <Divider />}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ padding: 2, color: "#000" }} variant="body1">
                    {formatPathway(String(pathway.optionId))}
                  </Typography>
                </Box>
              </Box>
            ))}
          </DisplayBox>
        </Box>
      )}
      <EditDetailsDialog editQuestionId={editQuestionId} setEditQuestionId={setEditQuestionId} />
    </>
  );
};

export default CareerPathways;
