import React, { useEffect, useState } from "react";
import {
  allMatchedProgramsAtom,
  programsAtom,
} from "../../recoil/providerAtoms";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import {
  loggedInStaffAtom,
  selectedStudentAtom,
  userTypeAtom,
} from "../../recoil/userAtoms";
import { Provider_Program, ReviewRecord } from "../../types/types";
import { Collection, Color, UserType } from "../../types/enums";
import useGetDoc from "../useGetDoc";

type DescriptionModalType = {
  title: string;
  description: string;
  open: boolean;
};

type Props = {
  programId: string;
};

const useProgramDetailsContainer = ({ programId }: Props) => {
  const [whatIsROIOpen, setWhatIsROIOpen] = useState(false);
  const [currentProgram, setCurrentProgram] = useState<Provider_Program | null>(
    null
  );
  const [showAutoMessage, setShowAutoMessage] = useState(false);
  const [editProgramOpen, setEditProgramOpen] = useState(false);
  const [applyLink, setApplyLink] = useState<string | null>(null);
  const [canEdit, setCanEdit] = useState(false);
  const [getProgramTrigger, setGetProgramTrigger] = useState(false);
  const { sendRequest: getDoc } = useGetDoc();

  const [reviews, setReviews] = useState<ReviewRecord | null>(null);

  const [descriptionModal, setDescriptionModal] =
    useState<DescriptionModalType>({
      title: "",
      description: "",
      open: false,
    });
  const programs = useRecoilValue(programsAtom);
  const allMatchedPrograms = useRecoilValue(allMatchedProgramsAtom);
  const userType = useRecoilValue(userTypeAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const navigate = useNavigate();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  useEffect(() => {
    if (!currentProgram) return;
    const getReviews = async () => {
      const results = await getDoc<ReviewRecord>({
        col: Collection.Reviews,
        id: currentProgram.providerId,
      });
      setReviews(results);
    };
    getReviews();
  }, [currentProgram, getDoc]);

  useEffect(() => {
    if (!loggedInStaff) return;
    if (loggedInStaff.userType > 4) {
      setCanEdit(true);
    }
  }, [loggedInStaff]);

  useEffect(() => {
    const getProgram = async () => {
      const results = await fetch(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getProgram`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            programId: programId,
          }),
        }
      );
      const { program } = (await results.json()) as {
        program: Provider_Program;
      };
      setCurrentProgram(program ? program : null);
    };

    const currentPrograms =
      userType === UserType.Student ? programs : allMatchedPrograms;
    const currentProgram = currentPrograms.find(
      (program) => program.id === programId
    );
    if (currentProgram) {
      setCurrentProgram(currentProgram);
    } else {
      getProgram();
    }
  }, [allMatchedPrograms, programs, programId, userType, getProgramTrigger]);

  useEffect(() => {
    if (selectedStudent && currentProgram) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const isAfterJuly1st = currentDate.getMonth() >= 6;

      if (selectedStudent.graduationYear === currentYear.toString()) {
        setShowAutoMessage(
          Boolean(currentProgram.autoAdmit) && !isAfterJuly1st
        );
      } else if (
        selectedStudent.graduationYear === (currentYear + 1).toString()
      ) {
        setShowAutoMessage(Boolean(currentProgram.autoAdmit) && isAfterJuly1st);
      }
    } else {
      setShowAutoMessage(false);
    }
  }, [currentProgram, selectedStudent]);

  const navigateBack = () => {
    navigate(-1);
  };

  const openWhatIsROI = () => {
    setWhatIsROIOpen(true);
  };

  const handleDescriptionModalClose = () => {
    setDescriptionModal({
      title: "",
      description: "",
      open: false,
    });
  };

  useEffect(() => {
    if (!currentProgram) return;
    const { applyURL, homeURL } = currentProgram;
    if (applyURL && applyURL.length > 3) {
      if (applyURL.includes("http")) {
        setApplyLink(applyURL);
      } else {
        setApplyLink(`https://${applyURL}`);
      }
    } else if (homeURL && homeURL.length > 3) {
      if (homeURL.includes("http")) {
        setApplyLink(homeURL);
      } else {
        setApplyLink(`https://${homeURL}`);
      }
    }
  }, [currentProgram]);

  return {
    currentProgram,
    showAutoMessage,
    navigateBack,
    openWhatIsROI,
    whatIsROIOpen,
    setWhatIsROIOpen,
    handleDescriptionModalClose,
    descriptionModal,
    applyLink,
    setDescriptionModal,
    editProgramOpen,
    setEditProgramOpen,
    canEdit,
    setGetProgramTrigger,
    reviews,
  };
};

export default useProgramDetailsContainer;
