import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { Dispatch, FormEvent, SetStateAction } from "react";
import { Program, Provider } from "../../../shared/types/types";
import Loading from "../../../shared/components/Loading";
import ManageProgramForm from "./ManageProgramForm";
import useManageProgramDialog from "../../hooks/dashboard/useManageProgramDialog";

type Props = {
  provider: Provider;
  program?: Program | null;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setGetProgramTrigger: Dispatch<SetStateAction<boolean>>;
};

const ManageProgramDialog = ({ provider, program, open, setOpen, setGetProgramTrigger }: Props) => {
  const { handleSave, form, setForm, loading, handleClose } = useManageProgramDialog({
    provider,
    program,
    setOpen,
  });

  const handleClickSave = async (e: FormEvent) => {
    e.preventDefault();
    await handleSave();
    setOpen(false);
    setGetProgramTrigger((prev) => !prev);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleClickSave}>
        <DialogTitle sx={{ color: "black" }}>{`${program ? "Edit" : "Add"} ${
          provider?.providerName ?? ""
        } Program`}</DialogTitle>
        <DialogContent sx={{ minHeight: 470 }}>
          {loading ? <Loading size={80} /> : <ManageProgramForm form={form} setForm={setForm} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ManageProgramDialog;
