import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { Provider_Program } from "../../../shared/types/types";
import { parseProgramResponse } from "../../../shared/utils/parsers";

type Return = {
	sortedPrograms: Provider_Program[];
};

const useRecommendedPrograms = (): Return => {
	const selectedStudent = useRecoilValue(selectedStudentAtom);
  const [recommendedPrograms, setRecommendedPrograms] = useState<Provider_Program[]>([]);
  const [sortedPrograms, setSortedPrograms] = useState<Provider_Program[]>([]);

  useEffect(() => {
    const getList = async () => {
      if (!selectedStudent) return;
      try {
        const results = await fetch(
          `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getListOfPrograms`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              programIds: [...selectedStudent.staffRecommendedProgramIds],
            }),
          }
        );
        const { rows } = (await results.json()) as { rows: Provider_Program[] };
        setRecommendedPrograms(parseProgramResponse(rows));
      } catch (error) {
        console.error("Error fetching recommended programs", error);
      }
    };
    getList();
  }, [selectedStudent]);

  useEffect(() => {
    const sortedProgramsTemp = [...recommendedPrograms];
    sortedProgramsTemp.sort((a, b) => {
      //sort programs by favorite
      const aFavorited =
        selectedStudent?.favoriteRecommendedProgramIds.includes(a.id) ||
        selectedStudent?.favoriteProviderIds.includes(a.id);
      const bFavorited =
        selectedStudent?.favoriteRecommendedProgramIds.includes(b.id) ||
        selectedStudent?.favoriteProviderIds.includes(b.id);

      // If only one of the programs is favorited, prioritize it
      if (aFavorited && !bFavorited) {
        return -1;
      } else if (!aFavorited && bFavorited) {
        return 1;
      }

      //sort programs by willowROI
      if (a.willowROI && b.willowROI) {
        return b.willowROI - a.willowROI;
      } else {
        return 0;
      }
    });

    setSortedPrograms(sortedProgramsTemp);
  }, [
    recommendedPrograms,
    selectedStudent?.favoriteProviderIds,
    selectedStudent?.favoriteRecommendedProgramIds,
  ]);

	return {
		sortedPrograms
	};
}

export default useRecommendedPrograms;