import React from "react";
import { Box } from "@mui/material";
import DisplayBox from "../../../shared/components/DisplayBox";
import StatsDetails from "./StatsDetails";

const StudentStats = () => {
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DisplayBox title="My Stats">
          <StatsDetails />
        </DisplayBox>
      </Box>
    </>
  );
};

export default StudentStats;
