import { useState, useEffect, useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStaffAtom, selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { buildInterestPrompt } from "../../../shared/utils/aiUtils";
import { schoolsAtom } from "../../../shared/recoil/districtAtoms";
import { StaffAlmaChatRecord, ChatEntry } from "../../../shared/types/types";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import { Collection } from "../../../shared/types/enums";
import { allAnswersAtom, studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import { questionsAtom } from "../../../shared/recoil/questionAtoms";
import useChatManagement from "./useChatManagement";
import useAIInteractions from "./useAIInteractions";

const useAlmaContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState("");
  const schools = useRecoilValue(schoolsAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const studentAnswers = useRecoilValue(studentAnswersAtom);
  const questions = useRecoilValue(questionsAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const setStudentAnswers = useSetRecoilState(studentAnswersAtom);
  const allAnswers = useRecoilValue(allAnswersAtom);
  const {
    resetChat,
    buildChatEntry,
    chatHistory,
    setChatHistory,
    addNewChatRecord,
    setStaffAlmaChats,
    currentStaffAlmaChat,
  } = useChatManagement();
  const { getAlmaResponse, loading: aiLoading } = useAIInteractions();

  useEffect(() => {
    setStudentAnswers(allAnswers.filter((answer) => answer.studentId === selectedStudent?.id));
  }, [allAnswers, selectedStudent?.id, setStudentAnswers]);

  type UpdateChatProps = {
    currentStaffAlmaChat: StaffAlmaChatRecord;
    newEntry: ChatEntry;
    responseToSave: ChatEntry;
  };

  //helper updates existing chat record if one already exists (they already started a chat)
  const updateNewChatRecord = useCallback(
    async ({ currentStaffAlmaChat, newEntry, responseToSave }: UpdateChatProps) => {
      const data = {
        chatHistory: [...currentStaffAlmaChat.chatHistory, newEntry, responseToSave],
      };

      await updateDoc({
        col: Collection.StaffAlmaChats,
        id: currentStaffAlmaChat.id,
        data: data,
      });
      return data;
    },
    [updateDoc]
  );

  const handleSubmit = useCallback(
    async (newMessage: string) => {
      if (!loggedInStaff) return;
      const chatHistoryForAI = [...chatHistory];
      let studentContext = "";
      if (selectedStudent) {
        const school = schools.find((school) => school.id === selectedStudent.schoolId);
        studentContext = buildInterestPrompt({
          studentAnswers,
          questions,
          selectedStudent,
          school,
          openingPrompt: "",
        });
      }
      const newEntry = buildChatEntry(newMessage);
      setChatHistory((prev) => [...prev, newEntry]);
      setIsLoading(true);
      try {
        const responseToSave = await getAlmaResponse({
          chatHistoryForAI,
          newEntry,
          studentContext,
        });
        if (!responseToSave) return;
        setChatHistory((prev) => [...prev, responseToSave]);
        if (!currentStaffAlmaChat) {
          const record = await addNewChatRecord({
            chatHistory,
            newEntry,
            responseToSave,
            loggedInStaff,
          });
          if (!record) return;
          setStaffAlmaChats([record]);
        } else {
          const data = await updateNewChatRecord({
            currentStaffAlmaChat,
            newEntry,
            responseToSave,
          });
          setStaffAlmaChats((prev) =>
            prev.map((chat) =>
              chat.id === currentStaffAlmaChat.id
                ? { ...chat, chatHistory: data.chatHistory }
                : chat
            )
          );
        }
        setError(null);
        setMessage("");
      } catch (err) {
        setError("Error communicating with the API");
        console.error(err)
      } finally {
        setIsLoading(false);
        setMessage("");
      }
    },
    [
      addNewChatRecord,
      buildChatEntry,
      chatHistory,
      currentStaffAlmaChat,
      getAlmaResponse,
      loggedInStaff,
      questions,
      schools,
      selectedStudent,
      setChatHistory,
      setStaffAlmaChats,
      studentAnswers,
      updateNewChatRecord,
    ]
  );
  return { chatHistory, isLoading, error, message, setMessage, handleSubmit, resetChat };
};

export default useAlmaContainer;
