import { Autocomplete, Box, Button, Checkbox, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useRecoilValue } from "recoil";
import { willowSubIndustriesAtom } from "../../recoil/providerAtoms";
import { Provider_Program, WillowSubIndustryRecord } from "../../types/types";
import useExplorePrograms from "../../hooks/exploreProggrams/useExplorePrograms";
import { Locale } from "../../types/enums";
import ProviderSearch from "./ProviderSearch";
import locations from "../../libraries/locationsForProviders.json";

type Props = {
  setProgramsToExplore: React.Dispatch<React.SetStateAction<Provider_Program[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Location = {
  id: string;
  name: string;
  type: string;
};

const FILTER_OPTIONS = [
  "In a City",
  "In a Suburb",
  "In a Town or Rural Area",
  "HBCU",
  "No Religious Affiliation",
  "Has Religious Affiliation",
  "Admission Rate of 50% or Less",
  "Admission Rate of 50% or More",
  "Workforce Programs Only",
];

enum Size {
  Small = "Small (Less than 3,000 students)",
  Medium = "Medium (3,000 - 10,000 students)",
  Large = "Large (More than 10,000 students)",
}

const SIZE_OPTIONS = [
  "Small (Less than 3,000 students)",
  "Medium (3,000 - 10,000 students)",
  "Large (More than 10,000 students)",
];

const FiltersPanel = ({ setProgramsToExplore, setLoading, setSearchSubmitted }: Props) => {
  const [providerNameSearch, setProviderNameSearch] = React.useState<string>(""); //search for provider name state
  const [selectedLocations, setSelectedLocations] = React.useState<Location[]>([]);
  const [selectedFilters, setSelectedFilters] = React.useState<string[]>([]);
  const [city, setCity] = useState<string>("");
  const [selectedSubIndustries, setSelectedSubIndustries] = React.useState<
    WillowSubIndustryRecord[]
  >([]);
  const [selectedSizes, setSelectedSizes] = React.useState<string[]>([]);
  const subIndustries = useRecoilValue(willowSubIndustriesAtom);
  const { queryDB } = useExplorePrograms();

  const handleSizeChange = (event: any, value: string[]) => {
    setSelectedSizes(value);
  };

  const handleLocationSelect = (event: any, value: Location[]) => {
    setSelectedLocations(value);
  };

  const handleFilterChange = (event: any, value: string[]) => {
    setSelectedFilters(value);
  };

  const handleSubIndustryChange = (event: any, value: WillowSubIndustryRecord[]) => {
    setSelectedSubIndustries(value);
  };

  // Convert selected filters to query params
  const handleSubmit = async () => {
    setLoading(true);
    setSearchSubmitted(true);
    //filter Criteria Arrays
    const sizes = [];
    const locales = [];

    //Filter Criteria Flags
    let religiousAffiliation = null;
    let admissionPercent = null;
    let workforceOnly = false;
    const hbcu = selectedFilters.includes("HBCU");

    if (selectedFilters.includes("Workforce Programs Only")) {
      workforceOnly = true;
    }

    //The user can select yes and no, or more than 50 / less than 50, which should then cancel out
    if (
      selectedFilters.includes("No Religious Affiliation") &&
      selectedFilters.includes("Has Religious Affiliation") === false
    ) {
      religiousAffiliation = "No";
    }
    if (
      selectedFilters.includes("Has Religious Affiliation") &&
      selectedFilters.includes("No Religious Affiliation") === false
    ) {
      religiousAffiliation = "Yes";
    }
    if (
      selectedFilters.includes("Admission Rate of 50% or Less") &&
      selectedFilters.includes("Admission Rate of 50% or More") === false
    ) {
      admissionPercent = "Less Than 50";
    }
    if (
      selectedFilters.includes("Admission Rate of 50% or More") &&
      selectedFilters.includes("Admission Rate of 50% or Less") === false
    ) {
      admissionPercent = "Greater Than 50";
    }

    //Convert selected sizes to query params
    if (selectedSizes.includes(Size.Small)) {
      sizes.push("Small");
    }
    if (selectedSizes.includes(Size.Medium)) {
      sizes.push("Medium");
    }
    if (selectedSizes.includes(Size.Large)) {
      sizes.push("Large");
    }

    //Convert selected locations to query params
    if (selectedFilters.includes("In a City")) {
      locales.push(Locale.Large_City, Locale.Medium_City, Locale.Small_City);
    }
    if (selectedFilters.includes("In a Suburb")) {
      locales.push(Locale.Suburban_Area);
    }
    if (selectedFilters.includes("In a Town or Rural Area")) {
      locales.push(Locale.Small_Town, Locale.Rural);
    }

    //regions and states are not mutually exclusive, if someone picks south and california, we should return all schools in the south and california
    const regions = selectedLocations
      .filter((location) => location.type === "region")
      .map((location) => location.id);
    const states = selectedLocations
      .filter((location) => location.type === "state")
      .map((location) => location.id);

    //industries are Willow Sub Industries
    const industries = selectedSubIndustries.map((industry) => industry.id);

    //found in useExplorePrograms Hook
    const results = await queryDB({
      sizes,
      regions,
      states,
      industries,
      locales,
      hbcu,
      religiousAffiliation,
      admissionPercent,
      providerNameSearch,
      workforceOnly,
      city,
    });
    setLoading(false);
    setProgramsToExplore(results);
  };

  return (
    <>
      <Paper sx={{ height: "89VH", m: 0, p: 2, overflowY: "auto" }}>
        <Typography variant="h3">Search</Typography>
        <ProviderSearch
          providerNameSearch={providerNameSearch}
          setProviderNameSearch={setProviderNameSearch}
        />
        {/* <ProgramSearch
        programNameSearch={programNameSearch}
        setProgramNameSearch={setProgramNameSearch}
      /> */}
        <Typography sx={{ mt: 1 }} variant="h3">
          Filters
        </Typography>
        <Typography sx={{ mt: 2 }} variant="h5">
          Campus Size
        </Typography>
        <Box sx={{ pl: 1 }}>
          <Autocomplete
            multiple
            id="size-select"
            options={SIZE_OPTIONS}
            disableCloseOnSelect
            onChange={handleSizeChange}
            fullWidth
            value={selectedSizes}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="All Sizes" fullWidth sx={{ mt: 1 }} />
            )}
          />
        </Box>
        <Typography sx={{ mt: 2 }} variant="h5">
          Region / State
        </Typography>
        <Box sx={{ pl: 1 }}>
          <Autocomplete
            multiple
            id="location-select"
            options={locations ? locations : []}
            disableCloseOnSelect
            onChange={handleLocationSelect}
            getOptionLabel={(option) => option.name}
            fullWidth
            value={selectedLocations}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="All Location" fullWidth sx={{ mt: 1 }} />
            )}
          />
        </Box>
        <Typography sx={{ mt: 2 }} variant="h5">
          City
        </Typography>
        <Box sx={{ pl: 1 }}>
          <TextField
            fullWidth
            value={city}
            onChange={(e) => setCity(e.target.value)}
            sx={{ mt: 1 }}
            label="City"
            placeholder="All Cities"
          />
        </Box>
        <Typography sx={{ mt: 2 }} variant="h5">
          Filters
        </Typography>
        <Box sx={{ pl: 1 }}>
          <Autocomplete
            multiple
            id="filters-select"
            options={FILTER_OPTIONS}
            disableCloseOnSelect
            onChange={handleFilterChange}
            fullWidth
            value={selectedFilters}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="No Filters Selected" fullWidth sx={{ mt: 1 }} />
            )}
          />
        </Box>
        <Typography sx={{ mt: 2 }} variant="h5">
          Industries
        </Typography>
        <Box sx={{ pl: 1 }}>
          <Autocomplete
            multiple
            id="industries-select"
            options={subIndustries ? subIndustries : []}
            disableCloseOnSelect
            onChange={handleSubIndustryChange}
            getOptionLabel={(option) => option.name}
            fullWidth
            value={selectedSubIndustries}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="All Industries" fullWidth sx={{ mt: 1 }} />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button color="secondary" onClick={handleSubmit} variant="contained" sx={{ mt: 4 }}>
            Show Results
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default FiltersPanel;
