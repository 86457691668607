import React from "react";
import { Provider } from "../../types/types";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { Locale, Region } from "../../types/enums";
import useEditProviderForm from "../../hooks/providerDetails/useEditProviderForm";

type Props = {
  form: Provider;
  setForm: React.Dispatch<React.SetStateAction<Provider>>;
};

const EditProviderForm = ({ form, setForm }: Props) => {
  const {
    handleTextChange,
    handleSwitchChange,
    handleNumberChange,
    handleSelectChange,
  } = useEditProviderForm({ setForm });
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="providerName"
              fullWidth
              onChange={handleTextChange}
              label="Provider Name"
              value={form.providerName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="providerDescription"
              multiline
              onChange={handleTextChange}
              label="Provider Description"
              value={form.providerDescription}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              onChange={handleTextChange}
              name="city"
              label="City"
              value={form.city}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              onChange={handleTextChange}
              name="state"
              label="State"
              value={form.state}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="region-select-label">Region</InputLabel>
              <Select
                fullWidth
                id="region-select"
                name="region"
                onChange={handleSelectChange}
                value={form?.region ?? ""}
                label="Region"
              >
                <MenuItem value={Region.New_England}>
                  {Region.New_England}
                </MenuItem>
                <MenuItem value={Region.Mid_East}>{Region.Mid_East}</MenuItem>
                <MenuItem value={Region.Great_Lakes}>
                  {Region.Great_Lakes}
                </MenuItem>
                <MenuItem value={Region.Mid_West}>{Region.Mid_West}</MenuItem>
                <MenuItem value={Region.South}>{Region.South}</MenuItem>
                <MenuItem value={Region.South_West}>
                  {Region.South_West}
                </MenuItem>
                <MenuItem value={Region.Rocky_Mountains}>
                  {Region.Rocky_Mountains}
                </MenuItem>
                <MenuItem value={Region.Far_West}>{Region.Far_West}</MenuItem>
                <MenuItem value={Region.Outlying}>{Region.Outlying}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              onChange={handleTextChange}
              name="zip"
              label="Zip"
              value={form.zip}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              onChange={handleTextChange}
              name="ownership"
              label="Ownership"
              value={form.ownership}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="locale-select-label">Locale</InputLabel>
              <Select
                fullWidth
                id="locale-select"
                name="locale"
                onChange={handleSelectChange}
                value={form?.locale ?? ""}
                label="Locale"
              >
                <MenuItem value={Locale.Medium_City}>
                  {Locale.Medium_City}
                </MenuItem>
                <MenuItem value={Locale.Small_City}>
                  {Locale.Small_City}
                </MenuItem>
                <MenuItem value={Locale.Small_Town}>
                  {Locale.Small_Town}
                </MenuItem>
                <MenuItem value={Locale.Rural}>{Locale.Rural}</MenuItem>
                <MenuItem value={Locale.Suburban_Area}>
                  {Locale.Suburban_Area}
                </MenuItem>
                <MenuItem value={Locale.Large_City}>
                  {Locale.Large_City}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="lat"
              label="Lat"
              value={form.lat}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="lon"
              label="Lon"
              value={form.lon}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              onChange={handleTextChange}
              name="religiousAffiliation"
              label="Religious Affiliation"
              value={form.religiousAffiliation}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="admissionPercent"
              label="Admission Percent"
              value={form.admissionPercent}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="satReadingMid"
              label="Sat Reading Mid"
              value={form.satReadingMid}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="satMathMid"
              label="Sat Math Mid"
              value={form.satMathMid}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="actMid"
              label="Act Mid"
              value={form.actMid}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="undergradTotalEnrollment"
              label="Undergrad Total Enrollment"
              value={form.undergradTotalEnrollment}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentWhite"
              label="Percent White"
              value={form.percentWhite}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentBlack"
              label="Percent Black"
              value={form.percentBlack}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentHispanic"
              label="Percent Hispanic"
              value={form.percentHispanic}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentAsian"
              label="Percent Asian"
              value={form.percentAsian}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentAIAN"
              label="Percent AIAN"
              value={form.percentAIAN}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentNHPI"
              label="Percent NHPI"
              value={form.percentNHPI}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentTwoOrMore"
              label="Percent Two Or More"
              value={form.percentTwoOrMore}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentNonCitizens"
              label="Percent Non Citizens"
              value={form.percentNonCitizens}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              onChange={handleNumberChange}
              name="percentPell"
              label="Percent Pell"
              value={form.percentPell}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              onChange={handleTextChange}
              name="homeURL"
              fullWidth
              label="Home URL"
              value={form.homeURL}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  name="hbcu"
                  onChange={handleSwitchChange}
                  checked={Boolean(form.hbcu)}
                />
              }
              label="HBCU"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  name="menOnly"
                  onChange={handleSwitchChange}
                  checked={Boolean(form.menOnly)}
                />
              }
              label="Men Only"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleSwitchChange}
                  name="womenOnly"
                  checked={Boolean(form.womenOnly)}
                />
              }
              label="Women Only"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleSwitchChange}
                  name="currentlyOperating"
                  checked={Boolean(form.currentlyOperating)}
                />
              }
              label="Currently Operating"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleSwitchChange}
                  name="providerIsActive"
                  checked={Boolean(form.providerIsActive)}
                />
              }
              label="IsActive"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditProviderForm;
