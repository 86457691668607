import { Avatar } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import useAvatarCell from "../../hooks/dashboard/useAvatarCell";

type Props = {
  params: GridRenderCellParams<any>;
};

//used in tables that show a user's avatar

export const AvatarCell = ({ params }: Props) => {
  const { url } = useAvatarCell({ fileName: params.value });

  return (
    <Avatar
      alt={`${params?.row?.firstName} ${params?.row?.lastName}`}
      src={url || ""}
      sx={{ width: 50, height: 50, mt: 1, mb: 1 }}
    />
  );
};
