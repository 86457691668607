import { CertificateAnswer, CredentialType } from "../types/enums";
import { AnswerRecord, Provider_Program } from "../types/types";

type FilterRemovedPrograms = {
  removedMatchIds: string[];
  providers: Provider_Program[];
  numberToReturn: number;
  recommendedPrograms: string[];
};

export const filterRemovedPrograms = ({
  removedMatchIds,
  providers,
  numberToReturn,
  recommendedPrograms,
}: FilterRemovedPrograms) => {
  const filteredResults = providers.filter(
    (provider) => removedMatchIds.includes(provider.id) === false
  );
  const filteredWithRecommendedRemoved = filteredResults.filter(
    (provider) => recommendedPrograms.includes(provider.id) === false
  );

  if (filteredWithRecommendedRemoved.length <= numberToReturn) return providers;
  return filteredWithRecommendedRemoved;
};

type FilterTypeAndProgramFrequency = {
  filteredProviders: Provider_Program[];
  answers: AnswerRecord[];
  numberToReturn: number;
  favoriteProgramIds: string[];
};

export const filterTypeAndProgramFrequency = ({
  filteredProviders,
  answers,
  numberToReturn,
  favoriteProgramIds,
}: FilterTypeAndProgramFrequency) => {
  // const bumpped = filteredProviders.filter(
  //   (provider) =>
  //     (provider.Prov_ID === 1002 || provider.Prov_ID === 1011) &&
  //     provider.Willow_ROI_Average_Cost >= 100000
  // );
  // const rest = filteredProviders.filter(
  //   (provider) => provider.Prov_ID !== 1002 && provider.Prov_ID !== 1011
  // );

  const newList = filteredProviders.filter(
    (provider) => favoriteProgramIds.includes(provider.id) === false
  );
  const providerIds: string[] = [];
  let certificateCount = 0;
  let degreeCount = 0;
  let certificateFull = Math.ceil(numberToReturn / 2);
  let degreeFull = numberToReturn - certificateFull;
  const finalArray: Provider_Program[] = [];
  const certificateAnswer = answers.find((answer) => answer.questionId === "certification")?.mc;

  if (!certificateAnswer) return newList.splice(0, numberToReturn);
  switch (certificateAnswer) {
    case CertificateAnswer.CollegeOnly:
      certificateFull = 1;
      degreeFull = numberToReturn - 1;
      break;
    case CertificateAnswer.LeaningCollege:
      degreeFull = Math.ceil(numberToReturn / 1.5);
      certificateFull = numberToReturn - degreeFull;
      break;
    case CertificateAnswer.Neutral:
      degreeFull = Math.ceil(numberToReturn / 2);
      certificateFull = numberToReturn - degreeFull;
      break;
    case CertificateAnswer.LeaningProfessional:
      degreeFull = Math.ceil(numberToReturn / 3);
      certificateFull = numberToReturn - degreeFull;
      break;
    case CertificateAnswer.ProfessionalOnly:
      certificateFull = numberToReturn - 1;
      degreeFull = numberToReturn - certificateFull;
      break;
    default:
      break;
  }

  newList.forEach((program) => {
    const existingProviders = providerIds.filter((id) => id === String(program.providerId));
    const degreeProgram =
      program.credentialLevel === CredentialType.Bachelor ||
      program.credentialLevel === CredentialType.Associates;
    if (degreeProgram) {
      if (existingProviders.length < 2 && degreeCount < degreeFull) {
        providerIds.push(String(program.providerId));
        degreeCount++;
        finalArray.push(program);
      }
    } else {
      if (existingProviders.length < 2 && certificateCount < certificateFull) {
        providerIds.push(String(program.providerId));
        certificateCount++;
        finalArray.push(program);
      }
    }
  });
  return finalArray;
};
