import { Avatar, Box, Button, Chip, Grid, TextField, Tooltip, Typography } from "@mui/material";
import SenderMessage from "./SenderMessage";
import ReceiverMessage from "./ReceiverMessage";
import CircleIcon from "@mui/icons-material/Circle";
import { useEffect, useRef, useState } from "react";
import { useMessagePanel } from "../../hooks/messages/useMessagePanel";
import { Color } from "../../../shared/types/enums";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { stagesAtom } from "../../../shared/recoil/stageAtoms";
import { formatBirthday, formatDotColor, formatLineColor, formatPhoneNumber } from "../../../shared/utils/functions";

function MessagePanel() {
  const stages = useRecoilValue(stagesAtom);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const [currentStageIndex, setCurrentStageIndex] = useState(0);
  const { messages, message, handleTextChange, handleSubmit, loggedInStaff } = useMessagePanel();

  useEffect(() => {
    // Scroll to the bottom of the messages container whenever messages change
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    setCurrentStageIndex(stages.findIndex((stage) => stage.id === selectedStudent?.stageId));
  }, [selectedStudent, stages]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container>
          {selectedStudent && (
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  width: "100%",
                  bgcolor: Color.LightGray,
                  paddingX: "38px",
                  paddingY: 3,
                  borderBottom: "solid 0.5px",
                  borderColor: "#989898",
                }}
              >
                <Grid item xs={4} sx={{ display: "flex", gap: "22px" }}>
                  <Avatar
                    alt={selectedStudent.firstName + " " + selectedStudent.lastName}
                    src={selectedStudent.avatarURL}
                    sx={{ width: 60, height: 60 }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: 20,
                        fontWeight: 700,
                        lineHeight: "26px",
                        color: "#03372F",
                      }}
                      variant="body1"
                    >
                      {`${selectedStudent.firstName} ${selectedStudent.lastName}`}
                    </Typography>
                    <Chip
                      label={selectedStudent.graduationYear === (new Date()).getFullYear().toString() ? "Senior" : "Junior"}
                      sx={{
                        width: "60px",
                        height: "20px",
                        color: "#824708",
                        bgcolor: "#FEE0B7",
                        fontSize: 10,
                        fontWeight: 500,
                        lineHeight: "13px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4} sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <img src="../message-birthday.svg" alt="Birthday" width="12px" height="11px" />
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#03372F",
                      }}
                      variant="body1"
                    >
                      {selectedStudent.birthday && formatBirthday(selectedStudent.birthday)}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <img src="../message-phone.svg" alt="Phone" width="12px" height="11px" />
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#03372F",
                      }}
                      variant="body1"
                    >
                      {formatPhoneNumber(selectedStudent.phone)}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <img src="../message-mail.svg" alt="Mail" width="12px" height="11px" />
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#03372F",
                      }}
                      variant="body1"
                    >
                      {selectedStudent.email}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: 14,
                      fontWeight: 700,
                      color: "#03372F",
                    }}
                    variant="body1"
                  >
                    Current Stage:
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#03372F",
                    }}
                    variant="body1"
                  >
                    Decide on Programs
                  </Typography>
                  <Box
                    sx={{
                      mt: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {stages.map((stage, index) => (
                      <Box key={stage.id} sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip key={stage.id} title={stage.name} placement="top">
                          <CircleIcon
                            sx={{
                              fontSize: currentStageIndex === index ? 14 : 12,
                              color: formatDotColor({ index, currentStageIndex }),
                            }}
                          />
                        </Tooltip>
                        {index !== stages.length - 1 && (
                          <div
                            style={{
                              height: 0,
                              borderTop: "1px solid",
                              borderColor: formatLineColor({
                                index: index,
                                currentStageIndex,
                              }),
                              width: 44,
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box
              ref={messagesContainerRef}
              style={{ overflowY: "auto", height: "calc(100VH - 288px)" }}
            >
              <Box>
                {messages.map((message) => (
                  <Box key={message.timestamp} sx={{ px: 5, py: 1 }}>
                    {message.staffPhoneNumber === loggedInStaff?.smsNumber ? (
                      <SenderMessage message={message} />
                    ) : (
                      <ReceiverMessage message={message} />
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <form
              style={{ display: "flex", gap: "10px", padding: "34px 28px" }}
              onSubmit={handleSubmit}
            >
              <TextField
                value={message.message}
                onChange={handleTextChange}
                fullWidth
                name="textfield for messaging"
                variant="outlined"
                autoComplete="off"
                size="small"
              />
              <Button sx={{ width: "77px" }} variant="contained" type="submit" size="small" disabled={message.message.trim().length === 0}>
                Send
              </Button>
            </form>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default MessagePanel;
