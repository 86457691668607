import React from "react";
import { Box, Button } from "@mui/material";
import UpdateFields from "./UpdateFields";
import UploadSOCData from "./UploadSOCData";
import UpdateCIPCodeCollection from "./UpdateCIPCodeCollection";

const ProgramDBManagementContainer = () => {
  const getSalaryData = async () => {
    try {
      const data = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URI}/onet/getONETData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });
      console.log(data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Button onClick={getSalaryData}>Generate ONET Data</Button>
      </Box>
      <Box>
        <UpdateFields />
      </Box>
      <Box>
        <UploadSOCData />
      </Box>
      <Box>
        <UpdateCIPCodeCollection />
      </Box>
    </>
  );
};

export default ProgramDBManagementContainer;
