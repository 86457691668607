import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import React, { Suspense, useState } from "react";
import StudentsTable from "./StudentsTable";
import { UserType } from "../../../shared/types/enums";
import StaffTable from "./StaffTable";
import { GroupRecord, StaffRecord, StudentRecord } from "../../../shared/types/types";
import { BLANK_STAFF_FORM, BLANK_GROUP_FORM, BLANK_STUDENT_FORM } from "../../../shared/libraries/blankForms";
import GroupsTable from "./GroupsTable";
import ManageStaffDialog from "./ManageStaffDialog";
import ManageStudentDialog from "./ManageStudentDialog";
import Loading from "../../../shared/components/Loading";
import { useRecoilValue } from "recoil";
import { allEnrolledSchoolStudentsAtom } from "../../../shared/recoil/userAtoms";
import ManageGroupDialog from "./ManageGroupDialog";

const SchoolSettingsContainer = () => {
  const [manageStaffOpen, setManageStaffOpen] = useState(false);
  const [manageStudentOpen, setManageStudentOpen] = useState(false);
  const [manageGroupOpen, setManageGroupOpen] = useState(false);
  const [staffForm, setStaffForm] = useState<StaffRecord>({ ...BLANK_STAFF_FORM });
  const [groupForm, setGroupForm] = useState<GroupRecord>({ ...BLANK_GROUP_FORM });
  const [studentForm, setStudentForm] = useState<StudentRecord>({ ...BLANK_STUDENT_FORM });
  const students = useRecoilValue(allEnrolledSchoolStudentsAtom);

  const handleNewStaffClick = () => {
    setStaffForm({ ...BLANK_STAFF_FORM });
    setManageStaffOpen(true);
  };

  const handleNewGroupClick = () => {
    setGroupForm({ ...BLANK_GROUP_FORM });
    setManageGroupOpen(true);
  };
  return (
    <>
      {students ? (
        <>
          <Box sx={{ p: 2 }}>
            <Paper sx={{ backgroundColor: "#fff", borderRadius: 1.5, mt: 2 }}>
              <Box sx={{ padding: 1 }}>
                <Typography variant="h6">Students</Typography>
              </Box>
              <Divider />
              <StudentsTable
                setStudentForm={setStudentForm}
                setManageStudentOpen={setManageStudentOpen}
              />
            </Paper>

            <Paper sx={{ backgroundColor: "#fff", borderRadius: 1.5, mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                  p: 1,
                }}
              >
                <Typography variant="h6">Staff</Typography>
                <Box>
                  {/* <Button variant="outlined" sx={{ mr: 2 }}>
                Upload Staff
              </Button> */}
                  <Button variant="outlined" onClick={handleNewStaffClick}>
                    Add Staff
                  </Button>
                </Box>
              </Box>
              <Divider />
              <Suspense fallback={<Loading size={80} />}>
                <StaffTable
                  userType={UserType.Staff}
                  setStaffForm={setStaffForm}
                  setManageStaffOpen={setManageStaffOpen}
                />
              </Suspense>
            </Paper>

            <Paper sx={{ backgroundColor: "#fff", borderRadius: 1.5, mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                  p: 1,
                }}
              >
                <Typography variant="h6">Groups</Typography>
                <Box>
                  <Button variant="outlined" onClick={handleNewGroupClick}>
                    Add Group
                  </Button>
                </Box>
              </Box>
              <Divider />
              <GroupsTable setGroupForm={setGroupForm} setManageGroupOpen={setManageGroupOpen} />
            </Paper>
          </Box>
        </>
      ) : (
        <Loading size={80} />
      )}
      <ManageStaffDialog
        open={manageStaffOpen}
        setOpen={setManageStaffOpen}
        staffForm={staffForm}
        setStaffForm={setStaffForm}
      />
      <ManageStudentDialog
        open={manageStudentOpen}
        setOpen={setManageStudentOpen}
        studentForm={studentForm}
        setStudentForm={setStudentForm}
      />
      <ManageGroupDialog
        open={manageGroupOpen}
        setOpen={setManageGroupOpen}
        groupForm={groupForm}
        setGroupForm={setGroupForm}
      />
    </>
  );
};

export default SchoolSettingsContainer;
