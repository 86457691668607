import React, { SyntheticEvent, useEffect, useState } from "react";
import { Snackbar, Alert, SnackbarCloseReason } from "@mui/material";
import { useErrorContext } from "../contexts/ErrorContext";

const ErrorSnackbar = () => {
  // Control Snackbar state if no closing mechanism is needed
  const [open, setOpen] = useState(false);
  const { errorMessage, setErrorMessage } = useErrorContext();

  useEffect(() => {
    setOpen(!!errorMessage);
  }, [errorMessage]);

  const handleClose = (event: Event | SyntheticEvent, reason?: SnackbarCloseReason) => {
    if (reason && reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrorMessage(null);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert severity="error" onClose={handleClose}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
