import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Typography,
  Link as MUILink,
  Rating,
} from "@mui/material";
import { formatCredentialCard, formatCurrency } from "../../utils/functions";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Color } from "../../types/enums";
import AutoAdmitMessage from "../AutoAdmitMessage";
import WhatIsROIDialog from "../whatIdROI/WhatIsROIDialog";
import DescriptionModal from "./DescriptionModal";
import useProgramDetailsContainer from "../../hooks/programDetails/useProgramDetailsContainer";
import ProgramStats from "./ProgramStats";
import EditProgramDialog from "./EditProgramDialog";
import Reviews from "./Reviews";

type Props = {
  programId: string;
};

const ProgramDetailsContainer = ({ programId }: Props) => {
  const {
    currentProgram,
    showAutoMessage,
    navigateBack,
    openWhatIsROI,
    setWhatIsROIOpen,
    whatIsROIOpen,
    descriptionModal,
    handleDescriptionModalClose,
    setDescriptionModal,
    applyLink,
    editProgramOpen,
    setEditProgramOpen,
    canEdit,
    setGetProgramTrigger,
    reviews,
  } = useProgramDetailsContainer({
    programId,
  });

  return (
    <>
      {currentProgram && (
        <>
          {showAutoMessage && (
            <Box sx={{ backgroundColor: "#D2F5D6", p: 4 }}>
              <Container maxWidth="md">
                <AutoAdmitMessage provider={currentProgram} />
              </Container>
            </Box>
          )}
          <Container maxWidth="sm">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={navigateBack}>
                <ArrowBackIosIcon />
              </IconButton>
              <Typography variant="body1">Back</Typography>
            </Box>

            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Typography sx={{ color: "#1DBC9D" }} variant="h5">
                {currentProgram.providerName}
              </Typography>
              <Box sx={{ textAlign: "center" }}>
                <Button href={`/provider-details/${currentProgram.providerId}`} sx={{ color: "#1DBC9D", textTransform: "none" }}>
                  Learn more about&nbsp;<span style={{ textDecoration: "underline" }}>{currentProgram.providerName}</span>
                </Button>
              </Box>
              <Typography sx={{ mt: 2 }} variant="h4">
                {currentProgram.programName}
              </Typography>
              <Box
                sx={{
                  backgroundColor: formatCredentialCard(currentProgram.credentialLevel),
                  padding: 1,
                  width: 200,
                  margin: "16px auto",
                }}
              >
                {currentProgram.credentialLevel}
              </Box>
              <Box>
                <img
                  alt={currentProgram?.programName ?? ""}
                  src={`../../assets/programImages/${currentProgram?.willowSubIndustryId}.svg`}
                  width="100%"
                />
              </Box>
              <Typography sx={{ color: "#1DBC9D" }} variant="h5">
                Overview
              </Typography>
              <Typography sx={{ mt: 2 }} variant="body1">
                {currentProgram.programShortDescription}
              </Typography>
              <Typography sx={{ mt: 2 }} variant="body1">
                {currentProgram.programDescription}
              </Typography>
              <ProgramStats
                currentProgram={currentProgram}
                setDescriptionModal={setDescriptionModal}
              />

              <Divider sx={{ mt: 2 }} />
              <Typography sx={{ color: "#1DBC9D", mt: 2, fontSize: 30 }} variant="h5">
                Return On Investment (ROI)
              </Typography>
              <MUILink
                sx={{
                  color: Color.Mint,
                  textDecoration: "none",
                  fontStyle: "italic",
                  fontSize: 12,
                }}
                component="button"
                onClick={openWhatIsROI}
              >
                Click To Learn More About ROI
              </MUILink>
              <Typography variant="h3" sx={{ mt: 3 }}>
                {currentProgram.willowROI
                  ? formatCurrency.format(currentProgram.willowROI)
                  : "Not Assigned"}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                In your lifetime, we estimate that you can expect to make this much more after
                completing this program.
              </Typography>
            </Box>
          </Container>
          {reviews && (
            <Box sx={{ mt: 2, backgroundColor: "rgb(0, 54, 46)", padding: 4 }}>
              <Container maxWidth="sm" sx={{ textAlign: "center" }}>
                <Typography sx={{ fontWeight: 600, fontSize: 30, color: Color.White }} variant="h6">
                  Hear From Students
                </Typography>
                {reviews.rating && reviews.rating > 0 && (
                  <>
                    <Rating
                      sx={{ borderColor: Color.Chalk }}
                      name="half-rating"
                      defaultValue={reviews.rating}
                      value={reviews.rating}
                      precision={0.5}
                      readOnly
                    />
                    <Typography sx={{ fontSize: 16, color: Color.Chalk }}>
                      {reviews.rating} out of 5 from{" "}
                      {reviews.source.length > 1 ? reviews.source : "Google"}
                    </Typography>
                  </>
                )}
                <Reviews reviews={reviews} />
              </Container>
            </Box>
          )}
          <Container maxWidth="sm" sx={{ textAlign: "center", pb: 4 }}>
            <Typography sx={{ color: "#1DBC9D", fontSize: 30, mt: 4 }} variant="h5">
              About The Provider
            </Typography>
            <Typography sx={{ mt: 2 }}>{currentProgram.providerDescription}</Typography>
            {applyLink && (
              <Button
                size="large"
                sx={{ mt: 2 }}
                component={Link}
                to={applyLink}
                variant="contained"
                target="_blank"
              >
                Apply to this program
              </Button>
            )}
            {canEdit && (
              <Box>
                <Button
                  size="large"
                  sx={{ mt: 2 }}
                  variant="contained"
                  onClick={() => setEditProgramOpen(true)}
                >
                  Edit Program
                </Button>
                <Box sx={{ mt: 2, color: "gray" }}>Program ID: {currentProgram.id}</Box>
              </Box>
            )}
          </Container>
          <WhatIsROIDialog open={whatIsROIOpen} setOpen={setWhatIsROIOpen} />
          <DescriptionModal
            title={descriptionModal.title}
            description={descriptionModal.description}
            open={descriptionModal.open}
            onClose={handleDescriptionModalClose}
          />
          <EditProgramDialog
            open={editProgramOpen}
            setOpen={setEditProgramOpen}
            provider={currentProgram}
            program={currentProgram}
            setGetProgramTrigger={setGetProgramTrigger}
          />
        </>
      )}
    </>
  );
};

export default ProgramDetailsContainer;
