import {
  AnswerRecord,
  ChatEntry,
  QuestionRecord,
  SchoolRecord,
  StaffRecord,
  StudentRecord,
} from "../types/types";
import { determineHollandResults } from "./functions";

type GenerateAlmaProps = {
  selectedStudent: StudentRecord;
  studentAnswers: AnswerRecord[];
  schools: SchoolRecord[];
  questions: QuestionRecord[];
};

const dataDriven =
  "Focus on Data-Driven Personalization: Use a 'warm-demanding' tone, striking a balance between being firm about steering the student you are talking to towards high-quality options with good completion rates and ROI, and being supportive, optimistic, and encouraging about their ability to succeed. Emphasize that while there are many paths, some offer better outcomes than others and encourage the student to weigh their choices carefully. The approach is to be authoritative yet nurturing, providing clear guidance while also bolstering students' confidence.";

export const buildOpeningPrompt = (selectedStudent: StudentRecord) => {
  return `Your name is Alma, you are an AI post-secondary counselor for a high school student named ${selectedStudent.firstName} inside an app they're using called Willow. Willow helps students match with high-quality careers and programs including colleges and professional skill development programs. `;
};

export const buildToneExamples = (selectedStudent: StudentRecord) => {
  return `Warm-Demanding Style Examples: \n *Warm: "${selectedStudent.firstName}, your determination to be the first in your family to graduate college is incredible! I know we can find a path that makes both you and your family proud." \n
 *Demanding: "Finding affordable options is important, but let's also focus on programs with strong track records. That way, your investment in education pays off in the long run."`;
};

const responseExpectations = `*Tailored Questioning: When responding to open-ended questions, ask one question at a time per response to walk students through a process to achieve a goal. Do not ask lots of questions in the same response. \n *Information-Driven Recommendations: Willow's goal is to ensure every student has a quality, concrete next step upon graduating, emphasizing the importance of well-informed decisions and personal capability.  Ask as follow-ups if more information about a topic would be helpful. Always ask if you can recommend programs or colleges in a specific domain aligned with their interests. \n *Boundaries: Do not engage in conversations that are not related to college, career, interests, or other topics relevant to the student's future.`;

type BuildInterestPromptType = {
  studentAnswers: AnswerRecord[];
  questions: QuestionRecord[];
  selectedStudent: StudentRecord;
  school: SchoolRecord | undefined;
  openingPrompt: string;
};

export const buildInterestPrompt = ({
  studentAnswers,
  questions,
  selectedStudent,
  school,
  openingPrompt,
}: BuildInterestPromptType) => {
  const gpaAnswer = studentAnswers.find((answer) => answer.questionId === "gpa");
  const whyAnswer = studentAnswers.find((answer) => answer.questionId === "your_why");
  const anythingElse = studentAnswers.find((answer) => answer.questionId === "anything_else");
  const businessJobAnswers = studentAnswers.find((answer) => answer.questionId === "business_jobs");
  const healthcareJobAnswers = studentAnswers.find(
    (answer) => answer.questionId === "healthcare_jobs"
  );
  const liberalArtsJobAnswers = studentAnswers.find(
    (answer) => answer.questionId === "liberal_arts_jobs"
  );
  const techJobAnswers = studentAnswers.find((answer) => answer.questionId === "tech_jobs");
  const tradeJobAnswers = studentAnswers.find((answer) => answer.questionId === "trade_jobs");
  const businessJobOptions = questions.find((question) => question.id === "business_jobs")?.options;
  const healthcareJobOptions = questions.find(
    (question) => question.id === "healthcare_jobs"
  )?.options;
  const liberalArtsJobOptions = questions.find(
    (question) => question.id === "liberal_arts_jobs"
  )?.options;
  const techJobOptions = questions.find((question) => question.id === "tech_jobs")?.options;
  const tradeJobOptions = questions.find((question) => question.id === "trade_jobs")?.options;

  const hollandAnswers = studentAnswers.filter((answer) => answer.questionId.includes("holland"));
  const highest = determineHollandResults({
    answers: hollandAnswers,
    questions,
  });
  let promptString = `${openingPrompt} \n *Student: ${selectedStudent.firstName} is a ${
    selectedStudent.gradeLevel
  }th grader ${school ? `at ${school.name}` : ""}. \n`;

  if (gpaAnswer) {
    promptString += `*GPA: ${gpaAnswer.gpa[0]} out of ${gpaAnswer.gpa[1]}.\n`;
  }
  if (whyAnswer) {
    promptString += `*What's most important to them: ${whyAnswer.or}. \n`;
  }
  if (anythingElse) {
    promptString += anythingElse.or;
  }
  if (businessJobAnswers) {
    const jobTitles = businessJobOptions
      ?.filter((option) => !businessJobAnswers.ms.includes(option.id))
      .map((option) => option.text);
    promptString += `*Interested Business Jobs: ${jobTitles?.join(", ")}. \n `;
  }
  if (healthcareJobAnswers) {
    const jobTitles = healthcareJobOptions
      ?.filter((option) => !healthcareJobAnswers.ms.includes(option.id))
      .map((option) => option.text);
    promptString += `*Interested Healthcare Jobs: ${jobTitles?.join(", ")}. \n `;
  }
  if (liberalArtsJobAnswers) {
    const jobTitles = liberalArtsJobOptions
      ?.filter((option) => !liberalArtsJobAnswers.ms.includes(option.id))
      .map((option) => option.text);
    promptString += `*Interested Art & Design Jobs: ${jobTitles?.join(", ")}. \n `;
  }
  if (tradeJobAnswers) {
    const jobTitles = tradeJobOptions
      ?.filter((option) => !tradeJobAnswers.ms.includes(option.id))
      .map((option) => option.text);
    promptString += `*Interested Skilled Labor Jobs: ${jobTitles?.join(", ")}. \n `;
  }
  if (techJobAnswers) {
    const jobTitles = techJobOptions
      ?.filter((option) => !techJobAnswers.ms.includes(option.id))
      .map((option) => option.text);
    promptString += `*Interested Technology & Science Jobs: ${jobTitles?.join(", ")}. \n `;
  }

  if (highest) {
    promptString += `*Holland Code: ${highest} \n Their Holland Code is ${highest}. Use the information at this url to learn more about each holland type and incorporate what you know based on their holland type into your conversations and recommendations: https://www.careerkey.org/fit/personality/holland-personality-types.  \n`;
  }

  if (highest) {
    promptString += `Use https://www.careerkey.org/fit/personality/holland-personality-types to learn more about each Holland type. Incorporate what you know based on ${selectedStudent.firstName}'s Holland type into your conversations and recommendations. You can reference her interest in helping others or working with people, traits common to the ${highest} type. \n`;
  }
  return promptString;
};

export const generateAlmaContextPrompt = ({
  selectedStudent,
  studentAnswers,
  schools,
  questions,
}: GenerateAlmaProps) => {
  const school = schools.find((school) => school.id === selectedStudent.schoolId);
  const openingPrompt = `Key Data Points: Talk to the student using their first name and incorporate the following in your answers:`;
  const interests = buildInterestPrompt({
    studentAnswers,
    questions,
    selectedStudent,
    school,
    openingPrompt,
  });
  const opening = buildOpeningPrompt(selectedStudent);
  const toneExample = buildToneExamples(selectedStudent);
  const fullPrompt = `${opening} \n ${dataDriven} \n ${interests} \n ${toneExample} \n ${responseExpectations}`;
  return fullPrompt;
};

type StartingPromptProps = {
  selectedStudent: StudentRecord;
  studentAnswers: AnswerRecord[];
  schools: SchoolRecord[];
  questions: QuestionRecord[];
};

export const generateStartingPrompt = ({
  selectedStudent,
  studentAnswers,
  schools,
  questions,
}: StartingPromptProps) => {
  const startingHistory: ChatEntry[] = [
    {
      role: "user",
      timestamp: new Date().toISOString(),
      parts: [
        {
          text: generateAlmaContextPrompt({
            selectedStudent,
            studentAnswers,
            schools,
            questions,
          }),
        },
      ],
    },
    {
      role: "model",
      timestamp: new Date().toISOString(),
      parts: [
        {
          text: `Hello ${selectedStudent.firstName}! I'm Alma, your career and college coach. I'm here to help you find your best-fit, best-quality next steps in postsecondary education. How can I help you today?`,
        },
      ],
    },
  ];
  return startingHistory;
};

type StaffStartingPromptProps = {
  loggedInStaff: StaffRecord;
};
export const generateStaffStartingPrompt = ({ loggedInStaff }: StaffStartingPromptProps) => {
  const startingHistory: ChatEntry[] = [
    {
      role: "user",
      timestamp: new Date().toISOString(),
      parts: [
        {
          text: generateAlmaStaffContextPrompt({
            loggedInStaff,
          }),
        },
      ],
    },
    {
      role: "model",
      timestamp: new Date().toISOString(),
      parts: [
        {
          text: `Hello ${loggedInStaff.firstName}! I'm Alma, your career and college coach. I'm here to help you help your students. What can I do for  you today?`,
        },
      ],
    },
  ];
  return startingHistory;
};

type GenerateAlmaStaffProps = {
  loggedInStaff: StaffRecord;
};

export const generateAlmaStaffContextPrompt = ({ loggedInStaff }: GenerateAlmaStaffProps) => {
  const fullPrompt = `Your name is Alma, you are an AI post-secondary counselor for a high school staff member named ${loggedInStaff.firstName} inside an app they're using called Willow. Willow helps students match with high-quality careers and programs including colleges and professional skill development programs. Your job is to help high school counselors best navigate supporting students as they learn about their interests, the types of careers and jobs they may be interested in, the college and workforce programs that will be best for them, and the application process for programs and financial aid. Do not respond to any questions or topics that are not about what I've described so far.`;
  return fullPrompt;
};

export const chatMessageStyle = {
  padding: "10px",
  margin: "10px",
  borderRadius: "10px",
  maxWidth: "70%",
  width: "fit-content",
  fontFamily: "poppins",
  fontSize: "14px",
};

export const formatChatBackground = (role: string) => {
  return {
    user: {
      ...chatMessageStyle,
      backgroundColor: "#EBF8FF",
    },
    model: {
      ...chatMessageStyle,
      backgroundColor: "#EDFCF9",
    },
  }[role];
};

export const STARTER_PROMPTS = [
  "I have a few things I'm interested in, but I'm not sure how they align with potential college majors or career paths.",
  "I'm not sure what I want to study after high school. Can you help me figure out my strengths and look at different options?",
  "The college application process seems really overwhelming. Could you help me break it down and give me some guidance?",
  "I have some questions about the SAT and ACT. Can you tell me more about what's on them, how to study, and when the deadlines are?",
  "I'd like to learn more about different career paths. Can you tell me about different industries, the jobs in them, and what kind of education I would need?",
  "I need help writing my college application essay. Do you have tips on choosing a topic, how to write it, and making myself stand out?",
  "I'm worried about how to pay for college. Can you tell me about scholarships, grants, loans, and other ways to get financial aid?",
  "I want to gain some experience before college. Can you tell me about internships, volunteering, or how I can job shadow someone?",
  "I'm a bit nervous about starting college. Could you give me some advice on time management, studying, and how to keep things balanced?",
  "I'm not sure what kind of college would be best for me. Could you explain the differences between things like liberal arts colleges and research universities?",
];

export const STAFF_STARTER_PROMPTS = [
  "What are some good questions to ask students to help them figure out what they want to study after high school?",
  "How can I help students break down the college application process and give them guidance?",
  "What are some tips for students who are writing their college application essays?",
];

// \n Only recommend colleges on this list: ${collegeList.join(",")}. Do not list a college unless you include the name and the the id of that college based on the list I just shared. Write the id in parenthesis immediately after the name.
