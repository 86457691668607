import { createContext, useContext } from "react";

type MentorDataContextType = {
  loading: boolean;
  sqlLoading: boolean;
};

const defaultValue: MentorDataContextType = {
  loading: true,
  sqlLoading: true,
};

export const MentorDataContext = createContext<MentorDataContextType>(defaultValue);

export const useMentorData = () => {
  return useContext(MentorDataContext);
};
