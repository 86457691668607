import { Box, Divider, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { Color } from "../types/enums";
import FeatherIcon from "../assets/icons/FeatherIcon";

type Props = {
  children?: React.ReactNode;
  title: string;
  titleSX?: React.CSSProperties;
  showAddButton?: boolean;
  showEditButton?: boolean;
  onClickAdd?: () => void;
  onClickEdit?: () => void;
  height?: string;
  boxPadding?: number;
};

const DisplayBox = ({
  children,
  title,
  titleSX,
  showAddButton = false,
  showEditButton = false,
  onClickAdd,
  onClickEdit,
  height = "auto",
  boxPadding = 1,
}: Props) => {
  const handleAddClick = (): void => {
    if (onClickAdd) {
      onClickAdd();
    }
  };

  const handleEditClick = (): void => {
    if (onClickEdit) {
      onClickEdit();
    }
  };

  return (
    <Paper sx={{ backgroundColor: "#fff", borderRadius: 1.5 }}>
      <Box
        sx={{ padding: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <Typography
          sx={{ textTransform: "upperCase", fontSize: 16, ...titleSX }}
          color={Color.Chalkboard}
          variant="h6"
        >
          {title}
        </Typography>
        {showAddButton && (
          <IconButton
            sx={{ color: Color.Standard_Gray }}
            disableRipple
            onClick={() => handleAddClick()}
          >
            <FeatherIcon width="30" icon="add" />
          </IconButton>
        )}
        {showEditButton && (
          <IconButton
            sx={{ color: Color.Standard_Gray }}
            disableRipple
            onClick={() => handleEditClick()}
          >
            <FeatherIcon width="16" icon="edit-2" />
          </IconButton>
        )}
      </Box>
      <Divider sx={{ borderColor: "#ddd" }} />

      <Box sx={{ p: boxPadding, height: height }}>{children}</Box>
    </Paper>
  );
};

export default DisplayBox;
