import React, { useRef, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { AddressComponents } from "../types/types";

type Props = {
  label: string;
  name: string;
  required?: boolean;
  onChange?: (addressComponents: AddressComponents) => void;
};

const GooglePlacesAutocomplete = ({ label, name, required = false, onChange }: Props) => {
  const inputRef = useRef(null);
  const [address, setAddress] = useState("");

  useEffect(() => {
    const initializeAutocomplete = () => {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
      autocomplete.setComponentRestrictions({ country: ["us"] });
      autocomplete.setTypes(["address"]);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const components = place.address_components;

        if (components.length >= 8) {
          setAddress(`${components[0].long_name} ${components[1].long_name}`);

          if (onChange) {
            onChange({
              address: `${components[0].long_name} ${components[1].long_name}`,
              city: components[3].long_name,
              state: components[5].short_name,
              zip: components[7].long_name,
              lat: place.geometry.location.lat(),
              lon: place.geometry.location.lng(),
            });
          }
        } else {
          setAddress("");
        }
      });
    };

    initializeAutocomplete();

    return () => {
      const googleMapsScript = document.querySelector(
        '[src^="https://maps.googleapis.com/maps/api/js"]'
      );
      if (googleMapsScript) {
        googleMapsScript.remove();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TextField
      inputRef={inputRef}
      fullWidth
      name={name}
      label={label}
      value={address}
      onChange={(event) => setAddress(event.target.value)}
      required={required}
    />
  );
};

export default GooglePlacesAutocomplete;
