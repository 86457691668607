import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { allEnrolledGroupStudentsAtom } from "../../../shared/recoil/userAtoms";
import { convertToCSV } from "../../../shared/utils/functions";
import { schoolsWithURLAtom } from "../../../shared/recoil/districtAtoms";
import { stagesAtom } from "../../../shared/recoil/stageAtoms";
import { studentRowsAtom } from "../../../shared/recoil/counselorDashboardAtoms";
import { questionsAtom } from "../../../shared/recoil/questionAtoms";

type StudentsForExport = {
  firstName: string;
  lastName: string;
  schoolEmail: string;
  schoolName: string;
  stage: string;
  gpa: string;
  numberHeartedPrograms: number;
  numberAppliedPrograms: number;
  numberAcceptedPrograms: number;
  personalityType: string;
  mostInterestedIndustries: string;
};

const useStudentTableTools = () => {
  const stages = useRecoilValue(stagesAtom);
  const schools = useRecoilValue(schoolsWithURLAtom);
  const [studentsAllForExport, setStudentsAllForExport] = useState("");
  const studentRows = useRecoilValue(studentRowsAtom);
  const students = useRecoilValue(allEnrolledGroupStudentsAtom);
  const questions = useRecoilValue(questionsAtom);
  const pathways = questions?.find((question) => question.id === "pathways");

  const formatPersonalityType = (type: string) => {
    switch (type) {
      case "Realistic":
        return "Practical Problem-Solver";
      case "Investigative":
        return "Analytical Explorer";
      case "Artistic":
        return "Creative Visionary";
      case "Social":
        return "Empathetic Connector";
      case "Enterprising":
        return "Dynamic Leader";
      case "Conventional":
        return "Organizational Wizard";
      default:
        return "Quiz Not Completed";
    }
  };

  const formatPathways = useCallback(
    (selectedPathways: string[]) => {
      const industryNames = selectedPathways.map((pathway) => {
        if (!pathways) return "";
        return pathways.options.find((path) => path.id === pathway)?.text ?? "";
      });
      const allIndustryNames = industryNames.join(", ");
      return `"${allIndustryNames}"`;
    },
    [pathways]
  );

  useEffect(() => {
    const tempArrayForExport: StudentsForExport[] = [];
    if (!studentRows) return;
    //generates the data needed for the overall CSV export
    studentRows.forEach((studentRow) => {
      const studentRecord = students.find((stu) => stu.id === studentRow.id);
      if (!studentRecord) return;
      tempArrayForExport.push({
        firstName: studentRow.firstName,
        lastName: studentRow.lastName,
        gpa: studentRow.gpa ? `${studentRow.gpa[0]} out of ${studentRow.gpa[1]}` : `N/A`,
        numberHeartedPrograms: studentRow.heartedPrograms,
        numberAppliedPrograms: studentRow.appliedPrograms,
        numberAcceptedPrograms: studentRow.acceptedPrograms,
        personalityType: formatPersonalityType(studentRow.personalityType),
        mostInterestedIndustries: formatPathways(studentRow.mostInterestedPathways),
        schoolEmail: studentRecord.schoolEmail,
        schoolName: schools.find((school) => school.id === studentRecord.schoolId)?.name ?? "",
        stage: stages.find((stage) => stage.id === studentRecord.stageId)?.name ?? "",
      });
    });

    const arrayForExport = convertToCSV(tempArrayForExport);
    setStudentsAllForExport(arrayForExport);
  }, [formatPathways, schools, stages, studentRows, students]);

  return { studentsAllForExport };
};

export default useStudentTableTools;
