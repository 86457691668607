import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ownProviderAtom,
  programsAtom,
  selectedProgramAtom,
} from "../../../shared/recoil/providerAtoms";
import {
  Program,
  Provider,
  Provider_Program,
  ProviderDashboardProgramRow,
  ProviderUser,
} from "../../../shared/types/types";
import { parseProgramResponse, parseProviderUser } from "../../../shared/utils/parsers";
import { useNavigate } from "react-router-dom";
import { programRowsAtom } from "../../../shared/recoil/providerDashboardAtoms";
import { loggedInProviderAtom } from "../../../shared/recoil/userAtoms";
import { PageRoute } from "../../../shared/types/enums";

type Return = {
  user: ProviderUser | null;
  provider: Provider | null;
  selectedProgram: Program | null;
  programRows: ProviderDashboardProgramRow[] | null;
  openProviderDialog: boolean;
  setOpenProviderDialog: Dispatch<SetStateAction<boolean>>;
  openProgramDialog: boolean;
  setOpenProgramDialog: Dispatch<SetStateAction<boolean>>;
  onAddClick: () => void;
  onRowClick: (row: ProviderDashboardProgramRow) => void;
  setGetProgramTrigger: Dispatch<SetStateAction<boolean>>;
  handleProviderEditClick: () => void;
};

const useProviderDashboardContainer = (): Return => {
  const navigate = useNavigate();
  const programRows = useRecoilValue(programRowsAtom);
  const loggedInProvider = useRecoilValue(loggedInProviderAtom);
  const [programs, setPrograms] = useRecoilState(programsAtom);
  const ownProvider = useRecoilValue(ownProviderAtom);
  const [selectedProgram, setSelectedProgram] = useRecoilState(selectedProgramAtom);
  const [openProviderDialog, setOpenProviderDialog] = useState(false);
  const [openProgramDialog, setOpenProgramDialog] = useState(false);
  const [getProgramTrigger, setGetProgramTrigger] = useState(false);
  const [user, setUser] = useState<ProviderUser | null>(null);
  const [provider, setProvider] = useState<Provider | null>(null);

  useEffect(() => {
    if (!loggedInProvider || !loggedInProvider.providerId) {
      navigate(PageRoute.Provider_Form);
      return;
    }

    setUser(parseProviderUser(loggedInProvider));
  }, [loggedInProvider, navigate]);

  useEffect(() => {
    if (!ownProvider) return;
    setProvider(ownProvider);
  }, [ownProvider]);

  useEffect(() => {
    if (!ownProvider) {
      return;
    }

    const getOwnPrograms = async () => {
      const results = await fetch(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getProgramsByProviderId`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            providerId: ownProvider.id,
          }),
        }
      );

      const { rows } = (await results.json()) as { rows: Provider_Program[] };

      setPrograms(parseProgramResponse(rows));
    };
    getOwnPrograms();
  }, [getProgramTrigger, ownProvider, setPrograms]);

  const onAddClick = () => {
    setSelectedProgram(null);
    setOpenProgramDialog(true);
  };

  const onRowClick = useCallback(
    (row: ProviderDashboardProgramRow) => {
      if (!programs) return;
      const selectedProgramRow = programs.find((program) => program.id === row.id);
      if (selectedProgramRow) {
        setSelectedProgram(selectedProgramRow as Program);
      }
      setOpenProgramDialog(true);
    },
    [setSelectedProgram, programs]
  );

  const handleProviderEditClick = () => {
    setOpenProviderDialog(true);
  }

  return {
    user,
    provider,
    programRows,
    selectedProgram,
    openProviderDialog,
    setOpenProviderDialog,
    openProgramDialog,
    setOpenProgramDialog,
    onAddClick,
    onRowClick,
    setGetProgramTrigger,
    handleProviderEditClick,
  };
};

export default useProviderDashboardContainer;
