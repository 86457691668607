import React, { Dispatch, SetStateAction, useState } from "react";
import { Box, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import ProgramCard from "../programCard/ProgramCard";
import { SelectedProgramSource } from "../../types/enums";
import { Provider_Program } from "../../types/types";
import ProgramDetailsDialog from "../programDetails/ProgramDetailsDialog";

type Props = {
  programsToExplore: Provider_Program[];
  loading: boolean;
  searchSubmitted: boolean;
  setSelectedProgram: Dispatch<SetStateAction<Provider_Program | null>>;
  setRecommendOpen: Dispatch<SetStateAction<boolean>>;
};

const ExploreProgramsResults = ({
  programsToExplore,
  loading,
  searchSubmitted,
  setSelectedProgram,
  setRecommendOpen,
}: Props) => {
  const [selectedLearnMoreProgram, setSelectedLearnMoreProgram] = useState<Provider_Program | null>(
    null
  );
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4">Results</Typography>
        <Divider />
        {loading ? (
          <Box sx={{ mt: 4, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress sx={{ mt: 4 }} size={80} />
          </Box>
        ) : (
          <Box sx={{ maxHeight: "80VH", overflow: "scroll", overflowX: "hidden" }}>
            {programsToExplore.length > 0 ? (
              <Grid container spacing={2}>
                {programsToExplore.map((program) => (
                  <Grid key={program.id} item xs={12} sm={12} md={12} lg={6}>
                    <ProgramCard
                      program={program}
                      source={SelectedProgramSource.Explore}
                      showDeleteButton={false}
                      setSelectedProgram={setSelectedProgram}
                      setRecommendOpen={setRecommendOpen}
                      setSelectedLearnMoreProgram={setSelectedLearnMoreProgram}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <>
                {searchSubmitted && (
                  <Typography sx={{ mt: 4 }} variant="h4" textAlign={"center"}>
                    No Results for That Search
                  </Typography>
                )}
              </>
            )}
            {!searchSubmitted && programsToExplore.length === 0 && (
              <Typography sx={{ mt: 4 }} variant="h4" textAlign={"center"}>
                Click Apply Filters to See Results
              </Typography>
            )}
          </Box>
        )}
      </Box>
      {selectedLearnMoreProgram && (
        <ProgramDetailsDialog
          selectedProgram={selectedLearnMoreProgram}
          setSelectedProgram={setSelectedLearnMoreProgram}
        />
      )}
    </>
  );
};

export default ExploreProgramsResults;
