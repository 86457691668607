import { Navigate } from "react-router-dom";
import { PageRoute, UserType } from "./shared/types/enums";
import { userTypeAtom } from "./shared/recoil/userAtoms";
import { useRecoilValue } from "recoil";
import { useAuth } from "./shared/contexts/AuthContext";

interface CustomRouteProps {
  children: React.ReactNode;
  permission: number;
  originRoute?: string;
}

const CustomRoute = ({ children, permission, originRoute = "" }: CustomRouteProps) => {
  const userType = useRecoilValue(userTypeAtom);
  const { currentAuthUser } = useAuth();
  
  if (permission === -1) {
    console.log("permission is -1");
    return <>{children}</>;
  }
  
  if (!currentAuthUser) {
    console.log("no currentAuthUser");
    return <Navigate to={PageRoute.Login} />;
  }
  
  if (
    !userType &&
    originRoute !== PageRoute.Student_Onboarding &&
    originRoute !== PageRoute.Provider_Signup &&
    originRoute !== PageRoute.Provider_Form
  ) {
    console.log("no userType and not from provider signup, form, or student onboarding");
    return <Navigate to={PageRoute.Student_Onboarding} />;
  }
  
  if (userType && originRoute === PageRoute.Student_Onboarding) {
    console.log("userType and originRoute is student onboarding");
    return <Navigate to={PageRoute.Student_Dashboard} />;
  }
  
  if (userType && originRoute === PageRoute.Provider_Signup) {
    console.log("userType and originRoute is provider signup");
    return <Navigate to={PageRoute.Provider_Form} />;
  }
  
  if (!userType) {
    console.log("no userType");
    return <>{children}</>;
  }

  if (userType >= permission) {
    console.log(userType, permission, originRoute, "userType >= permission");
    return <>{children}</>;
  }

  if (userType === UserType.ProviderUser) {
    return <Navigate to={PageRoute.Provider_Dashboard} />;
  }

  if (userType >= 2 && userType < permission) {
    return <Navigate to={PageRoute.Staff_Dashboard} />;
  }

  if (userType === 1 && userType < permission) {
    return <Navigate to={PageRoute.Student_Stages} />;
  }

  return <>Something Went Wrong</>;
};

export default CustomRoute;
