import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useCallback } from "react";

const useGetImage = () => {
  const storage = getStorage();
  const getImage = useCallback(
    async (fileName: string) => {
      if (!fileName || fileName.trim().length === 0) {
        return "";
      }
      let url = "";
      try {
        url = await getDownloadURL(ref(storage, `${fileName}_200x200`));
      } catch (e: any) {
        try {
          url = await getDownloadURL(ref(storage, `${fileName}`));
        } catch (e: any) {}
      }
      return url;
    },
    [storage]
  );
  return { getImage };
};

export default useGetImage;
