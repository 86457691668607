import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Color } from "../../../shared/types/enums";
import { useAuth } from "../../../shared/contexts/AuthContext";

const ProviderSignupNavbar = () => {
  const { logout, currentAuthUser } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          sx={{ zIndex: 99999999, backgroundColor: "#fff" }}
        >
          <Toolbar>
            <Box sx={{ flexGrow: 1, pt: "2px", pb: "2px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignContent: "flex-start",
                  alignItems: "center",
                  gap: 2,
                  padding: 1,
                }}
              >
                <img
                  style={{ width: "200px" }}
                  src="../../assets/branding/inline-chalkboard.svg"
                  alt="Willow"
                />
              </Box>
            </Box>
            {currentAuthUser ? (
              <Button
                sx={{ p: 2, color: Color.Chalkboard }}
                variant="text"
                color="white"
                onClick={handleLogout}
              >
                Logout
              </Button>
            ) : (
              <Button
                sx={{ p: 2, color: Color.White }}
                variant="text"
                onClick={() => navigate("/")}
              >
                Back
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default ProviderSignupNavbar;
