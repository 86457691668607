import { useRecoilValue } from "recoil";
import { AnswerRecord, AnswerTypeForm, Provider_Program, StudentRecord } from "../types/types";
import { removedMatchesAtom } from "../recoil/userAtoms";
import { PathwayHeader, SubIndustryDBName } from "../types/enums";
import {
  filterRemovedPrograms,
  filterTypeAndProgramFrequency,
} from "../utils/matchProgramFunctions";
import { useState } from "react";

type UpdateMatchProgramsProps = {
  studentAnswers: AnswerRecord[];
  selectedStudent: StudentRecord;
  editQuestionId?: string;
  newAnswer?: AnswerTypeForm;
  newRemovedId?: string;
};

const filterAdmissionRange = (gpaPercent: number) => {
  let lowestAdmissionRate = 0;
  let highestAdmissionRate = 1;
  if (gpaPercent < 50) {
    lowestAdmissionRate = 0.5;
    highestAdmissionRate = 1;
  } else if (gpaPercent < 63) {
    lowestAdmissionRate = 0.4;
    highestAdmissionRate = 1;
  } else if (gpaPercent < 75) {
    lowestAdmissionRate = 0.25;
    highestAdmissionRate = 1;
  } else if (gpaPercent < 94) {
    lowestAdmissionRate = 0.1;
    highestAdmissionRate = 1;
  } else {
    lowestAdmissionRate = 0;
    highestAdmissionRate = 1;
  }
  return { lowestAdmissionRate, highestAdmissionRate };
};

const returnAdmissionRateWeight = (gpaPercent: number, admissionPercent: number) => {
  let weight = 0;
  if (gpaPercent < 50) {
    if (admissionPercent > 0.5 && admissionPercent < 0.75) {
      weight += 5;
    } else if (admissionPercent > 0.75 && admissionPercent < 0.9) {
      weight += 10;
    } else {
      weight += 5;
    }
  } else if (gpaPercent < 63) {
    if (admissionPercent > 0.5 && admissionPercent < 0.75) {
      weight += 5;
    } else if (admissionPercent > 0.75 && admissionPercent < 0.9) {
      weight += 10;
    } else {
      weight += 5;
    }
  } else if (gpaPercent < 75) {
    if (admissionPercent > 0.5 && admissionPercent < 0.75) {
      weight += 5;
    } else if (admissionPercent > 0.75 && admissionPercent < 0.9) {
      weight += 10;
    } else {
      weight += 5;
    }
  } else if (gpaPercent < 0.94) {
    if (admissionPercent > 0.5 && admissionPercent < 0.75) {
      weight += 5;
    } else if (admissionPercent > 0.75 && admissionPercent < 0.9) {
      weight += 10;
    } else {
      weight += 5;
    }
  } else {
    if (admissionPercent > 0.5 && admissionPercent < 0.75) {
      weight += 5;
    } else if (admissionPercent > 0.75 && admissionPercent < 0.9) {
      weight += 10;
    } else {
      weight += 5;
    }
  }
  return weight;
};

const useUpdateMatches = () => {
  const removedMatches = useRecoilValue(removedMatchesAtom);
  const [loading, setLoading] = useState(false);

  const updateMatchPrograms = async ({
    studentAnswers,
    selectedStudent,
    editQuestionId,
    newAnswer,
    newRemovedId,
  }: UpdateMatchProgramsProps) => {
    //all answers with the newly edited answer included
    setLoading(true);

    const newAnswers = newAnswer
      ? studentAnswers.map((ans) => {
          return ans.questionId === editQuestionId ? { ...ans, ...newAnswer } : ans;
        })
      : [...studentAnswers];

    const onlineOnlyIncluded = newAnswers.find((ans) => ans.questionId === "onlineOnly")?.mc;
    const geographyAnswers = newAnswers.find((ans) => ans.questionId === "geography")
      ?.ms as String[];
    const cities = geographyAnswers?.map((geo) => geo.split("-")[0].trim());
    const states = geographyAnswers?.map((geo) => geo.split("-")[1].trim());
    const gpaAnswers = newAnswers.find((ans) => ans.questionId === "gpa")?.gpa;
    const gpaPercent = gpaAnswers ? (gpaAnswers[0] / gpaAnswers[1]) * 100 : 0;
    const { lowestAdmissionRate, highestAdmissionRate } = filterAdmissionRange(gpaPercent);
    const pathways = newAnswers.find((ans) => ans.questionId === "pathways")?.matrix;
    const notInterestedIndustries = pathways?.filter(
      (pathway) => pathway.headerId === PathwayHeader.NotInterested
    );
    const notInterestedIndustryIds =
      notInterestedIndustries?.map((pathway) => pathway.optionId) ?? [];

    const notInterestedBusinessSubIndustries =
      newAnswers.find((ans) => ans.questionId === SubIndustryDBName.Business)?.ms ?? [];
    const notInterestedArtsSubIndustries =
      newAnswers.find((ans) => ans.questionId === SubIndustryDBName.Arts)?.ms ?? [];
    const notInterestedSkilledTradeSubIndustries =
      newAnswers.find((ans) => ans.questionId === SubIndustryDBName.SkilledTrade)?.ms ?? [];
    const notInterestedSocialServicesSubIndustries =
      newAnswers.find((ans) => ans.questionId === SubIndustryDBName.SocialServices)?.ms ?? [];
    const notInterestedSciencesSubIndustries =
      newAnswers.find((ans) => ans.questionId === SubIndustryDBName.Sciences)?.ms ?? [];

    const notInterestedSubIndustries = [
      ...notInterestedBusinessSubIndustries,
      ...notInterestedArtsSubIndustries,
      ...notInterestedSkilledTradeSubIndustries,
      ...notInterestedSocialServicesSubIndustries,
      ...notInterestedSciencesSubIndustries,
    ];

    const mostInterestedIndusties = pathways
      ?.filter((pathway) => pathway.headerId === PathwayHeader.MostInterested)
      .map((pathway) => pathway.optionId);
    const startTime = new Date().getTime();

    const results = await fetch(
      `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/getProgramsForMatching`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          online: onlineOnlyIncluded,
          states: states,
          cities: cities,
          lowestAdmissionRate: lowestAdmissionRate,
          highestAdmissionRate: highestAdmissionRate,
          limit: 1000,
          notInterestedIndustryIds: notInterestedIndustryIds,
          notInterestedSubIndustryIds: notInterestedSubIndustries,
        }),
      }
    );
    const { rows } = (await results.json()) as { rows: Provider_Program[] };

    const programIdsToRemove = removedMatches.map((match) => match.providerId);
    if (newRemovedId) programIdsToRemove.push(newRemovedId);

    const filteredMatches = filterRemovedPrograms({
      removedMatchIds: programIdsToRemove,
      providers: rows,
      numberToReturn: 6 - selectedStudent.favoriteProviderIds.length,
      recommendedPrograms: selectedStudent.staffRecommendedProgramIds,
    });

    const programWeights: { programId: string; weight: number }[] = [];

    filteredMatches.forEach((match) => {
      let weight = 0;
      const willowROIWeight = Math.round(match?.willowROI ?? 0) / 50000;
      weight += willowROIWeight;
      const industryWeight = mostInterestedIndusties?.includes(match?.willowIndustryId ?? "")
        ? 10
        : 0;
      weight += industryWeight;
      const admissionWeight = returnAdmissionRateWeight(gpaPercent, match?.admissionPercent ?? 0);
      weight += admissionWeight;
      weight += match?.willowWeight ?? 0;

      programWeights.push({ programId: match.id, weight });
    });

    programWeights.sort((a, b) => b.weight - a.weight);

    const topMatches: Provider_Program[] = [];

    programWeights.forEach((programWeight) => {
      const match = filteredMatches.find((match) => match.id === programWeight.programId);
      if (match) {
        topMatches.push(match);
      }
    });


    const finalFullFilteredPrograms = filterTypeAndProgramFrequency({
      filteredProviders: topMatches,
      answers: newAnswers,
      numberToReturn: 6 - selectedStudent.favoriteProviderIds.length,
      favoriteProgramIds: selectedStudent.favoriteProviderIds,
    });

    const newMatchedProgramIds = [
      ...selectedStudent.favoriteProviderIds,
      ...finalFullFilteredPrograms.map((provider) => provider?.id),
    ];
    setLoading(false);
    return newMatchedProgramIds;
  };
  return { updateMatchPrograms, loading };
};

export default useUpdateMatches;
