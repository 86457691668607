import { Box, IconButton } from "@mui/material";
import React from "react";
import FeatherIcon from "../FeatherIcon";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import { useRecoilValue } from "recoil";
import { Color, SelectedProgramSource } from "../../types/enums";
import { Provider_Program } from "../../types/types";
import useReactions from "../../hooks/programCard/useReactions";
import RemoveMatchDialog from "./RemoveMatchDialog";
import ConsentToContactDialog from "../../../students/components/profile/ConsentToContactDialog";

type Props = {
  program: Provider_Program;
  source: SelectedProgramSource;
  showDeleteButton?: boolean;
  isEditable?: boolean;
};

const Reactions = ({ program, source, showDeleteButton, isEditable = true }: Props) => {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const {
    handleAddHeart,
    setRemoveOpen,
    handleRemoveHeart,
    handleRemoveClick,
    modalOpen,
    removeOpen,
    favorited,
    handleConsent,
  } = useReactions(program);

  return (
    <>
      <Box>
        {favorited ? (
          <IconButton onClick={() => isEditable ? handleRemoveHeart(source) : null} sx={{ color: "#F00" }}>
            <FeatherIcon icon="heart" fillColor="#F00" width="24px" />
          </IconButton>
        ) : (
          <>
            {selectedStudent && (
              <>
                {showDeleteButton && isEditable && (
                  <IconButton onClick={() => handleRemoveClick(source)} sx={{ color: Color.Mint }}>
                    <FeatherIcon width="24px" icon="trash" />
                  </IconButton>
                )}
                <IconButton onClick={() => isEditable ? handleAddHeart(source) : null} sx={{ color: Color.Mint }}>
                  <FeatherIcon icon="heart" fillColor="none" width="24px" />
                </IconButton>
              </>
            )}
          </>
        )}
      </Box>
      <RemoveMatchDialog open={removeOpen} setOpen={setRemoveOpen} programId={program.id} />
      <ConsentToContactDialog modalOpen={modalOpen} handleConsent={handleConsent} />
    </>
  );
};

export default Reactions;
