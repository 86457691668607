import React from "react";
import { Box, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import DisplayBox from "../../../shared/components/DisplayBox";

const MyWhy = () => {
  const answers = useRecoilValue(studentAnswersAtom);
  const whyAnswer = answers?.find((answer) => answer.questionId === "your_why")?.or;

  return (
    <Box sx={{ mt: 2 }}>
      <DisplayBox title="My Why">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Typography sx={{ padding: 2, color: "#000" }} variant="body1">
            {whyAnswer}
          </Typography>
        </Box>
      </DisplayBox>
    </Box>
  );
};

export default MyWhy;
