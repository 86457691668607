/**
 * Renders the container component for the Counselor Dashboard.
 * This component displays the staff dashboard, including a student table, resources, stage summary, and stats.
 */

import Resources from "./Resources";
import Overview from "./Overview";
import StudentTableAndFiltersContainer from "./StudentTableAndFiltersContainer";

const DashboardContainer = () => {
  return (
    <>
      <Overview />
      <StudentTableAndFiltersContainer />
      <Resources />
    </>
  );
};

export default DashboardContainer;
