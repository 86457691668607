import React, { useEffect, useState } from "react";
import { MentorUserRecord, ProviderUserRecord, StaffRecord, StudentRecord } from "../types/types";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";
import { useSetRecoilState } from "recoil";
import {
  loggedInStaffAtom,
  loggedInProviderAtom,
  selectedStudentAtom,
  userTypeAtom,
  loggedInMentorAtom,
} from "../recoil/userAtoms";
import { Collection, UserType } from "../types/enums";
import {
  parseSingleMentorUserResponse,
  parseSingleProviderUserResponse,
  parseSingleStaffResponse,
  parseSingleStudentResponse,
} from "../utils/parsers";
import { UserContext } from "../contexts/UserContext";
import { siteLoadingAtom } from "../recoil/loadingAtoms";

type Props = {
  children: JSX.Element;
};

export const UserProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const { currentAuthUser, loading: authLoading } = useAuth();
  const setLoggedInStaff = useSetRecoilState(loggedInStaffAtom);
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);
  const setLoggedInProvider = useSetRecoilState(loggedInProviderAtom);
  const setLoggedInMentor = useSetRecoilState(loggedInMentorAtom);
  const setUserType = useSetRecoilState(userTypeAtom);
  const setSiteLoading = useSetRecoilState(siteLoadingAtom);
  useEffect(() => {
    setLoading(true);
    if (!currentAuthUser) {
      if (!authLoading) {
        setLoading(false);
        setLoggedInStaff(null);
        setSelectedStudent(null);
      }
    } else {
      const getUserRecord = async () => {
        const start = new Date().getTime();
        const uid = currentAuthUser.uid;
        const staffDocRef = doc(db, Collection.Staff, uid);
        const studentDocRef = doc(db, Collection.Students, uid);
        const providerUserDocRef = doc(db, Collection.ProviderUsers, uid);
        const mentorUserDocRef = doc(db, Collection.MentorUsers, uid);
        const userPromises: [
          Promise<StaffRecord | null>,
          Promise<StudentRecord | null>,
          Promise<ProviderUserRecord | null>,
          Promise<MentorUserRecord | null>
        ] = [
          getDoc(staffDocRef).then((doc) =>
            doc.exists() ? ({ ...doc.data(), id: doc.id } as StaffRecord) : null
          ),
          getDoc(studentDocRef).then((doc) =>
            doc.exists() ? ({ ...doc.data(), id: doc.id } as StudentRecord) : null
          ),
          getDoc(providerUserDocRef).then((doc) =>
            doc.exists() ? ({ ...doc.data(), id: doc.id } as ProviderUserRecord) : null
          ),
          getDoc(mentorUserDocRef).then((doc) =>
            doc.exists() ? ({ ...doc.data(), id: doc.id } as MentorUserRecord) : null
          ),
        ];
        const [staffRecord, studentRecord, providerUserRecord, mentorUserRecord] =
          await Promise.all(userPromises);
        if (staffRecord) {
          setLoggedInStaff(staffRecord ? parseSingleStaffResponse(staffRecord) : null);
          setUserType(staffRecord.userType);
        } else if (studentRecord) {
          setSelectedStudent(parseSingleStudentResponse(studentRecord));
          setUserType(UserType.Student);
        } else if (providerUserRecord) {
          setLoggedInProvider(parseSingleProviderUserResponse(providerUserRecord));
          setUserType(UserType.ProviderUser);
        } else if (mentorUserRecord) {
          setLoggedInMentor(parseSingleMentorUserResponse(mentorUserRecord));
          setUserType(UserType.MentorUser);
        } else {
          setUserType(null);
          setSiteLoading(false);
        }
        setLoading(false);
        const end = new Date().getTime();
        console.log(`UserProvider took ${end - start}ms`);
      };
      getUserRecord();
    }
  }, [
    authLoading,
    currentAuthUser,
    setLoggedInStaff,
    setSelectedStudent,
    setLoggedInProvider,
    setLoggedInMentor,
    setUserType,
    setSiteLoading,
  ]);

  return <UserContext.Provider value={{ loading }}>{children}</UserContext.Provider>;
};
