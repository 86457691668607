import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { questionsAtom } from "../../../shared/recoil/questionAtoms";
import { studentAnswersAtom } from "../../../shared/recoil/answersAtoms";
import { BLANK_ANSWER_TYPE_FORM } from "../../../shared/libraries/blankForms";
import { AnswerTypeForm } from "../../../shared/types/types";

type Props = {
  editQuestionId: string | null;
  answer: AnswerTypeForm;
  setAnswer: React.Dispatch<React.SetStateAction<AnswerTypeForm>>;
};

const useEditDetailForm = ({ editQuestionId, answer, setAnswer }: Props) => {
  const questions = useRecoilValue(questionsAtom);
  const answers = useRecoilValue(studentAnswersAtom);
  const currentQuestion = questions?.find((question) => question.id === editQuestionId);

  useEffect(() => {
    if (!answers) return;
    const currentAnswer = answers.find((ans) => ans.questionId === editQuestionId);
    if (!currentAnswer) {
      setAnswer({ ...BLANK_ANSWER_TYPE_FORM });
      return;
    }
    setAnswer((pV) => ({ ...pV, or: currentAnswer.or }));
  }, [answers, editQuestionId, setAnswer]);

  return { currentQuestion, answer, setAnswer };
};

export default useEditDetailForm;
