import React from "react";
import { Route, Routes } from "react-router-dom";
import CustomRoute from "./CustomRoute";
import LoginPage from "./shared/pages/Login";
import { PageRoute, UserType } from "./shared/types/enums";
import InitialQuiz from "./students/pages/InitialQuiz";
import Congratulations from "./students/pages/Congratulations";
import ProgramDetails from "./shared/pages/ProgramDetails";
import DeveloperSettings from "./developers/pages/Settings";
import DistrictSettings from "./staff/pages/DistrictSettings";
import SchoolSettings from "./staff/pages/SchoolSettings";
import TransferUploads from "./developers/pages/TransferUploads";
import CleverOAuth from "./shared/pages/CleverOAuth";
import ProgramDBManagement from "./developers/pages/ProgramDBManagement";
import StudentProfile from "./students/pages/Profile";
import StudentStages from "./students/pages/Stages";
import ManagePrograms from "./staff/pages/ManagePrograms";
import ExplorePrograms from "./shared/pages/ExplorePrograms";
import StudentAlma from "./students/pages/Alma";
import ExploreCareers from "./shared/pages/ExploreCareers";
import StudentSignup from "./students/pages/Signup";
import StudentOnboarding from "./students/pages/Onboarding";
import ProviderForm from "./programProviders/pages/ProviderForm";
import ProviderDashboard from "./programProviders/pages/Dashboard";
import ProviderProfile from "./programProviders/pages/Profile";
import DemoLogin from "./shared/pages/DemoLogin";
import StaffAlma from "./staff/pages/Alma";
import ProviderLogin from "./programProviders/pages/Login";
import ProviderSignup from "./programProviders/pages/Signup";
import StudentDashboard from "./students/pages/Dashboard";
import StaffDashboard from "./staff/pages/Dashboard";
import StudentsByProgram from "./staff/pages/StudentsByProgram";
import Messages from "./staff/pages/Messages";
import MentorLogin from "./mentor/pages/Login";
import MentorDashboard from "./mentor/pages/Dashboard";

//-1 permission means public

export const routes = (
  <Routes>
    <Route
      path={PageRoute.Login}
      element={
        <CustomRoute permission={-1}>
          <LoginPage />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Demo_Login}
      element={
        <CustomRoute permission={-1}>
          <DemoLogin />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Signup}
      element={
        <CustomRoute permission={-1}>
          <StudentSignup />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Provider_Login}
      element={
        <CustomRoute permission={-1}>
          <ProviderLogin />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Provider_Signup}
      element={
        <CustomRoute permission={-1}>
          <ProviderSignup />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Student_Onboarding}
      element={
        <CustomRoute originRoute={PageRoute.Student_Onboarding} permission={1}>
          <StudentOnboarding />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Clever_Auth}
      element={
        <CustomRoute permission={-1}>
          <CleverOAuth />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Program_DB_Management}
      element={
        <CustomRoute permission={-1}>
          <ProgramDBManagement />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Student_Dashboard}
      element={
        <CustomRoute permission={UserType.Student}>
          <StudentStages />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Explore_Careers}
      element={
        <CustomRoute permission={UserType.Student}>
          <ExploreCareers />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Student_Dashboard_2}
      element={
        <CustomRoute permission={UserType.Student}>
          <ExploreCareers />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Alma}
      element={
        <CustomRoute permission={UserType.Student}>
          <StudentAlma />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Staff_Alma}
      element={
        <CustomRoute permission={UserType.Staff}>
          <StaffAlma />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Staff_Dashboard}
      element={
        <CustomRoute permission={UserType.Staff}>
          <StaffDashboard />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Explore_Programs}
      element={
        <CustomRoute permission={UserType.Student}>
          <ExplorePrograms />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Students_By_Program}
      element={
        <CustomRoute permission={UserType.Staff}>
          <StudentsByProgram />
        </CustomRoute>
      }
    />
    <Route
      path={`${PageRoute.Program_Details}/:programId`}
      element={
        <CustomRoute permission={UserType.Student}>
          <ProgramDetails />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Provider_Form}
      element={
        <CustomRoute originRoute={PageRoute.Provider_Form} permission={UserType.ProviderUser}>
          <ProviderForm />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Provider_Dashboard}
      element={
        <CustomRoute permission={UserType.ProviderUser}>
          <ProviderDashboard />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Provider_Profile}
      element={
        <CustomRoute permission={UserType.ProviderUser}>
          <ProviderProfile />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Take_Quiz}
      element={
        <CustomRoute permission={UserType.Student}>
          <InitialQuiz />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Congrats}
      element={
        <CustomRoute permission={UserType.Student}>
          <Congratulations />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Student_Profile}
      element={
        <CustomRoute permission={UserType.Student}>
          <StudentProfile />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Student_Stages}
      element={
        <CustomRoute permission={UserType.Student}>
          <StudentStages />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Developer_Settings}
      element={
        <CustomRoute permission={UserType.Developer}>
          <DeveloperSettings />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.District_Settings}
      element={
        <CustomRoute permission={UserType.DistrictAdmin}>
          <DistrictSettings />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.School_Settings}
      element={
        <CustomRoute permission={UserType.Staff}>
          <SchoolSettings />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Manage_Programs}
      element={
        <CustomRoute permission={UserType.Developer}>
          <ManagePrograms />
        </CustomRoute>
      }
    />
    <Route path={PageRoute.Transfer_Uploads} element={<TransferUploads />} />
    <Route
      path={`${PageRoute.Messages}/:studentId?`}
      element={
        <CustomRoute permission={UserType.Staff}>
          <Messages />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Mentor_Login}
      element={
        <CustomRoute permission={-1}>
          <MentorLogin />
        </CustomRoute>
      }
    />
    <Route
      path={PageRoute.Mentor_Dashboard}
      element={
        <CustomRoute permission={UserType.MentorUser}>
          <MentorDashboard />
        </CustomRoute>
      }
    />
    <Route
      path="/*"
      element={
        <CustomRoute permission={-1}>
          <LoginPage />
        </CustomRoute>
      }
    />
  </Routes>
);
