import React, { useState } from "react";
import { ErrorContext } from "../contexts/ErrorContext";

type Props = {
  children: JSX.Element;
};

const ErrorProvider = ({ children }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <ErrorContext.Provider value={{ errorMessage, setErrorMessage }}>
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;
