import { GetRecoilValue, atom, selector } from "recoil";
import {
  ApplicationDataRecord,
  MentorUserRecord,
  ProviderUserRecord,
  RemovedMatchRecord,
  StaffRecord,
  Student,
  StudentRecord,
} from "../types/types";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { UserType } from "../types/enums";
import { BLANK_STUDENT_FORM } from "../libraries/blankForms";
import { selectedGroupAtom } from "./districtAtoms";

const studentsInSelectedGroupGetter = ({ get }: { get: GetRecoilValue }) => {
  const students = get(allEnrolledSchoolStudentsAtom);
  const selectedGroup = get(selectedGroupAtom);
  const loggedInStaff = get(loggedInStaffAtom);
  if (!students || !loggedInStaff) return [];
  if (!selectedGroup) {
    return loggedInStaff.userType === UserType.Staff ? students : students;
  }
  return students.filter((student) => selectedGroup.studentIds.includes(student.id));
};

const staffAvatarURLGetter = async ({ get }: { get: GetRecoilValue }) => {
  const staff = get(staffAtom);
  const staffForSort = [...staff];
  const storage = getStorage();
  staffForSort.sort((a, b) => (a.lastName > b.lastName ? 1 : b.lastName > a.lastName ? -1 : 0));
  const requestPromises: Array<Promise<string | null>> = [];
  const staffWithURL: StaffRecord[] = [];

  staffForSort.map(async (staff, i) => {
    if (staff.avatar.length < 5) {
      requestPromises.push(Promise.resolve(null));
    } else {
      requestPromises.push(getDownloadURL(ref(storage, staff.avatar)));
    }
  });
  await Promise.all(requestPromises).then((results) => {
    results.forEach((result, i) => {
      staffWithURL.push({ ...staffForSort[i], avatarURL: result ? result : "" });
    });
  });
  return staffWithURL;
};

export const userTypeAtom = atom<UserType | null>({
  key: "userType",
  default: null,
});

export const staffAtom = atom<StaffRecord[]>({
  key: "staff",
  default: [],
});

export const districtStaffAtom = atom<StaffRecord[]>({
  key: "districtStaff",
  default: [],
});

export const staffWithURLAtom = selector({
  key: "staffWithURL",
  get: staffAvatarURLGetter,
  // set: ({ set }, newValue) => {
  //   set(staffLoadingAtom, false);
  // },
});

export const loggedInStaffAtom = atom<StaffRecord | null>({
  key: "loggedInStaff",
  default: null,
});

export const staffResetAtom = atom({
  key: "staffReset",
  default: false,
});

export const staffFormAtom = atom<StaffRecord | null>({
  key: "staffForm",
  default: null,
});

export const studentFormAtom = atom<StudentRecord | Student>({
  key: "studentForm",
  default: { ...BLANK_STUDENT_FORM },
});

export const studentsResetAtom = atom({
  key: "studentsReset",
  default: false,
});

export const selectedStudentAtom = atom<StudentRecord | null>({
  key: "selectedStudent",
  default: null,
});

export const loggedInProviderAtom = atom<ProviderUserRecord | null>({
  key: "loggedInProvider",
  default: null,
});

export const loggedInMentorAtom = atom<MentorUserRecord | null>({
  key: "loggedInMentor",
  default: null,
});

export const removedMatchesAtom = atom<RemovedMatchRecord[]>({
  key: "removedMatches",
  default: [],
});

//all enrolled district students

export const allEnrolledDistrictStudentsAtom = atom<StudentRecord[]>({
  key: "allEnrolledDistrictStudents",
  default: [],
});

// all enrolled school students

export const allEnrolledSchoolStudentsAtom = atom<StudentRecord[]>({
  key: "allEnrolledSchoolStudents",
  default: [],
});

// all enrolled group students

export const allEnrolledGroupStudentsAtom = selector<StudentRecord[]>({
  key: "allEnrolledGroupStudents",
  get: studentsInSelectedGroupGetter,
});

// all school students

export const allSchoolStudentsAtom = atom<StudentRecord[]>({
  key: "allSchoolStudents",
  default: [],
});

export const studentsLoadedAtom = atom({
  key: "studentsLoaded",
  default: false,
});

export const allStudentApplicationDataAtom = atom<ApplicationDataRecord[]>({
  key: "allStudentApplicationData",
  default: [],
});

export const allEnrolledMentorStudentsAtom = atom<StudentRecord[]>({
  key: "allEnrolledMentorStudents",
  default: [],
});