import React from "react";
import Navbar from "../../shared/components/navigation/Navbar";
import { Container } from "@mui/material";
import UploadReviews from "../components/transferUploads/UploadReviews";
import AddUIDUsers from "../components/transferUploads/AddUIDUsers";
import ExportsForEmail from "../components/transferUploads/ExportsForEmail";

const TransferUploads = () => {
  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ minHeight: "calc(100VH-64px)", mt: 8, pt: 2 }}>
        <UploadReviews />
        <AddUIDUsers />
        <ExportsForEmail />
      </Container>
    </>
  );
};

export default TransferUploads;
