import { Box, Button, Typography } from "@mui/material";
import React, { MouseEvent } from "react";
import { Color } from "../../../shared/types/enums";
import { STARTER_PROMPTS } from "../../../shared/utils/aiUtils";

type Props = {
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: (newMessage: string) => void;
  resetChat: () => void;
};

const ChatSidebar = ({ setMessage, handleSubmit, resetChat }: Props) => {
  const handlePromptClick = (event: MouseEvent<HTMLElement>) => {
    const prompt = event.currentTarget.dataset.name;
    handleSubmit(prompt || "");
  };
  return (
    <Box
      sx={{
        height: "calc(100vh - 132px)",
        p: 4,
        mt: 0,
        mr: 0,
        backgroundColor: "#fff",
      }}
    >
      <Box sx={{ height: "80vh", overflow: "scroll" }}>
        <Typography variant="h3" sx={{ fontWeight: 600, mb: 2, fontSize: 16, textAlign: "center" }}>
          Below are some common questions you may have about finding the right fit for you once you
          graduate. Click one of the prompts below or enter your own custom question to get the
          conversation started.
        </Typography>
        {STARTER_PROMPTS.map((prompt, index) => (
          <Box
            key={prompt}
            sx={{
              p: 1,
              fontWeight: 300,
              border: "1px solid #1DBC9D",
              borderRadius: 4,
              backgroundColor: Color.Surface_Green,
              mt: 2,
              textAlign: "center",
              fontSize: 12,
              cursor: "pointer",
              ":hover": {
                backgroundColor: "#1DBC9D",
                color: "#fff",
              },
            }}
            data-name={prompt}
            onClick={handlePromptClick}
          >
            {prompt}
          </Box>
        ))}
      </Box>
      <Button
        onClick={resetChat}
        disableRipple
        fullWidth
        type="submit"
        variant="outlined"
        sx={{ mt: 1 }}
      >
        Reset Chat
      </Button>
    </Box>
  );
};

export default ChatSidebar;
