import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { SyntheticEvent, useState } from "react";
import Papa from "papaparse";
import { Collection } from "../../../shared/types/enums";
import useAddDoc from "../../../shared/hooks/useAddDoc";

type UploadDictionary = {
  [key in Column]: string;
};
type Record = {
  cipCode: string;
  programName: string;
  socCode: string;
  socTitle: string;
  growth: string;
  newOpenings: number;
  willowSubIndustryId: string;
};

enum Column {
  cipCode = "cipCode",
  programName = "programName",
  socCode = "socCode",
  socTitle = "socTitle",
  growth = "growth",
  newOpenings = "newOpenings",
  willowSubIndustryId = "willowSubIndustryId",
}

type FormState = EventTarget & {
  name: string;
  value: string;
  files: File[];
};

type AddDocReturn = {
  id: string;
  createdAt: string;
  lastUpdatedAt: string;
};
const UploadSOCData = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [loading, setLoading] = useState(false);
  const { sendRequest: addDoc } = useAddDoc();

  const changeHandler = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSelectedFile(formState.files[0]);
  };

  const handleSubmission = async () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      setLoading(true);

      Papa.parse<UploadDictionary>(selectedFile, {
        complete: async function (results) {
          const length = results.data.length;
          const promises: Promise<AddDocReturn | null>[] = [];

          for (let index = 0; index < length; index++) {
            const value: UploadDictionary = results.data[index];
            const record: Record = {
              cipCode: value[Column.cipCode],
              programName: value[Column.programName],
              socCode: value[Column.socCode],
              socTitle: value[Column.socTitle],
              growth: value[Column.growth],
              newOpenings: parseInt(value[Column.newOpenings]),
              willowSubIndustryId: value[Column.willowSubIndustryId],
            };
            try {
              promises.push(addDoc({ col: Collection.SOC_CIP, data: record }));
            } catch (error) {
              console.error("Error during batch updates:", error);
            }
          }
          await Promise.all(promises);
          setLoading(false);
        },
        header: true,
      });
    }
  };
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ mt: 3 }}>
          <input
            id="uploadSOCData"
            type="file"
            name="file"
            style={{ display: "none" }}
            onChange={changeHandler}
          />
          <label htmlFor="uploadSOCData">
            {" "}
            <Button variant="contained" sx={{ mt: 3 }} component="span">
              Upload SOC Data
            </Button>
          </label>
          {!loading ? (
            <Button
              variant="contained"
              color="secondary"
              sx={{ mt: 3, ml: 2 }}
              onClick={handleSubmission}
            >
              Submit
            </Button>
          ) : (
            <CircularProgress />
          )}
          {selectedFile && <Typography>{selectedFile.name}</Typography>}
        </Box>
      </Box>
    </>
  );
};

export default UploadSOCData;
