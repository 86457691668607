import { Button, Box, Divider } from "@mui/material";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "../../recoil/userAtoms";
import { useAuth } from "../../contexts/AuthContext";
import MentorSelectSection from "./MentorSelectSection";

export default function MentorMenuDropdown() {
  const { logout } = useAuth();
  const userType = useRecoilValue(userTypeAtom);

  return (
    <>
      {userType && (
        <>
          <Box sx={{ padding: 2, textAlign: "center" }}>
            <MentorSelectSection />
            <Divider />
            <Button
              sx={{ mt: 2, p: 2 }}
              variant="outlined"
              fullWidth
              id="logout-button"
              color="primary"
              onClick={() => logout()}
            >
              Logout
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
