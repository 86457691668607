import { Container } from "@mui/material";
import SignupNavbar from "../../students/components/signup/SignupNavbar";
import ProviderFormContainer from "../components/providerForm/ProviderFormContainer";

const ProviderForm = () => {
  console.log("Provider Form Page Rendered")
  return (
    <>
      <SignupNavbar />
      <Container maxWidth="md" sx={{ mt: 8 }}>
        <ProviderFormContainer />
      </Container>
    </>
  );
};

export default ProviderForm;
