import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";
import { Color, PageRoute } from "../../types/enums";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "../../recoil/userAtoms";
import { Box } from "@mui/material";
import FeatherIcon from "../FeatherIcon";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const StaffSidebar = ({ open, setOpen }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userType = useRecoilValue(userTypeAtom);
  return (
    <>
      {/* Dashboard */}
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          disableRipple
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => navigate(PageRoute.Staff_Dashboard)}
        >
          <Box sx={{ marginRight: open ? 3 : "auto" }}>
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="clipboard"
              strokeColor={location.pathname === PageRoute.Staff_Dashboard ? "#1DBC9D" : "#F4F0DC"}
            />
          </Box>

          <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
      {/* Messages */}
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          disableRipple
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => navigate(PageRoute.Messages)}
        >
          <Box sx={{ marginRight: open ? 3 : "auto" }}>
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="message-circle"
              strokeColor={location.pathname === PageRoute.Messages ? "#1DBC9D" : "#F4F0DC"}
            />
          </Box>
          <ListItemText primary="Messages" sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
      {/* School Settings */}
      {userType && userType > 1 && (
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            disableRipple
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(PageRoute.School_Settings)}
          >
            <Box sx={{ marginRight: open ? 3 : "auto" }}>
              <FeatherIcon
                width="24px"
                fillColor="none"
                icon="settings"
                strokeColor={
                  location.pathname === PageRoute.School_Settings ? "#1DBC9D" : "#F4F0DC"
                }
              />
            </Box>
            <ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      )}
      {/* Students By Program */}
      {userType && userType > 1 && (
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            disableRipple
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(PageRoute.Students_By_Program)}
          >
            <Box sx={{ marginRight: open ? 3 : "auto" }}>
              <FeatherIcon
                width="24px"
                fillColor="none"
                icon="list"
                strokeColor={
                  location.pathname === PageRoute.Students_By_Program ? "#1DBC9D" : "#F4F0DC"
                }
              />
            </Box>
            <ListItemText primary="Student By Program" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      )}
      {/* Search */}
      {userType && userType > 1 && (
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            disableRipple
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(PageRoute.Explore_Programs)}
          >
            <Box sx={{ marginRight: open ? 3 : "auto" }}>
              <FeatherIcon
                width="24px"
                fillColor="none"
                icon="search"
                strokeColor={
                  location.pathname === PageRoute.Explore_Programs ? "#1DBC9D" : "#F4F0DC"
                }
              />
            </Box>
            <ListItemText primary="Explore Programs" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      )}
      {/* DB Management */}
      {userType && userType >= 5 && (
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            disableRipple
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(PageRoute.Program_DB_Management)}
          >
            <Box sx={{ marginRight: open ? 3 : "auto" }}>
              <FeatherIcon
                width="24px"
                fillColor="none"
                icon="db"
                strokeColor={
                  location.pathname === PageRoute.Program_DB_Management ? "#1DBC9D" : "#F4F0DC"
                }
              />
            </Box>
            <ListItemText primary="Program Management" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      )}
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => navigate(PageRoute.Staff_Alma)}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color: location.pathname === PageRoute.Staff_Alma ? Color.Mint : Color.Chalk,
            }}
          >
            <FeatherIcon
              width="24px"
              fillColor="none"
              icon="ai"
              strokeColor={location.pathname === PageRoute.Staff_Alma ? Color.Mint : Color.Chalk}
            />
          </ListItemIcon>
          <ListItemText primary="Alma AI Advisory" sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default StaffSidebar;
