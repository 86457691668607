import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Container, Box } from "@mui/material";
import useWindowDimensions from "../../../shared/hooks/useWindowDimensions";
import { useRecoilValue } from "recoil";
import { loggedInProviderAtom } from "../../../shared/recoil/userAtoms";
import ProviderLoginForm from "./ProviderLoginForm";
import { useAuth } from "../../../shared/contexts/AuthContext";
import { PageRoute } from "../../../shared/types/enums";
import Copyright from "../../../shared/components/login/Copyright";
import { useUser } from "../../../shared/contexts/UserContext";

const ProviderLoginContainer = () => {
  const navigate = useNavigate();
  const { currentAuthUser } = useAuth();
  const { loading: userLoading } = useUser();
  const { width } = useWindowDimensions();
  const loggedInProvider = useRecoilValue(loggedInProviderAtom);

  useEffect(() => {
    if (!currentAuthUser || userLoading) return;
    if (loggedInProvider) {
      navigate(PageRoute.Provider_Dashboard);
      return;
    }
    !loggedInProvider && currentAuthUser && navigate(PageRoute.Provider_Signup);
  }, [loggedInProvider, navigate, currentAuthUser, userLoading]);

  return (
    <Container maxWidth="sm" sx={{ pt: width > 900 ? 8 : 2 }}>
      <>
        {!currentAuthUser && (
          <>
            <Box sx={{ margin: "0 auto", textAlign: "center" }}>
              <img style={{ width: "60%" }} src="../willow-logo.svg" alt="Willow" />
            </Box>
            <Paper
              sx={{
                mt: width > 900 ? 4 : 2,
                pt: width > 900 ? 4 : 1,
                pl: 4,
                pr: 4,
                pb: width > 900 ? 4 : 3,
                backgroundColor: "rgb(244,240,220)",
                borderRadius: 8,
              }}
            >
              <ProviderLoginForm />
            </Paper>
          </>
        )}
      </>
      <Box sx={{ mt: width > 900 ? 8 : 2 }}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default ProviderLoginContainer;
