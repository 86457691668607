import { atom, selector } from "recoil";
import { AnswerRecord, GPA } from "../types/types";

//when a student is logged in
export const studentAnswersAtom = atom<AnswerRecord[]>({
  key: "studentAnswers",
  default: [],
});

export const allAnswersAtom = atom<AnswerRecord[]>({
  key: "allAnswers",
  default: [],
});

export const GPAAtom = selector<GPA[]>({
  key: "gpaAtom",
  get: ({ get }) => {
    const allAnswers = get(allAnswersAtom);

    const GPAs: GPA[] = allAnswers
      .filter((answer) => {
        const isGPAQuestion = answer.questionId === "gpa";
        const hasValidGPAAnswer = Array.isArray(answer.gpa) && answer.gpa.length >= 2;
        return isGPAQuestion && hasValidGPAAnswer;
      })
      .map((answer) => ({ studentId: answer.studentId, gpa: answer.gpa as number[] }));

    return GPAs;
  },
});
