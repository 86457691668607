import { getDatabase, push, ref } from "firebase/database";
import { Message, StudentRecord } from "../types/types";

type ProcessMessagesProps = {
  messages: any;
  selectedStudent: StudentRecord;
};

export const processMessages = ({ messages, selectedStudent }: ProcessMessagesProps) => {
  if (!messages) return [];
  return Object.keys(messages)
    .map((key) => ({
      ...messages[key],
      id: key,
    }))
    .filter(
      (message) =>
        message?.studentPhoneNumber && message.studentPhoneNumber.endsWith(selectedStudent?.phone)
    )
    .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
};

type UpdateMessagesProps = {
  newMessages: any;
  existingMessages: any;
  selectedStudent: StudentRecord;
};

export const updateMessages = ({
  newMessages,
  existingMessages,
  selectedStudent,
}: UpdateMessagesProps) => {
  if (!newMessages) return existingMessages;
  const updatedMessages = processMessages({
    messages: newMessages,
    selectedStudent: selectedStudent,
  });
  const combinedMessages = [...existingMessages, ...updatedMessages];

  return combinedMessages.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );
};

type SendSMSProps = {
  to: string;
  body: string;
  staffId: string;
  studentId: string;
  studentPhoneNumber: string;
  authorId: string;
  staffPhoneNumber: string;
};

export const sendSMS = async ({
  to,
  body,
  staffId,
  studentId,
  studentPhoneNumber,
  authorId,
  staffPhoneNumber,
}: SendSMSProps) => {
  const url = `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/sendSMS`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to,
        body,
        staffId,
        timestamp: new Date().toISOString(),
        studentId,
        studentPhoneNumber,
        authorId,
        staffPhoneNumber,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || `HTTP error! status: ${response.status}`);
    }
    return data;
  } catch (error: any) {
    console.error("Error sending SMS:", error.message);
  }
};

type PutMessageInRTDBProps = {
  message: Message;
  staffId: string;
  studentId: string;
  studentPhoneNumber: string;
  authorId: string;
  staffPhoneNumber: string;
};

export const putMessageInRTDB = async ({
  message,
  staffId,
  studentId,
  studentPhoneNumber,
  authorId,
  staffPhoneNumber,
}: PutMessageInRTDBProps) => {
  const newMessage = {
    ...message,
    staffId,
    timestamp: new Date().toISOString(),
    studentId,
    studentPhoneNumber,
    authorId,
    staffPhoneNumber,
  };
  const messagePath = `messages/${staffPhoneNumber}_${studentPhoneNumber}`;

  try {
    const db = getDatabase();
    const entryRef = ref(db, messagePath);
    const newEntry = await push(entryRef, newMessage);
    return newEntry.key;
  } catch (error: any) {
    console.error("Error adding message to RTDB:", error.message);
    return "";
  }
};
