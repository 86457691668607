import React, { useState } from "react";
import FiltersPanel from "./FiltersPanel";
import { Grid } from "@mui/material";
import ExploreProgramsResults from "./ExploreProgramsResults";
import { Provider_Program } from "../../types/types";
import RecommendDialog from "./RecommendDialog";

const ExploreProgramsContainer = () => {
  const [loading, setLoading] = useState(false);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [recommendOpen, setRecommendOpen] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<Provider_Program | null>(null);
  const [programsToExplore, setProgramsToExplore] = useState<Provider_Program[]>([]);
  return (
    <>
      <Grid container spacing={0} sx={{ m: 0 }}>
        <Grid item xs={12} sm={3} sx={{ m: 0 }}>
          <FiltersPanel
            setProgramsToExplore={setProgramsToExplore}
            setLoading={setLoading}
            setSearchSubmitted={setSearchSubmitted}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <ExploreProgramsResults
            programsToExplore={programsToExplore}
            loading={loading}
            searchSubmitted={searchSubmitted}
            setSelectedProgram={setSelectedProgram}
            setRecommendOpen={setRecommendOpen}
          />
        </Grid>
      </Grid>
      {selectedProgram && (
        <RecommendDialog
          program={selectedProgram}
          open={recommendOpen}
          setOpen={setRecommendOpen}
        />
      )}
    </>
  );
};

export default ExploreProgramsContainer;
