import { useRecoilState, useRecoilValue } from "recoil";
import { stagesAtom } from "../../../shared/recoil/stageAtoms";
import { studentFiltersAtom } from "../../../shared/recoil/counselorDashboardAtoms";
import { ChangeEvent, useEffect } from "react";

const useStudentFiltersPanel = () => {
  const stages = useRecoilValue(stagesAtom);
  const [
    { stages: filterStages, lastActivity, gpa: GPA, grades: selectedGrades },
    setStudentFilters,
  ] = useRecoilState(studentFiltersAtom);

  useEffect(() => {
    if (!stages) return;
    setStudentFilters((pV) => ({ ...pV, stages: stages.map((stage) => stage.id) }));
  }, [setStudentFilters, stages]);

  const handleStageChange = (stageId: string) => {
    setStudentFilters((pV) => ({
      ...pV,
      stages: pV.stages.includes(stageId)
        ? pV.stages.filter((stage) => stage !== stageId)
        : [...pV.stages, stageId],
    }));
  };

  const handleLastActivityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStudentFilters((pV) => ({ ...pV, lastActivity: Number(event.target.value) }));
  };

  const handleGPAChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedGPA = String(event.target.value);
    const isChecked = event.target.checked;

    setStudentFilters((prevFilters) => {
      const currentGPAs = prevFilters.gpa || [];
      let newGPAs;
      if (isChecked) {
        newGPAs = [...currentGPAs, selectedGPA];
      } else {
        newGPAs = currentGPAs.filter((gpa) => gpa !== selectedGPA);
      }

      return { ...prevFilters, gpa: newGPAs };
    });
  };

  const handleGradeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedGrade = String(event.target.value);
    const isChecked = event.target.checked;

    setStudentFilters((prevFilters) => {
      const currentGrades = prevFilters.grades || [];
      let newGrades;
      if (isChecked) {
        newGrades = [...currentGrades, selectedGrade];
      } else {
        newGrades = currentGrades.filter((grade) => grade !== selectedGrade);
      }

      return { ...prevFilters, grades: newGrades };
    });
  };

  return {
    stages,
    filterStages,
    handleStageChange,
    lastActivity,
    handleLastActivityChange,
    GPA,
    handleGPAChange,
    handleGradeChange,
    selectedGrades,
  };
};

export default useStudentFiltersPanel;
