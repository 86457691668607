import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import DisplayBox from "../../../shared/components/DisplayBox";
import { ownProviderAtom } from "../../../shared/recoil/providerAtoms";
import { useRecoilValue } from "recoil";
import EditProviderDialog from "../../../shared/components/providerDetails/EditProviderDialog";
import useProviderDetailCard from "../../hooks/profile/useProviderDetailCard";

const ProviderDetailCard = () => {
  const ownProvider = useRecoilValue(ownProviderAtom);
  const { setGetProviderTrigger } = useProviderDetailCard();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box>
        <DisplayBox title="Provider Detail" showEditButton onClickEdit={() => setOpen(true)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 2,
            }}
          >
            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>Admission Rate: </b>
              {ownProvider?.admissionPercent ?? "NA"}
            </Typography>
            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>Total Size: </b>
              NA
            </Typography>
            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>Median ACT or SAT </b>
            </Typography>
            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>Percent Black: </b>
              {ownProvider?.percentBlack ?? "NA"}
            </Typography>
            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>Percent Latinx: </b>
              NA
            </Typography>
            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>Percent Asian: </b>
              {ownProvider?.percentAsian ?? "NA"}
            </Typography>
            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>Percent AIAN: </b>
              {ownProvider?.percentAIAN ?? "NA"}
            </Typography>
            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>Percent NHPI: </b>
              {ownProvider?.percentNHPI ?? "NA"}
            </Typography>
            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>Percent Two or More: </b>
              {ownProvider?.percentTwoOrMore ?? "NA"}
            </Typography>
            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>Percent White: </b>
              {ownProvider?.percentWhite ?? "NA"}
            </Typography>

            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>HBCU: </b>
              {ownProvider?.hbcu ? "Yes" : "No"}
            </Typography>
            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>Religious Affiliation: </b>
              {ownProvider?.religiousAffiliation}
            </Typography>
            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>Single Gender: </b>
              {ownProvider?.menOnly ? "Men Only" : ownProvider?.womenOnly ? "Women Only" : "No"}
            </Typography>
            <Typography sx={{ mt: 1, textAlign: "left" }}>
              <b>Type: </b>
              {ownProvider?.ownership}
            </Typography>
          </Box>
        </DisplayBox>
        {ownProvider && (
          <EditProviderDialog
            open={open}
            setOpen={setOpen}
            provider={ownProvider}
            setGetProviderTrigger={setGetProviderTrigger}
          />
        )}
      </Box>
    </>
  );
};

export default ProviderDetailCard;
