import React, { ChangeEvent, useEffect, useState } from "react";
import { Message } from "../../../shared/types/types";
import { BLANK_MESSAGE_FORM } from "../../../shared/libraries/blankForms";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom, selectedStudentAtom } from "../../../shared/recoil/userAtoms";
import { putMessageInRTDB, sendSMS, updateMessages } from "../../../shared/utils/messageFunctions";
import { getDatabase, ref, onValue } from "firebase/database";

export const useMessagePanel = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState({ ...BLANK_MESSAGE_FORM });
  const [toggle, setToggle] = useState(false);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  //subscribe to all messages for this student
  useEffect(() => {
    if (!loggedInStaff || !selectedStudent) return;
    const db = getDatabase();
    const messagesRef = ref(db, `messages/${loggedInStaff.smsNumber}_${selectedStudent.phone}`);

    let initialMessages: any = [];

    const unsubscribeIncomingMessages = onValue(messagesRef, (snapshot) => {
      const newData = snapshot.val();
      setMessages((prevMessages) =>
        updateMessages({
          newMessages: newData,
          existingMessages: initialMessages,
          selectedStudent: selectedStudent,
        })
      );
    });

    return () => {
      // unsubscribeMessages();
      unsubscribeIncomingMessages();
    };
  }, [loggedInStaff, selectedStudent, toggle]);

  //the message path is always staff phone number, then student phone number
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!loggedInStaff || !selectedStudent) return;

    const updatedMessage = {
      ...message,
      message: `${message?.message}\nFrom ${loggedInStaff.firstName} ${loggedInStaff.lastName}`
    };

    const to = selectedStudent?.phone;
    const body = updatedMessage.message;

    sendSMS({
      to,
      body,
      staffId: loggedInStaff.id,
      studentId: selectedStudent?.id,
      studentPhoneNumber: selectedStudent?.phone,
      authorId: loggedInStaff.id,
      staffPhoneNumber: loggedInStaff.smsNumber,
    });

    putMessageInRTDB({
      message: updatedMessage,
      staffId: loggedInStaff.id,
      studentId: selectedStudent?.id,
      studentPhoneNumber: selectedStudent?.phone,
      authorId: loggedInStaff.id,
      staffPhoneNumber: loggedInStaff.smsNumber,
    });
    setMessage({ ...BLANK_MESSAGE_FORM });
    setToggle((pV) => !pV);
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage({ ...message, message: event.target.value });
  };

  return { handleTextChange, handleSubmit, messages, message, loggedInStaff };
};
