import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../recoil/userAtoms";
import { studentAnswersAtom } from "../../recoil/answersAtoms";
import { HollandType } from "../../types/enums";
import { questionsAtom } from "../../recoil/questionAtoms";
import { determineHollandResults } from "../../utils/functions";

const HollandResultsContainer = () => {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const answers = useRecoilValue(studentAnswersAtom);
  const questions = useRecoilValue(questionsAtom);
  const [hollandType, setHollandType] = useState<HollandType | null>(null);

  useEffect(() => {
    if (!selectedStudent || !answers || !questions) return;
    const hollandAnswers = answers.filter((ans) => ans.questionId.includes("holland"));
    const highest = determineHollandResults({
      answers: hollandAnswers,
      questions,
    });
    if (!highest) return;
    setHollandType(highest);
  }, [answers, selectedStudent, questions]);
  return (
    <>
      <Container maxWidth="md">
        {hollandType === HollandType.Realistic && (
          <>
            <Box sx={{ textAlign: "center" }}>
              <img
                src="../Holland-Realist.svg"
                alt="realistic"
                width="400px"
                style={{ maxWidth: "100%" }}
              />
            </Box>

            <Typography textAlign={"center"} variant="h5">
              You tend to be a Practical Problem-Solver.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../star.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                You are a realist that tends to excel in environments that allow you to work with
                tools, machines, or the outdoors. Practical Problem-Solvers prefer straightforward,
                direct communication, often opting for action over lengthy discussions.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../star.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                You bring a practical approach to problem-solving, leveraging technical skills to
                craft tangible and innovative solutions. Enthusiastic about physically engaging
                tasks, you may find desk-bound or abstract activities less appealing. Your grounded
                nature, combined with a love for hands-on challenges, makes you invaluable in roles
                where concrete outcomes and tangible results are essential.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img
                src="../briefcase.svg"
                alt="star-icon"
                width="30px"
                style={{ marginTop: "4px" }}
              />
              <Typography variant="body1">
                Possible Careers: Carpenter, Electrician, Mechanic, Farmer, Civil Engineer,
                Landscape Architect, Wildlife Biologist, Policeman, TSA Agent
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../ibook.svg" alt="star-icon" width="22px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                Possible College Majors: Civil Engineering, Agricultural Sciences, Environmental
                Science, Architecture
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../Badge.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                Possible Credentials: Certifications in specific trades (e.g., Certified
                Electrician, HVAC), Apprenticeships, Architectural Licensing, Solar Installer
              </Typography>
            </Box>
          </>
        )}
        {hollandType === HollandType.Investigative && (
          <>
            <Box sx={{ textAlign: "center" }}>
              <img
                src="../Holland-Investigate.svg"
                alt="Investigative"
                width="400px"
                style={{ maxWidth: "100%" }}
              />
            </Box>

            <Typography textAlign={"center"} variant="h5">
              You tend to be an Analytical Explorer.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../star.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                You are an investigative thinker who thrives when delving deep into complex
                challenges. Often communicating with precision, you value evidence-based discussions
                and critical thinking.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../star.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                Your natural curiosity combined with rigorous logic allows you to navigate the
                nuances of science, math, or technology. While you relish intellectual puzzles and
                deep research, repetitive tasks or superficial discussions might seem less engaging.
                Your pursuit of knowledge drives you to uncover truths, making you a pioneer in
                innovation and discovery.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img
                src="../briefcase.svg"
                alt="star-icon"
                width="30px"
                style={{ marginTop: "4px" }}
              />
              <Typography variant="body1">
                Possible Careers: Research Scientist, Data Analyst, Pharmacy Technician, Forensic
                Scientist, Geologist, Biotechnologist, Historian, Lab Technician
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../ibook.svg" alt="star-icon" width="22px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                Possible College Majors: Biology, Chemistry, Physics, Data Science, Environmental
                Studies, Pharmacy, Computer Science, Information Technology
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../Badge.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                Possible Credentials: AWS, IT Support (CompTIA A+), Data Analytics Certification,
                Pharmacy Tech Certification
              </Typography>
            </Box>
          </>
        )}
        {hollandType === HollandType.Artistic && (
          <>
            <Box sx={{ textAlign: "center" }}>
              <img
                src="../Holland-Artistic.svg"
                alt="Artistic"
                width="400px"
                style={{ maxWidth: "100%" }}
              />
            </Box>

            <Typography textAlign={"center"} variant="h5">
              You tend to be a Creative Visionary.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../star.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                As an artistic soul, you view the world through a unique lens of creativity and
                expression. With a flair for communicating with emotion, you often use metaphor and
                storytelling to convey your ideas. Your passion drives you to design, create, and
                inspire, pushing boundaries in art, music, or any form of communication. Thriving in
                open-ended, imaginative environments, you might feel stifled by strict guidelines or
                rigid structures. Your vibrant imagination brings color, innovation, and fresh
                perspectives to any endeavor.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img
                src="../briefcase.svg"
                alt="star-icon"
                width="30px"
                style={{ marginTop: "4px" }}
              />
              <Typography variant="body1">
                Possible Careers: Graphic Designer, Novelist, Musician, Film Director, Art
                Therapist, Interior Designer, Product Manager, UX/UI Designer
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../ibook.svg" alt="star-icon" width="22px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                Possible College Majors: Majors: Fine Arts, Graphic Design, Creative Writing, Film
                Studies, Music, Art Therapy
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../Badge.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                Credentials: Portfolio of work, Certification in graphic design tools (e.g., Adobe
                Creative Suite), Music or Film school certification
              </Typography>
            </Box>
          </>
        )}
        {hollandType === HollandType.Social && (
          <>
            <Box sx={{ textAlign: "center" }}>
              <img
                src="../Holland-Social.svg"
                alt="Artistic"
                width="400px"
                style={{ maxWidth: "100%" }}
              />
            </Box>

            <Typography textAlign={"center"} variant="h5">
              You tend to be an Empathetic Connector.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../star.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                You have a social inclination, driven by a desire to help, teach, and connect. Your
                communication is characterized by warmth, understanding, and active listening.
                Building relationships and harnessing a deep understanding of human emotions, you
                foster collaborative environments. You find fulfillment in roles that involve
                interpersonal interactions but might find solitary or overly competitive settings
                draining. Your innate ability to relate to others, paired with a passion for making
                a positive difference, places you at the heart of communities.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img
                src="../briefcase.svg"
                alt="star-icon"
                width="30px"
                style={{ marginTop: "4px" }}
              />
              <Typography variant="body1">
                Possible Careers: Social Worker, Counselor, Teacher, Occupational Therapist, Human
                Resources Specialist, Nurse, Paramedic
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../ibook.svg" alt="star-icon" width="22px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                Possible College Majors: Psychology, Education, Social Work, Nursing, Human
                Resources Management, Occupational Therapy
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../Badge.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                Possible Credentials: Teaching License, Licensed Clinical Social Worker (LCSW),
                Registered Nurse (RN), Human Resources Certification, Medical Assistant, Child
                Development Associate (CDA)
              </Typography>
            </Box>
          </>
        )}
        {hollandType === HollandType.Enterprising && (
          <>
            <Box sx={{ textAlign: "center" }}>
              <img
                src="../Holland-Enterprising.svg"
                alt="Enterprising"
                width="400px"
                style={{ maxWidth: "100%" }}
              />
            </Box>

            <Typography textAlign={"center"} variant="h5">
              You tend to be a Dynamic Leader.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../star.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                As an enterprising spirit, you're fueled by ambition, vision, and leadership.
                Communicating with confidence, you motivate others with your enthusiasm and vision.
                Your adeptness at persuasion enables you to seize opportunities to inspire teams and
                orchestrate change. Challenges energize you, and you lead from the front, but
                routine tasks or slow-paced environments might seem less fulfilling. Your drive,
                assertiveness, and ability to spot opportunities position you to excel in roles
                requiring bold decision-making and strategic foresight.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img
                src="../briefcase.svg"
                alt="star-icon"
                width="30px"
                style={{ marginTop: "4px" }}
              />
              <Typography variant="body1">
                Possible Careers: Business Developer, Marketing Manager, Financial Analyst, Sales
                Manager, Political Campaign Manager, Event Planner, Lawyer, Principal, Entrepreneur
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../ibook.svg" alt="star-icon" width="22px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                College Majors: Business Administration, Marketing, Finance, Political Science,
                Hospitality Management
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../Badge.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                Possible Credentials: Project Management, Certification in Digital Marketing,
                State-specific real estate licensing, Sales training programs, Banking
                certifications or training programs
              </Typography>
            </Box>
          </>
        )}
        {hollandType === HollandType.Conventional && (
          <>
            <Box sx={{ textAlign: "center" }}>
              <img
                src="../Holland-Conventional.svg"
                alt="Conventional"
                width="400px"
                style={{ maxWidth: "100%" }}
              />
            </Box>

            <Typography textAlign={"center"} variant="h5">
              You tend to be an Organizational Wizard.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../star.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                You have a conventional personality, shining brightest when structure, order, and
                detail are paramount. Communicating with clarity, you value established protocols
                and guidelines in your exchanges. Your unmatched precision allows you to craft
                organized systems and ensure efficiency.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../star.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                Enjoying predictability, you thrive in roles where your planning and organizational
                skills can be showcased. However, chaotic or overly spontaneous settings might throw
                you off balance. Your meticulous nature and love for routines and systems ensure
                stability and consistency in professional environments.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img
                src="../briefcase.svg"
                alt="star-icon"
                width="30px"
                style={{ marginTop: "4px" }}
              />
              <Typography variant="body1">
                Possible Careers: Accountant, Librarian, Database Administrator, Quality Assurance
                Tester, Health Information Technician, Actuary, Office Manager
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../ibook.svg" alt="star-icon" width="22px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                College Majors: Accounting, Library Science, Information Technology, Statistics,
                Health Informatics
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
                p: 2,
                gap: 2,
              }}
            >
              <img src="../Badge.svg" alt="star-icon" width="30px" style={{ marginTop: "4px" }} />
              <Typography variant="body1">
                Credentials: Data Science, Certified Information Systems Security Professional
                (CISSP), Actuarial Certification, Bookkeeping certifications (e.g., Certified
                Bookkeeper designation), Paralegal certificate programs, Microsoft Office Specialist
                (MOS) certification, IT certifications (e.g., CompTIA IT Fundamentals)
              </Typography>
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default HollandResultsContainer;
