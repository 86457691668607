import React, { useState } from "react";
import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "../../shared/recoil/userAtoms";
import { UserType } from "../../shared/types/enums";
import Sidebar from "../components/navigation/Sidebar";
import TopNavbar from "../components/navigation/TopNavbar";
import DashboardContainer from "../components/dashboard/DashboardContainer";

const Dashboard = () => {
  const userType = useRecoilValue(userTypeAtom);
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
        <Sidebar open={open} setOpen={setOpen} />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <TopNavbar open={open} title="Staff Dashboard" />
          <Box sx={{ minHeight: "calc(100VH-64px)", pt: 1, pr: 4, pl: 4 }}>
            {userType && userType >= UserType.Staff && <DashboardContainer />}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
