import React, { createContext, useContext } from "react";
import { InteractionDetails } from "../types/types";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

interface InteractionContextType {
  recordInteraction: (interactionDetails: InteractionDetails) => void;
}

// Create a Context with the default value
const InteractionContext = createContext<InteractionContextType>({
  recordInteraction: () => {},
});

export const useInteractionRecorder = () => useContext(InteractionContext);

type Props = {
  children: JSX.Element;
};

export const InteractionProvider = ({ children }: Props) => {
  // Function to record interactions
  const recordInteraction = (interactionDetails: InteractionDetails) => {
    updateDoc(doc(db, "students", interactionDetails.studentId), {
      lastActivity: interactionDetails.timestamp,
    });

    // Here, you can add logic to send this data to a server, store it locally, etc.
  };

  return (
    <InteractionContext.Provider value={{ recordInteraction }}>
      {children}
    </InteractionContext.Provider>
  );
};
