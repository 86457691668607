import React from "react";
import { Program } from "../../../shared/types/types";
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Commitment, CredentialType, Modality } from "../../../shared/types/enums";
import useManageProgramForm from "../../hooks/dashboard/useManageProgramForm";

type Props = {
  form: Program;
  setForm: React.Dispatch<React.SetStateAction<Program>>;
};

const ManageProgramForm = ({ form, setForm }: Props) => {
  const {
    handleTextChange,
    handleSwitchChange,
    handleNumberChange,
    handleSelectChange,
    handleSliderChange,
    cipCodes,
  } = useManageProgramForm({ setForm });
  console.log(form);
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Please select the program category that best aligns to your program. This is necessary
              to ensure your program shows up in student searches and is matched correctly in our
              algorithm that pairs students with programs aligned to their interests.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="programName"
              fullWidth
              onChange={handleTextChange}
              label="Program Name"
              value={form.programName}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth required>
              <Autocomplete
                id="cipCode-select"
                options={cipCodes}
                getOptionLabel={(option) => option.cipTitle}
                value={cipCodes.find((c) => c.cipCode === form.cipCode) ?? null}
                onChange={(e, value) => {
                  setForm((pV) => ({ ...pV, cipCode: value?.cipCode ?? "" }));
                }}
                renderInput={(params) => <TextField {...params} label="Program Category" />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth required>
              <InputLabel id="credential-level-select-label">Credential Level</InputLabel>
              <Select
                fullWidth
                id="credential-level-select"
                name="credentialLevel"
                onChange={handleSelectChange}
                value={form?.credentialLevel ?? ""}
                label="Credential Level"
              >
                <MenuItem value={CredentialType.Certificate}>{CredentialType.Certificate}</MenuItem>
                <MenuItem value={CredentialType.Bachelor}>{CredentialType.Bachelor}</MenuItem>
                <MenuItem value={CredentialType.Associates}>{CredentialType.Associates}</MenuItem>
                <MenuItem value={CredentialType.Apprenticeship}>
                  {CredentialType.Apprenticeship}
                </MenuItem>
                <MenuItem value={CredentialType.Credential}>{CredentialType.Credential}</MenuItem>
                <MenuItem value={CredentialType.Skill}>{CredentialType.Skill}</MenuItem>
                <MenuItem value={CredentialType.UndergradCert}>
                  {CredentialType.UndergradCert}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="programShortDescription"
              multiline
              required
              rows={2}
              onChange={handleTextChange}
              label="Program Description"
              value={form.programShortDescription}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography>Completion Rate</Typography>
            <FormControl fullWidth required>
              <Slider
                aria-label="Completion Rate"
                value={form?.completionRateIn6Years ?? 0}
                valueLabelDisplay="auto"
                step={0.05}
                marks
                min={0}
                onChange={(event, newValue) =>
                  handleSliderChange(event, newValue, "completionRateIn6Years")
                }
                name="completionRateIn6Years"
                max={1}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Employement Rate of Graduates</Typography>
            <Slider
              aria-label="Employement Rate of Graduates"
              value={form?.rateOfEmployment ?? 0}
              valueLabelDisplay="auto"
              step={0.05}
              marks
              min={0}
              onChange={(event, newValue) =>
                handleSliderChange(event, newValue, "rateOfEmployment")
              }
              name="rateOfEmployment"
              max={1}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              required
              fullWidth
              onChange={handleNumberChange}
              type="number"
              name="durationYears"
              label="Duration of Program In Years"
              value={
                form?.durationYears !== undefined && form?.durationYears !== null
                  ? form.durationYears
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              required
              name="durationMonths"
              onChange={handleNumberChange}
              type="number"
              label="Duration of Program In Months"
              value={form?.durationMonths ?? ""}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              required
              onChange={handleNumberChange}
              name="averageTotalCost"
              label="Average Total Cost of Program"
              value={form?.averageTotalCost ?? ""}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              fullWidth
              type="number"
              required
              onChange={handleNumberChange}
              name="startingSalary"
              label="Estimated Average Starting Salary of Graduates"
              value={form?.startingSalary ?? ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="departmentLink"
              onChange={handleTextChange}
              fullWidth
              label="Department URL"
              value={form.departmentLink}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleTextChange}
              name="applyURL"
              required
              fullWidth
              label="Apply URL"
              value={form.applyURL}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="modality-select-label">Modality</InputLabel>
              <Select
                name="modality"
                fullWidth
                onChange={handleSelectChange}
                id="modality-select"
                value={form?.modality ?? Modality.Unknown}
                label="Modality"
              >
                <MenuItem value={0}>{Modality.Unknown}</MenuItem>
                <MenuItem value={1}>{Modality.AllInPerson}</MenuItem>
                <MenuItem value={2}>{Modality.Mix}</MenuItem>
                <MenuItem value={3}>{Modality.SomeInPerson}</MenuItem>
                <MenuItem value={3}>{Modality.AllOnline}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="commitment-select-label">Commitment</InputLabel>
              <Select
                fullWidth
                name="commitment"
                id="commitment-select"
                value={
                  form.commitment !== "null"
                    ? form?.commitment ?? Commitment.Unknown
                    : Commitment.Unknown
                }
                label="Commitment"
                onChange={handleSelectChange}
              >
                <MenuItem value={Commitment.FullTime}>{Commitment.FullTime}</MenuItem>
                <MenuItem value={Commitment.PartTime}>{Commitment.PartTime}</MenuItem>
                <MenuItem value={Commitment.Both}>{Commitment.Both}</MenuItem>
                <MenuItem value={Commitment.Unknown}>{Commitment.Unknown}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch onChange={handleSwitchChange} name="autoAdmit" checked={form.autoAdmit} />
              }
              label="Do you want us to automatically admit students interested in your program?"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleSwitchChange}
                  name="autoScholarship"
                  checked={form.autoScholarship}
                />
              }
              label="Do you guaruntee scholarships to students interested in your program?"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ManageProgramForm;
