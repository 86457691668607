import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Container, Box } from "@mui/material";
import Copyright from "./Copyright";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useRecoilValue } from "recoil";
import {
  loggedInStaffAtom,
  loggedInProviderAtom,
  selectedStudentAtom,
  userTypeAtom,
  loggedInMentorAtom,
} from "../../recoil/userAtoms";
import LoginForm from "./LoginForm";
import { useAuth } from "../../contexts/AuthContext";
import { PageRoute } from "../../types/enums";
import { useUser } from "../../contexts/UserContext";

const LoginContainer = () => {
  const navigate = useNavigate();
  const { currentAuthUser } = useAuth();
  const { loading: userLoading } = useUser();
  const { width } = useWindowDimensions();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const loggedInProvider = useRecoilValue(loggedInProviderAtom);
  const loggedInMentor = useRecoilValue(loggedInMentorAtom);
  const userType = useRecoilValue(userTypeAtom);

  useEffect(() => {
    if (!currentAuthUser || userLoading) return;
    if (!userType) {
      console.log("Navigate to Student Onboarding");
      navigate(PageRoute.Student_Onboarding);
      return;
    }
    if (loggedInStaff) {
      navigate(PageRoute.Staff_Dashboard);
      return;
    }
    if (selectedStudent) {
      navigate(PageRoute.Student_Dashboard);
      return;
    }
    if (loggedInProvider) {
      navigate(PageRoute.Provider_Dashboard);
      return;
    }
    if (loggedInMentor) {
      navigate(PageRoute.Mentor_Dashboard);
      return;
    }
    !loggedInStaff &&
      !selectedStudent &&
      !loggedInProvider &&
      !loggedInMentor &&
      currentAuthUser &&
      navigate(PageRoute.Signup);
  }, [
    loggedInStaff,
    selectedStudent,
    loggedInProvider,
    loggedInMentor,
    navigate,
    currentAuthUser,
    userType,
    userLoading,
  ]);

  return (
    <Container maxWidth="sm" sx={{ pt: width > 900 ? 8 : 2 }}>
      <>
        {!currentAuthUser && (
          <>
            <Box sx={{ margin: "0 auto", textAlign: "center" }}>
              <img style={{ width: "60%" }} src="../willow-logo.svg" alt="Willow" />
            </Box>
            <Paper
              sx={{
                mt: width > 900 ? 4 : 2,
                pt: width > 900 ? 4 : 1,
                pl: 4,
                pr: 4,
                pb: width > 900 ? 4 : 3,
                backgroundColor: "rgb(244,240,220)",
                borderRadius: 8,
              }}
            >
              <LoginForm />
            </Paper>
          </>
        )}
      </>
      <Box sx={{ mt: width > 900 ? 8 : 2 }}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default LoginContainer;
