import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Color } from "../../types/enums";

const CareerTable = () => {
  return (
    <>
      <Box sx={{ backgroundColor: "#BBEBE1", height: 200, p: 2, position: "relative" , m: 2}}>
        <Box
          sx={{
            backgroundColor: "rgba(150, 150, 150, 0.2)",
            position: "absolute",
            top: 20,
            left: "35%",
            width: "45%",
            height: 205,
          }}
        ></Box>
        <Typography sx={{ fontSize:22, fontWeight: 600, mb: 0 }}>Potential Careers</Typography>
        <Grid container spacing={0}>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <Typography textAlign={"center"} sx={{ fontWeight: 800, fontSize: 12 }}>
              ESTIMATED SALARY
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <Grid container spacing={0} sx={{ borderBottom: `3px solid ${Color.Charcoal}` }}>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <Typography textAlign={"center"} sx={{ fontWeight: 600, fontSize: 12 }}>
              ENTRY
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography textAlign={"center"} sx={{ fontWeight: 600, fontSize: 12 }}>
              MEDIUM
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography textAlign={"center"} sx={{ fontWeight: 600, fontSize: 12 }}>
              HIGH
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography textAlign={"center"} sx={{ fontWeight: 600, fontSize: 12 }}>
              JOB PROSPECT
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0} sx={{ borderBottom: `1px solid ${Color.Charcoal}` }}>
          <Grid item xs={4} sx={{ padding: 1 }}>
            <Typography textAlign={"left"} sx={{ fontWeight: 400, fontSize: 12 }}>
              Fashion Designer
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
              $34,660
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
              $76,700
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
              $139,920
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
              Medium
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0} sx={{ borderBottom: `1px solid ${Color.Charcoal}` }}>
          <Grid item xs={4} sx={{ padding: 1 }}>
            <Typography textAlign={"left"} sx={{ fontWeight: 400, fontSize: 12 }}>
              Graphic Designer
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
              $35,430
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
              $57,990
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
              $100,920
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
              Medium
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0} sx={{ borderBottom: `1px solid ${Color.Charcoal}` }}>
          <Grid item xs={4} sx={{ padding: 1 }}>
            <Typography textAlign={"left"} sx={{ fontWeight: 400, fontSize: 12 }}>
              Illustrator
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
              $28,440
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
              $57,560
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
              $124,670
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
              Medium
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CareerTable;
