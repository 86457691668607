import React, { Suspense } from "react";
import { Alert, Box, Divider, Paper, Typography } from "@mui/material";
import ProgramTable from "./ProgramTable";
import ProgramTableTools from "./ProgramTableTools";
import useProviderDashboardContainer from "../../hooks/dashboard/useProviderDashboardContainer";
import DisplayBox from "../../../shared/components/DisplayBox";
import ManageProgramDialog from "./ManageProgramDialog";
import ManageProviderDialog from "./ManageProviderDialog";

const ProviderDashboardContainer = () => {
  const {
    user,
    provider,
    programRows,
    selectedProgram,
    openProviderDialog,
    setOpenProviderDialog,
    openProgramDialog,
    setOpenProgramDialog,
    setGetProgramTrigger,
    onAddClick,
    onRowClick,
    handleProviderEditClick,
  } = useProviderDashboardContainer();

  return (
    <>
      {provider && (
        <>
          <DisplayBox title="Provider Details" showEditButton={true} onClickEdit={handleProviderEditClick}>
            <Typography variant="h6">{provider.providerName}</Typography>
            <Typography variant="body1">{provider.providerDescription}</Typography>
          </DisplayBox>
          {programRows && programRows.length === 0 && (
            <Alert severity="info" sx={{ mt: 2 }}>
              No programs found. Click on the Add Program button to add a program.
            </Alert>
          )}
          <Paper sx={{ backgroundColor: "#fff", borderRadius: 1.5, mt: 2 }}>
            <Box sx={{ padding: 1 }}>
              <Suspense fallback={<div>Loading...</div>}>
                <ProgramTableTools onClickAddProgram={() => onAddClick()} />
              </Suspense>
            </Box>
            <Divider />
            <ProgramTable onClickEditProgram={(row) => onRowClick(row)} />
            <ManageProgramDialog
              open={openProgramDialog}
              setOpen={setOpenProgramDialog}
              provider={provider}
              program={selectedProgram}
              setGetProgramTrigger={setGetProgramTrigger}
            />
            {user && (
              <ManageProviderDialog
                open={openProviderDialog}
                setOpen={setOpenProviderDialog}
                user={user}
                provider={provider}
              />
            )}
          </Paper>
        </>
      )}
    </>
  );
};

export default ProviderDashboardContainer;
