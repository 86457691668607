import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Theme";
import { routes } from "./Routes";
import { InteractionProvider } from "./shared/providers/InteractionProvider";
import { StudentDBProvider } from "./students/providers/StudentDBProvider";
import { useRecoilValue } from "recoil";
import { siteLoadingAtom } from "./shared/recoil/loadingAtoms";
import LoadingPage from "./shared/components/LoadingPage";

function StudentBootstrap() {
  const siteLoading = useRecoilValue(siteLoadingAtom);
  return (
    <StudentDBProvider>
      <ThemeProvider theme={theme}>
        {siteLoading ? (
          <LoadingPage />
        ) : (
          <InteractionProvider>
            <Router>
              <main className="root">{routes}</main>
            </Router>
          </InteractionProvider>
        )}
      </ThemeProvider>
    </StudentDBProvider>
  );
}

export default StudentBootstrap;
