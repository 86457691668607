import { ChangeEvent, Dispatch } from "react";
import { School, SchoolRecord } from "../../../shared/types/types";
import useAddDoc from "../../../shared/hooks/useAddDoc";
import useUpdateDoc from "../../../shared/hooks/useUpdateDoc";
import { useSetRecoilState } from "recoil";
import { schoolsAtom } from "../../../shared/recoil/districtAtoms";

type Props = {
  schoolForm: SchoolRecord;
  setSchoolForm: Dispatch<React.SetStateAction<SchoolRecord>>;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const useManageSchoolDialog = ({ schoolForm, setSchoolForm, open, setOpen }: Props) => {
  const { sendRequest: addDoc } = useAddDoc();
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setSchools = useSetRecoilState(schoolsAtom);

  const handleTextChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setSchoolForm((pV) => {
      return { ...pV, [name as keyof School]: value };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    setOpen(false);
    if (schoolForm.id === "") {
      const response = await addDoc({ col: "schools", data: schoolForm });
      if (!response) return;
      setSchools((pV) => [
        ...pV,
        {
          ...schoolForm,
          id: response.id,
          createdAt: response.createdAt,
          lastUpdatedAt: response.lastUpdatedAt,
        },
      ]);
    } else {
      updateDoc({ col: "schools", id: schoolForm.id, data: schoolForm });
      setSchools((pV) =>
        pV.map((school) => {
          if (school.id === schoolForm.id) {
            return { ...school, ...schoolForm };
          } else {
            return school;
          }
        })
      );
    }
  };
  return { handleTextChange, handleClose, handleSave };
};

export default useManageSchoolDialog;
