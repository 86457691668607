import { useEffect, useState } from "react";
import { Program, Provider } from "../../types/types";
import { BLANK_PROGRAM_FORM } from "../../libraries/blankForms";

type Props = {
  provider: Provider;
  program?: Program | null;
};

const useEditProgramDialog = ({ provider, program }: Props) => {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState<Program>(BLANK_PROGRAM_FORM);

  useEffect(() => {
    if (program) {
      setForm({
        id: program.id || "",
        providerId: program.providerId || "",
        durationYears: program.durationYears || null,
        durationMonths: program.durationMonths || null,
        cipCode: program.cipCode || "",
        willowIndustryId: program.willowIndustryId || "",
        willowSubIndustryId: program.willowSubIndustryId || "",
        credentialLevel: program.credentialLevel || "",
        programName: program.programName || "",
        programShortDescription: program.programShortDescription || "",
        programDescription: program.programDescription || "",
        averageTotalCost: program.averageTotalCost || null,
        commitment: program.commitment || "",
        modality: program.modality || "",
        onlineOnly: program.onlineOnly || false,
        completionRateIn6Years: program.completionRateIn6Years || null,
        startingSalary: program.startingSalary || null,
        willowROI: program.willowROI || null,
        applyURL: program.applyURL || "",
        rateOfEmployment: program.rateOfEmployment || null,
        departmentLink: program.departmentLink || "",
        programIsActive: program.programIsActive || false,
        inAlgorithm: program.inAlgorithm || false,
        autoAdmit: program.autoAdmit || false,
        autoScholarship: program.autoScholarship || false,
        willowWeight: program.willowWeight || 0,
      });
    } else {
      setForm(BLANK_PROGRAM_FORM);
    }
  }, [program]);

  useEffect(() => {
    validate();
  }, [form]);

  const validate = () => {
    if (form.programName === "") {
      setValidated(false);
      return;
    }
    if (form.cipCode === "") {
      setValidated(false);
      return;
    }
    if (form.credentialLevel === "") {
      setValidated(false);
      return;
    }

    setValidated(true);
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      if (program) {
        const response = await fetch(
          `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/updateColumns`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              tableName: "programs",
              idColumn: "id",
              id: program.id,
              data: [form],
            }),
          }
        );
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/createRows`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              tableName: "programs",
              data: [
                {
                  ...form,
                  providerId: provider.id,
                  id:
                    provider.id +
                    "_" +
                    form.cipCode +
                    "_" +
                    form.credentialLevel,
                },
              ],
            }),
          }
        );
      }
    } catch (error) {
      console.error("Upload error:", error);
    }
    setLoading(false);
  };
  return { form, setForm, handleSave, validated, loading };
};

export default useEditProgramDialog;
