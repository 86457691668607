import { atom } from "recoil";
import { QuestionRecord } from "../types/types";
import { BLANK_QUESTION_FORM } from "../libraries/blankForms";

export const questionsAtom = atom<QuestionRecord[]>({
  key: "questions",
  default: [],
});

export const questionFormAtom = atom<QuestionRecord>({
  key: "questionForm",
  default: { ...BLANK_QUESTION_FORM },
});
