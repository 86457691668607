import { useEffect, useState } from "react";
import { Provider } from "../../types/types";
import { BLANK_PROVIDER_FORM } from "../../libraries/blankForms";

type Props = {
  provider: Provider;
};

const useEditProviderDialog = ({ provider }: Props) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<Provider>(BLANK_PROVIDER_FORM);

  useEffect(() => {
    setForm({
      id: provider.id,
      providerName: provider.providerName,
      providerDescription: provider.providerDescription,
      city: provider.city,
      state: provider.state,
      region: provider.region,
      zip: provider.zip,
      homeURL: provider.homeURL,
      ownership: provider.ownership,
      locale: provider.locale,
      lat: provider.lat,
      lon: provider.lon,
      hbcu: provider.hbcu,
      menOnly: provider.menOnly,
      womenOnly: provider.womenOnly,
      religiousAffiliation: provider.religiousAffiliation,
      admissionPercent: provider.admissionPercent,
      satReadingMid: provider.satReadingMid,
      satMathMid: provider.satMathMid,
      actMid: provider.actMid,
      undergradTotalEnrollment: provider.undergradTotalEnrollment,
      percentWhite: provider.percentWhite,
      percentBlack: provider.percentBlack,
      percentHispanic: provider.percentHispanic,
      percentAsian: provider.percentAsian,
      percentAIAN: provider.percentAIAN,
      percentNHPI: provider.percentNHPI,
      percentTwoOrMore: provider.percentTwoOrMore,
      percentNonCitizens: provider.percentNonCitizens,
      currentlyOperating: provider.currentlyOperating,
      percentPell: provider.percentPell,
      address: provider.address,
      providerIsActive: provider.providerIsActive,
    });
  }, [provider]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URI}/updateColumns`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tableName: "providers",
            idColumn: "id",
            id: provider.id,
            data: [form],
          }),
        }
      );
    } catch (error) {
      console.error("Upload error:", error);
    }
    setLoading(false);
  };
  return { form, setForm, handleSave, loading };
};

export default useEditProviderDialog;
