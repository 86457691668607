import React from "react";
import LoginContainer from "../components/login/LoginContainer";
import { Container } from "@mui/material";

const Login = () => {
  console.log("Login");
  return (
    <Container maxWidth={false} sx={{ minHeight: "100VH", backgroundColor: "rgb(27,186,155)" }}>
      <LoginContainer />
    </Container>
  );
};

export default Login;
