import { GetRecoilValue, atom, selector } from "recoil";
import { DistrictRecord, GroupRecord, SchoolRecord } from "../types/types";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

export const schoolsAvatarURLGetter = async ({ get }: { get: GetRecoilValue }) => {
  const schools = get(schoolsAtom);
  const schoolsForSort = [...schools];
  const storage = getStorage();
  schoolsForSort.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  const requestPromises: Array<Promise<string | null>> = [];
  const schoolsWithURL: SchoolRecord[] = [];

  schoolsForSort.map(async (school, i) => {
    if (school.avatar.length < 5) {
      requestPromises.push(Promise.resolve(null));
    } else {
      requestPromises.push(getDownloadURL(ref(storage, school.avatar)));
    }
  });
  await Promise.all(requestPromises).then((results) => {
    results.forEach((result, i) => {
      schoolsWithURL.push({ ...schoolsForSort[i], avatarURL: result ? result : "" });
    });
  });
  return schoolsWithURL;
};

export const districtsAtom = atom<DistrictRecord[]>({
  key: "districts",
  default: [],
});

export const selectedDistrictAtom = atom<DistrictRecord | null>({
  key: "selectedDistrict",
  default: null,
});

export const schoolsAtom = atom<SchoolRecord[]>({
  key: "schools",
  default: [],
});

export const allSchoolsAtom = atom<SchoolRecord[]>({
  key: "allSchools",
  default: [],
});

export const schoolsWithURLAtom = selector({
  key: "schoolsWithURL",
  get: schoolsAvatarURLGetter,
});

export const selectedSchoolAtom = atom<SchoolRecord | null>({
  key: "selectedSchool",
  default: null,
});

export const groupsAtom = atom<GroupRecord[]>({
  key: "groups",
  default: [],
});

export const selectedGroupAtom = atom<GroupRecord | null>({
  key: "selectedGroup",
  default: null,
});
