import React from "react";
import { Box, Chip, Divider, Typography } from "@mui/material";
import usePreferencesDetails from "../../hooks/dashboard/usePreferencesDetails";

const PreferencesDetails = () => {
  const {
    selectedStudent,
    formatOnline,
    formatFinancialAid,
    formatPreferredProgram,
    formatGeography,
  } = usePreferencesDetails();

  return (
    <>
      <Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
            }}
          >
            <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
              Are you open to online programs?
            </Typography>
          </Box>
          <Chip color="surfaceGreen" sx={{ ml: 1, mb: 1 }} label={formatOnline()} />
          <Divider />
          <Box
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}
          >
            <Typography sx={{ fontWeight: "800" }} variant="body1">
              Do you qualify for financial aid?
            </Typography>
          </Box>
          {formatFinancialAid()}
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
            }}
          >
            <Typography sx={{ fontWeight: "800" }} variant="body1">
              Preferred program type:
            </Typography>
          </Box>
          <Chip
            color="surfaceGreen"
            sx={{
              ml: 1,
              mb: 1,
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
                padding: 1,
              },
            }}
            label={formatPreferredProgram()}
          />
          <Divider />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
            }}
          >
            <Typography sx={{ fontWeight: "800" }} variant="body1">
              Location
            </Typography>
          </Box>
          {formatGeography()}
          <Divider />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
            }}
          >
            <Typography sx={{ fontWeight: "800" }} variant="body1">
              Can programs you've hearted reach out to you?
            </Typography>
          </Box>
          <Chip
            color="surfaceGreen"
            sx={{
              ml: 1,
              mb: 1,
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
                padding: 1,
              },
            }}
            label={selectedStudent?.consentToContact ?? ""}
          />
        </Box>
      </Box>
    </>
  );
};

export default PreferencesDetails;
