import { useState, useEffect, useCallback } from "react";
import {
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPopup,
} from "firebase/auth";
import { auth, provider as googleAuthProvider } from "../../firebase";

import * as Sentry from "@sentry/react";
import { AuthContext } from "../contexts/AuthContext";
import useLogout from "../hooks/useLogout";
import { generateAuthError } from "../utils/authFunctions";
import { useSetRecoilState } from "recoil";
import { siteLoadingAtom } from "../recoil/loadingAtoms";

type EmailPasswordProps = { email: string; password: string };

type Props = {
  children: JSX.Element;
};

const AuthProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const { logout } = useLogout();
  const [loginError, setLoginError] = useState<string | null>(null);
  const [currentAuthUser, setCurrentAuthUser] = useState<User | null>(null);
  const setSiteLoading = useSetRecoilState(siteLoadingAtom);

  const createEmailUser = useCallback(async ({ email, password }: EmailPasswordProps) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      return error.code;
    }
  }, []);

  const resetPassword = useCallback(async (email: string) => {
    setLoginError(null);
    try {
      const resetResults = await sendPasswordResetEmail(auth, email);
    } catch (error: any) {
      console.error(error.code);
      Sentry.captureMessage("Error sending password reset email: " + error.code);
      const message = generateAuthError(error.code);
      setLoginError(message);
      return null;
    }
  }, []);

  const emailSignIn = useCallback(async ({ email, password }: EmailPasswordProps) => {
    setLoading(true);
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!isValidEmail) {
      setLoginError("Please enter a valid email address and try again.");
      setLoading(false);
      return;
    }
    try {
      const results = await signInWithEmailAndPassword(auth, email, password);
      setLoading(false);
      return results;
    } catch (error: any) {
      console.error(error.code);
      Sentry.captureMessage("Error signing in with email and password: " + error.code);
      const message = generateAuthError(error.code);
      setLoginError(message);
      setLoading(false);
      return null;
    }
  }, []);

  const signInWithGoogle = useCallback(async () => {
    setLoading(true);
    try {
      const { user } = await signInWithPopup(auth, googleAuthProvider);
      if (!user) {
        logout();
      }
    } catch (error: any) {
      console.error(error);
      Sentry.captureMessage("Error signing in with Google: " + error.message);
    }

    setLoading(false);
  }, [logout]);

  const signInWithClever = useCallback(() => {
    setLoading(true);

    let cleverAuthUrl = new URL("https://clever.com/oauth/authorize");

    cleverAuthUrl.searchParams.set("client_id", String(process.env.REACT_APP_CLEVER_CLIENT_ID));
    cleverAuthUrl.searchParams.set(
      "redirect_uri",
      String(process.env.REACT_APP_CLEVER_REDIRECT_URI)
    );
    cleverAuthUrl.searchParams.set("response_type", "code");

    window.location.href = cleverAuthUrl.href;
    setLoading(false);
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user: User | null) => {
      if (!user) {
        logout();
        setCurrentAuthUser(null);
        setSiteLoading(false);
        return;
      }
      setCurrentAuthUser(user);
    });

    return unsubscribe;
  }, [logout, setSiteLoading]);

  //   const token = await user.getIdTokenResult();
  //   const userType = token.claims.userType;

  useEffect(() => {
    if (currentAuthUser) {
      setLoading(false);
    }
  }, [currentAuthUser]);

  const value = {
    currentAuthUser,
    signInWithGoogle,
    signInWithClever,
    logout,
    loading,
    createEmailUser,
    loginError,
    emailSignIn,
    resetPassword,
    setLoginError,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
