import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useCallback } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  allEnrolledDistrictStudentsAtom,
  allEnrolledSchoolStudentsAtom,
  loggedInStaffAtom,
  staffAtom,
  userTypeAtom,
} from "../../recoil/userAtoms";
import {
  districtsAtom,
  groupsAtom,
  schoolsAtom,
  selectedDistrictAtom,
  selectedGroupAtom,
  selectedSchoolAtom,
} from "../../recoil/districtAtoms";
import { Collection, UserType } from "../../types/enums";
import useGetDocs from "../../hooks/useGetDocs";
import {
  parseAnswerResponse,
  parseGroupResponse,
  parseSchoolResponse,
  parseStaffResponse,
  parseStudentResponse,
} from "../../utils/parsers";
import {
  AnswerRecord,
  GroupRecord,
  SchoolRecord,
  StaffRecord,
  StudentRecord,
} from "../../types/types";
import { getFBDocs } from "../../utils/queries";
import { allAnswersAtom } from "../../recoil/answersAtoms";

const StaffSelectSection = () => {
  const userType = useRecoilValue(userTypeAtom);
  const districts = useRecoilValue(districtsAtom);
  const schools = useRecoilValue(schoolsAtom);
  const groups = useRecoilValue(groupsAtom);
  const [selectedDistrict, setSelectedDistrict] = useRecoilState(selectedDistrictAtom);
  const [selectedSchool, setSelectedSchool] = useRecoilState(selectedSchoolAtom);
  const [selectedGroup, setSelectedGroup] = useRecoilState(selectedGroupAtom);
  const { sendRequest: getDocs } = useGetDocs();
  const setSchools = useSetRecoilState(schoolsAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const setAllEnrolledDistrictStudents = useSetRecoilState(allEnrolledDistrictStudentsAtom);
  const setAllEnrolledSchoolStudents = useSetRecoilState(allEnrolledSchoolStudentsAtom);
  const setGroups = useSetRecoilState(groupsAtom);
  const setStaff = useSetRecoilState(staffAtom);
  const setAllAnswers = useSetRecoilState(allAnswersAtom);

  type DistrictDependentProps = {
    districtId: string;
  };

  type SchoolDependentProps = {
    schoolId: string;
    userType: UserType;
  };

  const loadDistrictDependentData = useCallback(
    async ({ districtId }: DistrictDependentProps) => {
      const dataLoaderPromises: [Promise<StudentRecord[]>] = [
        getFBDocs<StudentRecord>({
          col: Collection.Students,
          config: { where: ["districtId", "==", districtId] },
        }),
      ];
      const [students] = await Promise.all(dataLoaderPromises);
      const parsedStudents = parseStudentResponse(students);
      const parsedFilteredStudents = parsedStudents.filter(
        (student) => student.uidId && student.enrolled
      );
      setAllEnrolledDistrictStudents(parseStudentResponse(parsedFilteredStudents));
    },
    [setAllAnswers, setAllEnrolledDistrictStudents]
  );

  const loadSchoolDependentData = useCallback(
    async ({ schoolId, userType }: SchoolDependentProps) => {
      const dataLoaderPromises: [
        Promise<StudentRecord[]>,
        Promise<GroupRecord[]>,
        Promise<StaffRecord[]>,
        Promise<AnswerRecord[]>
      ] = [
        getFBDocs<StudentRecord>({
          col: Collection.Students,
          config: { where: ["schoolId", "==", schoolId] },
        }),
        getFBDocs<GroupRecord>({
          col: Collection.Groups,
          config: { where: ["schoolId", "==", schoolId] },
        }),
        getFBDocs<StaffRecord>({
          col: Collection.Staff,
          config: { where: ["schoolId", "==", schoolId] },
        }),
        getFBDocs<AnswerRecord>({
          col: Collection.Answers,
          config: { where: ["schoolId", "==", schoolId] },
        }),
      ];
      const startQuery = new Date().getTime();
      const [students, groups, staff, answers] = await Promise.all(dataLoaderPromises);
      const parsedFilteredStudents = students.filter(
        (student) => student.uidId && student.enrolled
      );
      setAllEnrolledSchoolStudents(parseStudentResponse(parsedFilteredStudents));
      const parsedStaff = staff.filter((staff) => staff.uidId);
      setStaff(parseStaffResponse(parsedStaff));
      setGroups(parseGroupResponse(groups));
      if (userType === UserType.Staff) {
        setSelectedGroup(groups.length > 0 ? groups[0] : null);
      }
      setAllAnswers(parseAnswerResponse(answers));
    },
    [setAllAnswers, setAllEnrolledSchoolStudents, setGroups, setSelectedGroup, setStaff]
  );

  const getSchoolsByDistrict = async (districtId: string) => {
    const schools = await getDocs<SchoolRecord>({
      col: "schools",
      config: { where: ["districtId", "==", districtId] },
    });
    return parseSchoolResponse(schools);
  };

  const handleDistrictChange = async ({ target: { value } }: SelectChangeEvent) => {
    if (!loggedInStaff) return;
    setSelectedDistrict(districts.find((district) => district.id === value) || null);
    const newSchools = await getSchoolsByDistrict(value);
    setSchools(newSchools);
    setSelectedSchool(newSchools[0]);
    loadDistrictDependentData({ districtId: value });
    loadSchoolDependentData({ schoolId: newSchools[0].id, userType: loggedInStaff.userType });
  };

  const handleSchoolChange = ({ target: { value } }: SelectChangeEvent) => {
    if (!loggedInStaff) return;
    setSelectedSchool(schools.find((school) => school.id === value) || null);
    loadSchoolDependentData({ schoolId: value, userType: loggedInStaff.userType });
  };

  const handleGroupChange = ({ target: { value } }: SelectChangeEvent) => {
    setSelectedGroup(groups.find((group) => group.id === value) || null);
  };
  return (
    <>
      {userType && userType >= UserType.Developer && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="select-school-label">Select District</InputLabel>
          <Select
            fullWidth
            value={selectedDistrict?.id || ""}
            onChange={handleDistrictChange}
            label="Select District"
            labelId="select-school-label"
            data-testid="select-school-dropdown"
          >
            {districts.map((district) => (
              <MenuItem key={district.id} value={district.id}>
                {district.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {userType && userType >= UserType.DistrictAdmin && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="select-school-label">Select School</InputLabel>
          <Select
            fullWidth
            value={selectedSchool?.id || ""}
            onChange={handleSchoolChange}
            label="Select School"
            labelId="select-school-label"
            data-testid="select-school-dropdown"
          >
            {schools.map((school) => (
              <MenuItem key={school.id} value={school.id}>
                {school.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {userType && userType >= UserType.Staff && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="select-school-label">Select Group</InputLabel>
          <Select
            fullWidth
            value={selectedGroup?.id || "all"}
            onChange={handleGroupChange}
            label="Select Group"
            labelId="select-group-label"
            data-testid="select-group-dropdown"
          >
            {userType >= UserType.Staff && (
              <MenuItem key="all" value="all">
                All
              </MenuItem>
            )}
            {groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default StaffSelectSection;
