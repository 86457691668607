import { createContext, useContext } from "react";

type ProviderDataContextType = {
  loading: boolean;
  sqlLoading: boolean;
};

const defaultValue: ProviderDataContextType = {
  loading: true,
  sqlLoading: true,
};

export const ProviderDataContext = createContext<ProviderDataContextType>(defaultValue);

export const useProviderData = () => {
  return useContext(ProviderDataContext);
};
