import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "../components/navigation/Navbar";
import ProgramDetailsContainer from "../components/programDetails/ProgramDetailsContainer";

const ProgramDetails = () => {
  const { programId } = useParams<{ programId: string }>();

  //   React.useEffect(() => {
  //     if (!providerId) {
  //       navigate("/providers");
  //     }
  //   }, [providerId, navigate]);
  return (
    <>
      <Navbar />
      {programId && (
        <Box sx={{ minHeight: "calc(100VH-64px)", mt: 8, pt: 2, width: "100%" }}>
          <ProgramDetailsContainer programId={programId} />
        </Box>
      )}
    </>
  );
};

export default ProgramDetails;
